import {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PrintTable from "./PrintTable";

import numeral from "numeral";
import {Table, TableBody, TableHead, TableRow, TableCell, TableContainer} from "@mui/material";
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";
import { moneyFormat, formatNumber } from "helpers/money"
import "styles/print.css";



const FeasibilityCostsWidget = () => {

    const ThCell = styled(TableCell)({
        fontSize: '0.75rem',
        fontWeight: 'bold',
       padding: '4px 16px 4px 16px',
    });

    const TbCell = styled(TableCell)({
        fontSize: '0.75rem',
        padding: '4px 16px 4px 16px',
    });

    const {section, custom, contingency, summary } = useSelector(
        (state) => state.feasibility
    );


  return (
    <>
    <Box sx={{ m: 5 }}>
      <h1 className='text-3xl'>Project costs</h1>
          <TableContainer className="mt-10">
                <Table sx={{ width: '100%' }}>
                  <TableHead>
                      <TableRow>
                        { ['Section ','Cost', 'Quantity', 'Value', 'Total']?.map((h,i) => ( <ThCell key={i}>{h}</ThCell> ))}
                      </TableRow>
                  </TableHead>

                  <TableBody >
                      {
                        section?.map((section, sectionIndex) => (
                        <Fragment key={"table-root"+sectionIndex}>
                              <TableRow key={section.name}>
                                  <TbCell colSpan={5}>{section.name}</TbCell>
                              </TableRow>

                              {
                                    section?.cost?.map((cost, costIndex) => (
                                        <TableRow key={section.name + costIndex}>
                                            <TbCell></TbCell>
                                            <TbCell>{cost.name}</TbCell>
                                            <TbCell>{formatNumber(cost.quantity)}</TbCell>
                                            <TbCell>{moneyFormat(cost.value)}</TbCell>
                                            <TbCell>{moneyFormat(cost.total)}</TbCell>
                                    </TableRow>
                              ))}
                            </Fragment>
                            )
                        )}

                        <TableRow key={section.name}>
                              <TbCell colSpan={5}>Custom costs</TbCell>
                        </TableRow>
                          {
                                custom?.map((cost, costIndex) => (
                                    <TableRow key={section.name + costIndex}>
                                        <TbCell></TbCell>
                                        <TbCell>{cost.name}</TbCell>
                                        <TbCell>{formatNumber(cost.quantity)}</TbCell>
                                        <TbCell>{moneyFormat(cost.value)}</TbCell>
                                        <TbCell>{moneyFormat(cost.total)}</TbCell>
                                </TableRow>
                          ))}

                       <TableRow key={section.name}>
                           <TbCell colSpan={5}>Contingency funds</TbCell>
                       </TableRow>

                       <TableRow key={section.name}>
                           <TbCell> </TbCell>
                           <TbCell>Contingency as fixed cost</TbCell>
                           <TbCell> </TbCell>
                           <TbCell>{moneyFormat(contingency?.fixed?.value)}</TbCell>
                           <TbCell>{moneyFormat(contingency?.fixed?.total)}</TbCell>
                        </TableRow>
                       <TableRow key={section.name}>
                           <TbCell> </TbCell>
                           <TbCell>Contingency as percentage</TbCell>
                           <TbCell> </TbCell>
                           <TbCell>{moneyFormat(contingency?.percentage?.value)}</TbCell>
                           <TbCell>{moneyFormat(contingency?.percentage?.total)}</TbCell>
                        </TableRow>
                  </TableBody>

               <TableRow key='grandTotal'>
                   <ThCell colSpan={4}>Total</ThCell>
                   <TbCell>{moneyFormat(summary?.totalCost)}</TbCell>
               </TableRow>
              </Table>
          </TableContainer>
      </Box>
    </>
  );
};

export default FeasibilityCostsWidget;
