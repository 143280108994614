import React from "react";
const EditorSidebarItem = ({ onClickHandler, icon, muiIcon, heading }) => {
  return (
    <li
      onClick={onClickHandler}
      className='flex flex-col items-center cursor-pointer'
    >
      {icon && <img src={icon} alt={icon} />}
      {muiIcon && muiIcon}
      <p className='mt-1 text-xs text-center'>
        {heading.split(" ").map((word, index) => (
          <React.Fragment key={index}>
            {word}
            <br />
          </React.Fragment>
        ))}
      </p>
    </li>
  );
};

export default EditorSidebarItem;
