import IconPercentageItem from "./IconPercentageItem";

import rocketImg from "styles/img/rocket.svg";
import busImg from "styles/img/bus.svg";
import trainImg from "styles/img/train.svg";
import taxiImg from "styles/img/taxi.svg";
import motoImg from "styles/img/moto.svg";
import walkImg from "styles/img/walk.svg";
import passengerImg from "styles/img/passenger.svg";
import bbusImg from "styles/img/bbus.svg";
import bicycleImg from "styles/img/bicycle.svg";
import homeImg from "styles/img/home.svg";

const IconPercentageList = ({ percentages }) => {
  const {
    atHome,
    bicycle,
    bus,
    driving,
    lightRail,
    motorbike,
    other,
    passenger,
    taxi,
    train,
    walking,
  } = percentages;

  return (
    <div className="flex justify-between">
      <IconPercentageItem icon={rocketImg} percentage={other} title="Other" />
      <IconPercentageItem
        icon={busImg}
        percentage={lightRail}
        title="Light Rail"
      />
      <IconPercentageItem icon={trainImg} percentage={train} title="Train" />
      <IconPercentageItem icon={taxiImg} percentage={taxi} title="Taxi" />
      <IconPercentageItem
        icon={motoImg}
        percentage={motorbike}
        title="Motorbike"
      />
      <IconPercentageItem icon={walkImg} percentage={walking} title="Walking" />
      <IconPercentageItem
        icon={passengerImg}
        percentage={passenger}
        title="Passenger"
      />
      <IconPercentageItem icon={taxiImg} percentage={driving} title="Driving" />
      <IconPercentageItem icon={bbusImg} percentage={bus} title="Bus" />
      <IconPercentageItem
        icon={bicycleImg}
        percentage={bicycle}
        title="Bicycle"
      />
      <IconPercentageItem icon={homeImg} percentage={atHome} title="At Home" />
    </div>
  );
};

export default IconPercentageList;
