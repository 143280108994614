import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)(
  ({ theme, fontSize = 15, borderradius = 5, pad = "4px 35px 4px 8px" }) => ({
    "&.MuiInputBase-root": {
      borderRadius: `${borderradius}px`,
      backgroundColor: "#fff",
      fontSize: `${fontSize}px`,

      "& .MuiSelect-select": {
        padding: `${pad}`,
      },

      "& svg": {
        fontSize: "1.8rem",
        color: "#000",
      },

      "& fieldset": {
        border: "none",
      },
    },
  })
);

export default CustomSelect;
