import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import api from "api/api";
import WhiteCard from "common/WhiteCard";
import ResetPasswordForm from "forms/ResetPasswordForm";
import Toast from "ui/Toast";

const ResetPassword = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      setIsLoading(true);

      const data = {
        password: formValues.password,
        confirmPassword: formValues.confirmPassword,
        token,
      };
      if (formValues.password !== formValues.confirmPassword) {
        setError("Passwords are not the same. Try again");
        setIsLoading(false);
        return;
      }
      const response = await api.post("customer/auth/reset-password", data);
      window.location.href = response.data.url;
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setError(error.response.data);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <>
      <WhiteCard>
        <h1 className='text-4xl font-bold text-background-blue mb-5'>
          Create a new password
        </h1>
        <h2 className='text-xl text-center mb-5'>
          Enter the email address and we'll send you a link to reset your
          password
        </h2>
        <ResetPasswordForm
          formValues={formValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
        <Link to='/login' className='mt-16 mb-8 text-sm font-bold'>
          Back to login
        </Link>
      </WhiteCard>
      <Toast
        severity='error'
        message={
          typeof error === "string"
            ? error
            : "Something went wrong. Please try again"
        }
        openToast={error}
      />
    </>
  );
};

export default ResetPassword;
