import GrayCard from "common/GrayCard";
import GrayInfoCard from "common/GrayInfoCard";
import IconPercentageList from "common/IconPercentageList";
import SocialStatusLine from "common/SocialStatusLine";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import { useSelector } from "services";

import { Divider, Skeleton } from "@mui/material";

import ChartBars from "widgets/ChartBars";
import ChartPie from "widgets/ChartPie";

const ProjectDemographic = (props) => {
  const { fetchProjectStatus } = useSelector((state) => state.projects);
  const demographic = useSelector(
    (state) => state.projects.projectData.demographic
  );
  // console.log(demographic);

  return (
    <>
      <ProjectLayoutHeader header='Demographic' />
      <Topbar />

      <div className='flex gap-3.5 mb-7'>
        {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
          <Skeleton
            variant='rectangular'
            style={{ width: "100%", height: "200px" }}
          />
        ) : (
          <>
            <GrayCard className='flex-1'>
              <h1 className='text-xl mb-2'>Social Status</h1>
              <Divider />

              <SocialStatusLine
                title='Managerial'
                percentage={demographic?.socialStatus?.managerial ?? "-"}
                className='mt-2'
              />
              <SocialStatusLine
                title='Professional'
                percentage={demographic?.socialStatus?.professional ?? "-"}
              />
              <SocialStatusLine
                title='Skilled'
                percentage={demographic?.socialStatus?.skilled ?? "-"}
              />
              <SocialStatusLine
                title='Unskilled'
                percentage={demographic?.socialStatus?.unskilled ?? "-"}
              />
            </GrayCard>

            <GrayCard className='flex-1'>
              <h1 className='text-xl mb-2'>Health</h1>
              <Divider />
              <SocialStatusLine
                title='Health'
                percentage={demographic?.health?.health ?? "-"}
                infoIcon={false}
                className='mt-2'
              />
              <SocialStatusLine
                title='Deprivation'
                percentage={demographic?.health?.deprivation ?? "-"}
                infoIcon={false}
              />
            </GrayCard>

            <GrayInfoCard
              title='Constituency'
              content={demographic?.constituency ?? "-"}
              className='flex-1'
            />
            <GrayInfoCard
              title='Degree Education (%)'
              content={demographic?.degreeEducation ?? "-"}
              className='flex-1'
              contentBig='true'
            />
          </>
        )}
      </div>

      {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
        <Skeleton
          variant='rectangular'
          style={{ width: "100%", height: "200px" }}
        />
      ) : (
        <>
          <div className='flex gap-3.5 mb-7'>
            <GrayCard className='flex-1'>
              <h1 className='text-2xl text-center mb-3.5'>Politics</h1>
              {demographic?.politics ? (
                <ChartPie stats={demographic?.politics} />
              ) : (
                <div className='flex items-center justify-center'>
                  <h1>No data</h1>
                </div>
              )}
            </GrayCard>

            <GrayCard className='flex-1'>
              <h1 className='text-2xl text-center mb-3.5'>Age Percentage</h1>
              <ChartBars age={demographic?.agePercentage ?? []} />
            </GrayCard>
          </div>
        </>
      )}

      <GrayCard>
        <h1 className='text-center text-2xl mb-2'>Commute Percentage</h1>
        {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
          <Skeleton
            variant='rectangular'
            style={{ width: "100%", height: "28px" }}
          />
        ) : demographic?.commutePercentage ? (
          <IconPercentageList percentages={demographic?.commutePercentage} />
        ) : (
          <div className='flex items-center justify-center'>
            <h1>No data</h1>
          </div>
        )}
      </GrayCard>
    </>
  );
};

export default ProjectDemographic;
