import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";


const initialState = {
        subscribeState: "idle",
        activateState: "idle"
}

export const postCustomerSubscribe = createAsyncThunk<any, any, any>(
    "post/customer/subscribe",
    async (params, thunkApi) => {
        const { subscriptionId, token } = params;

        const response = await api.post(
            `customer/subscribe/` + subscriptionId,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);

export const postCustomerSubscribeActivate = createAsyncThunk<any, any, any>(
    "post/customer/subscribe/activate",
    async (params, thunkApi) => {
        const { id, token } = params;

        const response = await api.post(
            `customer/subscribe/activate/` + id,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);


const customerSubscribeSlice = createSlice({
    name: "customerSubscribe",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(postCustomerSubscribe.fulfilled, (state, action) => {
            console.log("subscribeCustomer.fulfilled");
            state.subscribeState = "success";
        })
        .addCase(postCustomerSubscribe.pending, (state, action) => {
            console.log("subscribeCustomer.pending");
            state.subscribeState = "pending";
        })
        .addCase(postCustomerSubscribe.rejected, (state, action) => {
            console.log("subscribeCustomer.failed");
            state.subscribeState = "failed";
        })
        .addCase(postCustomerSubscribeActivate.fulfilled, (state, action) => {
            console.log("postCustomerSubscribeActivate.fulfilled");
            state.activateState = "success";
        })
        .addCase(postCustomerSubscribeActivate.pending, (state, action) => {
            console.log("postCustomerSubscribeActivate.pending");
            state.activateState = "pending";
        })
        .addCase(postCustomerSubscribeActivate.rejected, (state, action) => {
            console.log("postCustomerSubscribeActivate.failed");
            state.activateState = "failed";
        })
    },
});



export default customerSubscribeSlice;
