import { Divider, Skeleton } from "@mui/material";
import GrayCard from "common/GrayCard";
import Cookies from "js-cookie";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import ChartDoughnut from "widgets/ChartDoughnut";
import { useEffect, useState} from "react";

import { useNavigate, useParams } from "react-router-dom";
import { RootState, useDispatch, useSelector } from "services";
import {
  refreshDataAsync,
  reInitPolling,
  selectPollingLoading,
} from "services/project";

import Button from "ui/Button";
import MapProjectPolygons from "../../common/MapProjectPolygons";
import numeral from "numeral";

const ProjectOverview = (props) => {


  const { projectPolygonData, status, fetchProjectStatus } = useSelector(
    (state: RootState) => state.projects
  );

  const {fetchFeasibilityStatus, summary} = useSelector(
      (state) => state.feasibility
  );

  const { polygon_count, polygons } = (projectPolygonData as any) || {};

  const { projectData }: any = useSelector(
    (state: RootState) => state.projects
  );

  const {
    title = "",
    status: projectStatus = "",
    streetNumber = "",
    streetAddress = "",
    city = "",
    postcode = "",
    summaryText = "",
  } = projectData || {};

  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.id;
  const loading = useSelector(selectPollingLoading);
  const token = Cookies.get("propToken");
  const navigate = useNavigate();

  return (
    <>
      <ProjectLayoutHeader header="Overview" />
      <Topbar />

      <div className="flex gap-6">
        <GrayCard className="flex-1 flex flex-col">
          {fetchProjectStatus === "loading" || fetchProjectStatus === "idle" || fetchFeasibilityStatus === "loading" || fetchFeasibilityStatus === "idle"   ? (
            <Skeleton
              variant="rectangular"
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <>
              <h1 className="text-3xl font-bold pb-1.5">{title}</h1>
              <h2 className="text-xl pb-5">{projectStatus}</h2>
              <Divider />
              <ul className="pt-7 text-xl pb-8">
                <li>
                  {streetNumber} {`${streetAddress} Estate`},
                </li>
                <li>{streetAddress},</li>
                <li>{city},</li>
                <li>{postcode}</li>
              </ul>
              <p className="text-xl mb-auto">{summaryText}</p>
            </>
          )}
        </GrayCard>

        <div className="flex-1 flex flex-col justify-between">
          {projectPolygonData &&
          Object.keys(projectPolygonData).length === 0 &&
          status !== "success" ? (
            <div className="flex items-center justify-center">
              <Skeleton
                variant="rectangular"
                height={350}
                style={{ width: "100%" }}
              />
            </div>
          ) : (
            <MapProjectPolygons
              mapHeight="350px"
              className="rounded-[20px]"
              zoom={19}
              totalPolygons={polygon_count}
              polygons={polygons}
            />
          )}

          <GrayCard className="mt-6">
            <div className="flex justify-between">
              <h1 className="text-xl">Profit</h1>
              <h2 className="text-xl font-bold">
                {summary?.profit
                  ? `£${numeral(summary?.profit).format("0,0")}`
                  : ""}
              </h2>
            </div>
            <Divider />
            <div className="flex justify-between pt-11">
              <h1 className="text-xl">Total GDV</h1>
              <h2 className="text-xl font-bold">
                £{numeral(summary?.totalGdv).format("0,0")}
              </h2>
            </div>
            <Divider />
            <div className="flex justify-between pt-11">
              <h1 className="text-xl">Total Cost</h1>
              <h2 className="text-xl font-bold">
                £{numeral(summary?.totalCost).format("0,0")}
              </h2>
            </div>
            <Divider />
          </GrayCard>

        </div>
      </div>

      <div className='flex gap-6 mt-8'>
          <GrayCard className='flex-1'>
            <h1 >Profit on Cost</h1>
            <Divider />
            <ChartDoughnut
              value={summary?.profitOnCost}
              color={summary?.profitOnCost > 0 ? "#50EBBE" : "#FF4B4B"}
            />
          </GrayCard>
          <GrayCard className='flex-1'>
            <h1> Profit on GDV</h1>
            <Divider />
            <ChartDoughnut
              value={summary?.profitOnGDV}
              color={summary?.profitOnGDV > 0 ? "#50EBBE" : "#FF4B4B"}
            />
         </GrayCard>

      </div>

    </>
  );
};

export default ProjectOverview;
function formatNumber(number) {
  debugger;
  const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
  const suffixIndex = Math.floor(Math.log10(number) / 3);
  const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(0);
  return shortNumber + suffixes[suffixIndex];
}
