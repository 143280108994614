import userIcon from "styles/img/user-mini.svg";

const SocialStatusLine = (props) => {
  const { title, percentage, className, infoIcon = true } = props;

  return (
    <div className={`flex items-center mb-2 ${className}`}>
      <div className='mr-2'>
        <img src={userIcon} alt='user' />
      </div>
      <h2 className='text-sm'>
        {title}:{percentage}%
      </h2>
    </div>
  );
};

export default SocialStatusLine;
