export const leaseTypes = ["Freehold", "Leasehold"];

export const propertyTypes = ["Detached", "Semi-Detached", "Terraced", "Flat"];
export const parkingOption = [
  "Private driveway",
  "Garage",
  "Underground parking garage",
  " Covered parking",
  " Open parking lot",
  "Gated parking",
  "On-street parking",
  " Reserved parking spaces",
  "Motorcycle parking",
  "Electric vehicle charging stations+",
];
export const beds = [0, 1, 2, 3, 4, 5];
