import { Skeleton } from "@mui/material";
import GrayCard from "common/GrayCard";
import GrayInfoCard from "common/GrayInfoCard";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import { useSelector } from "services";
import ChartHorizontalBars from "widgets/ChartHorizontalBars";

const ProjectCrime = (props) => {
  const { fetchProjectStatus } = useSelector((state) => state.projects);
  const text = useSelector((state) => state.projects.projectData);
  const crime = useSelector((state) => state.projects.projectData.crime);

  return (
    <>
      <ProjectLayoutHeader header='Crime' />
      <Topbar />
      <div className='flex content-between mb-6 gap-[70px]'>
        {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
          <Skeleton
            variant='rectangular'
            style={{ width: "100%", height: "200px" }}
          />
        ) : (
          <>
            <GrayInfoCard
              title='Rating'
              content={crime?.rating || "No data"}
              className='flex-1'
              contentBig='true'
            />
            <GrayInfoCard
              title='Last 12 Months'
              content={crime?.lastTwelveMonths || "No data"}
              className='flex-1'
              contentBig='true'
            />
            <GrayInfoCard
              title='Per 1000'
              content={crime?.perOneThousand || "No data"}
              className='flex-1'
              contentBig='true'
            />
          </>
        )}
      </div>
      {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
        <Skeleton
          variant='rectangular'
          style={{ width: "100%", height: "120px" }}
        />
      ) : (
        <GrayInfoCard
          title='Observations'
          className='flex-1 mb-6'
          content={crime?.observations?.join(". ") || "No data"}
        />
      )}
      <GrayCard>
        <h1 className='text-center text-lg'>
          Crime Statistics (last 12 months)
        </h1>
        {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
          <Skeleton
            variant='rectangular'
            style={{ width: "100%", height: "100%" }}
          />
        ) : crime?.crimeStatistics ? (
          <ChartHorizontalBars
            stats={crime?.crimeStatistics ? crime.crimeStatistics : []}
          />
        ) : (
          <div className='flex items-center justify-center'>
            <h1>No data available</h1>
          </div>
        )}
      </GrayCard>
    </>
  );
};

export default ProjectCrime;
