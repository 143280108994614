import AccountDetailsInfo from "common/AccountDetailsInfo";
import AccountBillingInfo from "common/AccountBillingInfo";
import AccountDetailsHeader from "common/AccountDetailsHeader";

const AccountDetails = (props) => {
  return (
    <div className="my-12 mx-7">
      <AccountDetailsHeader back="/dashboard/account/subscription" />

      <AccountDetailsInfo title="Payment method" />
      <AccountBillingInfo title="Billing address" className="mt-14" />
    </div>
  );
};

export default AccountDetails;
