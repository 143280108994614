import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PrintTable from "./PrintTable";
import {moneyFormat, formatNumber} from "helpers/money"
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";
import GrayCard from "common/GrayCard";
import {Table, TableBody, TableHead, TableRow, TableCell, TableContainer} from "@mui/material";


import numeral from "numeral";


const PredictedValueWidget = ({}) => {

    const ThCell = styled(TableCell)({
        fontSize: '0.75rem',
        fontWeight: 'bold',
       padding: '4px 16px 4px 16px',
    });

    const TbCell = styled(TableCell)({
        fontSize: '0.75rem',
        padding: '4px 16px 4px 16px',
    });

    const { values } = useSelector((state) => state.predictedValues);
    const { templates }  = useSelector((state) => state.property);

    const [showTable, setShowTable] = useState(false)

    const [data, setData] = useState(null)
    const [templateMap, setTemplateMap] = useState(null)

    var keys = ['address','saleDate', 'distance', 'sqft', 'price', 'pricePerSqFt']
    var headers =[
                                   'Address',
                                   'Date sold',
                                   'Distance',

                                   'Area(ft²)',
                                   'Price',
                                   'Price/ft²',
                                   ]

   useEffect(() => {
         console.log("UE:[values]")
         setData(values.filter((row) => row['sales'] != null))
         setShowTable(true)
   },[values]);

    useEffect(() => {
        console.log("UE:[templates]")
        console.log("--"+values)
        var tn = {}
        templates.forEach((v) => tn[v['id']] = v)
        setTemplateMap(tn)
    },[templates]);


  return (
    <>
     <h1 className='text-3xl pl-5'>Sale Study</h1>
     {

        data?.map((value, idx) => (
             <Box sx={{ m: 5 }}>
                             <span className='text-xl'>Template: </span><span>{templateMap[value['templateId']]['templateName']}</span><br/>
                             <span className='text-xl'>Average price: </span><span>{moneyFormat(value['value'])}</span>
                      <TableContainer>
                            <Table sx={{ width: 'auto' }}>
                              <TableHead>
                                  <TableRow>
                                    { headers?.map((h,i) => ( <ThCell key={i}>{h}</ThCell> ))}
                                  </TableRow>
                              </TableHead>
                              <TableBody >
                                  {
                                      value.sales?.map(
                                          (v, i) => (
                                              <TableRow key={i}>
                                                  { keys?.map((k,c) => ( <TbCell key={c}> {v?.[k]} </TbCell> ))}
                                              </TableRow>
                                          )
                                      )}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Box>
           ))
       }
    </>
  )
};

export default PredictedValueWidget;