import Header from "typography/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useSelector } from "services";
import { fetchCustomerAll } from "services/customerAdmin";
import ReactJson from 'react-json-view'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowsProp } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import UserTable from "admin/UserTable";
import OuterBox from "admin/OuterBox";
import StatCard from "admin/StatCard";

import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { fetchSales } from "services/SalesService";



const Sales = () => {

    const dispatch = useDispatch();
    let token = Cookies.get("propToken");

    const { sales } = useSelector((state) => state.sales);
    const [ salesRetrieved, setSalesRetrieved] = useState(false);
    const [ rows, setRows] = useState([]);

    const [ search, setSearch] = useState({
        token: Cookies.get("propToken"),
        postcode: "CF15 8FG",
        radius: 1,
        saleAge: 12,
        bedrooms: 0,
        propertyType: "all"
    });

    useEffect(() => {
        console.log("UE:[]")
        dispatch(fetchSales({
                                    token: token,
                                    postcode: "CF15 8FG",
                                    radius: 1,
                                    saleAge: 12,
                                    bedrooms: 0,
                                    propertyType: "all"
                                }));
    }, []);

    useEffect(() => {
        console.log("UE:[sales] ")

        setRows(sales.map((s) => {
            var sale = {...s}
            sale.id = s.addressKey
            return sale

        } ))
        //console.log(JSON.stringify(sales))

        //if (sales.status === "success") {
            setSalesRetrieved(true)
       // }
    }, [sales]);


        const columns: GridColDef[] = [
           // { field: 'id', headerName: 'id', width: 10 },
            { field: 'postcode', headerName: 'Postcode', width: 150},
            { field: 'saleDate', headerName: 'Sale date',width: 150},
            { field: 'price', headerName: 'Price',width: 150},
            { field: 'distance', headerName: 'Distance',width: 150}
        ];

  return (
    <>
          {
             salesRetrieved ? (
                 <>
                 <div style={{ height: 400, width: '50%' }}>
                          <Box sx={{ border: 1, borderRadius: 2, borderColor: '#DDDDDD', m: 2, backgroundColor: '#FCFCFC'}}
                               component="form"
                               noValidate
                               autoComplete="off"
                             >
                    <DataGrid rows={rows} columns={columns} />
                 </Box>
                 </div>
                 </>
             ) : (
             <div>Loading.....</div>
             )
         }
    </>
  );
};

export default Sales;

    /*
            "id": null,
            "searchHash": null,
            "createdAt": null,
            "lastModifiedAt": null,
            "postcode": "CF15 8LL",
            "saleDate": "2023-09-27",
            "address": "20, Chapel Road, Morganstown, Cardiff CF15 8LL",
            "price": 200000,
            "type": "Terraced",
            "tenure": "Freehold",
            "distance": 0.39,
            "bedrooms": 2,
            "sqft": 581,
            "pricePerSqFt": 344,
            "lat": 51.5261395,
            "lng": -3.2603073,
            "normalisedAddress": "20CHACF158LL",
            "uprn": "100100137719",
            "addressKey": "20CHACF158LL"
    */
