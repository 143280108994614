import { nanoid } from "nanoid";

import { colors } from "helpers/colors";
import { useState } from "react";

const ColorPicker = ({ className, handleColorChange }) => {
  const [selectedColor, setSelectedColor] = useState("");

  const selectColor = (evt) => {
    const color = evt.target.getAttribute("value");
    setSelectedColor(color);
    handleColorChange(color);
  };

  return (
    <div
      className={`bg-total-white rounded-xl pt-4 pr-8 pb-14 pl-3 ${className}`}
    >
      <h2 className="mb-4 text-lg">Choose a colour</h2>
      <ul className="flex gap-4 flex-wrap">
        {colors.map((c) => (
          <li
            key={nanoid()}
            style={{
              backgroundColor: `${c}`,
              width: "28px",
              height: "26px",
              borderRadius: "5px",
              border: ` ${
                c === "#fff" ? "0.5px solid #d9d9d9" : "0.5px solid transparent"
              }`,
              outline: `${selectedColor === c ? "1px solid #000" : ""}`,
              scale: `${selectedColor === c ? "1.1" : "1"}`,
              cursor: "pointer",
            }}
            value={c}
            onClick={selectColor}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default ColorPicker;
