const CloseButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute text-lg bg-green py-1 px-3 text-total-white right-4 top-3 cursor-pointer rounded"
    >
      X
    </button>
  );
};

export default CloseButton;
