import PropertyTemplateForm from "forms/PropertyTemplateForm"
import Header from "typography/Header";

const PropertyTemplates = () => {

  return (
    <>
      <div className='flex justify-between items-center pb-4'>
        <Header header='Property templates' />
      </div>
          <PropertyTemplateForm/>
    </>
  );
};

export default PropertyTemplates;