import IconPercentageList from "common/IconPercentageList";
import { useSelector } from "services";
import { selectDemographic } from "services/project";

const Demographic = (props) => {
  const demographic = useSelector(selectDemographic);

  return (
    <>
      <h1 className='text-3xl leading-6 mb-10'>Commute Percentage</h1>
      <IconPercentageList percentages={demographic.commutePercentage} />
    </>
  );
};

export default Demographic;
