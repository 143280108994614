import { useEffect, useState } from "react";
import { useNavigate, Navigate, Route } from "react-router-dom";
import PricingPlanCard from "common/PricingPlanCard";
import PricingPlanSelected from "common/PricingPlanSelected";
import { plans } from "helpers/pricing-plans";
import Button from "ui/Button";
import { useDispatch } from "services";
import { postCustomerSubscribe } from "services/CustomerSubscribeService";
import Cookies from "js-cookie";
import { useSelector } from "services";

const PricingPlans = (props) => {

  const token = Cookies.get("propToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ plan, setPlan] = useState(null);
  const { subscribeState  } = useSelector((state) => state.customerSubscribe);


// /customer/subscriptions/activate

  const handleChoosePlan = (plan) => {
    console.log("Customer subscribe to plan: "+plan.id)
    setPlan(plan)
    dispatch(postCustomerSubscribe({subscriptionId:plan.id, token}))
  };

  useEffect(() => {
        console.log("UE:[subscribeState] : " + subscribeState + " : " + plan?.id)

        if (subscribeState == "success") {
            // Free trial
            if (plan?.id == 1) navigate("/customer/subscriptions/activate")

            // Starter
            if (plan?.id == 2) window.location.replace(process.env.REACT_APP_GC_STARTER_URL)

            // Intermediate
            if (plan?.id == 3) window.location.replace(process.env.REACT_APP_GC_INTERMEDIATE_URL)
            }
    }, [subscribeState]);

  return (
    <section className='mx-7 z-10'>
      <>
          <div className="absolute bottom-16 left-12 z-30">
            <h1 className='text-center text-total-white text-5xl font-bold py-14'>
              Our plans per user
            </h1>

            <div className='flex gap-20'>
              {
                  plans.map((plan) => (
                    <PricingPlanCard
                      key={plan.id}
                      plan={plan}
                      className='flex-1'
                      handleChoosePlan={handleChoosePlan}
                    />
                  ))
              }
            </div>
          </div>
      </>
    </section>
  );
};

export default PricingPlans;
