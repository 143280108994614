import CustomInput from "ui/CustomInput";

const AccountInfoForm = ({ name, email }) => {
  return (
    <div className="flex flex-col gap-10">
    <CustomInput
          label="Name"
          arrowValue={name}
          noIcon={true}
          noArrow={true}
          type="arrow"
    />

    <CustomInput
        label="Email"
        arrowValue={email}
        noIcon={true}
        noArrow={true}
        type="arrow"
    />
    </div>
  );
};

export default AccountInfoForm;
