import Cookies from "js-cookie";
import { useEffect } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import { fetchProjectData } from "services/project";

import { fetchPropertyTemplates } from "../../services/property";
import { fetchFeasibility } from "../../services/feasibility";
import { fetchPredictedValues } from "../../services/PredictedValues";
import {fetchProjectSales, fetchUserProjects} from "services/project";


const FetchProjectData = () => {
  const token = Cookies.get("propToken");
  const params = useParams();
  const dispatch = useDispatch();
  const projectId = params.id;
   const navigate = useNavigate();

  const { projectData } = useSelector((state) => state.projects);
  const {fetchFeasibilityStatus} = useSelector((state) => state.feasibility);

  useEffect(() => {
    console.log("UE:[id] " + projectData.id + " : "+projectId )
    if (projectId != null && projectId != projectData.id) {
        console.log("UE:L:[id]")
        dispatch(fetchProjectData({ projectId, token }));
    } else if (projectData.id== projectId) {
        dispatch(fetchUserProjects({token}))
        dispatch(fetchPropertyTemplates({token}));
        dispatch(fetchFeasibility({ projectId, token}))
        dispatch(fetchPredictedValues({projectId, token}))
                   dispatch(
                        fetchProjectSales({
                            token,
                            postcode: projectData.postcode,
                            propertyTemplateId : "All",
                            salesDate : 6,
                            radius: 1,
                            projectId: projectData.id
                        }));

    }
  }, [projectData.id]);

  return (
 <>
            {
             fetchFeasibilityStatus === "loading" || fetchFeasibilityStatus === "idle" || fetchFeasibilityStatus === "initial"  ? (
                <>
                   <div> Loading </div>
                </>

            ) : (
                <>
                    <Outlet />
                </>
            )
            }
   </>
  );
};

export default FetchProjectData;
