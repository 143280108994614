import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(
  ({
    theme,
    borderradius = 15,
    fontSize = 15,
    backgroundcolor = "#fff",
    pleft = 7.5,
    border = "none",
    inputpad = "16.5px 14px",
    fontweight = "none"
  }) => ({
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: `${backgroundcolor}`,
      borderRadius: `${borderradius}px`,
      fontSize: `${fontSize}px`,
      lineHeight: "24px",

      fontWeight: `${fontweight}`,

      "& fieldset": {
        color: "#000",
        border: `${border}`,
      },

      "& input": {
        padding: `${inputpad} !important`,
      },
    },

    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "inherit",
      },
    },

    "&.MuiTextField-root": {
      "& label": {
        fontSize: `${fontSize}px`,
      },
    },

    "& .MuiAutocomplete-input": {
      paddingLeft: `${pleft}px !important`,
    },
  })
);

export default CustomTextField;
