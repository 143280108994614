import { calcIndicator } from "helpers/energy-diagram-indicator";

const EnergyClassDiagram = ({ currentEnergyEfficiency }) => {
  const { indicatorColor, indicatorTop } = calcIndicator(
    currentEnergyEfficiency
  );
  return (
    <>
      <div className="relative pr-[60px]">
        <span className="border-l-2 border-black inline-block h-[135px]"></span>
        <span className="inline-block border-b-2 border-black w-[124px]"></span>
        <div className="absolute top-0.5 left-1">
          <div className="flex flex-col gap-1">
            <span className="bg-energy-green text-outline inline-block rounded-r-lg pl-[23px] pr-1 self-start text-[10px] text-total-white">
              A
            </span>
            <span className="bg-energy-light-green text-outline inline-block rounded-r-lg pl-[37px] pr-1 self-start text-[10px] text-total-white">
              B
            </span>
            <span className="bg-energy-lighter-green text-outline inline-block rounded-r-lg pl-[51px] pr-1 self-start text-[10px] text-total-white">
              C
            </span>
            <span className="bg-energy-yellow text-outline inline-block rounded-r-lg pl-[66px] pr-1 self-start text-[10px] text-total-white">
              D
            </span>
            <span className="bg-energy-orange text-outline inline-block rounded-r-lg pl-[81px] pr-1 self-start text-[10px] text-total-white">
              E
            </span>
            <span className="bg-energy-dark-orange text-outline inline-block rounded-r-lg pl-[96px] pr-1 self-start text-[10px] text-total-white">
              F
            </span>
            <span className="bg-energy-red text-outline inline-block rounded-r-lg pl-[112px] pr-1 self-start text-[10px] text-total-white">
              G
            </span>
          </div>
        </div>

        <div
          className="absolute left-[147px]"
          style={{ top: `${indicatorTop}px` }}
        >
          <span
            className={`${indicatorColor} text-outline pl-6 text-total-white relative inline-block text-[10px] overflow-y-clip pr-1`}
          >
            {currentEnergyEfficiency}
            <span
              className={`${indicatorColor} absolute -left-2 top-[3px] h-full w-6 transform rotate-45`}
            ></span>
          </span>
        </div>
      </div>
    </>
  );
};

export default EnergyClassDiagram;
