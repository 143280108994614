import CustomTextField from "ui/CustomTextField";
import Button from "ui/Button";

const BillingAddressForm = ({
  handleSubmit,
  handleInputChange,
  formValues,
  postCodeError,
}) => {
  return (
    <div>
      <h2 className="font-bold text-xl mb-5">Payment method</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8 mb-10">
          <CustomTextField
            label="Full name *"
            type="text"
            name="fullName"
            backgroundcolor="#F0F0F0"
            value={formValues.fullName}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#000" },
            }}
            className="w-3/5"
          />
          <CustomTextField
            label="Billing address *"
            type="text"
            name="billingAddress"
            backgroundcolor="#F0F0F0"
            value={formValues.billingAddress}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#000" },
            }}
            className="w-3/5"
          />
          <div className="flex gap-7">
            <CustomTextField
              label="City *"
              type="text"
              name="city"
              backgroundcolor="#F0F0F0"
              fullWidth
              value={formValues.city}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#000" },
              }}
            />
            <CustomTextField
              label="Country *"
              type="text"
              name="country"
              backgroundcolor="#F0F0F0"
              fullWidth
              value={formValues.country}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#000" },
              }}
              inputProps={{
                maxLength: 8, // Limit the input length to 8 characters (including dashes)
              }}
            />
          </div>
          <CustomTextField
            label="Post code *"
            type="text"
            name="postCode"
            backgroundcolor={"#F0F0F0"}
            style={{
              width: "48%",
            }}
            value={formValues.postCode}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: `${postCodeError ? "red" : "#000"}` },
            }}
            className={`text-sm ${postCodeError ? " animate-jiggle" : ""} `}
          />
        </div>
        <Button
          title="Confirm"
          className="bg-green text-2xl py-3 px-10 rounded-xl"
        />
      </form>
    </div>
  );
};

export default BillingAddressForm;
