// @ts-nocheck
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import {
  MapContainer,
  Polygon,
  Popup,
  TileLayer,
  LayersControl,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { Skeleton } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { RootState, useSelector } from "services";
import StayCard from "./MapPopupCard";
import Box from "@mui/material/Box";

interface MapProjectPolygonsProps {
  mapHeight: string;
  className: string;
  zoom: number;
  isClickable?: boolean;
  totalPolygons: number;
  polygons: any;
}

const MapProjectPolygons: React.FC<MapProjectPolygonsProps> = (props) => {
  const { mapHeight, className, zoom, isClickable = false } = props;
  const [tileLoaded, setTileLoaded] = useState(false);
  const { projectPolygonData, projectData } = useSelector(
    (state: RootState) => state.projects
  );

  var polygons = projectPolygonData?.polygons; // Add nullish coalescing operator

  if (polygons == null) {
    console.log("LAT: " + projectData?.latitude + "  LNG:" + projectData?.longitute )
    if  (projectData?.latitude != null && projectData?.longitute != null){
        polygons = [
            {
                "coords" : [
                    {
                        "lat": projectData.latitude + 0.00007,
                        "lng": projectData.longitute + 0.0001
                    },
                    {
                        "lat": projectData.latitude + 0.00007,
                        "lng": projectData.longitute - 0.0001
                    },
                    {
                        "lat": projectData.latitude - 0.00007,
                        "lng": projectData.longitute - 0.0001
                    },
                    {
                        "lat": projectData.latitude - 0.00007,
                        "lng": projectData.longitute + 0.0001
                    }
                ],
                "polygon_size": 0.0,
                "approx_centre": {
                        "lat": projectData.latitude,
                        "lng": projectData.longitute
                }
            }
        ];
    }

  }

  const center: LatLngExpression = [
    polygons?.length > 0 ? polygons[0]?.approx_centre?.lat : 0,
    polygons?.length > 0 ? polygons[0]?.approx_centre?.lng : 0,
  ];

  const { BaseLayer } = LayersControl;

  const handlePolygonClick = (evt: any) => {
    if (!isClickable) return;
    evt?.target?.setStyle({ fillColor: "#9164DC" });
  };

  const handlePopClose = () => {
    if (!isClickable) return;
  };

  if (!polygons) {
    return (
      <Skeleton
        variant="rectangular"
        style={{ width: "100%", height: `${mapHeight}` }}
      />
    );
  }

  return (
    <div
      className={`${className} overflow-hidden relative`}
      style={{ height: `${mapHeight}` }}
    >
      <MapContainer
        center={center}
        zoom={zoom}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
        className="z-40 joyride-dashboard-map"
      >
        <LayersControl position="topright">
          <BaseLayer checked name="Satelite">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              eventHandlers={{
                load: () => setTileLoaded(true),
              }}
            />
          </BaseLayer>
          <BaseLayer name="OpenStreetMap">
            <TileLayer
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              url="https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=As1cT1qw2uIJHfPCpdfpFlDvFWCKmo5q"
              maxZoom={20}
              eventHandlers={{
                load: () => setTileLoaded(true),
              }}
            />
          </BaseLayer>
        </LayersControl>
        {tileLoaded && (
          <MarkerClusterGroup chunkedLoading={false}>
            {polygons &&
              polygons.map((polygon: any, index) => (
                <Polygon
                  key={index}
                  pathOptions={{
                    color: "#9164DC",
                    fillColor: "transparent",
                  }}
                  positions={polygon.coords}
                  weight={3}
                  fill={true}
                  eventHandlers={{
                    click: handlePolygonClick,
                    popupclose: handlePopClose,
                  }}
                >
                  {isClickable && (
                    <Popup>
                      <StayCard
                        size="small"
                        title={projectData?.title}
                        data={projectPolygonData}
                        className="shadow-2xl"
                      />
                    </Popup>
                  )}
                </Polygon>
              ))}
          </MarkerClusterGroup>
        )}
      </MapContainer>
    </div>
  );
};

export default MapProjectPolygons;
