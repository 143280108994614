import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PrintTable from "./PrintTable";

import numeral from "numeral";


const Schedules = () => {

    const { schedules } = useSelector((state) => state.feasibility);
    const { templates } = useSelector((state) => state.property);
    const [values, setValues] = useState([]);


    useEffect(() => {
        console.log("UE:[schedules]")
        let templateNames = new Map()
        templates.forEach(e => {templateNames.set(e.id, e.templateName)})

        let v = schedules.map((s) => {
            return {
                plot: s.plot,
                templateName: templateNames.get(s.template),
                quantity: s.quantity,
                predictedNet: `£${numeral(s.predictedNet).format("0,0")}`,
                adjustedNet: `£${numeral(s.adjustedNet).format("0,0")}`,
                gross: `£${numeral(s.gross).format("0,0")}`,
            }
        });
        setValues(v)
       }, [schedules]);


  return (
    <>
        <PrintTable
            title = 'Accommodation schedules'
            headers = {['Plot', 'Property Template', 'Number of units', 'Predicted Value (per unit)', 'Net (per unit)', 'Gross (per unit)']}
            keys = {['plot', 'templateName', 'quantity', 'predictedNet', 'adjustedNet', 'gross']}
            values = {values}
        />
    </>
  );
};

export default Schedules;
