import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "&.MuiDialog-root": {
    zIndex: 99,
  },
  "& .MuiPaper-root": {
    padding: "30px 35px",
    borderRadius: "20px",
    backgroundColor: "#f0f0f0",
  },
}));

export default CustomDialog;
