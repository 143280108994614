const Header = (props) => {
  const { header, className } = props;

  return (
    <h1 className={`text-3xl font-bold text-background-blue ${className}`}>
      {header}
    </h1>
  );
};

export default Header;
