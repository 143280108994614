import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  Divider,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import api from "api/api";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Button from "ui/Button";
import CustomRadioInput from "ui/CustomRadioInput";
import CustomTextField from "ui/CustomTextField";
import { useDebouncedCallback } from "use-debounce";
const AddProjectForm = ({
  formValues,
  handleInputChange,
  handleSubmit,
  status,
  handleSearchChange,
  loading,
}) => {
  const [addresses, setAddresses] = useState([]);
  const token = Cookies.get("propToken");
  const [responseOptions, setResponseOptions] = useState([]);

  const handleSearch = useDebouncedCallback((value) => {
    const response = api.get(
      `report-generation/addresses?searchTerm=${value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.then((e) => setResponseOptions(e.data.Items));
  }, 400);

  useEffect(() => {
    console.log("UE:[responseOptions]")
    setAddresses(responseOptions);
  }, [responseOptions]);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <span className='joyride-add-project-title'>
          <label htmlFor='title' className='text-xl mb-1'>
            Project title
          </label>
          <CustomTextField
            id='title'
            name='title'
            value={formValues.title}
            onChange={handleInputChange}
            className='w-full'
          />
          <div className='mt-5'>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='Research and Due diligence pending'
                name='status'
                value={formValues.status}
                onChange={handleInputChange}
                row
              >
                <CustomRadioInput
                  value='Research and Due diligence pending'
                  control={<Radio size='small' />}
                  label='Research and Due diligence pending'
                />
                <CustomRadioInput
                  value='Offer stage'
                  control={<Radio size='small' />}
                  label='Offer stage'
                />
                <CustomRadioInput
                  value='Legals instructed'
                  control={<Radio size='small' />}
                  label='Legals instructed'
                />
                <CustomRadioInput
                  value='Planning submitted'
                  control={<Radio size='small' />}
                  label='Planning submitted'
                />
                <CustomRadioInput
                  value='Planning granted'
                  control={<Radio size='small' />}
                  label='Planning granted'
                />
                <CustomRadioInput
                  value='Exchange and Completion'
                  control={<Radio size='small' />}
                  label='Exchange and Completion completed'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </span>
        <div className='my-6'>
          <Divider />
        </div>
        <div className='flex gap-10'>
          <div className='flex flex-col flex-1 gap-4'>
            <div className='flex flex-col'>
              <label htmlFor='location' className='text-xl mb-1'>
                Search Location
              </label>
              {/* <CustomTextField
                id="location"
                name="location"
                value={formValues.location}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon
                        className="text-green"
                        style={{ fontSize: 35 }}
                      />
                    </InputAdornment>
                  ),
                }}
              /> */}
              <Autocomplete
                filterOptions={(options, state) => options}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                options={addresses}
                getOptionLabel={(option) => {
                  return option && option?.Text ? option.Text : "";
                }}
                onInputChange={(event, value) => handleSearch(value)}
                onChange={handleSearchChange}
                renderInput={(params) => (
                  <div className='relative'>

                    <CustomTextField {...params} label='' pleft={100} />
                  </div>
                )}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='streetNumber' className='text-xl mb-1'>
                Street number
              </label>
              <CustomTextField
                backgroundcolor={"transparent"}
                disabled={true}
                id='streetNumber'
                name='streetNumber'
                value={formValues.streetNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='streetAddress' className='text-xl mb-1'>
                Street address
              </label>
              <CustomTextField
                disabled={true}
                backgroundcolor={"transparent"}
                id='streetAddress'
                name='streetAddress'
                value={formValues.streetAddress}
                onChange={handleInputChange}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='city' className='text-xl mb-1'>
                City
              </label>
              <CustomTextField
                backgroundcolor={"transparent"}
                disabled={true}
                id='city'
                name='city'
                value={formValues.city}
                onChange={handleInputChange}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='postcode' className='text-xl mb-1'>
                Postcode
              </label>
              <CustomTextField
                backgroundcolor={"transparent"}
                disabled={true}
                id='postcode'
                name='postcode'
                value={formValues.postcode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='flex flex-col flex-1'>
            <label htmlFor='summary' className='text-xl mb-1'>
              Project summary
            </label>
            <CustomTextField
              id='summary'
              name='summary'
              value={formValues.summary}
              onChange={handleInputChange}
              multiline
              rows={13}
            />
            {loading ? (
              <div className='flex items-center justify-center'>
                <CircularProgress className='self-end' />
              </div>
            ) : (
              <Button
                disabled={loading || formValues.title < 3 || formValues.postcode < 5}
                title='Add Project'
                type='submit'
                className='bg-green text-background-blue rounded-2xl text-xl mt-auto pt-3.5 pb-3.5 pr-16 pl-16 self-end joyride-add-project-button'
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProjectForm;
