import {Fragment, useEffect, useState} from "react";
import {Skeleton} from "@mui/material";
import StatCard from "common/StatCard";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import {useDispatch} from "react-redux";
import PropertyTemplateForm from "forms/PropertyTemplateForm"
import Cookies from "js-cookie";
import GrayCard from "common/GrayCard";
import {Divider, MenuItem, Table, TableBody, TableHead, TableRow,} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import {CHLabel, CILabel, CTextField, TCell, CSelect} from "common/EditableTableStyle";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteIcon from '@mui/icons-material/Delete'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import {json} from "react-router-dom";
import {useSelector} from "services";
import CustomSelect from "ui/CustomSelect";
import Box from "@mui/material/Box";
import Button from "ui/Button";
import {createFeasibility, fetchFeasibility} from "services/feasibility";
import {fetchPredictedValues } from "../../services/PredictedValues";
import {fetchPropertyTemplates} from "services/property";
import { moneyFormat, formatNumber } from "helpers/money"
import numeral from "numeral";

import {
    addSchedule,
    updateSchedule,
    deleteSchedule,
    setCost,
    addCustomCost,
    updateCustomCost,
    deleteCustomCost,
    setArea,
    updateContingencyPercentage,
    updateFixedContingency,
    reCalculateTotalCost
} from "services/feasibility"


const ProjectFeasibility = (props) => {

    const dispatch = useDispatch();
    const token = Cookies.get("propToken");

    const [showPropertyTemplateForm, setShowPropertyTemplateForm] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const [predictedValueMap, setPredictedValueMap] = useState({});

    const { fetchProjectStatus } = useSelector(
        (state) => state.projects
    );

    const {id} = useSelector(
        (state) => state.projects.projectData
    );

    const {section, schedules, custom, contingency, summary} = useSelector(
        (state) => state.feasibility
    );

    const {propertyTemplateOptions, templates} = useSelector(
        (state) => state.property
    );

    const { values } = useSelector(
        (state) => state.predictedValues
    );

    const [localSummary, setLocalSummary] = useState( {
            area: 0,
    });

    const handleInputChange = (evt, sectionIndex, costIndex) => {
        const {name, value} = evt.target;
        console.log("handleInputChange: name: " + name + "  value: " + value + " section: " + sectionIndex + "  cost: " + costIndex);
        dispatch(setCost({name, value:parseInt(value.replace("£", "").replaceAll(",", "")), sectionIndex, costIndex}))
        setDisableSaveButton(false)
    };

    const handleCustomInputChange = (evt, index) => {
        const {name, value} = evt.target;
        console.log("handleCustomInputChange - name: " + name + "  value: " + value);
        dispatch(updateCustomCost({name, value:parseInt(value.replace("£", "").replaceAll(",", "")), index}))
        setDisableSaveButton(false)
    };

    const handleCustomNameChange = (evt, index) => {
        const {name, value} = evt.target;
        console.log("handleCustomNameChange - name: " + name + "  value: " + value);
        dispatch(updateCustomCost({name, value, index}))
        setDisableSaveButton(false)
    };
    const handleAddCustomCost = (evt) => {
        console.log("handleAddCustomCost")
        dispatch(addCustomCost())
    };

    const handleDeleteCustomCost = (evt, index) => {
        console.log("handleDeleteCustomCost "+ index)
        dispatch(deleteCustomCost({index}))
        setDisableSaveButton(false)
    };

    const handleUpdateContingencyPercentage = (evt) => {
        const {value} = evt.target;
        console.log("handleUpdateContingencyPercentage value: " + value);
        dispatch(updateContingencyPercentage({value:parseInt(value.replaceAll(",", ""))}))
        setDisableSaveButton(false)
    };

    const handleUpdateFixedContingency = (evt) => {
        const {value} = evt.target;
        console.log("handleUpdateFixedContingency value: " + value);
        dispatch(updateFixedContingency({value:parseInt(value.replace("£", "").replaceAll(",", ""))}))
        setDisableSaveButton(false)
    };

    const handleScheduleChange = (evt, index) => {
         const {name, value} = evt.target;
         console.log("handleScheduleChange: name "+name+" value:"+value);

         if (name == 'template') {
           let predictedValue = values.find(e => e.templateId == value)?.value
            if (predictedValue > 0) {
                dispatch(updateSchedule({name:"predictedNet", value:predictedValue, index}))
            }
         }
         dispatch(updateSchedule({name, value, index}))
         setDisableSaveButton(false)
    }

    const handleScheduleMoneyChange = (evt, index) => {
         const {name, value} = evt.target;
         dispatch(updateSchedule({name:name, value:parseInt(value.replace("£", "").replaceAll(",", "")), index:index}))
         setDisableSaveButton(false)
    }

    const handleAddSchedule = (evt) => {
        console.log("handleAddSchedule")
        dispatch(addSchedule())
    };

    const handleDeleteSchedule = (evt, index) => {
        console.log("Handle delete schedule")
        dispatch(deleteSchedule({index}))
        setDisableSaveButton(false)
    };

    const onSubmit = (evt) => {
        console.log("onSubmit feasibility "+id)
        setDisableSaveButton(true)
        let projectId = id
        evt.preventDefault();
        const body = {
            schedules: schedules,
            section: section,
            custom: custom,
            summary: summary,
            contingency: contingency
        };
        dispatch(
            createFeasibility({
                projectId,
                body,
                token,
            })
        )
    };

    const addPropertyTemplate = () => {
        setShowPropertyTemplateForm(true);
    };

    const propertyTemplateDone = () => {
        setShowPropertyTemplateForm(false);
        setDisableSaveButton(false)
    };

    useEffect(() => {
        console.log("UE:[schedules]")
        let area = 0
        schedules?.forEach(function (schedule,) {
            let propertyTemplateId = schedule.template
            templates?.forEach(function (template) {
                if (propertyTemplateId != "" && propertyTemplateId == template.id){
                    console.log("Template match");
                    area = area + (parseFloat(template.area) * parseInt(schedule.quantity))
                }
            });
        });
        dispatch(setArea({area}))
    }, [schedules]);

    useEffect(() => {
        console.log("UE:[values]")
        let vmap = {}
        values?.forEach(function (predictedValue, index) {
            vmap[predictedValue.templateId] = predictedValue.value
        });
        setPredictedValueMap(vmap)
        console.log("map "+vmap)
    }, [values]);

 //, color: '#2fa89f'

    useEffect(() => {
        console.log("UE:[id]")
        if (id != null) {
            console.log("useEffect ProjectFeasibility, projectId +"+id)
//            dispatch(fetchPropertyTemplates({token}));
//            dispatch(fetchFeasibility({projectId: id, token}));
//            dispatch(fetchPredictedValues({projectId: id, token}))
            dispatch(reCalculateTotalCost({}))
         }
    }, [id]);

    useEffect(() => {
        console.log("UE:()")
        console.log("useEffect ProjectFeasibility, on load");
    });


        return (
            <>
                {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
                    <Skeleton
                        variant='rectangular'
                        style={{ width: "100%", height: "200px" }}
                    />
                ) : (

                        showPropertyTemplateForm ? (
                        <PropertyTemplateForm
                            handleDone={propertyTemplateDone}
                            showPredictedValues={true}
                        />
                ) : (
                    <>
            <ProjectLayoutHeader header={`Feasibility study`} />
            <Topbar />
           <div className="grid grid-cols-4 gap-4">
                      <StatCard
                          title="GDV"
                          value={moneyFormat(summary?.totalGdv)}
                      />
                      <StatCard
                          title="NDV"
                          value={moneyFormat(summary?.totalAdjustedNdv)}
                      />
                      <StatCard
                          title="Project Cost"
                          value={moneyFormat(summary?.totalCost)}
                      />
                      <StatCard
                          title="Cost of Sale"
                          value={moneyFormat(summary?.costOfSale)}
                      />
                      <StatCard
                          title="Profit"
                          value={moneyFormat(summary?.profit)}
                      />
                      <StatCard
                          title="Profit on GDV"
                          value={formatNumber(summary?.profitOnGDV)+'%'}
                      />
                      <StatCard
                          title="Profit on NDV"
                          value={formatNumber(summary?.profitOnNet)+'%'}
                      />
                      <StatCard
                          title="Profit on Cost"
                          value={formatNumber(summary?.profitOnCost)+'%'}
                      />
                  </div>

              <div className='!mb-12'/>

              <GrayCard>
                  <h1 className="text-2xl font-bold">Accommodation schedule</h1>
                  <div className='!mb-6'/>
                  <TableContainer>
                      <Table sx={{minWidth: 700, border: "none", borderCollapse: "separate"}} aria-label='customized table'>
                          <TableHead>
                              <TableRow key="scheduleHeaderKey">
                                  <TCell><CHLabel>Plot</CHLabel></TCell>
                                  <TCell><Box className={'flex flex-row flex-start'}><CHLabel>Property Template</CHLabel><IconButton
                                      sx={{ml:0, mt:-1 }}
                                      onClick={ addPropertyTemplate}> <AddCircleOutlineTwoToneIcon/></IconButton></Box></TCell>
                                  <TCell><CHLabel>Number of units</CHLabel></TCell>
                                  <TCell><CHLabel>Predicted Value (per unit)</CHLabel></TCell>
                                  <TCell><CHLabel>Net (per unit)</CHLabel></TCell>
                                  <TCell><CHLabel>Gross (per unit)</CHLabel></TCell>
                                  <TCell><IconButton edge="end" onClick={ handleAddSchedule}> <AddCircleOutlineTwoToneIcon/></IconButton></TCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {
                                  schedules?.map(
                                      (schedule, scheduleIndex) => (
                                          <TableRow key={scheduleIndex}>

                                              <TCell><CTextField fullWidth name="plot" value={schedule.plot} onChange={(e) => {handleScheduleChange(e, scheduleIndex)}}/></TCell>
                                              <TCell>
                                                  <CSelect fullWidth
                                                           value={schedule.template}
                                                           name='template'
                                                           onChange={(e) => {handleScheduleChange(e, scheduleIndex)}}
                                                  >
                                                      {propertyTemplateOptions?.map((option, index) => (
                                                          <MenuItem key={index} value={option.value}>
                                                              {option.option}
                                                          </MenuItem>
                                                      ))}
                                                  </CSelect>
                                              </TCell>
                                              <TCell><CTextField fullWidth name="quantity" type="number" value={schedule.quantity} onChange={(e) => {handleScheduleChange(e, scheduleIndex)}}/></TCell>
                                              <TCell><CTextField fullWidth name="predictedNet" value={moneyFormat(predictedValueMap[schedule.template])} disabled/></TCell>
                                              <TCell><CTextField fullWidth name="adjustedNet" value={moneyFormat(schedule.adjustedNet)} onChange={(e) => {handleScheduleMoneyChange(e, scheduleIndex)}}/></TCell>
                                              <TCell><CTextField fullWidth name="gross"  value={moneyFormat(schedule.gross)} onChange={(e) => {handleScheduleMoneyChange(e, scheduleIndex)}}/></TCell>
                                              <TCell><IconButton edge="end" onClick={(e) => {handleDeleteSchedule(e, scheduleIndex)}}> <DeleteOutlineTwoToneIcon/></IconButton></TCell>
                                          </TableRow>
                                      )
                                  )}
                          </TableBody>
                      </Table>
                  </TableContainer>

                  <div className='flex gap-2 flex-1 mt-5'>
                      <Button
                          type='button'
                          className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end'
                          title='Save'
                          onClick={onSubmit}
                          disabled={disableSaveButton}
                      />
                  </div>
              </GrayCard>

              {/* ------------------------------------------------------------------------------*/}


              <div className='!mb-12'/>


              <GrayCard>
                  <h1 className="text-2xl font-bold">Project Costs</h1>
                  <div className='!mb-6'/>
                  <TableContainer>
                      <Table
                          sx={{minWidth: 700, border: "none", borderCollapse: "separate"}}
                          aria-label='customized table'
                      >

                          <TableHead>

                              <TableRow key="costheaders">
                                  <TCell><CHLabel></CHLabel></TCell>
                                  <TCell><CHLabel>Quantity</CHLabel></TCell>
                                  <TCell><CHLabel>Value</CHLabel></TCell>
                                  <TCell colSpan={2}><CHLabel>Total</CHLabel></TCell>
                              </TableRow>

                          </TableHead>
                          <TableBody>
                              {
                                  section?.map(
                                      (section, sectionIndex) => (
                                          <Fragment key={"table-root"+sectionIndex}>
                                              <TableRow key={section.name}>
                                                  <TCell colSpan={5}><CHLabel>{section.name}</CHLabel></TCell>
                                              </TableRow>

                                              {section?.cost?.map(
                                                  (cost, costIndex) => (
                                                      <TableRow key={cost.name}>
                                                          <TCell>
                                                              <CILabel> {cost.name} </CILabel>
                                                          </TCell>
                                                          <TCell>
                                                              <CTextField
                                                                  fullWidth
                                                                  name={'quantity'}
                                                                  value={formatNumber(cost.quantity)}
                                                                  onChange={(e) => {handleInputChange(e, sectionIndex, costIndex)}}
                                                                  disabled={sectionIndex==2 && costIndex==0}
                                                              />
                                                          </TCell>
                                                          <TCell >
                                                              <CTextField
                                                                  fullWidth
                                                                  name={'value'}
                                                                  value={moneyFormat(cost.value)}
                                                                  onChange={(e) => { handleInputChange(e, sectionIndex, costIndex )}}
                                                              />
                                                              </TCell>
                                                          <TCell colSpan={1}>
                                                              <CTextField
                                                                  fullWidth
                                                                  disabled
                                                                  value={moneyFormat(cost.total)}
                                                              />
                                                          </TCell>
                                                          <TCell></TCell>
                                                      </TableRow>
                                                  )
                                              )}
                                              </Fragment>
                                      )
                                  )
                              }
                              <TableRow key="bottom-bar">
                                  <TCell colSpan={4}><CHLabel>Custom costs</CHLabel></TCell>
                                  <TCell><IconButton
                                  edge="end"
                                  onClick={handleAddCustomCost}><AddCircleOutlineTwoToneIcon/></IconButton></TCell>
                              </TableRow>
                              {
                                  custom?.map(
                                      (cost, costIndex) => (
                                          <TableRow key={"custom" + costIndex}>
                                              <TCell><CTextField
                                                  sx={{pl: '30px'  }}
                                                  fullWidth name={'name'} value={cost.name} onChange={(e) => {handleCustomNameChange(e, costIndex)}}/></TCell>
                                              <TCell><CTextField fullWidth name={'quantity'} value={formatNumber(cost.quantity)}  onChange={(e) => {handleCustomInputChange(e, costIndex)}}/></TCell>
                                              <TCell><CTextField fullWidth name={'value'} value={moneyFormat(cost.value)}     onChange={(e) => {handleCustomInputChange(e, costIndex)}}/></TCell>
                                              <TCell><CTextField fullWidth name={'total'} value={moneyFormat(cost.total)} disabled/></TCell>
                                              <TCell><IconButton
                                                  edge="end"
                                                  onClick={(e) => { handleDeleteCustomCost(e, costIndex) }}
                                              ><DeleteOutlineTwoToneIcon/></IconButton></TCell>
                                          </TableRow>
                                      )
                                  )
                              }

                            <TableRow key={section.name}>
                                <TCell colSpan={5}><CHLabel>Contingency funds</CHLabel></TCell>
                            </TableRow>

                            <TableRow>
                                <TCell><CILabel>Contingency as fixed cost</CILabel></TCell>
                                <TCell colSpan={2}><CTextField fullWidth name={'value'} value={moneyFormat(contingency?.fixed?.value)} onChange={handleUpdateFixedContingency}/></TCell>
                                <TCell><CTextField fullWidth name={'total'} value={moneyFormat(contingency?.fixed?.total)} disabled/></TCell>
                                <TCell></TCell>
                            </TableRow>
                                <TableRow>
                                <TCell><CILabel>Contingency as % of total costs</CILabel></TCell>
                                <TCell colSpan={2}><CTextField fullWidth name={'value'} value={formatNumber(contingency?.percentage?.value)} onChange={handleUpdateContingencyPercentage} /></TCell>
                                <TCell><CTextField fullWidth name={'total'} value={moneyFormat(contingency?.percentage?.total)} disabled/></TCell>
                                <TCell></TCell>
                            </TableRow>

                          </TableBody>
                      </Table>
                  </TableContainer>

                  <div className='flex gap-2 flex-1 mt-5'>
                      <Button
                          type='button'
                          className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end'
                          title='Save'
                          onClick={onSubmit}
                          disabled={disableSaveButton}
                      />
                  </div>

              </GrayCard>

                    </>
                  )
                )
                }
            </>
        );
};


export default ProjectFeasibility;
