import L from "leaflet";
import { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  // Polygon,
  useMapEvents,
  LayersControl,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import MapProjectPopupSm from "./MapProjectPopupSm";

import "leaflet/dist/leaflet.css";
// import { nanoid } from "nanoid";

import { useSelector } from "services";
import {
  selectApplyFiltering,
  selectApproved,
  selectGranted,
  selectProjectData,
  selectSubmitted,
} from "services/planning";
import marker from "styles/img/marker.svg";

const MapPlanning = (props) => {
  const { mapHeight, className, popupSm = false, zoom } = props;
  const { longitute, latitude, planning } = useSelector(selectProjectData);
  const approved = useSelector(selectApproved);
  const submitted = useSelector(selectSubmitted);
  const granted = useSelector(selectGranted);
  const applyFilter = useSelector(selectApplyFiltering);
  const [tileLoaded, setTileLoaded] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [data, setData] = useState(planning);
  const customIcon = L.icon({
    iconUrl: marker,
    iconSize: [32, 32],
  });
  const LocationFinder = () => {
    useMapEvents({
      click(e) {
        setShowPopup(false);
      },
    });
    return null;
  };

  const handleMarkerClick = (evt) => {
    setSelectedPlan(planning.planningApplications[evt.target.options.index]);
    setShowPopup(true);
  };
  useEffect(() => {
    console.log("UE:[planning]")
    !data && setData(planning);
  }, [planning]);

  useEffect(() => {
    console.log("UE:[applyFilter]")
    if (applyFilter) {
      let tmp = { ...planning };
      const e = tmp.planningApplications.filter((application) => {
        if (approved !== -1 && application.approved !== Boolean(approved)) {
          return false;
        }
        if (submitted !== -1 && application.submitted !== Boolean(submitted)) {
          return false;
        }
        if (granted !== -1 && application.granted !== Boolean(granted)) {
          return false;
        }
        return true;
      });
      tmp.planningApplications = e;
      setData(tmp);
    }
  }, [applyFilter]);

  if (!longitute || !latitude) {
    return null;
  }

  const { BaseLayer } = LayersControl;

  return (
    <div
      className={`${className} overflow-hidden relative`}
      style={{ height: `${mapHeight}` }}
    >
      {popupSm && selectedPlan && Object.keys(selectedPlan).length > 0 ? (
        <MapProjectPopupSm
          className={`${
            showPopup
              ? "opacity-100 visible translate-x-0 transition-all duration-300 p-5"
              : "opacity-0 invisible translate-x-full transition-all duration-300"
          }`}
          handleClose={() => setShowPopup(false)}
          data={selectedPlan}
        />
      ) : (
        ""
      )}

      <MapContainer
        center={[latitude, longitute]}
        zoom={zoom}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
        className="z-40 joyride-dashboard-map"
      >
        <LayersControl position="topright">
          <BaseLayer checked name="Satelite">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              eventHandlers={{
                load: () => setTileLoaded(true),
              }}
            />
          </BaseLayer>
          <BaseLayer name="OpenStreetMap">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={20}
              eventHandlers={{
                load: () => setTileLoaded(true),
              }}
            />
          </BaseLayer>
        </LayersControl>
        {tileLoaded && (
          <MarkerClusterGroup chunkedLoading={true}>
            {data?.planningApplications &&
              data.planningApplications.length > 0 &&
              data.planningApplications.map((marker, index) => (
                <Marker
                  position={[marker.latitude, marker.longitude]}
                  key={index}
                  icon={customIcon}
                  index={index}
                  eventHandlers={{ click: handleMarkerClick }}
                />
              ))}
          </MarkerClusterGroup>
        )}

        <LocationFinder />
      </MapContainer>
    </div>
  );
};

export default MapPlanning;
