import MapProjectPolygons from "common/MapProjectPolygons";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import { useSelector } from "services";

const ProjectMap = (props) => {
  const { status, projectData } = useSelector((state) => state.projects);
  return (
    <>
      <ProjectLayoutHeader header='Map' />
      <Topbar />

      {/* {status !== "success" ? (
        <div className='flex items-center justify-center'>
          <Skeleton
            variant='rectangular'
            height={640}
            style={{ width: "100%" }}
          />
        </div>
      ) : ( */}
      <MapProjectPolygons
        mapHeight='640px'
        className='rounded-[20px]'
        zoom={19}
        isClickable={true}
      />
      {/* )} */}
    </>
  );
};

export default ProjectMap;
