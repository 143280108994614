import Box from '@mui/material/Box';


const OuterBox = (props) => {

const bc = props.boarder ? '#DDDDDD' : '#FAFAFA'

  return (
        <div className="mb-10">
            <Box sx={{ border: 1, borderRadius: 2, borderColor: bc, m: 1, backgroundColor: '#FAFAFA'}}
                autoComplete="off"
                >
                    <h1 className={'text-2xl font-bold pl-5 pt-2'}>
                      {props.header}
                   </h1>

                {props.children}
            </Box>
        </div>
  );
};

export default OuterBox;