import { Outlet, useLocation } from "react-router-dom";

import Sidebar from "layout/Sidebar";

const RootLayout = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <Sidebar />
      <div
        className={`${
          pathname.startsWith("/dashboard/account")
            ? ""
            : "ml-32 mt-8 mr-9 mb-9"
        }`}
      >
        <Outlet />
      </div>
    </>
  );
};

export default RootLayout;
