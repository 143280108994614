import { CircularProgress } from "@mui/material";
import Button from "ui/Button";
import CustomTextField from "ui/CustomTextField";

const ResetPasswordForm = ({
  handleSubmit,
  formValues,
  handleInputChange,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-full'>
      <CustomTextField
        label='Password'
        type='password'
        name='password'
        backgroundcolor='#F0F0F0'
        fullWidth
        value={formValues.password}
        onChange={handleInputChange}
        fontSize='20'
        borderradius='10'
        InputLabelProps={{
          style: { color: "#A9A9A9" },
        }}
      />
      <CustomTextField
        label='Confirm Password'
        type='password'
        name='confirmPassword'
        backgroundcolor='#F0F0F0'
        fullWidth
        value={formValues.confirmPassword}
        onChange={handleInputChange}
        fontSize='20'
        borderradius='10'
        InputLabelProps={{
          style: { color: "#A9A9A9" },
        }}
      />

      {isLoading ? (
        <div className='flex items-center justify-center'>
          <CircularProgress />
        </div>
      ) : (
        <Button
          title='Confirm'
          type='submit'
          className=' text-2xl bg-green py-3 rounded-xl'
        />
      )}
    </form>
  );
};

export default ResetPasswordForm;
