import L from "leaflet";
import { memo, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import "leaflet/dist/leaflet.css";
// import { nanoid } from "nanoid";

import marker from "styles/img/marker.svg";

import { coords } from "helpers/coords";

const MapPreview = (props) => {
  const {
    mapHeight,
    className,
    popupSm = false,
    zoom,
    lat,
    long,
    setReportTile,
  } = props;
  const [tileLoaded, setTileLoaded] = useState(false);
  const customIcon = L.icon({
    iconUrl: marker,
    iconSize: [32, 32],
  });
  return (
    <div
      className={`${className} overflow-hidden relative`}
      style={{ height: `${mapHeight}`, pointerEvents: "none" }}
    >
      <MapContainer
        draggable={false}
        center={[lat, long]}
        zoom={zoom}
        scrollWheelZoom={false}
        zoomControl={false}
        minZoom={zoom}
        maxZoom={zoom}
        style={{ height: "100%", width: "100%" }}
        className='z-40 joyride-dashboard-map'
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom={20}
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          eventHandlers={{
            load: () => {
              setTileLoaded(true);
              setReportTile && setReportTile(true);
            },
          }}
        />
        {tileLoaded && (
          <MarkerClusterGroup chunkedLoading={true}>
            {coords.map((marker, index) => (
              // <Polygon
              //   key={nanoid()}
              //   pathOptions={{ color: "#9164DC" }}
              //   positions={polygon.position}
              //   weight={4}
              //   fill={false}
              // ></Polygon>
              <Marker
                position={[marker.LATITUDE, marker.LONGITUDE]}
                key={marker.UPRN}
                icon={customIcon}
              />
            ))}
          </MarkerClusterGroup>
        )}
      </MapContainer>
    </div>
  );
};

export default memo(MapPreview);
