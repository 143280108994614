import { Link } from "react-router-dom";
import pencil from "styles/img/pencil-blue.svg";

const AccountDetailsInfo = ({ title, className }) => {
  return (
    <div className={className}>
      <h2 className="text-xl font-bold mb-6">{title}</h2>
      <div className="flex">
        <div className="flex flex-col gap-4 text-xl text-bold-gray mr-[90px]">
          <h3>Account holder:</h3>
          <h3>Account number:</h3>
          <h3>Sort code:</h3>
          <h3>Bank name:</h3>
        </div>
        <div className="text-xl flex flex-col gap-4">
          <h3>Kieran Lewis</h3>
          <h3>XXXXXXX34</h3>
          <h3>01-01-04</h3>
          <h3>Barclays Bank PLC</h3>
        </div>
      </div>
      <Link to="update-payment" className="flex mt-8">
        <p className="text-sm text-[#007983] font-bold underline mr-3">
          Change payment method
        </p>
        <img src={pencil} alt="pencil" />
      </Link>
    </div>
  );
};

export default AccountDetailsInfo;
