import CustomSelect from "ui/CustomSelect";
import CustomTextField from "ui/CustomTextField";
import { MenuItem } from "@mui/material";

const LabelInput = (props) => {
  const {
    label,
    type,
    labelWeight,
    className,
    value,
    onChange,
    name,
    options,
  } = props;

  return (
    <div className={`flex-1 flex flex-col ${className}`}>
      <h5
        className={`text-sm pb-2.5 ${
          labelWeight === "bold" ? "font-bold" : ""
        }`}
      >
        {label}
      </h5>
      {type === "select" ? (
        <CustomSelect
          className="flex-1"
          onChange={onChange}
          name={name}
          value={value}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.option}
            </MenuItem>
          ))}
        </CustomSelect>
      ) : (
        <CustomTextField
          sx={{
            "& .MuiInputBase-root": {
              boxShadow: "none",
            },
          }}
          className="flex-1"
          borderradius="5px"
          fontSize="15px"
          inputpad="3px 5px"
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default LabelInput;
