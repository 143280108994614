import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const UserTable = ({
  rows,
  columns,
  title,
}) => {
  return (

         <Box sx={{ border: 1, borderRadius: 2, borderColor: '#DDDDDD', m: 2, backgroundColor: '#FCFCFC'}}
              component="form"
              noValidate
              autoComplete="off"
            >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <DataGrid rows={rows} columns={columns} />
        </AccordionDetails>
      </Accordion>

      </Box>
  );
};

export default UserTable;