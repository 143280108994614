import { NavLink, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { useDispatch } from "services";
import { useEffect, useState } from "react";
import { authActions } from "services/auth";
import account from "styles/img/account.svg";
import dashboard from "styles/img/dashboard.svg";
import logo from "styles/img/propsmarts-logo-sidebar.svg";
import logout from "styles/img/logout.svg";
import projects from "styles/img/projects.svg";
import SidebarItem from "./SidebarItem";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { useSelector } from "services";
import { fetchUserData } from "services/auth";



const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let token = Cookies.get("propToken");

  const { userData } = useSelector((state) => state.auth);


      useEffect(() => {
          console.log("UE:[userData]")
           console.log(JSON.stringify(userData))
      }, [userData]);

      useEffect(() => {
          console.log("UE:[]"+ token)

           dispatch(fetchUserData(token));
      }, []);


  const logoutHandler = () => {
    Cookies.remove("propToken");
    dispatch(authActions.logout());
    navigate("/login", { replace: true });
  };

  return (
    <aside className='bg-background-blue fixed h-screen w-24 top-0 left-0 rounded-tl-[0px] rounded-tr-[40px] rounded-br-[40px] rounded-bl-[0px] py-14 px-6 z-50'>
      <ul className='flex flex-col gap-10 h-full text-[13px] text-[#fff] joyride-dashboard-sidebar'>
        <li className='cursor-pointer'>
          <NavLink to='/dashboard'>
            <img className='p-2' src={logo} alt='logo' />
          </NavLink>
        </li>
        {

            !userData.locked ? (
            <>
            <SidebarItem
              url='projects'
              className='joyride-dashboard-sidebar-projects'
              icon={dashboard}
              text='Projects'
            />
            <SidebarItem
                url='propertytemplates'
                icon={projects}
                text='Templates' />
                </>
                ) : ('')


        }
        {
                    (userData.role == 'admin') ? (
                    <>
                    <SidebarItem
                      url='admin'
                      icon={account}
                      text='Admin'
                    />
                    </>
                    ) : ('')
        }

        <SidebarItem
          url='account'
          icon={account}
          text='Account'
          className='mt-auto'
        />
        <SidebarItem
          url='/'
          icon={logout}
          text='Log Out'
          onClick={logoutHandler}
        />
      </ul>
    </aside>
  );
};

export default Sidebar;
