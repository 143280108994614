import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartBars = ({ age }) => {
  const data = {
    labels: Object.keys(age),
    datasets: [
      {
        label: "Age Percentage",
        data: Object.values(age),
        backgroundColor: [
          "#A69FDA",
          "#FF4B4B",
          "#50EBBE",
          "#9164DC",
          "#CD4354",
          "#50EBBE",
          "#9164DC",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + "%";
          },
          color: "#000",
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
        },
      },
    },

    maintainAspectRatio: false,
    categoryPercentage: 0.8,
    barPercentage: 0.7,
    borderRadius: 5,
  };

  return (
    <div className='h-[170px]'>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ChartBars;
