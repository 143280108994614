import MapPlanning from "common/MapPlanning";
import ProjectPlanningFilters from "common/ProjectPlanningFilters";
import ShowPlanning from "common/ShowPlanning";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import { useSelector } from "services";
import { selectShowPlanning } from "services/planning";
// import MapProjectPopup from "common/MapProjectPopup";

const ProjectPlanning = (props) => {
  const showPlanning = useSelector(selectShowPlanning);
  return (
    <>
      <ProjectLayoutHeader header='Planning' />
      <Topbar />
      <ProjectPlanningFilters />

      <div className='relative'>
        {showPlanning ? (
          <ShowPlanning />
        ) : (
          <MapPlanning
            popupSm
            mapHeight='517px'
            className='rounded-3xl'
            zoom={18}
          />
        )}
      </div>
    </>
  );
};

export default ProjectPlanning;
