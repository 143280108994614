import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "services";
import { authActions, login } from "services/auth";
import Cookies from "js-cookie";

import WhiteCard from "common/WhiteCard";
import LoginForm from "forms/LoginForm";
import Toast from "ui/Toast";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.loginStatus);
  const error = useSelector((state) => state.auth.loginError);
  const userData = useSelector((state) => state.auth.loginData);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    if (name === "rememberMe") {
      setFormValues({ ...formValues, [name]: evt.target.checked });
      return;
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    Cookies.remove("propToken");
    dispatch(authActions.logout());
    dispatch(authActions.setRememberMe(formValues.rememberMe));

    // create data and send the request
    const data = {
      email: formValues.email,
      password: formValues.password,
    };
    const res = await dispatch(login(data));

    if (res.payload.status === 200) navigate("two-factor-auth");
  };

  return (
    <>
      <WhiteCard>
        <LoginForm
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          formValues={formValues}
          status={status}
          error={error}
          userData={userData}
        />
        <Link className="mt-5 text-sm font-bold" to="/login/forgot-password">
          Forgotten password?
        </Link>
        <p className="text-sm mt-2">
          Don't have an account?{" "}
          <Link to="/signup" className="font-bold cursor-pointer">
            Sign Up
          </Link>
        </p>
      </WhiteCard>
      <Toast severity="error" message={error} openToast={status === "failed"} />
    </>
  );
};

export default Login;
