import { useDispatch, useSelector } from "services";
import {
  selectApproved,
  selectGranted,
  selectSubmitted,
  setApplyFiltering,
  setApproved,
  setGranted,
  setSubmitted,
} from "services/planning";
import Button from "ui/Button";
import GrayCard from "./GrayCard";
import PlanningFilters from "./PlanningFilters";

const ProjectPlanningFilters = (props) => {
  const approved = useSelector(selectApproved);
  const submitted = useSelector(selectSubmitted);
  const granted = useSelector(selectGranted);
  const dispatch = useDispatch();
  return (
    <>
      <GrayCard className='flex gap-11 mb-7 items-center'>
        <PlanningFilters
          value={approved}
          handleChange={(e) => dispatch(setApproved(e.target.value))}
          label='Plans Approved'
          type='select'
          className='flex-grow-0'
        />
        <PlanningFilters
          value={submitted}
          handleChange={(e) => dispatch(setSubmitted(e.target.value))}
          label='Plans Submitted'
          type='select'
          className='flex-grow-0'
        />
        <PlanningFilters
          value={granted}
          handleChange={(e) => dispatch(setGranted(e.target.value))}
          label='Plans Granted'
          type='select'
          className='flex-grow-0'
        />
        <Button
          onClick={() => dispatch(setApplyFiltering())}
          type='button'
          className='bg-green ml-auto text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end self-center'
          title='Search'
        />
      </GrayCard>
    </>
  );
};

export default ProjectPlanningFilters;
