import { Divider, Skeleton } from "@mui/material";
import GrayCard from "./GrayCard";
import Button from "../ui/Button";

const ButtonCard = (props) => {
  const { title, buttonTitle, value, salesStatus, onSubmit, disabled } = props;

  return (
    <GrayCard className='flex-grow'>
      <h1 className='text-xl mb-2.5'>{title}</h1>
      <Divider />
      {salesStatus === "idle" || salesStatus === "loading" ? (
        < Skeleton className='mt-2.5' variant="rectangular" width={"100%"} height={30} />
      ) :(
      <div className='grid grid-cols-2 gap-4'>
        <h2 className='text-xl font-bold mt-2.5'>{value}</h2>
        <div className="flex flex-row-reverse">
                <Button
                    type='button'
                    className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end mt-2.5'
                    title={buttonTitle}
                    onClick={onSubmit}
                    disabled={disabled}
                />
        </div>
        </div>
      )
      }
    </GrayCard>
  );
};

export default ButtonCard;

