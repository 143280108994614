import { useSelector } from "services";

const ProjectLayoutHeader = (props) => {
  const { header, className } = props;

  const {title} = useSelector(
    (state) => state.projects.projectData
  );

  return (
    <div className={`flex  gap-6 pb-4 ${className}`}>
        <div className="text-4xl font-bold ">{header +': '}</div>
        <div className="text-4xl ">{title}</div>
    </div>
  );
};

export default ProjectLayoutHeader;
