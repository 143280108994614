export const maskSortCode = (sortCode) => {
  // Remove any non-numeric characters from the input value
  const numericValue = sortCode.replace(/\D/g, "");

  // Format the value with dashes after every two digits
  let formattedValue = "";
  for (let i = 0; i < numericValue.length; i += 2) {
    const segment = numericValue.substr(i, 2);
    if (segment) {
      formattedValue += segment + "-";
    }
  }
  formattedValue = formattedValue.slice(0, -1); // Remove the extra dash at the end

  return formattedValue;
};
