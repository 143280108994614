import numeral from "numeral";

export function moneyFormat(value) {
  let v = ""
  if (typeof value == 'string') {
    v = '£'+numeral(parseInt(value.replace("£", "").replaceAll(",", ""))).format("0,0[.]00")
  } else {
    v =  '£'+numeral(value).format("0,0")
  }
  return v == "£0" ? "" : v
}



export function formatNumber(value) {
  let v = ""
  if (typeof value == 'string') {
    v = numeral(parseInt(value.replace("£", "").replaceAll(",", ""))).format("0,0[.]00")
  } else {
    v =  numeral(value).format("0,0[.]00")
  }
  return v == "0" || v == "NaN" ? "" : v
}