import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "api/api";
import { toast } from "react-toastify";

const initialState = {
  status: "idle",
  error: "",
  searchQuery: "",
  fetchProjectStatus: "idle",
  fetchProjectError: "",

  createdProject: {},

  projectData: {},

  projectsData: {},
  activeProjects: [],
  archivedProjects: [],
  numberOfActiveProjects: null,
  numberOfTotalProjects: null,
  projectsCoordinates: [],
  totalCostSpent: null,
  totalProfit: null,
  projectPolygonData: {},

  salesStatus: "idle",
  salesError: "",
  projectSalesData: [],

  projectId: null,
  pollingLoading: false,
  pollingStatus: false,
  statuses: {},
  stopPolling: false,
};

export const fetchUserProjects = createAsyncThunk<any, any, any>(
  "report-generation",
  async (params, thunkApi) => {
    const { token } = params;
    const response = await api.get(`report-generation/projects`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response && response.status >= 400) {
      return thunkApi.rejectWithValue(response.data);
    } else {
      return thunkApi.fulfillWithValue(response.data);
    }
  }
);

export const createProject = createAsyncThunk<any, any, any>(
  "report-generation/create-project",
  async (params, thunkApi) => {
    const { projectData, token } = params;
    try {
      const res = await api.post("report-generation/projects", projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return thunkApi.fulfillWithValue(res.data);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        return thunkApi.rejectWithValue(error.response.data.message);
      }

      // Handle other errors
      return thunkApi.rejectWithValue("Unknown error occurred.");
    }
  }
);

export const fetchProjectData = createAsyncThunk<any, any, any>(
  "report-generation/fetch-project",
  async (params, thunkApi) => {
    const { projectId, token } = params;
    const res = await api.get(`report-generation/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res && res.status >= 400) {
      return thunkApi.rejectWithValue(res.data);
    } else {
      return thunkApi.fulfillWithValue(res.data);
    }
  }
);

export const refreshDataAsync = createAsyncThunk<any, any, any>(
  "report-generation/refresh-data",
  async (params, thunkApi) => {
    const { projectId, token } = params;
    const res = await api.get(
      `report-generation/projects/refreshData/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res && res.status >= 400) {
      return thunkApi.rejectWithValue(res.data);
    } else {
      return thunkApi.fulfillWithValue(res.data);
    }
  }
);

export const fetchProjectSales = createAsyncThunk<any, any, any>(
  "report-generation/projects/sales",
  async (params, thunkApi) => {
    const { salesDate, postcode, propertyTemplateId, radius, token, projectId } = params;
    const res = await api.get("report-generation/projects/sales", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   ...(recentlySold ?? recentlySold),
      //   postcode,
      //   ...(propertyType ?? propertyType),
      //   ...(radius ?? radius),
      // },
      params: {
        salesDate,
        postcode,
        propertyTemplateId,
        radius,
        projectId
      },
    });
    if (res && res.status >= 400) {
      return thunkApi.rejectWithValue(res.data);
    } else {
      return thunkApi.fulfillWithValue(res.data);
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setPollingLoading(state, action) {
      state.pollingLoading = action.payload;
    },
    setSearchQuery(state, { payload }) {
      state.searchQuery = payload;
    },

    resetPolygon(state, action) {
      state.projectPolygonData = {};
      state.projectData = {};
    },
    setPollingStatuses(state, action) {
      state.statuses = action.payload;
    },
    setPollingStatus(state, action) {
      state.pollingStatus = action.payload;
    },
    setStopPolling(state, action) {
      state.stopPolling = action.payload;
    },
    setSuccessPolling(state, action) {
      state.pollingLoading = action.payload.loading;
      state.pollingStatus = action.payload.status;
      state.stopPolling = action.payload.stop;
    },
    reInitPolling(state, action) {
      state.projectId = action.payload;
      state.pollingLoading = initialState.pollingLoading;
      state.pollingStatus = initialState.pollingStatus;
      state.stopPolling = initialState.stopPolling;
      state.fetchProjectStatus = initialState.fetchProjectStatus;
      state.fetchProjectError = initialState.fetchProjectError;
      state.projectData = initialState.projectData;
      state.projectPolygonData = initialState.projectPolygonData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserProjects.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserProjects.fulfilled, (state, action) => {
        state.status = "success";
        state.projectsData = action.payload;
        state.activeProjects = action.payload.activeProjects;
        state.archivedProjects = action.payload.archivedProjects;
        state.numberOfActiveProjects = action.payload.numberOfActiveProjects;
        state.numberOfTotalProjects = action.payload.numberOfTotalProjects;
        state.projectsCoordinates = action.payload.projectsCoordinates;
        state.projectsCoordinates = action.payload.projectsCoordinates;
        state.totalCostSpent = action.payload.totalCostSpent;
        state.totalCostSpent = action.payload.totalCostSpent;
        state.totalProfit = action.payload.totalProfit;
      })
      .addCase(fetchUserProjects.rejected, (state, action) => {
        state.status = "failed";

        state.error = action.error?.message ?? "Something Went Wrong";
        toast.error(action.error?.message);
      })
      // .addCase(fetchProjectPolygon.pending, (state, action) => {
      //   state.status = "loading";
      // })
      // .addCase(fetchProjectPolygon.fulfilled, (state, action) => {
      //   if (action.payload.status !== 200) {
      //     state.status = "failed";
      //     state.error = action.payload.data;
      //   } else {
      //     state.status = "success";
      //     state.projectPolygonData = action.payload.data;
      //   }
      // })
      // .addCase(fetchProjectPolygon.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error;
      // })
      .addCase(createProject.pending, (state, action) => {
        state.status = "loading";
        state.projectId = null;
        state.statuses = {};
        state.pollingLoading = false;
        state.pollingStatus = false;
        state.stopPolling = false;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.projectId = action.payload.id;
        state.status = "success";
        state.createdProject = action.payload;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            typeof action.payload === "string" ? action.payload : "";
          toast.error(String(action.payload));
          return;
        }
        state.error = action.error?.message ?? "Something Went Wrong";
        toast.error(action.error?.message);
      })
      .addCase(fetchProjectData.pending, (state, action) => {
        state.fetchProjectStatus = "loading";
      })
      .addCase(fetchProjectData.fulfilled, (state, action) => {
        state.fetchProjectStatus = "success";
        state.projectData = action.payload;
        state.projectPolygonData = action.payload?.map?.titleResponse?.data;
      })
      .addCase(fetchProjectData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message ?? "Something Went Wrong";
        toast.error(action.error?.message);
      })
      .addCase(refreshDataAsync.pending, (state, action) => {
        state.fetchProjectStatus = "loading";
      })
      .addCase(refreshDataAsync.fulfilled, (state, action) => {
        state.fetchProjectStatus = "success";
      })
      .addCase(refreshDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message ?? "Something Went Wrong";
        toast.error(action.error?.message);
      })
      .addCase(fetchProjectSales.pending, (state, action) => {
        state.salesStatus = "loading";
      })
      .addCase(fetchProjectSales.fulfilled, (state, action) => {
        state.salesStatus = "success";
        state.projectSalesData = action.payload;
      })
      .addCase(fetchProjectSales.rejected, (state, action) => {
        state.salesStatus = "failed";
        state.salesError = action.error?.message ?? "Something Went Wrong";
        toast.error(action.error?.message);
      });
  },
});

export default projectsSlice;

export const {
  setStopPolling,
  setPollingLoading,
  setPollingStatuses,
  setPollingStatus,
  setSuccessPolling,
  reInitPolling,
  resetPolygon,
  setSearchQuery,
} = projectsSlice.actions;

export const selectProjectPolygonData = ({ projects }) =>
  projects.projectPolygonData;
export const selectSearchQuery = ({ projects }) => projects.searchQuery;
export const selectProjectData = ({ projects }) => projects.projectData;
export const selectProjectId = ({ projects }) => projects.projectId;
export const selectPollingLoading = ({ projects }) => projects.pollingLoading;
export const selectStatuses = ({ projects }) => projects.statuses;
export const selectPollingStatus = ({ projects }) => projects.pollingStatus;
export const selectStopPolling = ({ projects }) => projects.stopPolling;
export const selectDemographic = ({ projects }) =>
  projects.projectData?.demographic;
