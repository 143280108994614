export const colors = [
  "#fff",
  "#D9D9D9",
  "#BCBCBC",
  "#7D7C7C",
  "#000000",
  "#141831",
  "#9164DC",
  "#A69FDA",
  "#2FA89F",
  "#50EBBE",
  "#88DDCF",
  "#CD4354",
  "#FF4B4B",
  "#FF8276",
];
