import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";
import { v4 as uuidv4 } from 'uuid';



const defaultTemplate =
    {
        id: "",
        templateName: "",
        propertyType: "",
        bedrooms: "",
        tenure: "",
        parkingFacility: "",
        area: ""
    }

const initialState = {
    status: "idle",
    error: "",
    templates: [defaultTemplate],
    propertyTemplateOptions: [{}]
};


export const savePropertyTemplates = createAsyncThunk<any, any, any>(
    "property/templates",
    async (params, thunkApi) => {
        const { body, token } = params;

        const response = await api.post(
            `report-generation/property/template/all`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);

export const deletePropertyTemplate = createAsyncThunk<any, any, any>(
    "property/template",
    async (params, thunkApi) => {
        const {templateId, token } = params;
        console.log("deletePropertyTemplates::: "+templateId+" : "+token);
        const response = await api.delete(
            `report-generation/property/template`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    templateId,
                },
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        console.log("deletePropertyTemplates::: success:: "+templateId);
        const fetchResponse = await api.get(
            `report-generation/property/template/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }

        return thunkApi.fulfillWithValue(response.data);
    }
);

export const fetchPropertyTemplates = createAsyncThunk<any, any, any>(
    "property/template/all",
    async (params, thunkApi) => {
        const { token } = params;
        console.log("fetchPropertyTemplates");
        const response = await api.get(
            `report-generation/property/template/all`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }

        return thunkApi.fulfillWithValue(response.data);
    }
);

export const createPropertyTemplate = createAsyncThunk<any, any, any>(
    "property/template",
    async (params, thunkApi) => {
        const { projectId, body, token } = params;

        const response = await api.post(
            `report-generation/property/template`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    projectId,
                },
            }
        );

        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);


const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {
        addTemplate(state, action){
            console.log("addTemplate")
            let template = {...defaultTemplate}
            template.id = uuidv4()
            state.templates.push(template)
        },
        deleteTemplate(state, action){
            console.log("deleteTemplate: " + action.payload.index)
            state.templates.splice(action.payload.index, 1)
        },
        updateTemplate(state, action){
            console.log("updateTemplate: " + action.payload.index)
            state.templates[action.payload.index][action.payload.name] = action.payload.value
        },
    },
    extraReducers(builder) {
        builder
            //.addCase(deletePropertyTemplate.pending, (state), action)
            .addCase(fetchPropertyTemplates.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchPropertyTemplates.fulfilled, (state, action) => {
                state.status = "success";
                state.templates = action.payload.templates;
                state.propertyTemplateOptions = action.payload.templates?.map((pt)=> {return {option : pt.templateName, value: pt.id}})
            })
            .addCase(fetchPropertyTemplates.rejected, (state, action) => {
                state.status = "failed";

            })
            .addCase(savePropertyTemplates.fulfilled, (state, action) => {
                console.log("savePropertyTemplates.fulfilled")
                state.propertyTemplateOptions = state.templates?.map((pt)=> {return {option : pt.templateName, value: pt.id}})
            })
    },
});


export const {
    addTemplate,
    deleteTemplate,
    updateTemplate,
} = propertySlice.actions;

export default propertySlice;
