import { MenuItem } from "@mui/material";
import CustomSelect from "ui/CustomSelect";

const PlanningFilters = (props) => {
  const { label, type, labelWeight, className, value, handleChange } = props;

  return (
    <div className={`flex-1  flex flex-col ${className}`}>
      <h5
        className={`text-sm pb-2.5 w-28 ${
          labelWeight === "bold" ? "font-bold" : ""
        }`}
      >
        {label}
      </h5>
      <CustomSelect value={value} onChange={handleChange} className="flex-1">
        <MenuItem value={1}>Yes</MenuItem>
        <MenuItem value={0}>No</MenuItem>
      </CustomSelect>
    </div>
  );
};

export default PlanningFilters;
