import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "services";

import PricingPlansBillingForm from "forms/PricingPlansBillingForm";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { CircularProgress } from "@mui/material";
import api from "api/api";
import { maskSortCode } from "helpers/input-masks";
import Cookies from "js-cookie";
import { isValid } from "postcode";
import { Link, useNavigate } from "react-router-dom";
import { subscribe } from "services/payment";
import Button from "ui/Button";
import Toast from "ui/Toast";

const PricingPlansBilling = (props) => {
  const status = useSelector((state) => state.subscribe.status);
  const error = useSelector((state) => state.subscribe.error);
  const selectedPlanId = useSelector((state) => state.subscribe.selectedPlanId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [userError, setUserError] = useState(null);
  const [postCodeError, setPostCodeError] = useState(false);
  const token = Cookies.get("propToken");

  useEffect(() => {
    console.log("UE:[]")
    const fetchUserData = async () => {
      try {
        setIsUserLoading(true);
        const res = await api.get("customer/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(res.data);
        setIsUserLoading(false);
      } catch (error) {
        setIsUserLoading(false);
        if (error.response) {
          setUserError(error.response);
        } else {
          setUserError(error.message);
        }
      }
    };

    fetchUserData();
  }, []);

  const [formValues, setFormValues] = useState({
    bankAccountNumber: "",
    sortCode: "",
    firstName: "",
    lastName: "",
    companyName: "",
    billingAddressLineOne: "",
    billingAddressLineTwo: "",
    city: "",
    country: "United Kingdom",
    postCode: "",
    agree: "",
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    if (name === "agree" && value === "agree") {
      setBtnDisabled(false);
    }

    if (name === "sortCode") {
      const formattedValue = maskSortCode(value);
      setFormValues({ ...formValues, [name]: formattedValue });
      return;
    }

    if (name === "postCode") setPostCodeError(false);

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const isValidPostcode = isValid(formValues.postCode);
    if (!isValidPostcode) {
      setPostCodeError(true);
      return;
    }

    const data = {
      values: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: userData.email,
        companyName: formValues.companyName,
        accountNumber: formValues.bankAccountNumber,
        branchCode: Number(formValues.sortCode.replaceAll("-", "")),
        accountHolderName: formValues.companyName ? formValues.companyName : formValues.firstName + " " + formValues.lastName,
        countryCode: "GB",
        city: formValues.city,
        postCode: formValues.postCode,
        country: formValues.country,
        subscriptionId: selectedPlanId,
        billingAddressLineOne: formValues.billingAddressLineOne,
        billingAddressLineTwo: formValues.billingAddressLineTwo,
      },
      token,
    };

    const res = await dispatch(subscribe(data));

    if (res.payload.status === 201) navigate("/dashboard?buyPlan=true");
  };

  return (
    <>
      {isUserLoading ? (
        <div className='flex items-center justify-center'>
          <CircularProgress />
        </div>
      ) : (
        <div className='flex flex-col'>
          <Link to='..' relative='path'>
            <Button
              title='Back to plans'
              className='bg-green p-4 text-sm rounded-xl mb-7 flex items-center absolute bottom-[800px] left-8'
              icon={
                <ArrowBackIosIcon
                  style={{ fontSize: "13px", marginRight: "10px" }}
                />
              }
            />
          </Link>

          <div className="absolute bottom-2 left-8 flex flex-col items-center 2xl:bottom-9 z-30">
            <div className="bg-total-white py-[2rem] px-[1.75rem] rounded-[1.5rem] ">

              <div
                className="flex flex-col justify-center items-center"
                style={{ width: '700px' }}
              >
                <h1 className=' text-4xl text-background-blue font-bold mb-1 mt4'>
                  Set up a Direct Debit with Propsmarts
                </h1>
                <PricingPlansBillingForm
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                  formValues={formValues}
                  status={status}
                  error={error}
                  btnDisabled={btnDisabled}
                  postCodeError={postCodeError}
                  freeTrial={selectedPlanId == 1}
                />

              </div>
            </div>
          </div>
        </div>
      )}

      <Toast
        severity='error'
        message={
          typeof error === "string"
            ? error
            : "Something went wrong. Please try again."
        }
        openToast={status === "failed"}
      />
      <Toast
        severity='error'
        message={
          typeof userError === "string"
            ? userError
            : "Something went wrong. Please try again."
        }
        openToast={userError}
      />
    </>
  );
};

export default PricingPlansBilling;
