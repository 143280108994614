import { sizeValues } from "helpers/epc-filter-dropdowns";
import GrayCard from "./GrayCard";
import LabelInput from "./LabelInput";
import Button from "ui/Button";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import {
  getTypeOptions,
  getYearOptions,
} from "helpers/data-tansform-functions";
import { useEffect, useState } from "react";

const EpcTableFilters = ({
  formValues,
  handleSelect,
  handleSearch,
  handleExportCsv,
}) => {
  const status = useSelector((state) => state.projects.fetchProjectStatus);
  const epc = useSelector((state) => state.projects.projectData.epc);
  const [fromYearOptions, setFromYearOptions] = useState([
    { option: "", value: "" },
  ]);
  const [toYearOptions, setToYearOptions] = useState([
    { option: "", value: "" },
  ]);
  const [typeOptions, setTypeOptions] = useState([{ option: "", value: "" }]);

  useEffect(() => {
    console.log("UE:[epc]")
    if (!epc?.statistics) return;
    setFromYearOptions(getYearOptions(epc.statistics, "from"));
    setToYearOptions(getYearOptions(epc.statistics, "to"));
    setTypeOptions(getTypeOptions(epc.statistics));
  }, [epc]);

  return (
    <GrayCard>
      <div className="flex gap-11">
        {status === "idle" || status === "loading" ? (
          <Skeleton height={100} style={{ width: "100%" }} />
        ) : (
          <>
            <LabelInput
              label="Type"
              type="select"
              labelWeight="bold"
              className="self-end"
              value={formValues.type}
              name="type"
              onChange={handleSelect}
              options={typeOptions}
            />

            <LabelInput
              label="Size"
              type="select"
              labelWeight="bold"
              className="self-end"
              value={formValues.size}
              name="size"
              onChange={handleSelect}
              options={sizeValues}
            />

            <LabelInput
              type="select"
              labelWeight="bold"
              className="self-end"
              value={formValues.fromYear}
              name="fromYear"
              onChange={handleSelect}
              options={fromYearOptions}
              selectLabel="From Year"
            />

            <LabelInput
              type="select"
              labelWeight="bold"
              className="self-end"
              value={formValues.toYear}
              name="toYear"
              onChange={handleSelect}
              options={toYearOptions}
              selectLabel="To Year"
            />

            <Button
              type="button"
              className="bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end"
              title="Search"
              onClick={handleSearch}
            />
            <Button
              type="button"
              className="bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end"
              title="Export CSV"
              onClick={handleExportCsv}
            />
          </>
        )}
      </div>
    </GrayCard>
  );
};

export default EpcTableFilters;
