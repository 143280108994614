import CustomInput from "ui/CustomInput";

const AccountSubscriptionForm = ({
  selectCurrentPlan,
  selectUpgradePlan,
  selectCancelPlan,
  selectedPlan,
}) => {
  return (
    <div className="flex flex-col gap-9">
      <CustomInput
        label="Current plan"
        arrowValue={selectedPlan.title}
        type="arrow"
        name="plan"
        noIcon={true}
        noArrow={true}
      />
      <CustomInput
        label="Upgrade subscription"
        type="arrow"
        name="upgrade"
        onClick={selectUpgradePlan}
      />
      <CustomInput
        label="Cancel subscription"
        type="arrow"
        name="cancel"
        onClick={selectCancelPlan}
      />
    </div>
  );
};

export default AccountSubscriptionForm;
