import {TableCell, TextField, InputLabel, Select} from "@mui/material";


import trashCanSVG from "../styles/img/trash-can.svg";
import CustomTextField from "../ui/CustomTextField";
import {styled} from "@mui/material/styles";


export const TCell = styled(TableCell)(({}) => ({
    "&.MuiTableCell-root": {
        padding: "6px 6px 6px 6px !important",
    },
}));


export const CSelect = styled(Select)(({}) => ({
        "&.MuiInputBase-root": {
            borderRadius: "5px",
            backgroundColor: "#fff",
            fontSize: "1rem",

            "& .MuiSelect-select": {
                padding: "6px 55px 6px 6px",
            },

            "& svg": {
                fontSize: "1.8rem",
                color: "#000",
            },

            "& fieldset": {
                border: "none",
            },
        },
    })
);
export const CTextField = styled(TextField)(({}) => ({
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& input[type=number]": {
            MozAppearance: "textfield",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "5px",
            fontWeight: "none",
            padding: "0px 0px 0px 0px !important",

            "& fieldset": {
                color: "#000",
                border: "none",
            },

            "& input": {
                padding: "6px 3px !important",
            },
        },



    })
);

export const CILabel = styled(InputLabel)(({}) => ({
        "&.MuiInputLabel-root": {
            backgroundColor: "bg-background-gray",
            fontWeight: "none",
            padding: "6px 3px 6px 30px !important",
            color: "#000",
        },
    })
);

export const CNLabel = styled(InputLabel)(({}) => ({
        "&.MuiInputLabel-root": {
            backgroundColor: "bg-background-gray",
            fontWeight: "none",
            padding: "0px 0px 0px 0px !important",
            color: "#000",
        },
    })
);

export const CHLabel = styled(InputLabel)(({}) => ({
        "&.MuiInputLabel-root": {
            backgroundColor: "bg-background-gray",
            fontWeight: "bold",
            padding: "6px 3px 6px 3px !important",
            color: "#000",
        },
    })
);


const CPSText = ({value}) => {
    return (
        <CustomTextField
            sx={{
                "& .MuiInputBase-root": {
                    boxShadow: "none",
                },
            }}
            // className="flex-1"
            borderradius="5px"
            fontSize="15px"
            inputpad="3px 5px"
            value={value}
            //onChange={onChange}
        />
    );
};

const CPHText = ({value}) => {
    return (
        <CustomTextField
            sx={{
                "& .MuiInputBase-root": {
                    boxShadow: "none",
                },
            }}
            borderradius="5px"
            inputpad="3px 5px"
            backgroundcolor="bg-background-gray"
            fontweight="bold"
            value={value}
        />
    );
};

