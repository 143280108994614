import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "services";
import { fetchUserData } from "services/auth";
import UpdatePaymentDetails from "Pages/Account/UpdatePaymentDetails";
import PricingPlansBilling from "Pages/Dashboard/PricingPlansBilling";

const Subscribed = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  let token = Cookies.get("propToken");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userDataRetrieved, setUserDataRetrieved] = useState(false);

  useEffect(() => {
        console.log("UE:[]")
        dispatch(fetchUserData(token)).then(() => setUserDataRetrieved(true));
  }, []);

  useEffect(() => {
    console.log("UE:[userData.id]")
    setIsSubscribed(userData.subscribed && !userData.locked && !userData.subscriptionExpired)
  }, [userData.id]);

  return (
        <>
        {
           userDataRetrieved ? (
               <>
                   {
                       isSubscribed ? (
                       <>
                            <Outlet />
                       </>
                       ) : (
                        <>
                           <Navigate replace to="/dashboard/account/subscription" />
                        </>
                       )
                   }
               </>
           ) : (
           <div>Loading.....</div>
           )
       }
      </>
   );
};


export default Subscribed;
