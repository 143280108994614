import { Link } from "react-router-dom";
import { Typography } from '@mui/material';


import Button from "ui/Button";

import { useSelector } from "services";
import { selectProjectId } from "services/project";
import MapPreview from "./MapPreview";
import { truncateString } from "helpers/truncate-string";
import { useEffect } from "react";

const MapProject = (props) => {
  const { className, project, key } = props;
  const projectIdUnderPolling = useSelector(selectProjectId);
  const imgsrc = "https://maps.googleapis.com/maps/api/staticmap?center="+project.latitude+","+project.longitude+"&scale=1&maptype=satellite&zoom=19&size=480x400&key=AIzaSyDQ4wU3ls_KmcAuRmeLotD5rvl6gyLqbyA"
  const ptitle = project?.projectTitle?.length <= 31 ? project.projectTitle : project.projectTitle.slice(0, 30)+"..."

  return (
        <div  style={{ height: "240px", width: "240px" }} className={"rounded-t-3xl rounded-b-3xl bg-background-blue overflow-hidden"}>

          <Link to={`details/${project.projectId}`}>
          {!project.latitude || !project.longitude ? (
            <img width={"200px"} height={"150px"} src="data:," alt="map" />
          ) : (
                <div>
                    <img
                     style={{ height: "200px", width: "240px" }}
                     src={imgsrc}
                     alt="Loading map..."/>
                 </div>
          )}
          <div className="py-2 px-4 bg-background-blue flex justify-between items-center rounded-b-3xl">
            <div>
              <Typography noWrap variant="caption" display="block"  className="font-bold text-total-white overflow-hidden nowrap">
                {ptitle}
              </Typography>
            </div>
          </div>
          </Link>
        </div>
      );
};

export default MapProject;
