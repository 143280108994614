import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoBox, InfoWindow } from '@react-google-maps/api';
import { moneyFormat, formatNumber } from "helpers/money"
import { useEffect, useState } from "react";


import {useSelector} from "services";
import ReactJson from 'react-json-view'
import GrayCard from "common/GrayCard";




const containerStyle = {
  width: '100%',
  height: '95vh'
};


const MyComponent = ({data, mapZoom}) => {

    const {projectData} = useSelector(
        (state) => state.projects
    );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDQ4wU3ls_KmcAuRmeLotD5rvl6gyLqbyA"
  })

  const [map, setMap] = React.useState(null)
  const [bounds, setBounds] = React.useState(null)


      const centre = {
        lat: parseFloat(projectData.latitude),
        lng: parseFloat(projectData.longitute)
      };

    const optionsMap = {
      center: centre,
      mapTypeId: 'satellite',
      scrollwheel: false
    }


  const onLoad = React.useCallback(function callback(map, x) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  useEffect(() => {
    console.log("UE:[map,data]")
    if (map) {
          const b = new window.google.maps.LatLngBounds();

          data?.map(marker => {
            b.extend({
              lat: marker.lat,
              lng: marker.lng,
            });
          });

          b.extend(centre)
          setBounds(b)
          map.fitBounds(b);
          map.setCenter(b.getCenter())
    }
  }, [map, data]);

    useEffect(() => {
      console.log("UE:[mapZoom]")
      console.log("useEffect salesMap: mapZoom")
      if (map && mapZoom) {
          map.setCenter({lat: mapZoom.lat, lng: mapZoom.lng})
          map.setZoom(22);
      }
    }, [mapZoom]);

  return isLoaded ? (

    <>

                <div className="mt-10">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    options={optionsMap}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={() => {
                        map.fitBounds(bounds)
                        }}
                  >
                          <Marker
                             icon={{

                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: "yellow",
                                    fillOpacity: 0.9,
                                    scale:15,
                                    strokeColor: "yellow",
                                    strokeWeight: 2,
                                  }}
                            position={centre}
                            visible={true}
                            onClick={() => {
                                 map.setZoom(22);
                                 map.setCenter(centre)
                             }}
                          />

                {

                        data?.map((row, index) => (
                            <Marker
                              key={index}
                              position={{ lat: row.lat, lng: row.lng}}
                              visible={true}
                              label={index.toString()}
                              onClick={() => {
                                           map.setZoom(22);
                                           map.setCenter({ lat: row.lat, lng: row.lng});
                                         }}
                            >
                                <InfoBox
                                    key={index}
                                    visible={true}
                                    position={{ lat: row.lat, lng: row.lng}}
                                >
                                      <div style={{ backgroundColor: 'white', opacity: 0.85, padding: 12}} className={'rounded-md'}>
                                       <div style={{ fontSize: '0.9rem',  fontWeight: 'bold' , display: 'inline-block'}}>
                                          {row.address.split(",",2).join(" ")}
                                         </div>
                                       <div style={{ fontSize: '0.9rem' }}>
                                         {row.bedrooms} bed {row.type.replace('_', ' ')}
                                       </div>
                                       <div style={{ fontSize: '0.9rem' }}>
                                         Price: <span style={{ fontWeight: 'bold' }}>{moneyFormat(row.price)}</span>
                                        </div>
                                      </div>
                                </InfoBox>
                            </Marker>
                        ))
                    }


                  </GoogleMap>
                  </div>



     </>
  ) : <></>
}

export default React.memo(MyComponent)