import { NavLink, useLocation } from "react-router-dom";

const ProfileSidebar = (props) => {
  const location = useLocation();
  const locationSubscriptionActive = location.pathname.startsWith(
    "/dashboard/account/subscription"
  );

  return (
    <aside className="pl-24 h-screen bg-[#f1f1f1] fixed">
      <div className="pt-10">
        <h1 className="text-3xl font-bold mb-8 ml-7 mr-11">Account settings</h1>
        <ul className="flex flex-col">
          <NavLink
            to="/dashboard/account"
            className="text-xl pl-7 py-5"
            style={({ isActive }) =>
              isActive ? { backgroundColor: "#e5e9f2" } : {}
            }
            end
          >
            Account
          </NavLink>
          <NavLink
            to="/dashboard/account/subscription"
            className="text-xl pl-7 py-5"
            style={({ isActive }) =>
              isActive || locationSubscriptionActive
                ? { backgroundColor: "#e5e9f2" }
                : {}
            }
            end
          >
            Manage subscription
          </NavLink>
        </ul>
      </div>
    </aside>
  );
};

export default ProfileSidebar;
