import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import Button from "ui/Button";
import ProjectReportPhase1 from "./ProjectReportPhase1";
import ProjectReportPhase2 from "./ProjectReportPhase2";

const ProjectReport = (props) => {
  const { projectData, fetchProjectStatus } = useSelector(
    (state) => state.projects
  );
  const [formValues, setFormValues] = useState({
    title: "",
    date: new Date().toISOString().split("T")[0],
    image: "",
    logo: "",
    color: "",
  });

  useEffect(() => {
    setFormValues({
      ...formValues,
      title: projectData.title,
    });
  }, [projectData]);

  const [phase1, setPhase1] = useState(true);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleColorChange = (c) => {
    setFormValues({ ...formValues, color: c });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setPhase1(false);
  };
  const handleLogoChange = (file) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      logo: file,
    }));
  };
  const handleImageChange = (file) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      image: file,
    }));
  };

  return (
    <>
      <ProjectLayoutHeader header='Report' />
      <Topbar />
      {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
        <Skeleton />
      ) : phase1 ? (
        <>
          <ProjectReportPhase1
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleColorChange={handleColorChange}
            handleLogoChange={handleLogoChange}
            handleImageChange={handleImageChange}
          />
          <div className='flex justify-end mt-5'>
            <Button
              title='Next'
              className='bg-green py-2 px-11 rounded-2xl text-xl'
              type='button'
              onClick={handleSubmit}
            />
          </div>
        </>
      ) : (
        <ProjectReportPhase2 formValues={formValues} />
      )}
    </>
  );
};

export default ProjectReport;
