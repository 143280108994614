import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  approved: -1,
  submitted: -1,
  granted: -1,
  filteredData: null,
  applyFiltering: false,
  showPlanning: false,
  planningUrl: "",
};
const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    setApproved(state, action) {
      state.approved = action.payload;
      state.applyFiltering = false;
    },
    setSubmitted(state, action) {
      state.submitted = action.payload;
      state.applyFiltering = false;
    },
    setGranted(state, action) {
      state.granted = action.payload;
      state.applyFiltering = false;
    },
    setApplyFiltering(state) {
      state.applyFiltering = true;
    },
    setShowPlanning(state, action) {
      state.showPlanning = action.payload.show;
      state.planningUrl = action.payload.url;
    },
  },
});

export default planningSlice;

export const {
  setApproved,
  setSubmitted,
  setGranted,
  setApplyFiltering,
  setShowPlanning,
} = planningSlice.actions;

export const selectApproved = ({ planning }) => planning.approved;
export const selectSubmitted = ({ planning }) => planning.submitted;
export const selectGranted = ({ planning }) => planning.granted;
export const selectApplyFiltering = ({ planning }) => planning.applyFiltering;
export const selectProjectData = ({ projects }) => projects.projectData;
export const selectShowPlanning = ({ planning }) => planning.showPlanning;
export const selectPlanningUrl = ({ planning }) => planning.planningUrl;
