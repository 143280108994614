import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { StyledEditor } from "./styles";

export const formats = [
  "align",
  "background",
  "blockquote",
  "bold",
  "bullet",
  "code",
  "code-block",
  "color",
  "direction",
  "font",
  "formula",
  "header",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "script",
  "size",
  "strike",
  "table",
  "underline",
  "emoji",
];

export default function Editor({
  customId,
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
}) {
  const [modules] = useState(() => ({
    toolbar: {
      container: "#minimal-quill" + customId,
    },
    history: {
      delay: 500,
      maxStack: 300,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  }));

  const [isEditing, setIsEditing] = useState(true);

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  return (
    <div onMouseEnter={handleEdit} onMouseLeave={handleBlur}>
      <h1 className='text-3xl leading-6 mb-10'> </h1>
      {!isEditing ? (
        <div className='ml-[-15px]' onClick={handleEdit}>
          <ReactQuill value={value} readOnly={true} theme={"bubble"} />
        </div>
      ) : (
        <div>
          <StyledEditor
            style={{
              ...(error && {
                border: `red`,
              }),
            }}
          >
            <ReactQuill
              value={value}
              onChange={onChange}
              theme={"snow"}
              placeholder='Write your Description'
            />
          </StyledEditor>

          {helperText && helperText}
        </div>
      )}
    </div>
  );
}
