import Slider from "react-slick";

import MapProject from "common/MapProject";

import { Fragment } from "react";
import "styles/mapProjectList.css";

const MapProjectList = ({ className, projects }) => {
  const sliderSettings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
  };
  return (
    // <section>
    //   <Slider {...sliderSettings} className={`overflow-hidden ${className}`}>
    //     {projects &&
    //       projects.length > 0 &&
    //       projects.map((project, index) => (
    //         <Fragment key={project.projectId}>
    //           <MapProject project={project} />
    //         </Fragment>
    //       ))}
    //   </Slider>
    // </section>


      <section>
        <div className="flex flex-wrap gap-6">
          {projects &&
              projects.length > 0 &&
              projects.map((project, index) => (
                   <Fragment key={project.projectId}>
                      <MapProject project={project} />
                   </Fragment>
              ))}
        </div>
      </section>

  );
};

export default MapProjectList;
