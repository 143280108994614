import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";


const initialState = {
    status: "idle",
    fetchFeasibilityStatus: "initial",
    error: "",
    schedules: [ { plot: '', template: '', quantity: '', predictedNet: '', adjustedNet: '', gross: ''} ],
    section: [
            {
               name: "Land",
               cost: [
                   {name: "Land purchase price", quantity: "", value: "", total: ""},
               ]
            },
            {
               name: "Acquisition",
               cost: [
                   {name: "Legal/Conveyancing Fees ", quantity: "", value: "", total: ""},
                   {name: "Sourcing Fees", quantity: "", value: "", total: ""},
                   {name: "Legal fees", quantity: "", value: "", total: ""},
                   {name: "Valuation Fees", quantity: "", value: "", total: ""},
                   {name: "SDLT fees", quantity: "", value: "", total: ""},
               ]
            },
            {
               name: "Works",
               cost: [
                   {name: "Build cost (Sq ft)", quantity: "", value: "", total: ""},
                   {name: "Landscaping", quantity: "", value: "", total: ""},
                   {name: "Estate road", quantity: "", value: "", total: ""},
                   {name: "Utilities", quantity: "", value: "", total: ""},
                   {name: "Site clearance", quantity: "", value: "", total: ""},
               ]
            },
            {
               name: "Additional",
               cost: [
               //    {name: "Contingency funds", quantity: "", value: "", total: ""},
                   {name: "Professional fees", quantity: "", value: "", total: ""},
                   {name: "Insurance", quantity: "", value: "", total: ""},
                   {name: "Section 106 contributions", quantity: "", value: "", total: ""},
                   {name: "Mining exploration/works", quantity: "", value: "", total: ""},
               ]
            },
            {
               name: "Indicative",
               cost: [
                   {name: "Indicative finance cost", quantity: "", value: "", total: ""},
               ]
            },
     ],
    custom:[
       {name: "", quantity: "", value: "", total: ""},
    ],

    contingency: {
        fixed: {
           value: 0, total: 0
        },
        percentage: {
           value: 0, total: 0
        }
    },
    summary: {
        totalGdv: 0,
        totalPredictedNdv: 0,
        totalAdjustedNdv: 0,
        totalWithoutContingency: 0,
        totalCost: 0,
        costOfSale: 0,
        profit: 0,
        profitOnGDV: 0,
        profitOnNet: 0,
        profitOnCost: 0,
        totalArea: 0,
    }
};

function calculateProfit(state) {
    state.summary.costOfSale = state.summary.totalGdv -  state.summary.totalAdjustedNdv
    state.summary.profit = state.summary.totalAdjustedNdv - state.summary.totalCost
    state.summary.profitOnGDV = Math.round((state.summary.profit/state.summary.totalGdv)*100)
    state.summary.profitOnNet = Math.round((state.summary.profit/state.summary.totalAdjustedNdv)*100)
    state.summary.profitOnCost = Math.round((state.summary.profit/state.summary.totalCost)*100)
}

export const fetchFeasibility = createAsyncThunk<any, any, any>(
    "get/report-generation/projects/feasibility",
    async (params, thunkApi) => {
        const { projectId, token } = params;
        console.log("fetchFeasibility");
        const response = await api.get(
            `report-generation/projects/feasibility`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    projectId,
                },
            }
        );
        console.log(JSON.stringify(response.data))
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        if (response.data.section == null){
            console.log("xxx Returning null feasibility data")
            return thunkApi.fulfillWithValue({...initialState})
        }

        return thunkApi.fulfillWithValue(response.data);
    }
);

export const createFeasibility = createAsyncThunk<any, any, any>(
    "post/report-generation/projects/feasibility",
    async (params, thunkApi) => {
        const { projectId, body, token } = params;
        console.log("CreateFeasibility "+projectId+"  "+body+"  "+token)
        const response = await api.post(
            `report-generation/projects/feasibility`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    projectId,
                },
            }
        );
        console.log("CreateFeasibility: "+ response.data)

        if (response.status >= 400) {

            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);

function calculateScheduleSummary(state) {
    console.log("calculateScheduleSummary")

    let  summary =  {
            totalGdv: 0,
            totalPredictedNdv: 0,
            totalAdjustedNdv: 0,
            totalWithoutContingency: 0,
            totalCost: 0,
            costOfSale: 0,
            profit: 0,
            profitOnGDV: 0,
            profitOnNet: 0,
            profitOnCost: 0,
            totalArea: 0,
        }

    // TODO does this perform?
    function summarize(value, index, array) {
     summary.totalAdjustedNdv = filterZero(summary.totalAdjustedNdv, value.quantity, value.adjustedNet)
     summary.totalGdv = filterZero(summary.totalGdv, value.quantity, value.gross)
     summary.totalPredictedNdv += value.predictedNet
     summary.totalArea += value.area
    }
    state.schedules.forEach(summarize);

    state.summary.totalArea = summary.totalArea
    state.summary.totalGdv = summary.totalGdv >= 0 ? summary.totalGdv : 0
    state.summary.totalPredictedNdv = summary.totalPredictedNdv >= 0 ? summary.totalPredictedNdv : 0
    state.summary.totalAdjustedNdv = summary.totalAdjustedNdv >= 0 ? summary.totalAdjustedNdv : 0
}

function calculateTotalCost(state) {
    console.log("calculateTotalCost");

    let totalCost = 0
    state.section.forEach(function (section) {
        section.cost.forEach(function (cost) {
            let total = parseFloat(cost.total)
            totalCost = totalCost + (total >= 0 ? total : 0)
        });
    });
    state.custom.forEach(function (cost) {
        let total = parseFloat(cost.total)
        totalCost = totalCost + (total >= 0 ? total : 0)
    });
    console.log("TotalCostWithoutContingency:: "+totalCost)
    state.summary.totalWithoutContingency = totalCost

    if (state?.contingency?.percentage?.value > 0) {
        state.contingency.percentage.total = (totalCost/100)*state.contingency.percentage.value
        totalCost = totalCost + state.contingency.percentage.total
        console.log("TotalCostWithPercentageContingency:: "+totalCost)
    } else {
        state.contingency.percentage.total = 0;
    }

    if (state?.contingency?.fixed?.value > 0 ) {
        state.contingency.fixed.total = state.contingency.fixed.value
        totalCost = totalCost + state.contingency.fixed.total
        console.log("TotalCostWithFixedContingency:: "+totalCost)
    } else {
        state.contingency.fixed.total = 0;
    }
    state.summary.totalCost = totalCost
}

function filterZero(summary, quantity, value) {
    return (parseInt(quantity) * parseFloat(value)) + parseFloat(summary)
}

const feasibilitySlice = createSlice({
    name: "feasibility",
    initialState,
    reducers: {

    //  Schedules
        addSchedule(state, action) {
            console.log("feasibility action: addSchedule")
            state.schedules.push({ plot: "", template: "", quantity: "", predictedNet: "", adjustedNet: "", gross: ""})
        },
        updateSchedule(state, action) {
            console.log("feasibility action: updateSchedule :" + action.payload.name + " : " +action.payload.value)
            state.schedules[action.payload.index][action.payload.name] = action.payload.value
            calculateScheduleSummary(state)
            calculateTotalCost(state)
            calculateProfit(state)

        },
        deleteSchedule(state, action) {
            console.log("feasibility action: deleteSchedule :" + action.payload.index)
            state.schedules.splice(action.payload.index, 1)
            calculateScheduleSummary(state)
            calculateTotalCost(state)
            calculateProfit(state)
        },

    // Custom costs
        addCustomCost(state, action){
            console.log("feasibility action: addCustomCost")
            state.custom.push({name: "", quantity: "", value: "", total: ""})
        },
        updateCustomCost(state, action){
            console.log("feasibility action: updateCustomCost :" + action.payload.name + " : " +action.payload.value)
            state.custom[action.payload.index][action.payload.name] = action.payload.value
            let value =  parseFloat(state.custom[action.payload.index]['value'])
            let quantity = parseInt(state.custom[action.payload.index]['quantity'])
            let total = (quantity > 0 && value > 0) ? quantity * value : ''
            state.custom[action.payload.index]['total'] = total.toString()
            calculateTotalCost(state)
            calculateProfit(state)
        },
        deleteCustomCost(state, action){
            console.log("feasibility action: deleteCustomCost :" + action.payload.index)
            state.custom.splice(action.payload.index, 1)
           calculateTotalCost(state)
           calculateProfit(state)
        },

    // Default costs
        setCost(state, action) {
            console.log("feasibility action: setCost :" + action.payload.name + " : " +action.payload.value)
            state.section[action.payload.sectionIndex].cost[action.payload.costIndex][action.payload.name] = action.payload.value
            let value =  parseFloat(state.section[action.payload.sectionIndex].cost[action.payload.costIndex]['value'])
            let quantity = parseInt(state.section[action.payload.sectionIndex].cost[action.payload.costIndex]['quantity'])
            let total = (quantity > 0 && value > 0) ? quantity * value : ''
            state.section[action.payload.sectionIndex].cost[action.payload.costIndex]['total'] = total.toString()
            calculateTotalCost(state)
            calculateProfit(state)
        },

    // Set the area - this is dynamically set based off schedules and propertyTemplates
        setArea(state, action) {
            console.log("feasibility action: setArea :" + action.payload.area)
            // Is this a bit hacky ??
            state.section[2].cost[0].quantity =  action?.payload?.area

            if (state.section[2].cost[0].quantity != null && state.section[2].cost[0].value != null){
                state.section[2].cost[0].total = (parseInt(state.section[2].cost[0]?.quantity) * parseInt(state.section[2].cost[0]?.value)).toString()
            }
            calculateTotalCost(state)
            calculateProfit(state)
        },

        updateContingencyPercentage(state, action) {
            console.log("feasibility action: updateContingencyPercentage :" + action.payload.value)
            if (state.contingency == null){
                state.contingency = {fixed: {value: 0, total: 0}, percentage:{value: 0, total: 0}}
            }
            state.contingency.percentage.value = parseFloat(action.payload.value)
            state.contingency.percentage.total = parseFloat(action.payload.value)
            calculateTotalCost(state)
            calculateProfit(state)
        },
        updateFixedContingency(state, action) {
            console.log("feasibility action: updateFixedContingency :" + action.payload.value)
            if (state.contingency == null){
                state.contingency = {fixed: {value: 0, total: 0}, percentage:{value: 0, total: 0}}
            }
            state.contingency.fixed = { value:parseFloat(action.payload.value), total:parseFloat(action.payload.value) }
            calculateTotalCost(state)
            calculateProfit(state)
        },
        reCalculateTotalCost(state, action) {
            calculateTotalCost(state)
            calculateProfit(state)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFeasibility.pending, (state, action) => {
                console.log("[service]fetchFeasibility.pending");
                state.fetchFeasibilityStatus = "loading"
                state.status = "loading";
            })
            .addCase(fetchFeasibility.fulfilled, (state, action) => {
                console.log("[service]fetchFeasibility.fulfilled ::"+state?.fetchFeasibilityStatus);
                //console.log(JSON.stringify(action.payload));

                if (action.payload.section != null) {
                    state.schedules = action.payload.schedules;
                    state.section = action.payload.section;
                    state.custom = action.payload.custom;
                    state.summary = action.payload.summary;
                    state.contingency = action.payload.contingency;
                } else {
                console.log("fetchFeasibility.fulfilled null section");
                    state = {...initialState}

                    state.summary = {
                                                totalGdv: 0,
                                                totalPredictedNdv: 0,
                                                totalAdjustedNdv: 0,
                                                totalWithoutContingency: 0,
                                                totalCost: 0,
                                                costOfSale: 0,
                                                profit: 0,
                                                profitOnGDV: 0,
                                                profitOnNet: 0,
                                                profitOnCost: 0,
                                                totalArea: 0,
                                            }
                }
                state.status = "success";
                state.fetchFeasibilityStatus = "success"
                console.log("xxx fetchFeasibility.fulfilled :::"+state?.fetchFeasibilityStatus);
            })
            .addCase(fetchFeasibility.rejected, (state, action) => {
                console.log("fetchFeasibility - failed");
                state.status = "failed";
                state.fetchFeasibilityStatus = "error"

            })
            .addCase(createFeasibility.pending, (state, action) => {
                console.log("createFeasibility - loading "+action.payload);
            })
            .addCase(createFeasibility.fulfilled, (state, action) => {
                console.log("createFeasibility - success : "+ action.payload);
            })
            .addCase(createFeasibility.rejected, (state, action) => {
                console.log("createFeasibility - failed");
            })
    },
});


export default feasibilitySlice;

export const {
  addSchedule,
  updateSchedule,
  deleteSchedule,
  setCost,
  addCustomCost,
  updateCustomCost,
  deleteCustomCost,
  setArea,
  updateContingencyPercentage,
  updateFixedContingency,
  reCalculateTotalCost,
} = feasibilitySlice.actions;
