export const formatDate = (date) => {
  if (!date) return;

  const dateObj = new Date(date);

  const formattedDate = dateObj.toLocaleDateString("en-US");
  return formattedDate;
};
export function normalizeDate(e) {
  const date = new Date(e);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);

  return `${day}/${month}/${year}`;
}
export const reverseDate = (date) => {
  if (!date) return;

  const timestamp = "2023-06-25T09:05:59.649358";
  const fullDate = new Date(timestamp);
  const year = fullDate.getFullYear();
  const month = String(fullDate.getMonth() + 1).padStart(2, "0");
  const day = String(fullDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
