import CustomDialog from "ui/CustomDialog";
import Dialog from '@mui/material/Dialog';


const Modal = (props) => {
  const { onClose, open, content, maxWidth, fullWidth, className } = props;

  return (

    <CustomDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth ? maxWidth : "sm"}
      fullWidth={fullWidth}
    >
      <html>
      <div className={`flex flex-col items-start ${className}`}>{content}</div>
      </html>
    </CustomDialog>
  );
};

export default Modal;
