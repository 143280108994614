import { useEffect, useState } from "react";

import { Alert, Snackbar } from "@mui/material";

const Toast = ({ severity, message, openToast }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("UE:[openToast]")
    setOpen(openToast);
  }, [openToast]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
