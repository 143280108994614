// import { useState } from "react";

// import AccountAdminForm from "forms/AccountAdminForm";
import AccountBasicInfoForm from "forms/AccountBasicInfoForm";
import AccountInfoForm from "forms/AccountInfoForm";

import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "services";
import { fetchUserData } from "services/auth";
// import account from "styles/img/account-user.png";
import Toast from "ui/Toast";

const Account = (props) => {
  const dispatch = useDispatch();
  const token = Cookies.get("propToken");
  const state = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    console.log("UE:[]")
    dispatch(fetchUserData(token));
  }, []);

  return (
    <>
      {state !== "success" ? (
        <div className="flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex">
          <div className="flex flex-col flex-1">
            <div className="bg-background-blue h-screen max-h-[50vh]">
              <div className="flex items-center justify-center h-full relative">
                <div className="flex flex-col items-center">
                  <span className="bg-[#0086b3] w-[200px] h-[200px] rounded-[50%] flex justify-center items-center">
                    <p className="text-4xl text-background-blue">
                      {userData.name.at(0).toUpperCase()}
                    </p>
                  </span>
                  <h1 className="text-4xl text-total-white my-1">
                    {userData.name}
                  </h1>
                </div>
              </div>
            </div>

            <div className="h-full relative pt-8 pl-9 mb-20">
              <div className="w-[75%]">
                <h2 className="text-3xl text-background-blue font-bold mb-6 mt-11">
                  Account info
                </h2>
                <AccountInfoForm name={userData.name} email={userData.email} />
              </div>
            </div>
          </div>
        </div>
      )}

      <Toast
        severity="error"
        message={
          typeof error === "string"
            ? error
            : "Something went wrong. Please try again."
        }
        openToast={error}
      />
    </>
  );
};

export default Account;
