import { normalizeDate } from "helpers/date-format";
import { useSelector } from "react-redux";
import logo from "styles/img/logo.svg";
import rics from "styles/img/rics.svg";
import GrayCard from "./GrayCard";
import MapPreview from "./MapPreview";

const ProjectReportCover = ({ className, formValues }) => {
  const { projectData } = useSelector((state) => state.projects);
  console.log(projectData?.latitude, projectData?.longitute);
  return (
    <GrayCard className={className}>
      <div className='flex h-full'>
        <div
          className='bg-background-blue flex flex-col justify-center px-8 py-1.5 rounded-l-xl'
          style={{
            backgroundColor: formValues.color ? formValues.color : "#141831",
          }}
        >
          <div className='flex flex-col h-full justify-center'>
            <h2 className='text-total-white text-3xl mb-2'>
              {formValues.title ? formValues.title : projectData.title}
            </h2>
            <p className='text-total-white text-base mb-2'>
              {projectData.streetAddress} {projectData.streetNumber}.{" "}
              {projectData.postcode}
            </p>
            <p className='text-total-white text-lg'>
              Date:{" "}
              {formValues.date
                ? normalizeDate(formValues.date)
                : normalizeDate(projectData.createdAt)}
            </p>
          </div>

          <div className='flex flex-grow justify-start items-end'>
            <div className='flex flex-row'>
              <img
                src={
                  formValues.logo ? URL.createObjectURL(formValues.logo) : logo
                }
                alt='logo'
                className='w-[32px] mb-1 mr-2'
              />
              <img
                src={
                  formValues.image
                    ? URL.createObjectURL(formValues.image)
                    : rics
                }
                alt='rics-logo'
                className='w-[80px]'
              />
            </div>
          </div>
        </div>

        <div className='flex-1'>
          {projectData?.latitude && projectData?.longitute && (
            <MapPreview
              mapHeight='100%'
              className='w-full'
              zoom={17}
              lat={projectData?.latitude}
              long={projectData?.longitute}
            />
          )}
        </div>
      </div>
    </GrayCard>
  );
};

export default ProjectReportCover;
