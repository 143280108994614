import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomRadioInput = styled(FormControlLabel)(
  ({ theme, fontsize = "20px", fontweight = "400" }) => ({
    "&.MuiFormControlLabel-root": {
      "& .MuiButtonBase-root": {
        color: "#000",

        "& .MuiSvgIcon-root:nth-of-type(2)": {
          fill: "#50EBBE",
        },
      },

      "& .MuiTypography-root": {
        fontSize: `${fontsize}`,
        fontWeight: `${fontweight}`,
        fontFamily: "Urbanist",
      },
    },
  })
);

export default CustomRadioInput;
