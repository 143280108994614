import { Checkbox, CheckboxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)(({}) => ({
  "&.MuiCheckbox-root.Mui-checked": {
    color: "#50EBBE",
  },
}));

interface StatusProps extends CheckboxProps {
  isSuccess: boolean;
}
export const StyledStatusCheckBox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "isSuccess",
})<StatusProps>(({ theme, isSuccess }) => ({
  "&.MuiCheckbox-root.Mui-checked": {
    color: isSuccess ? "#50EBBE" : "#FF4B4B",
  },
}));

export default CustomCheckbox;
