export const privacyNotice = `
Website Privacy Policy

<h1>1 INTRODUCTION</h1>
1.1 Important information and who we are
Welcome to Propsmarts.com Limited’s Privacy and Data Protection Policy (“Privacy Policy
”).
At Propsmarts.com Limited (“we”, “us”, or “our”) we are committed to protecting and
respecting your privacy and Personal Data in compliance with the United Kingdom General
Data Protection Regulation (“GDPR”), the Data Protection Act 2018 and all other mandatory
laws and regulations of the United Kingdom.
This Privacy Policy explains how we collect, process and keep your data safe. The Privacy
Policy will tell you about your privacy rights, how the law protects you, and inform our
employees and staff members of all their obligations and protocols when processing data.
The individuals from which we may gather and use data can include:
Customers
Suppliers
Business contacts
Employees/Staff Members
Third parties connected to your customers
and any other people that the organisation has a relationship with or may need to contact.
This Privacy Policy applies to all our employees and staff members and all Personal Data
processed at any time by us.

2

1.2 Your Data Controller
Propsmarts.com Limited is your Data Controller and responsible for your Personal Data.
We are not obliged by the GDPR to appoint a data protection officer and have not
voluntarily appointed one at this time. Therefore, any inquiries about your data should
either be sent to us by email to enqiries@propsmarts.com or by post to Welsh Ice
Britannia House, , Caerphilly Business Park, , Caerphilly,, CF83 3GG, United Kingdom.
You have the right to make a complaint at any time to the Information Commissioner’s
Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We
would, however, appreciate the chance to deal with your concerns before you approach
the ICO so please contact us in the first instance.
1.3 Processing data on behalf of a Controller and processors’ responsibility to you
In discharging our responsibilities as a Data Controller we have employees who will deal
with your data on our behalf (known as “Processors”). The responsibilities below may be
assigned to an individual or may be taken to apply to the organisation as a whole. The
Data Controller and our Processors have the following responsibilities:
Ensure that all processing of Personal Data is governed by one of the legal bases
laid out in the GDPR (see 2.2 below for more information);
Ensure that Processors authorised to process Personal Data have committed
themselves to confidentiality or are under an appropriate statutory obligation of
confidentiality;
Implement appropriate technical and organisational measures to ensure a level of
security appropriate to the risk associated with the processing of Personal Data;
Obtain the prior specific or general authorisation of the Controller before engaging
another Processor;
Assist the Controller in the fulfilment of the Controller's obligation to respond to
requests for exercising the data subject's rights;
Make available to the Controller all information necessary to demonstrate
compliance with the obligations laid down in the GDPR and allow for and contribute
to audits, including inspections, conducted by the Controller or another auditor
mandated by the Controller;
Maintain a record of all categories of processing activities carried out on behalf of a
Controller;
Cooperate, on request, with the supervisory authority in the performance of its
tasks;
Ensure that any person acting under the authority of the Processor who has access
to Personal Data does not process Personal Data except on instructions from the
Controller; and
Notify the Controller without undue delay after becoming aware of a Personal Data
Breach.

2 LEGAL BASIS FOR DATA COLLECTION
2.1 Types of data / Privacy policy scope

3

“Personal Data” means any information about an individual from which that person can
be identified. It does not include data where the identity has been removed (anonymous
data).
We may collect, use, store and transfer different kinds of Personal Data about you which
we have grouped together below. Not all of the following types of data will necessarily be
collected from you but this is the full scope of data that we collect and when we collect it
from you:
Profile/Identity Data: This is data relating to your first name, last name, gender, date
of birth.
Billing Data: This is information relating to your debit and credit card information
such as the name attached to your payment details and your billing address.
Transactional Data: This is information of details and records of all payments you
have made for our services or products.
Usage Data: information about how you use our website, products and services.
We do not collect any Special Categories of Personal Data about you (this includes details
about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
political opinions, trade union membership, information about your health, and genetic
and biometric data). Nor do we collect any information about criminal convictions and
offences.
2.2 The Legal Basis for Collecting That Data
There are a number of justifiable reasons under the GDPR that allow collection and
processing of Personal Data. The main avenues we rely on are:
“Consent”: Certain situations allow us to collect your Personal Data, such as when
you tick a box that confirms you are happy to receive email newsletters from us, or
‘opt in’ to a service.
“Contractual Obligations”: We may require certain information from you in order
to fulfil our contractual obligations and provide you with the promised service.
“Legal Compliance”: We’re required by law to collect and process certain types of
data, such as fraudulent activity or other illegal actions.
“Legitimate Interest”: We might need to collect certain information from you to be
able to meet our legitimate interests - this covers aspects that can be reasonably
expected as part of running our business, that will not have a material impact on
your rights, freedom or interests. Examples could be your address, so that we know
where to deliver something to, or your name, so that we have a record of who to
contact moving forwards.

3 HOW WE USE YOUR PERSONAL DATA
3.1 Our data uses
We will only use your Personal Data when the law allows us to.
Set out below is a table containing the different types of Personal Data we collect and the
lawful basis for processing that data. Please refer to section 2.2 for more information on
the lawful basis listed in the table below.

4

Examples provided in the table below are indicative in nature and the purposes for which
we use your data may be broader than described but we will never process your data
without a legal basis for doing so and it is for a related purpose. For further inquiries
please contact us.
Activity Type of
data

Legal
Justification

Lawful basis for
processing data

when a customer
signs up Contact
Data
Billing
Data

Consent

legitimate interest

3.2 Change of purpose
We will only use your Personal Data for the purposes for which we collected it, unless we
reasonably consider that we need to use it for another reason and that reason is
compatible with the original purpose. If you wish to get an explanation as to how the
processing for the new purpose is compatible with the original purpose, please contact us.
If we need to use your Personal Data for an unrelated purpose, we will notify you and we
will explain the legal basis which allows us to do so.
Please note that we may process your Personal Data without your knowledge or consent,
in compliance with the above rules, where this is required or permitted by law.

4 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US
4.1 Your legal rights
Under certain circumstances, you have the following rights under data protection laws in
relation to your personal data:
Right to be informed. You have a right to be informed about our purposes for
processing your personal data, how long we store it for, and who it will be shared
with. We have provided this information to you in this policy.
Right of access. This enables you to receive a copy of the personal data we hold
about you and to check that we are lawfully processing it (also known as a "data
subject access request"). See section 4.4 below for more details on how you can
make a data subject access request.
Right to rectification. You have a right to request correction of the personal data
that we hold about you. This enables you to have any incomplete or inaccurate data
we hold about you corrected, though we may need to verify the accuracy of the new
data you provide to us.
Right to erasure. You have the right to ask us to delete or remove personal data
where there is no good reason for us continuing to process it, where you have
successfully exercised your right to object to processing (see below), where we may
have processed your information unlawfully or where we are required to erase your
personal data to comply with local law. Note, however, that we may not always be

5

able to comply with your request of erasure for specific legal reasons which will be
notified to you, if applicable, at the time of your request.
Right to object. You can object to the processing of personal data we hold about
you. This effectively allows you to stop or prevent us from processing your personal
data. Note that this is not an absolute right and it only applies in certain
circumstances, for example:
(i) Where we are processing your personal data for direct marketing purposes.
(ii) Where we are relying on a legitimate interest (or those of a third party) and
there is something about your particular situation which makes you want to
object to processing on this ground as you feel it impacts on your
fundamental rights and freedoms.
(iii) In some cases, we may continue processing your data if we can demonstrate
that we have compelling legitimate grounds to process your information
which override your rights and freedoms.
Right to restrict processing. You have the right to request the restriction or
suppression of their personal data. Note that this is not an absolute right and it only
applies in certain circumstances:
(i) If you want us to establish the data's accuracy.
(ii) Where our use of the data is unlawful but you do not want us to erase it.
(iii) Where you need us to hold the data even if we no longer require it as you
need it to establish, exercise or defend legal claims.
(iv) You have objected to our use of your data but we need to verify whether we
have overriding legitimate grounds to use it.
Right to data portability. You have the right to request the transfer of your
personal data to you or to a third party. If you make such a request, we will provide
to you, or a third party you have chosen, your personal data in a structured,
commonly used, machine-readable format. Note that this right only applies to
automated information which you initially provided consent for us to use or where
we used the information to perform a contract with you.
If you wish to make a request under any of these rights, please contact us at
enqiries@propsmarts.com.
4.2 Your control over Propsmarts.com Limited’s use of your Personal Data
You may delete your account at any time – this will remove your account page from our
systems and our related software.
We guarantee this will delete all stored data.
You can access information associated with your account by logging into your account you
created with us.
Your account information will be protected by a password for your privacy and security.
You need to prevent unauthorized access to your account and personal information by
selecting and protecting your password appropriately and limiting access to your
computer or device and by signing off after you have finished accessing your account.
California Privacy Rights: Under California Civil Code sections 1798.83-1798.84, California
residents are entitled to ask us for a notice identifying the categories of personal customer

6

information which we share with our affiliates and/or third parties for marketing purposes,
and providing contact information for such affiliates and/or third parties. If you are a
California resident and would like a copy of this notice, please submit a written request to
enqiries@propsmarts.com.
4.3 How Propsmarts.com Limited protects customers' Personal Data
We are concerned with keeping your data secure and protecting it from inappropriate
disclosure. Any Personal Data collected by us is only accessible by a limited number of
employees who have special access rights to such systems and are bound by obligations
of confidentiality. If and when we use subcontractors to store your data, we will not
relinquish control of your Personal Data or expose it to security risks that would not have
arisen had the data remained in our possession. However, unfortunately no transmission
of data over the internet is guaranteed to be completely secure. It may be possible for
third parties not under the control of Propsmarts.com Limited to intercept or access
transmissions or private communications unlawfully. While we strive to protect your
Personal Data, we cannot ensure or warrant the security of any Personal Data you
transmit to us. Any such transmission is done at your own risk. If you believe that your
interaction with us is no longer secure, please contact us.
4.4 How to request your data and the process for obtaining it
You will not have to pay a fee to access your Personal Data (or to exercise any of the other
rights). However, if your request is clearly unfounded, we could refuse to comply with your
request.
We may need to request specific information from you to help us confirm your identity
and ensure you have the right to access your Personal Data (or to exercise any of your
other rights). This is a security measure to ensure that Personal Data is not disclosed to
any person who has no right to receive it. We may also contact you to ask you for further
information in relation to your request to speed up our response.

5 YOUR DATA AND THIRD PARTIES
5.1 Sharing your data with third parties
We may also share Personal Data with interested parties in the event that Propsmarts.com
Limited anticipates a change in control or the acquisition of all or part of our business or
assets or with interested parties in connection with the licensing of our technology.
If Propsmarts.com Limited is sold or makes a sale or transfer, we may, in our sole
discretion, transfer, sell or assign your Personal Data to a third party as part of or in
connection with that transaction. Upon such transfer, the Privacy Policy of the acquiring
entity may govern the further use of your Personal Data. In all other situations your data
will still remain protected in accordance with this Privacy Policy (as amended from time to
time).
We may share your Personal Data at any time if required for legal reasons or in order to
enforce our terms or this Privacy Policy.

6 HOW LONG WE RETAIN YOUR DATA

SeedLegals - Website Privacy Policy - PYCP-2-2022-10-22
d_Hv7ufXySNS 7
We will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes
we collected it for. We may retain your Personal Data for a longer period than usual in the event
of a complaint or if we reasonably believe there is a prospect of litigation in respect to our
relationship with you.
7 INTERNATIONAL TRANSFER OF DATA
Your information may be stored and processed in the US or other countries or jurisdictions
outside the US where Propsmarts.com Limited has facilities. By using Propsmarts.com Limited,
you are permitting and consenting to the transfer of information, including Personal Data,
outside of the US.
8 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY
We keep our Privacy Policy under review and will place any updates here. This version is dated 28
June 2023.
By using Propsmarts.com Limited, you consent to the collection and use of data by us as set out
in this Privacy Policy. Continued access or use of Propsmarts.com Limited will constitute your
express acceptance of any modifications to this Privacy Policy.
9 INTERPRETATION
All uses of the word "including" mean "including but not limited to" and the enumerated
examples are not intended to in any way limit the term which they serve to illustrate. Any email
addresses set out in this policy may be used solely for the purpose for which they are stated to
be provided, and any unrelated correspondence will be ignored. Unless otherwise required by
law, we reserve the right to not respond to emails, even if they relate to a legitimate subject
matter for which we have provided an email address. You are more likely to get a reply if your
request or question is polite, reasonable and there is no relatively obvious other way to deal with
or answer your concern or question (e.g. FAQs, other areas of our website, etc.).
Our staff are not authorised to contract on behalf of Propsmarts.com Limited, waive rights or
make representations (whether contractual or otherwise). If anything contained in an email from
a Propsmarts.com Limited address contradicts anything in this policy, our terms or any official
public announcement on our website, or is inconsistent with or amounts to a waiver of any
Propsmarts.com Limited rights, the email content will be read down to grant precedence to the
latter. The only exception to this is genuine correspondence expressed to be from the
Propsmarts.com Limited legal department.`;

export const saaSLink = (
  <>
      <p>
        <strong>Service  agreement</strong>
      </p><br/>

    <p>
      The definitions and rules of interpretation in this clause apply in this
      agreement.
    </p><br/>
    <p>
      <strong>Authorised Users:</strong> those employees, agents, and
      independent contractors of the Customer who are authorised by the Customer
      to use the Services and the Documentation, as further described in clause
      2.2(d).
    </p><br/>
    <p>
      <strong>Business Day:</strong> a day other than a Saturday, Sunday or
      public holiday in England and Wales when banks in London are open for
      business.
    </p><br/>
    <p>
      <strong>Change of Control:</strong> shall be as defined in section 1124 of
      the Corporation Tax Act 2010, and the expression change of control shall
      be construed accordingly.
    </p><br/>
    <p>
      <strong>Confidential Information:</strong> information that is proprietary
      or confidential and is either clearly labelled as such or identified as
      Confidential Information in clause 11.5 or clause 11.6.
    </p><br/>
    <p>
      <strong>
        Controller, processor, data subject, personal data, personal data
        breach, processing and appropriate technical and organisational
        measures:
      </strong>{" "}
      as defined in the Data Protection Legislation.
    </p><br/>
    <p>
      <strong>Customer Data:</strong> the data inputted by the Customer,
      Authorised Users, or the Supplier on the Customer's behalf for the purpose
      of using the Services or facilitating the Customer's use of the Services.
    </p><br/>
    <p>
      <strong>Data Protection Legislation:</strong> the UK Data Protection
      Legislation and any other European Union legislation relating to personal
      data and all other legislation and regulatory requirements in force from
      time to time which apply to a party relating to the use of personal data
      (including, without limitation, the privacy of electronic communications);
      and the guidance and codes of practice issued by the relevant data
      protection or supervisory authority and applicable to a party.
    </p><br/>
    <p>
      <strong>Documentation:</strong> the document made available to the
      Customer by the Supplier online via{" "}
      <a href="www.propsmarts.com">www.propsmarts.com</a> or such other web
      address notified by the Supplier to the Customer from time to time which
      sets out a description of the Services and the user instructions for the
      Services.
    </p><br/>
    <p>
      <strong>Effective Date:</strong> the date of this agreement.
    </p><br/>
    <p>
      <strong>Heightened Cybersecurity Requirements:</strong> any laws,
      regulations, codes, guidance (from regulatory and advisory bodies. Whether
      mandatory or not), international and national standards, [industry
      schemes] and sanctions, which are applicable to either the Customer or an
      Authorised User (but not the Supplier) relating to security of network and
      information systems and security breach and incident reporting
      requirements, which may include the cybersecurity Directive ((EU)
      2016/1148), Commission Implementing Regulation ((EU) 2018/151), the
      Network and Information systems Regulations 2018 (SI 506/2018), all as
      amended or updated from time to time.
    </p><br/>
    <p>
      <strong>Initial Subscription Term:</strong> 12 months.
    </p><br/>
    <p>
      <strong>Normal Business Hours:</strong> 9.00 am to 5.00 pm local UK time,
      each Business Day.
    </p><br/>
    <p>
      <strong>Renewal Period:</strong> the period described in clause 14.1.
    </p><br/>
    <p>
      <strong>Services:</strong> the subscription services provided by the
      Supplier to the Customer under this agreement via{" "}
      <a href="www.propsmarts.com">www.propsmarts.com</a> or any other website
      notified to the Customer by the Supplier from time to time, as more
      particularly described in the Documentation.
    </p><br/>
    <p>
      <strong>Software:</strong> the online software applications provided by
      the Supplier as part of the Services.
    </p><br/>
    <p>
      <strong>Subscription Fees:</strong> the subscription fees payable by the
      Customer to the Supplier for the User Subscriptions, as set out in
      paragraph 1 of Schedule 1.
    </p><br/>
    <p>
      <strong>Subscription Term:</strong> has the meaning given in clause 14.1
      (being the Initial Subscription Term together with any subsequent Renewal
      Periods).
    </p><br/>
    <p>
      <strong>Support Services Policy:</strong> The Supplier's policy for
      providing support in relation to the Services as made available at{" "}
      <a href="www.propsmarts.com">www.propsmarts.com</a> or such other website
      address as may be notified to the Customer from time to time.
    </p><br/>
    <p>
      <strong>UK Data Protection Legislation:</strong> all applicable data
      protection and privacy legislation in force from time to time in the UK
      including the General Data Protection Regulation ((EU) 2016/679); the Data
      Protection Act 2018; the Privacy and Electronic Communications Directive
      2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and
      Electronic Communications Regulations 2003 (SI 2003/2426) as amended.
    </p><br/>
    <p>
      <strong>User Subscriptions:</strong> the user subscriptions purchased by
      the Customer pursuant to clause 9.1 which entitle Authorised Users to
      access and use the Services and the Documentation in accordance with this
      agreement.
    </p><br/>
    <p>
      <strong>Virus:</strong> Any thing or device (including any software, code,
      file, or programme) which may: prevent, impair or otherwise adversely
      affect the operation of any computer software, hardware or network, any
      telecommunications service, equipment, or network or any other service or
      device; prevent, impair or otherwise adversely affect access to or the
      operation of any programme or data, including the reliability of any
      programme or data (whether by re-arranging, altering, or erasing the
      programme or data in whole or part or otherwise); or adversely affect the
      user experience, including worms, trojan horses, viruses, and other
      similar things or devices.
    </p><br/>
    <p>
      <strong>Vulnerability:</strong> a weakness in the computational logic (for
      example, code) found in software and hardware components that when
      exploited, results in a negative impact to the confidentiality, integrity,
      or availability, and the term Vulnerabilities shall be construed
      accordingly.
    </p><br/>
    <ul className="list-disc">
      <li>
        Clause, schedule, and paragraph headings shall not affect the
        interpretation of this agreement.
      </li>
      <li>
        A person includes an individual, corporate, or unincorporated body
        (whether or not having separate legal personality) [and that person's
        legal and personal representatives, successors or permitted assigns].
      </li>
      <li>
        A reference to a company shall include any company, corporation, or
        other body corporate, wherever and however incorporated or established.
      </li>
      <li>
        Unless the context otherwise requires, words in the singular shall
        include the plural and, in the plural, shall include the singular.
      </li>
      <li>
        Unless the context otherwise requires, a reference to one gender shall
        include a reference to the other genders.
      </li>
      <li>
        A reference to a statute or statutory provision is a reference to it as
        it is in force as at the date of this agreement.
      </li>
      <li>
        A reference to a statute or statutory provision shall include all
        subordinate legislation made as at the date of this agreement under that
        statute or statutory provision.
      </li>
      <li>A reference to writing or written includes e-mail.</li>
    </ul>
    <p>
      <strong>
        References to clauses and schedules are to the clauses and schedules of
        this agreement; references to paragraphs are to paragraphs of the
        relevant schedule to this agreement.
      </strong>
    </p><br/>
    <ol>
      <li>
        <strong>User Subscriptions</strong>
      </li>
    </ol>
    <p>
      Subject to the Customer purchasing the User Subscriptions in accordance
      with clause 3.3 and clause 9.1, the restrictions set out in this clause 2
      and the other terms and conditions of this agreement, the Supplier hereby
      grants to the Customer a non-exclusive, non-transferable right, without
      the right to grant sublicenses, to permit the Authorized Users to use the
      Services and the Documentation during the Subscription Term solely for the
      Customer's internal business operations.
    </p><br/>
    <p>
      <strong>
        In relation to the Authorized Users, the Customer undertakes that:
      </strong>
    </p><br/>
    <ul className="list-disc">
      <li>
        the maximum number of Authorized Users that it authorizes to access and
        use the Services and the Documentation shall not exceed the number of
        User Subscriptions it has purchased from time to time.
      </li>
      <li>
        it will not allow or suffer any User Subscription to be used by more
        than one individual Authorized User unless it has been reassigned in its
        entirety to another individual Authorized User, in which case the prior
        Authorized User shall no longer have any right to access or use the
        Services and/or Documentation.
      </li>
      <li>
        each Authorized User shall keep a secure password for his use of the
        Services and Documentation, that such password shall be changed no less
        frequently than [once every three months] and that each Authorized User
        shall keep his password confidential.
      </li>
      <li>
        it shall maintain a written, up to date list of current Authorized Users
        and provide such list to the Supplier within 5 Business Days of the
        Supplier's written request at any time or times.
      </li>
      <li>
        it shall permit the Supplier or the Supplier's designated auditor to
        audit the Services to establish the name and password of each Authorized
        User and the Supplier's data processing facilities to audit compliance
        with this agreement. Each such audit may be conducted no more than once
        per quarter, at the Supplier's expense, and this right shall be
        exercised with reasonable prior notice, in such a manner as not to
        substantially interfere with the Customer's normal conduct of business.
      </li>
      <li>
        if any of the audits referred to in clause 2.2(e) reveal that any
        password has been provided to any individual who is not an Authorized
        User, then without prejudice to the Supplier's other rights, the
        Customer shall promptly disable such passwords and the Supplier shall
        not issue any new passwords to any such individual.
      </li>
      <li>
        if any of the audits referred to in clause 2.2(e) reveal that the
        Customer has underpaid Subscription Fees to the Supplier, then without
        prejudice to the Supplier's other rights, the Customer shall pay to the
        Supplier an amount equal to such underpayment as calculated in
        accordance with the prices set out in paragraph 1 of Schedule 1 within
        10 Business Days of the date of the relevant audit.
      </li>
    </ul>
    <ul className="list-disc">
      <li>
        The Customer shall not access, store, distribute or transmit any
        Viruses, or any material during its use of the Services that:
      </li>
    </ul>
    <ul className="list-disc">
      <li>
        is unlawful, harmful, threatening, defamatory, obscene, infringing,
        harassing or racially or ethnically offensive.
      </li>
      <li>facilitates illegal activity.</li>
      <li>depicts sexually explicit images.</li>
      <li>promotes unlawful violence.</li>
      <li>
        is discriminatory based on race, gender, color, religious belief, sexual
        orientation, disability; or is otherwise illegal or causes damage or
        injury to any person or property.
      </li>
    </ul>
    <p>
      The Supplier reserves the right, without liability or prejudice to its
      other rights to the Customer, to disable the Customer's access to any
      material that breaches the provisions of this clause.
    </p><br/>
    <strong>The Customer shall not:</strong>
    <ul className="list-disc">
      <li>
        except as may be allowed by any applicable law which is incapable of
        exclusion by agreement between the parties and except to the extent
        expressly permitted under this agreement:
      </li>
      <ul className="list-disc">
        <li>
          attempt to copy, modify, duplicate, create derivative works from,
          frame, mirror, republish, download, display, transmit, or distribute
          all or any portion of the Software and/or Documentation (as
          applicable) in any form or media or by any means; or
        </li>
        <li>
          attempt to de-compile, reverse compile, disassemble, reverse engineer,
          or otherwise reduce to human-perceivable form all or any part of the
          Software; or
        </li>
        <li>
          access all or any part of the Services and Documentation to build a
          product or service which competes with the Services and/or the
          Documentation; or
        </li>
        <li>
          use the Services and/or Documentation to provide services to third
          parties; or
        </li>
      </ul>
      <li>
        subject to clause 22.1, license, sell, rent, lease, transfer, assign,
        distribute, display, disclose, or otherwise commercially exploit, or
        otherwise make the Services and/or Documentation available to any third
        party except the Authorized Users, or
      </li>
      <li>
        attempt to obtain, or assist third parties in obtaining, access to the
        Services and/or Documentation, other than as provided under this clause
        2; or
      </li>
      <li>
        introduce or permit the introduction of, any Virus or Vulnerability into
        the Supplier's network and information systems.
      </li>
    </ul>
    <p>
      The Customer shall use all reasonable endeavors to prevent any
      unauthorized access to, or use of, the Services and/or the Documentation
      and, in the event of any such unauthorized access or use, promptly notify
      the Supplier.
    </p><br/>
    <ol>
      <li>
        The rights provided under this clause 2 are granted to the Customer only
        and shall not be considered granted to any subsidiary or holding company
        of the Customer.
      </li>
    </ol>
    <ol>
      <li>
        <strong>Additional user subscriptions</strong>
      </li>
    </ol>
    <ol>
      <li>
        Subject to clause 3.2 and clause 3.3, the Customer may, from time to
        time during any Subscription Term, purchase additional User
        Subscriptions in excess of the number set out in paragraph 1 of Schedule
        1 and the Supplier shall grant access to the Services and the
        Documentation to such additional Authorized Users in accordance with the
        provisions of this agreement.
      </li>
      <li>
        If the Customer wishes to purchase additional User Subscriptions, the
        Customer shall notify the Supplier in writing. The Supplier shall
        evaluate such request for additional User Subscriptions and respond to
        the Customer with approval or rejection of the request [(such approval
        not to be unreasonably withheld)]. Where the Supplier approves the
        request, the Supplier shall activate the additional User Subscriptions
        within 7 days of its approval of the Customer's request.
      </li>
      <li>
        If the Supplier approves the Customer's request to purchase additional
        User Subscriptions, the Customer shall, within 30 days of the date of
        the Supplier's invoice, pay to the Supplier the relevant fees for such
        additional User Subscriptions as set out in paragraph 2 of Schedule 1
        and, if such additional User Subscriptions are purchased by the Customer
        part way through the Initial Subscription Term or any Renewal Period (as
        applicable), such fees shall be pro-rated from the date of activation by
        the Supplier for the remainder of the Initial Subscription Term or then
        current Renewal Period (as applicable).
      </li>
    </ol>
    <ol start="6">
      <li>
        <strong>Services</strong>
      </li>
    </ol>
    <p>
      The Supplier shall, during the Subscription Term, provide the Services and
      make available the Documentation to the Customer on and subject to the
      terms of this agreement.
    </p><br/>
    <p>
      The Supplier shall use commercially reasonable endeavors to make the
      Services available 24 hours a day, seven days a week, except for:
    </p><br/>
    <ul className="list-disc">
      <li>
        planned maintenance carried out during the maintenance window of 10.00
        pm to 2.00 am UK time; and
      </li>
      <li>
        unscheduled maintenance performed outside Normal Business Hours,
        provided that the Supplier has used reasonable endeavors to give the
        Customer at least 6 Normal Business Hours' notice in advance.
      </li>
    </ul>
    <p>
      The Supplier will, as part of the Services and in consideration of the
      support fees set out in Schedule 1, provide the Customer with the
      Supplier's standard customer support services during Normal Business Hours
      in accordance with the Supplier's Support Services Policy in effect at the
      time that the Services are provided. The Supplier may amend the Support
      Services Policy in its sole and absolute discretion from time to time. The
      Customer may purchase enhanced support services separately at the
      Supplier's then current rates.
    </p><br/>
    <ol start="8">
      <li>
        <strong>Customer data</strong>
      </li>
    </ol>
    <ol>
      <li>
        The Customer shall own all right, title, and interest in and to all of
        the Customer Data that is not personal data and shall have sole
        responsibility for the legality, reliability, integrity, accuracy, and
        quality of all such Customer Data.
      </li>
      <li>
        The Supplier shall follow its archiving procedures for Customer Data as
        set out in its Back-Up Policy available at www.propsmarts.com or such
        other website address as may be notified to the Customer from time to
        time, as such document may be amended by the Supplier in its sole
        discretion from time to time. In the event of any loss or damage to
        Customer Data, the Customer's sole and exclusive remedy against the
        Supplier shall be for the Supplier to use reasonable commercial
        endeavors to restore the lost or damaged Customer Data from the latest
        back-up of such Customer Data maintained by the Supplier in accordance
        with the archiving procedure described in its Back-Up Policy. The
        Supplier shall not be responsible for any loss, destruction, alteration,
        or disclosure of Customer Data caused by any third party (except those
        third parties sub-contracted by the Supplier to perform services related
        to Customer Data maintenance and back-up for which it shall remain fully
        liable under clause 5.9).
      </li>
      <li>
        The Supplier shall, in providing the Services, comply with its Privacy
        and Security Policy relating to the privacy and security of the Customer
        Data available at https://insights.dcwgroup.co.uk/ or such other website
        address as may be notified to the Customer from time to time, as such
        document may be amended from time to time by the Supplier in its sole
        discretion.
      </li>
    </ol>
    <p>
      Both parties will comply with all applicable requirements of the Data
      Protection Legislation. This clause 5 is in addition to, and does not
      relieve, remove, or replace, a party's obligations or rights under the
      Data Protection Legislation.
    </p><br/>
    <p>The parties acknowledge that:</p><br/>
    <ul className="list-disc">
      <li>
        if the Supplier processes any personal data on the Customer's behalf
        when performing its obligations under this agreement, the Customer is
        the controller and the Supplier is the processor for the purposes of the
        Data Protection Legislation.
      </li>
      <li>
        the personal data may be transferred or stored outside the EEA or the
        country where the Customer and the Authorized Users are in order to
        carry out the Services and the Supplier's other obligations under this
        agreement.
      </li>
    </ul>
    <p>
      <strong>1. Without prejudice to the generality of clause 5.4</strong>, the
      Customer will ensure that it has all necessary appropriate consents and
      notices in place to enable lawful transfer of the personal data to the
      Supplier for the duration and purposes of this agreement so that the
      Supplier may lawfully use, process, and transfer the personal data in
      accordance with this agreement on the Customer's behalf.
    </p><br/>
    <p>
      <strong>2. Without prejudice to the generality of clause 5.4</strong>, the
      Supplier shall, in relation to any personal data processed in connection
      with the performance by the Supplier of its obligations under this
      agreement:
    </p><br/>
    <ul className="list-disc">
      <li>
        process that personal data only on the documented written instructions
        of the Customer unless the Supplier is required by the laws of any
        member of the European Union or by the laws of the European Union
        applicable to the Supplier and/or Domestic UK Law (where Domestic UK Law
        means the UK Data Protection Legislation and any other law that applies
        in the UK) to process personal data (Applicable Laws). Where the
        Supplier is relying on Applicable Laws as the basis for processing
        personal data, the Supplier shall promptly notify the Customer of this
        before performing the processing required by the Applicable Laws unless
        those Applicable Laws prohibit the Supplier from so notifying the
        Customer;
      </li>
      <li>
        not transfer any personal data outside of the European Economic Area and
        the United Kingdom unless the following conditions are fulfilled:
        <ul className="list-disc">
          <li>
            the Customer or the Supplier has provided appropriate safeguards in
            relation to the transfer.
          </li>
          <li>
            the data subject has enforceable rights and effective legal
            remedies.
          </li>
          <li>
            the Supplier complies with its obligations under the Data Protection
            Legislation by providing an adequate level of protection to any
            personal data that is transferred; and
          </li>
          <li>
            the Supplier complies with reasonable instructions notified to it in
            advance by the Customer with respect to the processing of the
            personal data.
          </li>
        </ul>
      </li>
      <li>
        assist the Customer, at the Customer's cost, in responding to any
        request from a data subject and in ensuring compliance with its
        obligations under the Data Protection Legislation with respect to
        security, breach notifications, impact assessments, and consultations
        with supervisory authorities or regulators;
      </li>
      <li>
        notify the Customer without undue delay on becoming aware of a personal
        data breach.
      </li>
      <li>
        at the written direction of the Customer, delete or return personal data
        and copies thereof to the Customer on termination of the agreement
        unless required by Applicable Law to store the personal data (and for
        these purposes, the term "delete" shall mean to put such data beyond
        use); and
      </li>
      <li>
        maintain complete and accurate records and information to demonstrate
        its compliance with this clause 5 and immediately inform the Customer
        if, in the opinion of the Supplier, an instruction infringes the Data
        Protection Legislation.
      </li>
    </ul>
    <p>
      <strong>
        3. Each party shall ensure that it has in place appropriate technical
        and organizational measures, reviewed and approved by the other party
      </strong>
      , to protect against unauthorized or unlawful processing of personal data
      and against accidental loss or destruction of, or damage to, personal
      data, appropriate to the harm that might result from the unauthorized or
      unlawful processing or accidental loss, destruction, or damage.
    </p><br/>
    <p>
      <strong>
        4. The Customer consents to the Supplier appointing 'Soitron UK' as a
        third-party processor of personal data under this agreement.
      </strong>{" "}
      The Supplier confirms that it has entered or (as the case may be) will
      enter with the third-party processor into a written agreement
      substantially on that third party's standard terms of business and in
      which case the Supplier confirms that they will reflect and will continue
      to reflect the requirements of the Data Protection Legislation. As between
      the Customer and the Supplier, the Supplier shall remain fully liable for
      all acts or omissions of any third-party processor appointed by it
      pursuant to this clause 5.
    </p><br/>
    <p>
      <strong>
        5. Either party may, at any time on not less than 30 days' notice,
        revise this clause 5
      </strong>{" "}
      by replacing it with any applicable controller to processor standard
      clauses or similar terms forming part of an applicable certification
      scheme (which shall apply when replaced by attachment to this agreement).
    </p><br/>
    <p>
      <strong>6. Third-party providers</strong>
    </p><br/>
    <p>
      The Customer acknowledges that the Services may enable or assist it to
      access the website content of, correspond with, and purchase products and
      services from, third parties via third-party websites and that it does so
      solely at its own risk. The Supplier makes no representation, warranty, or
      commitment and shall have no liability or obligation whatsoever in
      relation to the content or use of, or correspondence with, any such
      third-party website, or any transactions completed, and any contract
      entered into by the Customer, with any such third party. Any contract
      entered and any transaction completed via any third-party website is
      between the Customer and the relevant third party, and not the Supplier.
      The Supplier recommends that the Customer refers to the third party's
      website terms and conditions and privacy policy prior to using the
      relevant third-party website. The Supplier does not endorse or approve any
      third-party website nor the content of any of the third-party website made
      available via the Services.
    </p><br/>
    <p>
      <strong>7. Supplier's obligations</strong>
    </p><br/>
    <p>
      <strong>
        7.1 The Supplier undertakes that the Services will be performed
        substantially in accordance with the Documentation and with reasonable
        skill and care.
      </strong>
    </p><br/>
    <p>
      <strong>
        7.2 The undertaking at clause 7.1 shall not apply to the extent of any
        non-conformance which is caused by use of the Services contrary to the
        Supplier's instructions, or modification or alteration of the Services
        by any party other than the Supplier or the Supplier's duly authorized
        contractors or agents.
      </strong>{" "}
      If the Services do not conform with the foregoing undertaking, Supplier
      will, at its expense, use all reasonable commercial endeavors to correct
      any such non-conformance promptly, or provide the Customer with an
      alternative means of accomplishing the desired performance. Such
      correction or substitution constitutes the Customer's sole and exclusive
      remedy for any breach of the undertaking set out in clause 7.1.
    </p><br/>
    <p>
      <strong>8. The Supplier:</strong>
    </p><br/>
    <p>
      <strong>8.1 does not warrant that:</strong>
    </p><br/>
    <ul className="list-disc">
      <li>
        the Customer's use of the Services will be uninterrupted or error-free;
        or
      </li>
      <li>
        that the Services, Documentation, and/or the information obtained by the
        Customer through the Services will meet the Customer's requirements; or
      </li>
      <li>
        the Software or the Services will be free from Vulnerabilities; or
      </li>
      <li>
        the Software, Documentation, or Services will comply with any Heightened
        Cybersecurity Requirements.
      </li>
    </ul>
    <p>
      <strong>
        8.2 is not responsible for any delays, delivery failures, or any other
        loss or damage resulting from the transfer of data over communications
        networks and facilities, including the internet
      </strong>
      , and the Customer acknowledges that the Services and Documentation may be
      subject to limitations, delays, and other problems inherent in the use of
      such communications facilities.
    </p><br/>
    <p>
      <strong>
        9. This agreement shall not prevent the Supplier from entering into
        similar agreements with third parties, or from independently developing,
        using, selling, or licensing documentation, products, and/or services
        which are similar to those provided under this agreement.
      </strong>
    </p><br/>
    <p>
      <strong>
        10. The Supplier warrants that it has and will maintain all necessary
        licenses, consents, and permissions necessary for the performance of its
        obligations under this agreement.
      </strong>
    </p><br/>
    <p>
      <strong>1. Customer's obligations</strong>
    </p><br/>
    <p>The Customer shall:</p><br/>
    <ul className="list-disc">
      <li>Provide the Supplier with:</li>
      <ul className="list-disc">
        <li>All necessary co-operation in relation to this agreement; and</li>
        <li>
          All necessary access to such information as may be required by the
          Supplier to provide the Services, including but not limited to
          Customer Data, security access information, and configuration
          services.
        </li>
      </ul>
      <li>
        Without affecting its other obligations under this agreement, comply
        with all applicable laws and regulations with respect to its activities
        under this agreement.
      </li>
      <li>
        Carry out all other Customer responsibilities set out in this agreement
        in a timely and efficient manner. In the event of any delays in the
        Customer's provision of such assistance as agreed by the parties, the
        Supplier may adjust any agreed timetable or delivery schedule as
        reasonably necessary.
      </li>
      <li>
        Ensure that the Authorized Users use the Services and the Documentation
        in accordance with the terms and conditions of this agreement and shall
        be responsible for any Authorized User's breach of this agreement.
      </li>
      <li>
        Obtain and shall maintain all necessary licenses, consents, and
        permissions necessary for the Supplier, its contractors, and agents to
        perform their obligations under this agreement, including without
        limitation the Services.
      </li>
      <li>
        Ensure that its network and systems comply with the relevant
        specifications provided by the Supplier from time to time; and
      </li>
      <li>
        Be, to the extent permitted by law and except as otherwise expressly
        provided in this agreement, solely responsible for procuring,
        maintaining, and securing its network connections and telecommunications
        links from its systems to the Supplier's data centers, and all problems,
        conditions, delays, delivery failures, and all other loss or damage
        arising from or relating to the Customer's network connections or
        telecommunications links or caused by the internet.
      </li>
    </ul>
    <p>
      <strong>2. Charges and payment</strong>
    </p><br/>
    <p>
      The Customer shall pay the Subscription Fees to the Supplier for the User
      Subscriptions in accordance with this clause 9 and Schedule 1 and the
      support fees in accordance with clause 4.3 and Schedule 1.
    </p><br/>
    <p>
      The Customer shall, on the Effective Date, provide to the Supplier valid,
      up-to-date, and complete credit card details or approved purchase order
      information acceptable to the Supplier and any other relevant valid,
      up-to-date, and complete contact and billing details. If the Customer
      provides:
    </p><br/>
    <ul className="list-disc">
      <li>
        Its credit card details to the Supplier, the Customer hereby authorizes
        the Supplier to bill such credit card:
      </li>
      <ul className="list-disc">
        <li>
          On the Effective Date for the Subscription Fees payable in respect of
          the Initial Subscription Term; and
        </li>
        <li>
          Subject to clause 14.1, on each anniversary of the Effective Date for
          the Subscription Fees payable in respect of the next Renewal Period.
        </li>
      </ul>
      <li>
        Its approved purchase order information to the Supplier, the Supplier
        shall invoice the Customer:
      </li>
      <ul className="list-disc">
        <li>
          On the Effective Date for the Subscription Fees payable in respect of
          the Initial Subscription Term; and
        </li>
        <li>
          Subject to clause 14.1, at least 30 days prior to each anniversary of
          the Effective Date for the Subscription Fees payable in respect of the
          next Renewal Period,
        </li>
      </ul>
    </ul>
    <p>
      The Customer shall pay each invoice within 14 days after the date of such
      invoice.
    </p><br/>
    <p>
      If the Supplier has not received payment within 14 days after the due
      date, and without prejudice to any other rights and remedies of the
      Supplier:
    </p><br/>
    <ul className="list-disc">
      <li>
        The Supplier may, without liability to the Customer, disable the
        Customer's password, account, and access to all or part of the Services
        and the Supplier shall be under no obligation to provide any or all of
        the Services while the invoice(s) concerned remain unpaid; and
      </li>
      <li>
        Interest shall accrue on a daily basis on such due amounts at an annual
        rate equal to 3% over the then current base lending rate of the
        Supplier's bankers in the UK from time to time, commencing on the due
        date and continuing until fully paid, whether before or after judgment.
      </li>
    </ul>
    <p>
      <strong>3. All amounts and fees</strong>
    </p><br/>
    <p>All amounts and fees stated or referred to in this agreement:</p><br/>
    <ul className="list-disc">
      <li>Shall be payable in pounds sterling.</li>
      <li>
        Are, subject to clause 13.3(b), non-cancellable and non-refundable.
      </li>
      <li>
        Are exclusive of value added tax, which shall be added to the Supplier's
        invoice(s) at the appropriate rate.
      </li>
    </ul>
    <p>
      <strong>4. Excess data storage fees</strong>
    </p><br/>
    <p>
      If, at any time while using the Services, the Customer exceeds the amount
      of disk storage space specified in the Documentation, the Supplier shall
      charge the Customer, and the Customer shall pay, the Supplier's then
      current excess data storage fees. The Supplier's excess data storage fees
      current as of the Effective Date are set out in Schedule 1.
    </p><br/>
    <p>
      <strong>5. Subscription fee increase</strong>
    </p><br/>
    <p>
      The Supplier shall be entitled to increase the Subscription Fees, the fees
      payable in respect of the additional User Subscriptions purchased pursuant
      to clause 3.3, the support fees payable pursuant to clause 4.3, and/or the
      excess storage fees payable pursuant to clause 9.5 at the start of each
      Renewal Period upon 90 days' prior notice to the Customer, and Schedule 1
      shall be deemed to have been amended accordingly.
    </p><br/>
    <p>
      <strong>6. Proprietary rights</strong>
    </p><br/>
    <p>
      The Customer acknowledges and agrees that the Supplier and/or its
      licensors own all intellectual property rights in the Services and the
      Documentation. Except as expressly stated herein, this agreement does not
      grant the Customer any rights to, under or in, any patents, copyright,
      database right, trade secrets, trade names, trademarks (whether registered
      or unregistered), or any other rights or licenses in respect of the
      Services or the Documentation.
    </p><br/>
    <p>
      The Supplier confirms that it has all the rights in relation to the
      Services and the Documentation that are necessary to grant all the rights
      it purports to grant under, and in accordance with, the terms of this
      agreement.
    </p><br/>
    <p>
      <strong>7. Confidentiality and compliance with policies</strong>
    </p><br/>
    <p>
      Each party may be given access to Confidential Information from the other
      party to perform its obligations under this agreement. A party's
      Confidential Information shall not be deemed to include information that:
    </p><br/>
    <ul className="list-disc">
      <li>
        Is or becomes officially known other than through any act or omission of
        the receiving party.
      </li>
      <li>Was in the other party's lawful possession before the disclosure.</li>
      <li>
        Is lawfully disclosed to the receiving party by a third party without
        restriction on disclosure; or
      </li>
      <li>
        Is independently developed by the receiving party, which independent
        development can be shown by written evidence.
      </li>
    </ul>
    <p>
      Subject to clause 11.4, each party shall hold the other's Confidential
      Information in confidence and not make the other's Confidential
      Information available to any third party or use the other's Confidential
      Information for any purpose other than the implementation of this
      agreement.
    </p><br/>
    <p>
      Each party shall take all reasonable steps to ensure that the other's
      Confidential Information to which it has access is not disclosed or
      distributed by its employees or agents in violation of the terms of this
      agreement.
    </p><br/>
    <p>
      A party may disclose Confidential Information to the extent such
      Confidential Information is required to be disclosed by law, by any
      governmental or other regulatory authority, or by a court or other
      authority of competent jurisdiction, provided that, to the extent it is
      legally permitted to do so, it gives the other party as much notice of
      such disclosure as possible and, where notice of disclosure is not
      prohibited and is given in accordance with this clause 11.4, it takes into
      account the reasonable requests of the other party in relation to the
      content of such disclosure.
    </p><br/>
    <p>
      The Customer acknowledges that details of the Services, and the results of
      any performance tests of the Services, constitute the Supplier's
      Confidential Information.
    </p><br/>
    <p>
      The Supplier acknowledges that the Customer Data is the Confidential
      Information of the Customer.
    </p><br/>
    <p>
      No party shall make, or permit any person to make, any public announcement
      concerning this agreement without the prior written consent of the other
      parties (such consent not to be unreasonably withheld or delayed), except
      as required by law, any governmental or regulatory authority (including,
      without limitation, any relevant securities exchange), any court or other
      authority of competent jurisdiction.
    </p><br/>
    <p>
      The above provisions of this clause 11 shall survive termination of this
      agreement, however arising.
    </p><br/>
    <p>
      <strong>8. Compliance with Supplier's business policies</strong>
    </p><br/>
    <p>
      In performing its obligations under this agreement, the Customer shall
      comply with the Supplier’s business policies as amended from time to time.
    </p><br/>
    <p>
      <strong>9. Indemnity</strong>
    </p><br/>
    <p>
      The Customer shall defend, indemnify and hold harmless the Supplier
      against claims, actions, proceedings, losses, damages, expenses and costs
      (including without limitation court costs and reasonable legal fees)
      arising out of or in connection with the Customer's use of the Services
      and/or Documentation, provided that:
    </p><br/>
    <ul className="list-disc">
      <li>The Customer is given prompt notice of any such claim.</li>
      <li>
        The Supplier provides reasonable cooperation to the Customer in the
        defense and settlement of such claim, at the Customer's expense; and
      </li>
      <li>
        The Customer is given sole authority to defend or settle the claim.
      </li>
    </ul>
    <p>
      The Supplier shall defend the Customer, its officers, directors, and
      employees against any claim that the Customer's use of the Services or
      Documentation in accordance with this agreement infringes any United
      Kingdom patent effective as of the Effective Date, copyright, trademark,
      database right, or right of confidentiality, and shall indemnify the
      Customer for any amounts awarded against the Customer in judgment or
      settlement of such claims, provided that:
    </p><br/>
    <ul className="list-disc">
      <li>The Supplier is given prompt notice of any such claim.</li>
      <li>
        The Customer provides reasonable cooperation to the Supplier in the
        defense and settlement of such claim, at the Supplier's expense; and
      </li>
      <li>
        The Supplier is given sole authority to defend or settle the claim.
      </li>
    </ul>
    <p>
      In the defense or settlement of any claim, the Supplier may procure the
      right for the Customer to continue using the Services, replace or modify
      the Services so that they become non-infringing, or, if such remedies are
      not reasonably available, terminate this agreement on 2 Business Days'
      notice to the Customer without any additional liability or obligation to
      pay liquidated damages or other additional costs to the Customer.
    </p><br/>
    <p>
      In no event shall the Supplier, its employees, agents, and subcontractors
      be liable to the Customer to the extent that the alleged infringement is
      based on:
    </p><br/>
    <ul className="list-disc">
      <li>
        A modification of the Services or Documentation by anyone other than the
        Supplier; or
      </li>
      <li>
        The Customer's use of the Services or Documentation in a manner contrary
        to the instructions given to the Customer by the Supplier; or
      </li>
      <li>
        The Customer's use of the Services or Documentation after notice of the
        alleged or actual infringement from the Supplier or any appropriate
        authority.
      </li>
    </ul>
    <p>
      The foregoing and clause 13.3(b) state the Customer's sole and exclusive
      rights and remedies, and the Supplier's (including the Supplier's
      employees', agents', and subcontractors') entire obligations and
      liability, for infringement of any patent, copyright, trademark, database
      right, or right of confidentiality.
    </p><br/>
    <p>
      <strong>10. Limitation of liability</strong>
    </p><br/>
    <p>Except as expressly and specifically provided in this agreement:</p><br/>
    <ul className="list-disc">
      <li>
        The Customer assumes sole responsibility for results obtained from the
        use of the Services and the Documentation by the Customer and for
        conclusions drawn from such use. The Supplier shall have no liability
        for any damage caused by errors or omissions in any information,
        instructions, or scripts provided to the Supplier by the Customer in
        connection with the Services or any actions taken by the Supplier at the
        Customer's direction.
      </li>
      <li>
        All warranties, representations, conditions, and all other terms of any
        kind whatsoever implied by statute or common law are, to the fullest
        extent permitted by applicable law, excluded from this agreement.
      </li>
      <li>
        The Services and the Documentation are provided to the Customer on an
        "as is" basis.
      </li>
    </ul>
    <p>Nothing in this agreement excludes the liability of the Supplier:</p><br/>
    <ul className="list-disc">
      <li>
        For death or personal injury caused by the Supplier's negligence; or
      </li>
      <li>For fraud or fraudulent misrepresentation.</li>
    </ul>
    <p>Subject to clause 13.1 and clause 13.2:</p><br/>
    <ul className="list-disc">
      <li>
        The Supplier shall not be liable, whether in tort (including for
        negligence or breach of statutory duty), contract, misrepresentation,
        restitution, or otherwise, for any loss of profits, loss of business,
        depletion of goodwill and/or similar losses or loss or corruption of
        data or information or pure economic loss, or for any special, indirect
        or consequential loss, costs, damages, charges, or expenses, however
        arising under this agreement; and
      </li>
      <li>
        The Supplier's total aggregate liability in contract (including in
        respect of the indemnity at clause 12.2), tort (including negligence or
        breach of statutory duty), misrepresentation, restitution, or otherwise,
        arising in connection with the performance or contemplated performance
        of this agreement shall be limited to the total Subscription Fees paid
        for the User Subscriptions during the 12 months immediately preceding
        the date on which the claim arose.
      </li>
    </ul>
    <p>
      <strong>1. Term and Termination</strong>
    </p><br/>
    <p>
      This agreement shall, unless otherwise terminated as provided in this
      clause 14, commence on the Effective Date and shall continue for the
      Initial Subscription Term and, thereafter, this agreement shall be
      automatically renewed for successive periods of 60 months (each a Renewal
      Period), unless:
    </p><br/>
    <ul className="list-disc">
      <li>
        either party notifies the other party of termination, in writing, at
        least 60 days before the end of the Initial Subscription Term or any
        Renewal Period, in which case this agreement shall terminate upon the
        expiry of the applicable Initial Subscription Term or Renewal Period; or
      </li>
      <li>
        otherwise terminated in accordance with the provisions of this
        agreement.
      </li>
    </ul>
    <p>
      and the Initial Subscription Term together with any subsequent Renewal
      Periods shall constitute the Subscription Term.
    </p><br/>
    <p>
      Without affecting any other right or remedy available to it, either party
      may terminate this agreement with immediate effect by giving written
      notice to the other party if:
    </p><br/>
    <ul className="list-disc">
      <li>
        the other party fails to pay any amount due under this agreement on the
        due date for payment and remains in default not less than 14 days after
        being notified in writing to make such payment.
      </li>
      <li>
        the other party commits a material breach of any other term of this
        agreement which breach is irremediable or (if such breach is remediable)
        fails to remedy that breach within a period of 14 days after being
        notified in writing to do so;
      </li>
      <li>
        the other party repeatedly breaches any of the terms of this agreement
        in such a manner as to reasonably justify the opinion that its conduct
        is inconsistent with it having the intention or ability to give effect
        to the terms of this agreement;
      </li>
      <li>
        the other party suspends, or threatens to suspend, payment of its debts
        or is unable to pay its debts as they fall due or admits inability to
        pay its debts or is deemed unable to pay its debts within the meaning of
        section 123 of the Insolvency Act 1986, as if the words "it is proved to
        the satisfaction of the court" did not appear in sections 123(1)(e) or
        123(2) of the Insolvency Act 1986;
      </li>
      <li>
        the other party commences negotiations with all or any class of its
        creditors with a view to rescheduling any of its debts, or makes a
        proposal for or enters into any compromise or arrangement with its
        creditors other than for the sole purpose of a scheme for a solvent
        amalgamation of that other party with one or more other companies or the
        solvent reconstruction of that other party;
      </li>
      <li>
        a petition is filed, a notice is given, a resolution is passed, or an
        order is made, for or in connection with the winding up of that other
        party other than for the sole purpose of a scheme for a solvent
        amalgamation of that other party with one or more other companies or the
        solvent reconstruction of that other party;
      </li>
      <li>
        an application is made to court, or an order is made, for the
        appointment of an administrator, or if a notice of intention to appoint
        an administrator is given or if an administrator is appointed, over the
        other party;
      </li>
      <li>
        the holder of a qualifying floating charge over the assets of that other
        party has become entitled to appoint or has appointed an administrative
        receiver;
      </li>
      <li>
        a person becomes entitled to appoint a receiver over the assets of the
        other party or a receiver is appointed over the assets of the other
        party;
      </li>
      <li>
        a creditor or encumbrancer of the other party attaches or takes
        possession of, or a distress, execution, sequestration or other such
        process is levied or enforced on or sued against, the whole or any part
        of the other party's assets and such attachment or process is not
        discharged within 14 days;
      </li>
      <li>
        any event occurs, or proceeding is taken, with respect to the other
        party in any jurisdiction to which it is subject that has an effect
        equivalent or similar to any of the events mentioned in clause 14.2(d)
        to clause 14.2(j) (inclusive);
      </li>
      <li>
        the other party suspends or ceases, or threatens to suspend or cease,
        carrying on all or a substantial part of its business; or
      </li>
      <li>there is a change of control of the other party; or</li>
      <li>
        any warranty given by the Supplier in clause 7.5 of this agreement is
        found to be untrue or misleading.
      </li>
    </ul>
    <p>On termination of this agreement for any reason:</p><br/>
    <ul className="list-disc">
      <li>
        all licenses granted under this agreement shall immediately terminate
        and the Customer shall immediately cease all use of the Services and/or
        the Documentation.
      </li>
      <li>
        each party shall return and make no further use of any equipment,
        property, Documentation, and other items (and all copies of them)
        belonging to the other party;
      </li>
      <li>
        the Supplier may destroy or otherwise dispose of any of the Customer
        Data in its possession in accordance with clause 5.7(c), unless the
        Supplier receives, no later than ten days after the effective date of
        the termination of this agreement, a written request for the delivery to
        the Customer of the then most recent backup of the Customer Data. The
        Supplier shall use reasonable commercial endeavors to deliver the backup
        to the Customer within 30 days of its receipt of such a written request,
        provided that the Customer has, at that time, paid all fees and charges
        outstanding at and resulting from termination (whether or not due at the
        date of termination). The Customer shall pay all reasonable expenses
        incurred by the Supplier in returning or disposing of Customer Data; and
      </li>
      <li>
        any rights, remedies, obligations, or liabilities of the parties that
        have accrued up to the date of termination, including the right to claim
        damages in respect of any breach of the agreement which existed at or
        before the date of termination shall not be affected or prejudiced.
      </li>
    </ul>
    <p>
      <strong>2. Force Majeure</strong>
    </p><br/>
    <p>
      The Supplier shall have no liability to the Customer under this agreement
      if it is prevented from or delayed in performing its obligations under
      this agreement, or from carrying on its business, by acts, events,
      omissions, or accidents beyond its reasonable control, including, without
      limitation, strikes, lock-outs or other industrial disputes (whether
      involving the workforce of the Supplier or any other party), failure of a
      utility service or transport or telecommunications network, act of God,
      war, riot, civil commotion, malicious damage, compliance with any law or
      governmental order, rule, regulation, or direction, accident, breakdown of
      plant or machinery, fire, flood, storm, or default of suppliers or
      subcontractors, provided that the Customer is notified of such an event
      and its expected duration.
    </p><br/>
    <p>
      <strong>3. Conflict</strong>
    </p><br/>
    <p>
      If there is an inconsistency between any of the provisions in the main
      body of this agreement and the Schedules, the provisions in the main body
      of this agreement shall prevail.
    </p><br/>
    <p>
      <strong>4. Variation</strong>
    </p><br/>
    <p>
      No variation of this agreement shall be effective unless it is in writing
      and signed by the parties (or their authorized representatives).
    </p><br/>
    <p>
      <strong>5. Waiver</strong>
    </p><br/>
    <p>
      No failure or delay by a party to exercise any right or remedy provided
      under this agreement or by law shall constitute a waiver of that or any
      other right or remedy, nor shall it prevent or restrict the further
      exercise of that or any other right or remedy. No single or partial
      exercise of such right or remedy shall prevent or restrict the further
      exercise of that or any other right or remedy.
    </p><br/>
    <p>
      <strong>6. Rights and Remedies</strong>
    </p><br/>
    <p>
      Except as expressly provided in this agreement, the rights and remedies
      provided under this agreement are in addition to, and not exclusive of,
      any rights or remedies provided by law.
    </p><br/>
    <p>
      <strong>7. Severance</strong>
    </p><br/>
    <ol>
      <li>
        If any provision or part-provision of this agreement is or becomes
        invalid, illegal, or unenforceable, it shall be deemed deleted, but that
        shall not affect the validity and enforceability of the rest of this
        agreement.
      </li>
      <li>
        If any provision or part-provision of this agreement is deemed deleted
        under clause 20.1 the parties shall negotiate in good faith to agree a
        replacement provision that, to the greatest extent possible, achieves
        the intended commercial result of the original provision.
      </li>
    </ol>
    <p>
      <strong>8. Entire Agreement</strong>
    </p><br/>
    <p>
      This agreement constitutes the entire agreement between the parties and
      supersedes and extinguishes all previous agreements, promises, assurances,
      warranties, representations, and understandings between them, whether
      written or oral, relating to its subject matter.
    </p><br/>
    <p>
      Each party acknowledges that in entering into this agreement it does not
      rely on, and shall have no remedies in respect of, any statement,
      representation, assurance, or warranty (whether made innocently or
      negligently) that is not set out in this agreement.
    </p><br/>
    <p>
      Each party agrees that it shall have no claim for innocent or negligent
      misrepresentation or negligent misstatement based on any statement in this
      agreement.
    </p><br/>
    <p>
      Nothing in this clause shall limit or exclude any liability for fraud.
    </p><br/>
    <p>
      <strong>9. Assignment</strong>
    </p><br/>
    <ol>
      <li>
        The Customer shall not, without the prior written consent of the
        Supplier, assign, transfer, charge, subcontract, or deal in any other
        manner with all or any of its rights or obligations under this
        agreement.
      </li>
      <li>
        The Supplier may at any time assign, transfer, charge, sub-contract, or
        deal in any other manner with all or any of its rights or obligations
        under this agreement.
      </li>
    </ol>
    <p>
      <strong>10. No Partnership or Agency</strong>
    </p><br/>
    <p>
      Nothing in this agreement is intended to or shall operate to create a
      partnership between the parties or authorize either party to act as an
      agent for the other, and neither party shall have the authority to act in
      the name or on behalf of or otherwise to bind the other in any way
      (including, but not limited to, the making of any representation or
      warranty, the assumption of any obligation or liability, and the exercise
      of any right or power).
    </p><br/>
    <p>
      <strong>11. Third Party Rights</strong>
    </p><br/>
    <p>
      This agreement does not confer any rights on any person or party (other
      than the parties to this agreement and, where applicable, their successors
      and permitted assigns) pursuant to the Contracts (Rights of Third Parties)
      Act 1999.
    </p><br/>
    <p>
      <strong>12. Notices</strong>
    </p><br/>
    <ol>
      <li>
        Any notice required to be given under this agreement shall be in writing
        and shall be delivered by hand or sent by prepaid first-class post or
        recorded delivery post to the other party at its address set out in this
        agreement, or such other address as may have been notified by that party
        for such purposes, or sent by fax to the other party's fax number as set
        out in this agreement.
      </li>
      <li>
        A notice delivered by hand shall be deemed to have been received when
        delivered (or if delivery is not in business hours, at 9 am on the first
        business day following delivery). A correctly addressed notice sent by
        pre-paid first-class post or recorded delivery post shall be deemed to
        have been received at the time at which it would have been delivered in
        the normal course of post. A notice sent by fax shall be deemed to have
        been received at the time of transmission (as shown by the timed
        printout obtained by the sender).
      </li>
    </ol>
    <p>
      <strong>13. Governing Law and Jurisdiction</strong>
    </p><br/>
    <ol>
      <li>
        This agreement and any dispute or claim arising out of or in connection
        with it or its subject matter or formation (including non-contractual
        disputes or claims) shall be governed by and construed in accordance
        with the law of England and Wales.
      </li>
      <li>
        Each party irrevocably agrees that the courts of England and Wales shall
        have exclusive jurisdiction to settle any dispute or claim arising out
        of or in connection with this agreement or its subject matter or
        formation (including non-contractual disputes or claims).
      </li>
    </ol>
    <p>
      <strong>14. Counterparts</strong>
    </p><br/>
    <p>
      This agreement may be executed in any number of counterparts, each of
      which when executed shall constitute a duplicate original, but all the
      counterparts shall together constitute the one agreement.
    </p><br/>
  </>
);

export const privacyLink = (
  <>
    <p>
      <strong>Website Privacy Policy</strong>
    </p><br/>
    <p>
      <strong>1 INTRODUCTION</strong>
    </p><br/>
    <p>
      <strong>1.1 Important information and who we are</strong>
    </p><br/>
    <p>
      Welcome to Propsmarts.com Limited’s Privacy and Data Protection Policy
      ("Privacy Policy").
    </p><br/>
    <p>
      At Propsmarts.com Limited ("we", "us", or "our") we are committed to
      protecting and respecting your privacy and Personal Data in compliance
      with the United Kingdom General Data Protection Regulation ("GDPR"), the
      Data Protection Act 2018 and all other mandatory laws and regulations of
      the United Kingdom.
    </p><br/>
    <p>
      This Privacy Policy explains how we collect, process and keep your data
      safe. The Privacy Policy will tell you about your privacy rights, how the
      law protects you, and inform our employees and staff members of all their
      obligations and protocols when processing data.
    </p><br/>
    <p>The individuals from which we may gather and use data can include:</p><br/>
    <ul className="list-disc">
      <li>Customers</li>
      <li>Suppliers</li>
      <li>Business contacts</li>
      <li>Employees/Staff Members</li>
      <li>Third parties connected to your customers</li>
      <li>
        and any other people that the organisation has a relationship with or
        may need to contact.
      </li>
    </ul>
    <p>
      This Privacy Policy applies to all our employees and staff members and all
      Personal Data processed at any time by us.
    </p><br/>
    <p>
      <strong>1.2 Your Data Controller</strong>
    </p><br/>
    <p>
      Propsmarts.com Limited is your Data Controller and responsible for your
      Personal Data.
    </p><br/>
    <p>
      We are not obliged by the GDPR to appoint a data protection officer and
      have not voluntarily appointed one at this time. Therefore, any inquiries
      about your data should either be sent to us by email to
      enqiries@propsmarts.com or by post to Welsh Ice Britannia House,
      Caerphilly Business Park, Caerphilly, CF83 3GG, United Kingdom.
    </p><br/>
    <p>
      You have the right to make a complaint at any time to the Information
      Commissioner's Office (ICO), the UK supervisory authority for data
      protection issues (www.ico.org.uk). We would, however, appreciate the
      chance to deal with your concerns before you approach the ICO, so please
      contact us in the first instance.
    </p><br/>
    <p>
      <strong>
        1.3 Processing data on behalf of a Controller and processors'
        responsibility to you
      </strong>
    </p><br/>
    <p>
      In discharging our responsibilities as a Data Controller, we have
      employees who will deal with your data on our behalf (known as
      "Processors"). The responsibilities below may be assigned to an individual
      or may be taken to apply to the organisation as a whole. The Data
      Controller and our Processors have the following responsibilities:
    </p><br/>
    <ul className="list-disc">
      <li>
        Ensure that all processing of Personal Data is governed by one of the
        legal bases laid out in the GDPR (see 2.2 below for more information);
      </li>
      <li>
        Ensure that Processors authorised to process Personal Data have
        committed themselves to confidentiality or are under an appropriate
        statutory obligation of confidentiality;
      </li>
      <li>
        Implement appropriate technical and organisational measures to ensure a
        level of security appropriate to the risk associated with the processing
        of Personal Data;
      </li>
      <li>
        Obtain the prior specific or general authorisation of the Controller
        before engaging another Processor;
      </li>
      <li>
        Assist the Controller in the fulfilment of the Controller's obligation
        to respond to requests for exercising the data subject's rights;
      </li>
      <li>
        Make available to the Controller all information necessary to
        demonstrate compliance with the obligations laid down in the GDPR and
        allow for and contribute to audits, including inspections, conducted by
        the Controller or another auditor mandated by the Controller;
      </li>
      <li>
        Maintain a record of all categories of processing activities carried out
        on behalf of a Controller;
      </li>
      <li>
        Cooperate, on request, with the supervisory authority in the performance
        of its tasks;
      </li>
      <li>
        Ensure that any person acting under the authority of the Processor who
        has access to Personal Data does not process Personal Data except on
        instructions from the Controller; and
      </li>
      <li>
        Notify the Controller without undue delay after becoming aware of a
        Personal Data Breach.
      </li>
    </ul>
    <p>
      <strong>2 LEGAL BASIS FOR DATA COLLECTION</strong>
    </p><br/>
    <p>
      <strong>2.1 Types of data / Privacy policy scope</strong>
    </p><br/>
    <p>
      "Personal Data" means any information about an individual from which that
      person can be identified. It does not include data where the identity has
      been removed (anonymous data).
    </p><br/>
    <p>
      We may collect, use, store and transfer different kinds of Personal Data
      about you which we have grouped together below. Not all of the following
      types of data will necessarily be collected from you, but this is the full
      scope of data that we collect and when we collect it from you:
    </p><br/>
    <ul className="list-disc">
      <li>
        Profile/Identity Data: This is data relating to your first name, last
        name, gender, date of birth.
      </li>
      <li>
        Billing Data: This is information relating to your debit and credit card
        information such as the name attached to your payment details and your
        billing address.
      </li>
      <li>
        Transactional Data: This is information of details and records of all
        payments you have made for our services or products.
      </li>
      <li>
        Usage Data: information about how you use our website, products, and
        services.
      </li>
    </ul>
    <p>
      We do not collect any Special Categories of Personal Data about you (this
      includes details about your race or ethnicity, religious or philosophical
      beliefs, sex life, sexual orientation, political opinions, trade union
      membership, information about your health, and genetic and biometric
      data). Nor do we collect any information about criminal convictions and
      offences.
    </p><br/>
    <p>
      <strong>2.2 The Legal Basis for Collecting That Data</strong>
    </p><br/>
    <p>
      There are a number of justifiable reasons under the GDPR that allow
      collection and processing of Personal Data. The main avenues we rely on
      are:
    </p><br/>
    <ul className="list-disc">
      <li>
        "Consent": Certain situations allow us to collect your Personal Data,
        such as when you tick a box that confirms you are happy to receive email
        newsletters from us or 'opt-in' to a service.
      </li>
      <li>
        "Contractual Obligations": We may require certain information from you
        to fulfill our contractual obligations and provide you with the promised
        service.
      </li>
      <li>
        "Legal Compliance": We're required by law to collect and process certain
        types of data, such as fraudulent activity or other illegal actions.
      </li>
      <li>
        "Legitimate Interest": We might need to collect certain information from
        you to be able to meet our legitimate interests - this covers aspects
        that can be reasonably expected as part of running our business, that
        will not have a material impact on your rights, freedom or interests.
        Examples could be your address, so that we know where to deliver
        something to, or your name, so that we have a record of who to contact
        moving forwards.
      </li>
    </ul>
    <p>
      <strong>3 HOW WE USE YOUR PERSONAL DATA</strong>
    </p><br/>
    <p>
      <strong>3.1 Our data uses</strong>
    </p><br/>
    <p>We will only use your Personal Data when the law allows us to.</p><br/>
    <p>
      Set out below is a table containing the different types of Personal Data
      we collect and the lawful basis for processing that data. Please refer to
      section 2.2 for more information on the lawful basis listed in the table
      below.
    </p><br/>
    <p>
      Examples provided in the table below are indicative in nature and the
      purposes for which we use your data may be broader than described, but we
      will never process your data without a legal basis for doing so and it is
      for a related purpose. For further inquiries, please contact us.
    </p><br/>
    <p>
      Activity Type of data Legal Justification Lawful basis for processing data
    </p><br/>
    <p>
      when a customer signs up Contact Data Billing Data Consent legitimate
      interest
    </p><br/>
    <p>
      <strong>3.2 Change of purpose</strong>
    </p><br/>
    <p>
      We will only use your Personal Data for the purposes for which we
      collected it, unless we reasonably consider that we need to use it for
      another reason and that reason is compatible with the original purpose. If
      you wish to get an explanation as to how the processing for the new
      purpose is compatible with the original purpose, please contact us.
    </p><br/>
    <p>
      If we need to use your Personal Data for an unrelated purpose, we will
      notify you, and we will explain the legal basis which allows us to do so.
    </p><br/>
    <p>
      Please note that we may process your Personal Data without your knowledge
      or consent, in compliance with the above rules, where this is required or
      permitted by law.
    </p><br/>
    <strong>4 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</strong>
    <strong>4.1 Your legal rights</strong>
    <p>
      Under certain circumstances, you have the following rights under data
      protection laws in relation to your personal data:
    </p><br/>
    <ol>
      <li>
        <strong>Right to be informed.</strong> You have a right to be informed
        about our purposes for processing your personal data, how long we store
        it for, and who it will be shared with. We have provided this
        information to you in this policy.
      </li>
      <li>
        <strong>Right of access.</strong> This enables you to receive a copy of
        the personal data we hold about you and to check that we are lawfully
        processing it (also known as a "data subject access request"). See
        section 4.4 below for more details on how you can make a data subject
        access request.
      </li>
      <li>
        <strong>Right to rectification.</strong> You have a right to request
        correction of the personal data that we hold about you. This enables you
        to have any incomplete or inaccurate data we hold about you corrected,
        though we may need to verify the accuracy of the new data you provide to
        us.
      </li>
      <li>
        <strong>Right to erasure.</strong> You have the right to ask us to
        delete or remove personal data where there is no good reason for us
        continuing to process it, where you have successfully exercised your
        right to object to processing (see below), where we may have processed
        your information unlawfully or where we are required to erase your
        personal data to comply with local law. Note, however, that we may not
        always be able to comply with your request of erasure for specific legal
        reasons which will be notified to you, if applicable, at the time of
        your request.
      </li>
      <li>
        <strong>Right to object.</strong> You can object to the processing of
        personal data we hold about you. This effectively allows you to stop or
        prevent us from processing your personal data. Note that this is not an
        absolute right and it only applies in certain circumstances, for
        example:
        <ol type="i">
          <li>
            Where we are processing your personal data for direct marketing
            purposes.
          </li>
          <li>
            Where we are relying on a legitimate interest (or those of a third
            party) and there is something about your particular situation which
            makes you want to object to processing on this ground as you feel it
            impacts on your fundamental rights and freedoms.
          </li>
          <li>
            In some cases, we may continue processing your data if we can
            demonstrate that we have compelling legitimate grounds to process
            your information which override your rights and freedoms.
          </li>
        </ol>
      </li>
      <li>
        <strong>Right to restrict processing.</strong> You have the right to
        request the restriction or suppression of their personal data. Note that
        this is not an absolute right and it only applies in certain
        circumstances:
        <ol type="i">
          <li>If you want us to establish the data's accuracy.</li>
          <li>
            Where our use of the data is unlawful but you do not want us to
            erase it.
          </li>
          <li>
            Where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise or defend legal claims.
          </li>
          <li>
            You have objected to our use of your data but we need to verify
            whether we have overriding legitimate grounds to use it.
          </li>
        </ol>
      </li>
      <li>
        <strong>Right to data portability.</strong> You have the right to
        request the transfer of your personal data to you or to a third party.
        If you make such a request, we will provide to you, or a third party you
        have chosen, your personal data in a structured, commonly used,
        machine-readable format. Note that this right only applies to automated
        information which you initially provided consent for us to use or where
        we used the information to perform a contract with you.
      </li>
    </ol>
    <p>
      If you wish to make a request under any of these rights, please contact us
      at enqiries@propsmarts.com.
    </p><br/>
    <strong>
      4.2 Your control over Propsmarts.com Limited’s use of your Personal Data
    </strong>
    <p>
      You may delete your account at any time – this will remove your account
      page from our systems and our related software. We guarantee this will
      delete all stored data. You can access information associated with your
      account by logging into your account you created with us. Your account
      information will be protected by a password for your privacy and security.
      You need to prevent unauthorized access to your account and personal
      information by selecting and protecting your password appropriately and
      limiting access to your computer or device and by signing off after you
      have finished accessing your account.
    </p><br/>
    <p>
      <strong>California Privacy Rights:</strong> Under California Civil Code
      sections 1798.83-1798.84, California residents are entitled to ask us for
      a notice identifying the categories of personal customer
    </p><br/>
    <strong>6 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</strong>
    <strong>6.1 Your legal rights</strong>
    <p>
      Under certain circumstances, you have the following rights under data
      protection laws in relation to your personal data:
    </p><br/>
    <ol>
      <li>
        <strong>Sharing your data with third parties.</strong> We may also share
        Personal Data with interested parties in the event that Propsmarts.com
        Limited anticipates a change in control or the acquisition of all or
        part of our business or assets or with interested parties in connection
        with the licensing of our technology. If Propsmarts.com Limited is sold
        or makes a sale or transfer, we may, in our sole discretion, transfer,
        sell or assign your Personal Data to a third party as part of or in
        connection with that transaction. Upon such transfer, the Privacy Policy
        of the acquiring entity may govern the further use of your Personal
        Data. In all other situations, your data will still remain protected in
        accordance with this Privacy Policy (as amended from time to time). We
        may share your Personal Data at any time if required for legal reasons
        or to enforce our terms or this Privacy Policy.
      </li>
    </ol>
    <strong>7 HOW LONG WE RETAIN YOUR DATA</strong>
    <p>
      We will only retain your Personal Data for as long as reasonably necessary
      to fulfill the purposes we collected it for. We may retain your Personal
      Data for a longer period than usual in the event of a complaint or if we
      reasonably believe there is a prospect of litigation in respect to our
      relationship with you.
    </p><br/>
    <strong>8 INTERNATIONAL TRANSFER OF DATA</strong>
    <p>
      Your information may be stored and processed in the US or other countries
      or jurisdictions outside the US where Propsmarts.com Limited has
      facilities. By using Propsmarts.com Limited, you are permitting and
      consenting to the transfer of information, including Personal Data,
      outside of the US.
    </p><br/>
    <strong>9 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</strong>
    <p>
      We keep our Privacy Policy under review and will place any updates here.
      This version is dated 28 June 2023. By using Propsmarts.com Limited, you
      consent to the collection and use of data by us as set out in this Privacy
      Policy. Continued access or use of Propsmarts.com Limited will constitute
      your express acceptance of any modifications to this Privacy Policy.
    </p><br/>
    <strong>10 INTERPRETATION</strong>
    <p>
      All uses of the word "including" mean "including but not limited to" and
      the enumerated examples are not intended to in any way limit the term
      which they serve to illustrate. Any email addresses set out in this policy
      may be used solely for the purpose for which they are stated to be
      provided, and any unrelated correspondence will be ignored. Unless
      otherwise required by law, we reserve the right to not respond to emails,
      even if they relate to a legitimate subject matter for which we have
      provided an email address. You are more likely to get a reply if your
      request or question is polite, reasonable and there is no relatively
      obvious other way to deal with or answer your concern or question (e.g.
      FAQs, other areas of our website, etc.). Our staff are not authorized to
      contract on behalf of Propsmarts.com Limited, waive rights, or make
      representations (whether contractual or otherwise). If anything contained
      in an email from a Propsmarts.com Limited address contradicts anything in
      this policy, our terms, or any official public announcement on our
      website, or is inconsistent with or amounts to a waiver of any
      Propsmarts.com Limited rights, the email content will be read down to
      grant precedence to the latter. The only exception to this is genuine
      correspondence expressed to be from the Propsmarts.com Limited legal
      department.
    </p><br/>
  </>
);
