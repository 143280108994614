import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartDoughnut = (props) => {
  let { value, color } = props;
  // Cap the value at 100%
  const renderedValue = value;
  value = Math.min(value, 100);
  const data = {
    labels: ["Percentage"],
    datasets: [
      {
        label: "Percentage",
        data: [value, 100 - value],
        backgroundColor: [color, "#E6E6E6"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    cutout: 75,
    responsive: true,
    rotation: -150,
    circumference: 300,
    borderWidth: 0,
    borderRadius: 8,
    events: [],
  };

  return (
    <div className='h-[200px] pt-4 relative'>
      <Doughnut data={data} options={options} />
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/4 text-5xl'>
        {renderedValue} {!!renderedValue && "%"}
      </div>
    </div>
  );
};

export default ChartDoughnut;
