import { CircularProgress } from "@mui/material";
import PricingPlansBillingForm from "forms/PricingPlansBillingForm";
import { plans } from "helpers/pricing-plans";
import Cookies from "js-cookie";
import { isValid } from "postcode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "services";
import { fetchUserData } from "services/auth";
import { subscribe } from "services/payment";
import UpgradeSubscriptionInfo from "./UpgradeSubscriptionInfo";
import Toast from "ui/Toast";


const CreateSubscription = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const token = Cookies.get("propToken");
  const state = useSelector((state) => state.auth.status);
  const creatingError = useSelector((state) => state.subscribe.error);
  const creatingState = useSelector((state) => state.subscribe.status);
  const userData = useSelector((state) => state.auth.userData);
  const [postCodeError, setPostCodeError] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const selectedPlanId = +params.id;
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);

  useEffect(() => {
    if (userData?.id) return;
    console.log("UE:[]")
    dispatch(fetchUserData(token));
  }, []);

  const [formValues, setFormValues] = useState({
    bankAccountNumber: "",
    sortCode: "",
    firstName: "",
    lastName: "",
    companyName: "",
    billingAddressLineOne: "",
    billingAddressLineTwo: "",
    city: "",
    country: "United Kingdom",
    postCode: "",
    agree: "",
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    if (name === "agree" && value === "agree") {
      setBtnDisabled(false);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const isValidPostcode = isValid(formValues.postCode);
    if (!isValidPostcode) {
      setPostCodeError(true);
      return;
    } else {
      setPostCodeError(false);
    }
    const data = {
      values: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: userData.email,
        companyName: formValues.companyName,
        accountNumber: formValues.bankAccountNumber,
        branchCode: Number(formValues.sortCode.replaceAll("-", "")),
        accountHolderName: formValues.companyName ? formValues.companyName : formValues.firstName + " " + formValues.lastName,
        countryCode: "GB",
        city: formValues.city,
        postCode: formValues.postCode,
        country: formValues.country,
        subscriptionId: selectedPlanId,
        billingAddressLineOne: formValues.billingAddressLineOne,
        billingAddressLineTwo: formValues.billingAddressLineTwo,
      },
      token,
    };

    const res = await dispatch(subscribe(data));

    if (res.payload.status === 201) setSubscriptionSuccess(true);
  };

  return (
    <>
      <div className="my-12 mx-7">
        {state === "loading" || state === "idle" ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : subscriptionSuccess ? (
          <UpgradeSubscriptionInfo
            title="Subscription successfully changed!"
            body={true}
            planTitle={selectedPlan.title}
          />
        ) : (

                  <div style={{ width: "70%" }}>
                    <h1 className=' text-4xl text-background-blue font-bold mb-3 mt-6'>
                      Set up a Direct Debit with Propsmarts
                    </h1>
              <PricingPlansBillingForm
                handleInputChange={handleInputChange}
                formValues={formValues}
                handleSubmit={handleSubmit}
                postCodeError={postCodeError}
                status={creatingState}
                btnDisabled={btnDisabled}
                backLink='../..'
              />
          </div>
        )}

      </div>
      <Toast
        severity="error"
        message={creatingError}
        openToast={creatingState === "failed"}
      />
    </>
  );
};

export default CreateSubscription;
