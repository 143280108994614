const ProjectPlanningInfo = (props) => {
  const { title, value } = props;

  return (
    <div className="flex flex-col">
      <h3 className="text-sm font-bold">{title}</h3>
      <h4 className="text-sm">{value}</h4>
    </div>
  );
};

export default ProjectPlanningInfo;
