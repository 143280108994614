import { useParams } from "react-router-dom";
import appraisalIcon from "../styles/img/appraisal.svg";
import crimeIcon from "../styles/img/crime.svg";
import demographicIcon from "../styles/img/demographic.svg";
import epcIcon from "../styles/img/epc_register.svg";
import mapIcon from "../styles/img/map.svg";
import overviewIcon from "../styles/img/overview.svg";
import planIcon from "../styles/img/planning.svg";
import reportIcon from "../styles/img/report.svg";
import salesStudyIcon from "../styles/img/sales_study.svg";
import TopbarItem from "./TopbarItem";

const Topbar = ({ className = "", walkthroughUrl = "" }) => {
  const params = useParams();
  const projectId = params.id;

  let url = "";
  if (walkthroughUrl) {
    url = walkthroughUrl;
  } else {
    url = `/dashboard/projects/details/${projectId}`;
  }

  return (
    <header
      className={`bg-background-blue rounded-[20px] mb-3.5 relative ${className}`}
    >
    <div className='flex py-3 px-16 items-end justify-between text-total-white text-sm'>
        <TopbarItem url={url} icon={overviewIcon} text='Overview' />
        <TopbarItem url={`${url}/map`} icon={mapIcon} text='Map' />
        <TopbarItem url={`${url}/sales-study`} icon={salesStudyIcon} text='Sales Study'/>
        <TopbarItem url={`${url}/feasibility`} icon={appraisalIcon} text='Feasibility' />
        <TopbarItem url={`${url}/demographic`} icon={demographicIcon} text='Demographic'/>
        <TopbarItem url={`${url}/crime`} icon={crimeIcon} text='Crime'/>
        <TopbarItem url={`${url}/epc-register`} icon={epcIcon} text='EPC Register' />
        <TopbarItem url={`${url}/planning`} icon={planIcon} text='Planning' />
        <TopbarItem url={`${url}/report`} icon={reportIcon} text='Report' />
    </div>
    </header>
  );
};

export default Topbar;
