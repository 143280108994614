import CustomTextField from "ui/CustomTextField";
import Button from "ui/Button";

import { CircularProgress, FormControlLabel } from "@mui/material";
import CustomCheckbox from "ui/CustomCheckbox";

const LoginForm = ({
  handleSubmit,
  formValues,
  handleInputChange,
  status,
  error,
  userData,
}) => {
  const labelStyle = { fontFamily: "Urbanist" };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
      <CustomTextField
        label="Email"
        type="email"
        name="email"
        backgroundcolor="#F0F0F0"
        fullWidth
        value={formValues.email}
        onChange={handleInputChange}
        fontSize="20"
        borderradius="10"
        InputLabelProps={{
          style: { color: "#A9A9A9" },
          shrink: true,
        }}
      />
      <CustomTextField
        label="Password"
        type="password"
        name="password"
        backgroundcolor="#F0F0F0"
        fullWidth
        value={formValues.password}
        onChange={handleInputChange}
        fontSize="20"
        borderradius="10"
        InputLabelProps={{
          style: { color: "#A9A9A9" },
          shrink: true,
        }}
      />
      {status === "loading" ? (
        <div className="flex justify-center mt-3">
          <CircularProgress style={{ color: "#141831" }} />
        </div>
      ) : (
        <Button
          title="Login"
          type="submit"
          className="bg-green py-3 w-full rounded-[1.5rem] mt-3 text-2xl"
        />
      )}
    </form>
  );
};

export default LoginForm;
