import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";


const initialState = {
        status: "idle",
        error: "",
        values: [{"templateId": "All", "value": 0}] // todo less generic name
    }


export const fetchPredictedValues = createAsyncThunk<any, any, any>(
    "get/report-generation/projects/predictedValues",
    async (params, thunkApi) => {
        const { projectId, token } = params;
        console.log("fetchPredictedValues");
        const response = await api.get(
            `report-generation/projects/predictedValues`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    projectId,
                },
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }

        return thunkApi.fulfillWithValue(response.data);
    }
);


export const createPredictedValue = createAsyncThunk<any, any, any>(
    "post/report-generation/projects/predictedValues",
    async (params, thunkApi) => {
        const {token, projectId, templateId, templateName, value, sales } = params;
        console.log("createPredictedValue "+projectId+"  "+templateId+"  "+value)
        const response = await api.post(
            `report-generation/projects/predictedValues`,
            {
                "projectId" : projectId,
                "templateId" : templateId,
                "value": value,
                "sales" : sales
             },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params:{}
            }
        );
        console.log("predictedValues: "+ response.data)
        if (response.status >= 400) {

            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);


const predictedValuesSlice = createSlice({
    name: "predictedValues",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchPredictedValues.fulfilled, (state, action) => {
            console.log("fetchPredictedValues - success action.payload");
            state.status = "success";
            state.values = action.payload
        })
        .addCase(createPredictedValue.fulfilled, (state, action) => {
            console.log("createPredictedValue - success action.payload");
            state.status = "success";
            let values = [...state.values]
            let update = action.payload
            let current = values.find(e => e.templateId == update.templateId)
            if (current != null){
                console.log("createPredictedValue - updating existing");
                current.value = update.value
            } else {
                console.log("createPredictedValue - pushing new");
                values.push(update)
            }
            state.values = values;
        })
    },
});


export default predictedValuesSlice;