import Button from "ui/Button";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const AccountDetailsHeader = ({ back }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between mb-5">
      <h1 className="text-3xl font-bold">Account Details</h1>
      <Button
        title="Back"
        className="text-sm p-4 rounded-xl border border-[#141831]"
        icon={
          <ArrowBackIosIcon style={{ fontSize: "13px", marginRight: "10px" }} />
        }
        onClick={() => navigate(back)}
      />
    </div>
  );
};

export default AccountDetailsHeader;
