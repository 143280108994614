const IconPercentageItem = (props) => {
  const { icon, percentage, title } = props;

  return (
    <div>
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-col items-center bg-background-blue pt-2 px-5 pb-1 text-total-white gap-1 rounded">
          <img src={icon} alt={`${icon}`} className="w-[20px] h-[28px]" />
          <p>{percentage ? percentage : "0,0"}%</p>
        </div>
        <h3 className="text-sm">{title}</h3>
      </div>
    </div>
  );
};

export default IconPercentageItem;
