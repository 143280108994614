import CheckIcon from "@mui/icons-material/Check";
import Button from "ui/Button";

const PricingPlanCard = ({
  plan,
  className,
  handleChoosePlan,
  planBackgroundColor,
  selectedPlanId,
}) => {
  const { color, title, subtitle, description, properties, btn, id } = plan;
  let btnText;
  let showBtn = true;
  if (selectedPlanId) {
    if (selectedPlanId < id) {
      btnText = "Upgrade";
    } else {
      showBtn = false;
    }
  }

  return (
    <div className={`rounded-[30px] bg-total-white overflow-hidden ${className} flex flex-col`} >
      <div style={{ backgroundColor: color }} className="py-5">
        <h1 className="font-bold text-center text-total-white" style={{ fontSize: '1.75rem' }} >
          {title}
        </h1>
      </div>
      <div
        className="pt-4 pb-10 px-4 flex flex-col h-full"
        style={{ backgroundColor: planBackgroundColor ? planBackgroundColor : "#fff", }}
      >
      <h2 className="font-bold text-center" style={{ fontSize: '2rem' }}>{subtitle}</h2>
    <div className="flex flex-col mb-auto">
      <div className="font-bold text-center" style={{ fontSize: '1rem' }}>{properties[0]}</div>
      <div className="font-bold text-center" style={{ fontSize: '1rem' }}>{properties[1]}</div>
      <div className="font-bold text-center pt-5 " style={{ fontSize: '1.5rem' }}>{properties[2]}</div>
       <div className="font-bold text-center pt-12" style={{ fontSize: '1.5rem' }}>{properties[3]}</div>
    </div>
    <>
        {
        (handleChoosePlan) ? (
            <>
          {
            plan.mail ? (
                <>
                  <div  className={`bg-green py-4 rounded-2xl w-full text-center`} style={{ fontSize: '1rem' }} >
                    <a href="mailto:prof@propsmarts.com">Email: prof@propsmarts.com</a>
                  </div>
               </>
            ) : (
             <>
                  <Button
                    title={btnText ? btnText : btn}
                    className={`bg-green py-4 rounded-2xl text-xl w-full ${
                      showBtn ? "visible" : "invisible"
                    }`} style={{ fontSize: '1.5rem' }}
                    onClick={() => handleChoosePlan(plan)}
                  />
             </>
            )
          }
            </>

        ) : (<div></div>)
}
       </>
      </div>
    </div>
  );
};

export default PricingPlanCard;
