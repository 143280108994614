import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { useMemo } from "react";

import plus from "styles/img/plus.svg";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: "50vw",
  height: "auto",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Previews(props) {
  const [files, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);
  const title = useMemo(() => {
    return files.length > 0 ? "Images" : "Upload Images";
  }, [files.length]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      const convertedFiles = acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result;
          setBase64Files((prevFiles) => [
            ...prevFiles,
            { name: file.name, base64 },
          ]);
        };
        reader.readAsDataURL(file);
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      setFiles(convertedFiles);
    },
  });

  const thumbs = base64Files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.base64}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data URIs to avoid memory leaks, will run on unmount
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <section className='container'>
      <h1 className='text-3xl leading-6 mb-10'>{title}</h1>
      {files.length === 0 && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />

          <span className='bg-total-white rounded-xl py-10 px-10 flex flex-col items-center justify-center'>
            <img src={plus} alt='plus' />
          </span>
        </div>
      )}
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}

export default Previews;
