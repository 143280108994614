import Button from "ui/Button";
import PricingPlanCard from "common/PricingPlanCard";

import { plans } from "helpers/pricing-plans";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import UpgradeSubscriptionInfo from "./UpgradeSubscriptionInfo";
import Cookies from "js-cookie";
import api from "api/api";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const UpgradeSubscription = () => {
  const navigate = useNavigate();
  const params = useParams();
  const selectedPlanId = +params.id;
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
  const [upgradeToPlan, setUpgradeToPlan] = useState(null);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);
  const token = Cookies.get("propToken");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // if trial then open DD flow
  // therwise

  const selectPlanToUpgrade = async (plan) => {
    try {
      setIsLoading(true);
      const id = plan.id;
      const upgradePlan = plans.find((plan) => plan.id === id);
      setUpgradeToPlan(upgradePlan);
      const res = await api.post(
        `customer/subscribeToPlan/upgrade?subscriptionId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) setUpgradeSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setError(error.response);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <div className="my-12 mx-7">
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-3xl font-bold">Upgrade subscription</h1>
        <Button
          title="Back"
          className="text-sm px-4 py-3 rounded-xl bg-green"
          icon={
            <ArrowBackIosIcon
              style={{ fontSize: "13px", marginRight: "10px" }}
            />
          }
          onClick={() => navigate("/dashboard/account/subscription")}
        />
      </div>
      {upgradeSuccess ? (
        <UpgradeSubscriptionInfo
          title="Subscription successfully changed!"
          body={true}
          planTitle={upgradeToPlan.title}
        />
      ) : (
        <>
          <p className="text-xl font-bold mb-10 text-[#7d7c7c]">
            You currently have the {selectedPlan.title} plan. Compare and
            upgrade below.
          </p>
          <div className="flex gap-8">
            {isLoading ? (
              <div className="flex items-center justify-center w-full">
                <CircularProgress />
              </div>
            ) : (
              <>
                {plans
                  .filter((plan) => plan.id !== 1)
                  .map((plan) => (
                    <PricingPlanCard
                      key={plan.id}
                      plan={plan}
                      className="flex-1"
                      planBackgroundColor="#f1f1f1"
                      handleChoosePlan={selectPlanToUpgrade}
                      selectedPlanId={selectedPlanId}
                    />
                  ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UpgradeSubscription;
