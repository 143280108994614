import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "services";

import AddProjectForm from "forms/AddProjectForm";
import {
  createProject,
  selectPollingLoading,
  selectPollingStatus,
  selectProjectId,
  selectStatuses,
  setPollingStatuses,
} from "services/project";
import Header from "typography/Header";
import Button from "ui/Button";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import api from "api/api";
import Cookies from "js-cookie";
import PollingModal from "layout/PollingModal";
import CustomStatusCheckboxField from "ui/CustomStatusCheckbox";

const AddProject = () => {
  const token = Cookies.get("propToken");

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const pollingStatus = useSelector(selectPollingStatus);
  const pollingData = useSelector(selectStatuses);
  const loading = useSelector(selectPollingLoading);
  const projectId = useSelector(selectProjectId);
  const { status } = useSelector((state) => state.projects);
  const pollingFields = useMemo(
    () => [
      {
        id: "crimeDataStatus",
        label: "Crime",
        status: pollingData?.["crimeDataStatus"] || "PENDING",
      },
      {
        id: "demographicDataStatus",
        label: "Demographics",
        status: pollingData?.["demographicDataStatus"] || "PENDING",
      },
      {
        id: "epcDataStatus",
        label: "EPC",
        status: pollingData?.["epcDataStatus"] || "PENDING",
      },
      {
        id: "planningDataStatus",
        label: "Planning",
        status: pollingData?.["planningDataStatus"] || "PENDING",
      },
      {
        id: "salesDataStatus",
        label: "Sales",
        status: pollingData?.["salesDataStatus"] || "PENDING",
      },
      {
        id: "searchDataStatus",
        label: "Search",
        status: pollingData?.["searchDataStatus"] || "PENDING",
      },
    ],
    [pollingData]
  );

  const [formValues, setFormValues] = useState({
    title: "",
    summary: "",
    status: "Research and Due diligence pending",
    streetNumber: "",
    streetAddress: "",
    city: "",
    postcode: "",
  });
  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSearchChange = async (evt, value) => {
    if (!value || !value?.Id) return;
    console.log("handleSearchChange :: "+value.Id)

    const { Id } = value;
    try {
      dispatch(setPollingStatuses(null));
      const response = await api.get(
        `report-generation/addresses/details?addressId=${encodeURIComponent(
          Id
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const addressDetails = response.data.Items[0];
      console.log("handleSearchChange addressDetails:: "+addressDetails.PostalCode)

      const firstSpaceIndex = addressDetails.Line1.indexOf(" ");
      const number = addressDetails.Line1.substring(0, firstSpaceIndex);
      const addr = addressDetails.Line1.substring(firstSpaceIndex + 1);
      setFormValues({
        ...formValues,
        city: addressDetails.City,
        streetNumber: number,
        streetAddress: addr,
        postcode: addressDetails.PostalCode,
      });
    } catch (error) {
      if (error.response) {
        setError(error.response);
      } else {
        setError(error.message);
      }
    }
  };

  const handleSubmit = async (evt) => {
    console.log("handleSubmit :: " + formValues.postcode)
    if(formValues.postcode != null && formValues.postcode.length  >= 5){
        setOpen(true)
        evt.preventDefault();
        const data = {
          projectData: formValues,
          token,
        };
        let promise = dispatch(createProject(data));
        promise.then((e) => e.meta.requestStatus === "fulfilled" && setOpen(true));
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <Header header="Add Project" />
        <div className="flex">
          <Link to=".." relative="path">
            <Button
              type="button"
              className="bg-green text-black rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 mr-4 flex items-center"
              title="Back"
              icon={
                <ArrowBackIosIcon
                  style={{ fontSize: "13px", marginRight: "10px" }}
                />
              }
            />
          </Link>
          {/* <Filter /> */}
        </div>
      </div>
      <div className="bg-background-gray py-8 px-5 pb-8 pl-6 rounded-3xl">
        <AddProjectForm
          loading={loading}
          status={pollingData}
          formValues={formValues}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          status={status}
          handleSearchChange={handleSearchChange}
        />
      </div>
      {open && (
        <PollingModal
          fullWidth={true}
          projectId={projectId}
          loading={loading}
          pollingStatus={pollingStatus}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => setOpen(false)}
          open={open}
          maxWidth={"md"}
          content={
            <>
              {pollingFields.map((field) => (
                <Fragment key={field.id}>
                  <CustomStatusCheckboxField
                    status={field.status}
                    id={field.id}
                    text={field.label}
                  />
                </Fragment>
              ))}
            </>
          }
        />
      )}
    </>
  );
};

export default AddProject;
