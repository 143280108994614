import ProjectTable from "common/ProjectTable";
import ProjectTableFilters from "common/ProjectTableFilters";
import DebugJson from "common/DebugJson";
import StatCard from "common/StatCard";
import ButtonCard from "common/ButtonCard";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import { useDispatch, useSelector } from "services";
import PropertyTemplateForm from "forms/PropertyTemplateForm"
import Cookies from "js-cookie";
import numeral from "numeral";
import {fetchProjectSales} from "services/project";
import {createPredictedValue} from "services/PredictedValues"
import {useEffect, useState} from "react";
import {fetchPredictedValues} from "services/PredictedValues";
import {fetchPropertyTemplates} from "services/property";
import ReactJson from 'react-json-view'
import {useLocation, useParams} from "react-router-dom";

import GMap from "./SalesMap"


const ProjectSalesStudy = (props) => {

    const {projectSalesData, salesStatus, projectData} = useSelector(
        (state) => state.projects
    );

    const {propertyTemplateOptions} = useSelector(
        (state) => state.property
    );

    const { values } = useSelector(
        (state) => state.predictedValues
    );

  const [searchResults, setSearchResults] = useState(null)
  const [averagePrice, setAveragePrice] = useState(null)
  const [mapZoom, setMapZoom] = useState({zoom:false, lat:0, lng:0})


  const location = useLocation();
  const params = useParams();
  let { debug } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const token = Cookies.get("propToken");
    const [showPropertyTemplateForm, setShowPropertyTemplateForm] = useState(false);
    const [disableSaveToTemplateButton, setDisableSaveToTemplateButton] = useState(true);

    const [formValues, setFormValues] = useState({
        propertyTemplateId: "All",
        salesDate: "6",
        radius: "1",
    });

    const handleSelect = (evt) => {
        const {name, value} = evt.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSearch = () => {
        console.log("Searching")
        dispatch(
            fetchProjectSales({
                token,
                postcode: projectData.postcode,
                propertyTemplateId : formValues.propertyTemplateId,
                salesDate : formValues.salesDate,
                radius: formValues.radius,
                projectId: projectData.id
            })
        );
        if (formValues.propertyTemplateId != 'All'){
            setDisableSaveToTemplateButton(false)
        }
    };

    const handleAddHouseTemplate = () => {
        setShowPropertyTemplateForm(true);
    };

    const handleDone = () => {
        setShowPropertyTemplateForm(false);
    };

    const handleSaveToTemplate = () => {
        console.log("Handle SaveToTemplate: " + averagePrice + "  " + formValues.propertyTemplateId )
        // TODO Save the results set for reporting.
        setDisableSaveToTemplateButton(true)
        JSON.stringify(searchResults)

        dispatch(
            createPredictedValue({
                token,
                postcode: projectData.postcode,
                projectId: projectData.id,
                templateId: formValues.propertyTemplateId,
                value: averagePrice,
                sales: searchResults,
            })
        );
    }

    const handleMapZoom = (evt, lat, lng) => {
        console.log("Handle handleMapZoom: " + lat + " " + lng)
        setMapZoom({zoom:true, lat: lat, lng: lng})
    };

    const handleDeleteRow = (evt, index) => {
        console.log("Handle delete row: " + index)
        let data = [...searchResults]
        data.splice(index, 1);
        setSearchResults(data)
    };

     useEffect(() => {
     console.log("UE:[searchResults]")
       if (searchResults && searchResults.length > 0){
            const sum = searchResults?.map(x => x.price).reduce((a, b) => a + b, 0);
            const avg = Math.round((sum / searchResults.length)/1000)*1000 || 0;
            setAveragePrice(avg)
        }
    }, [searchResults]);


    useEffect(() => {
        let data = []
        console.log("UE:[projectSalesData.sales]")
         console.log(JSON.stringify(projectSalesData))
              projectSalesData?.map(row => {
              console.log(row.saleDate)
                data.push(
                    {
                        saleDate: row.saleDate,
                        address: row.address,
                        price: row.price,
                        lat: row.lat,
                        lng: row.lng,
                        distance: row.distance,
                        type: row.type,
                        bedrooms: row.bedrooms,
                        tenure: row.tenure,
                        sqft: row.sqft,
                        pricePerSqFt: row.pricePerSqFt,
                        postcode: row.postcode,
                        addressKey: row.addressKey,
                        reject: false
                    }
                )
              });
        JSON.stringify("------"+data)
        setSearchResults(data)

    }, [projectSalesData]);

    return (

        <>
            {
                showPropertyTemplateForm ? (
                <>
                    <ProjectLayoutHeader header="Property templates"/>
                    <Topbar/>
                    <PropertyTemplateForm
                        handleDone={handleDone}
                        showPredictedValues={true}
                    />
                </>
            ) : (
                <>
                    <ProjectLayoutHeader header="Sales Study"/>
                    <Topbar/>
                    <>
                        <div className="flex gap-8 mb-8 flex-row w-full">
                            <StatCard
                                title="Postcode"
                                value={projectData.postcode}
                            />
                            <ButtonCard
                                title="Predicted value"
                                buttonTitle="Save to template"
                                value={
                                    `£${numeral(averagePrice).format(
                                        "0,0"
                                    )}` ?? "Not Available"
                                }
                                salesStatus={salesStatus}
                                onSubmit={handleSaveToTemplate}
                                disabled={disableSaveToTemplateButton}
                            />
                        </div>

                        <ProjectTableFilters
                            className="mb-8"
                            handleAddHouseTemplate={handleAddHouseTemplate}
                            handleSearch={handleSearch}
                            formValues={formValues}
                            handleSelect={handleSelect}
                        />

                        <ProjectTable status={salesStatus} data={searchResults} handleDeleteRow={handleDeleteRow} handleMapZoom={handleMapZoom}/>

                        <GMap key='salesSearchMap' data={searchResults} mapZoom={mapZoom}/>

                        <DebugJson data={[
                            ['projectSalesData', projectSalesData],
                            ['propertyTemplateOptions', propertyTemplateOptions],
                            ['predictedValues', values],
                            ['projectData', projectData]
                        ]}/>
                    </>
                </>
            )}
        </>
    )
}

export default ProjectSalesStudy;
