import { useSelector } from "services";

import WhiteCard from "common/WhiteCard";

import { CircularProgress } from "@mui/material";
import api from "api/api";
import { useState } from "react";
import email from "styles/img/email.svg";
import Button from "ui/Button";
import Toast from "ui/Toast";

const SignupVerify = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleResend = async () => {
    const { id } = userData;

    try {
      setIsLoading(true);
      const res = await api.post(
        `customer/auth/register/resend-email?customerId=${id}`
      );
      if (res.status === 200) {
        setSeverity("success");
        setMessage("Resend email. Check your account.");
        setOpen(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSeverity("error");
      setOpen(true);
      setMessage(error?.response?.data && "Error, please try again.");
    }
  };

  return (
    <>
      <WhiteCard>
        <h2 className="text-l">
          You will need to verify your email to complete registration.
        </h2>
        <div className="my-5">
          <img src={email} alt="email" />
        </div>
        <p className="text-center text-l">
          An email has been sent to {userData.email} with a link to verify your
          account. If you have not recieved an email in the next few minutes,
          please check your spam folder.
        </p>
        <div className="flex justify-between w-full my-6">
          {isLoading ? (
            <div className="flex items-center justify-center w-full">
              <CircularProgress />
            </div>
          ) : (
            <>
 <div className="flex flex-col  gap-4 w-full">
              <Button
                title="Resend email"
                className="py-3.5 px-5 bg-green rounded-2xl text-xl"
                onClick={handleResend}
              />
              <button
                className="py-3.5 px-8 bg-green rounded-2xl text-xl"
                onClick={() =>
                  (window.location = "mailto:support@propsmarts.com")
                }
              >
                Contact support
              </button>
               </div>
            </>
          )}
        </div>
      </WhiteCard>
      <Toast severity={severity} message={message} openToast={open} />
    </>
  );
};

export default SignupVerify;
