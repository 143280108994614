// @ts-nocheck
import { LatLngExpression } from "leaflet";
import { useState } from "react";
import { LayersControl, MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { Skeleton } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { RootState, useSelector } from "services";
import marker from "styles/img/marker.svg";
const LocalMap = () => {
  const [tileLoaded, setTileLoaded] = useState(false);
  const { projectPolygonData, projectData } = useSelector(
    (state: RootState) => state.projects
  );

  const customIcon = L.icon({
    iconUrl: marker,
    iconSize: [32, 32],
  });

  const polygons: any = projectPolygonData?.polygons; // Add nullish coalescing operator
  const center: LatLngExpression = [
    polygons?.length > 0 ? polygons[0]?.approx_centre?.lat : 0,
    polygons?.length > 0 ? polygons[0]?.approx_centre?.lng : 0,
  ];

  const { BaseLayer } = LayersControl;

  const handlePolygonClick = (evt: any) => {};

  const handlePopClose = () => {};

  if (!polygons) {
    return (
      <Skeleton
        variant='rectangular'
        style={{ width: "100%", height: `640px` }}
      />
    );
  }

  return (
    <>
      <h1 className=' text-3xl leading-6 mb-10'>Local Area Map</h1>
      <div
        className={`rounded-[20px] overflow-hidden relative`}
        style={{ height: `640px` }}
      >
        <MapContainer
          center={center}
          zoom={19}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%" }}
          className='z-40 joyride-dashboard-map'
        >
          <LayersControl position='topright'>
            <BaseLayer checked name='Satelite'>
              <TileLayer
                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                eventHandlers={{
                  load: () => setTileLoaded(true),
                }}
              />
            </BaseLayer>
            <BaseLayer name='OpenStreetMap'>
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                maxZoom={20}
                eventHandlers={{
                  load: () => setTileLoaded(true),
                }}
              />
            </BaseLayer>
          </LayersControl>
          {tileLoaded && (
            <MarkerClusterGroup chunkedLoading={false}>
              <Marker
                position={center}
                icon={customIcon}
                eventHandlers={{
                  click: handlePolygonClick,
                  popupclose: handlePopClose,
                }}
              ></Marker>
            </MarkerClusterGroup>
          )}
        </MapContainer>
      </div>
    </>
  );
};
export default LocalMap;
