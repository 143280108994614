import { CircularProgress } from "@mui/material";
import Button from "ui/Button";
import CustomTextField from "ui/CustomTextField";

const ForgotPasswordForm = ({
  handleSubmit,
  email,
  handleEmailChange,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
      <CustomTextField
        label="Email"
        type="email"
        name="email"
        backgroundcolor="#F0F0F0"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        fontSize="20"
        borderradius="10"
        InputLabelProps={{
          style: { color: "#A9A9A9" },
        }}
      />
      {isLoading ? (
        <div className="flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Button
          type="submit"
          title="Send email"
          className="bg-green text-2xl py-3 rounded-xl"
        />
      )}
    </form>
  );
};

export default ForgotPasswordForm;
