export const coords = [
  {
    UPRN: 1,
    LATITUDE: 51.4526008,
    LONGITUDE: -2.602075,
  },
  {
    UPRN: 26,
    LATITUDE: 51.5266333,
    LONGITUDE: -2.6793612,
  },
  {
    UPRN: 27,
    LATITUDE: 51.5266333,
    LONGITUDE: -2.6793612,
  },
  {
    UPRN: 30,
    LATITUDE: 51.5213173,
    LONGITUDE: -2.6528615,
  },
  {
    UPRN: 31,
    LATITUDE: 51.521236,
    LONGITUDE: -2.652918,
  },
  {
    UPRN: 32,
    LATITUDE: 51.5206696,
    LONGITUDE: -2.671979,
  },
  {
    UPRN: 33,
    LATITUDE: 51.5196842,
    LONGITUDE: -2.6852966,
  },
  {
    UPRN: 34,
    LATITUDE: 51.5201489,
    LONGITUDE: -2.6857792,
  },
  {
    UPRN: 38,
    LATITUDE: 51.5205131,
    LONGITUDE: -2.6865486,
  },
  {
    UPRN: 41,
    LATITUDE: 51.5203621,
    LONGITUDE: -2.6548369,
  },
  {
    UPRN: 42,
    LATITUDE: 51.4525618,
    LONGITUDE: -2.6035375,
  },
  {
    UPRN: 43,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 44,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 45,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 46,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 47,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 48,
    LATITUDE: 51.5195913,
    LONGITUDE: -2.6888696,
  },
  {
    UPRN: 49,
    LATITUDE: 51.5065027,
    LONGITUDE: -2.6975704,
  },
  {
    UPRN: 53,
    LATITUDE: 51.5184701,
    LONGITUDE: -2.6837938,
  },
  {
    UPRN: 54,
    LATITUDE: 51.5196251,
    LONGITUDE: -2.683105,
  },
  {
    UPRN: 55,
    LATITUDE: 51.5196251,
    LONGITUDE: -2.683105,
  },
  {
    UPRN: 57,
    LATITUDE: 51.5196322,
    LONGITUDE: -2.677239,
  },
  {
    UPRN: 58,
    LATITUDE: 51.5196322,
    LONGITUDE: -2.677239,
  },
  {
    UPRN: 59,
    LATITUDE: 51.5195685,
    LONGITUDE: -2.6773677,
  },
  {
    UPRN: 63,
    LATITUDE: 51.5204488,
    LONGITUDE: -2.6898339,
  },
  {
    UPRN: 68,
    LATITUDE: 51.5208601,
    LONGITUDE: -2.6887015,
  },
  {
    UPRN: 70,
    LATITUDE: 51.5190858,
    LONGITUDE: -2.686138,
  },
  {
    UPRN: 71,
    LATITUDE: 51.5187007,
    LONGITUDE: -2.6874149,
  },
  {
    UPRN: 74,
    LATITUDE: 51.5200477,
    LONGITUDE: -2.6907647,
  },
  {
    UPRN: 79,
    LATITUDE: 51.5187176,
    LONGITUDE: -2.6845326,
  },
  {
    UPRN: 80,
    LATITUDE: 51.5173283,
    LONGITUDE: -2.6820922,
  },
  {
    UPRN: 81,
    LATITUDE: 51.516959,
    LONGITUDE: -2.6823444,
  },
  {
    UPRN: 84,
    LATITUDE: 51.518402,
    LONGITUDE: -2.6846864,
  },
  {
    UPRN: 85,
    LATITUDE: 51.5187176,
    LONGITUDE: -2.6845326,
  },
  {
    UPRN: 86,
    LATITUDE: 51.5180781,
    LONGITUDE: -2.6984308,
  },
  {
    UPRN: 88,
    LATITUDE: 51.5180781,
    LONGITUDE: -2.6984308,
  },
  {
    UPRN: 89,
    LATITUDE: 51.5180781,
    LONGITUDE: -2.6984308,
  },
  {
    UPRN: 90,
    LATITUDE: 51.5180781,
    LONGITUDE: -2.6984308,
  },
  {
    UPRN: 92,
    LATITUDE: 51.5180781,
    LONGITUDE: -2.6984308,
  },
  {
    UPRN: 94,
    LATITUDE: 51.5179115,
    LONGITUDE: -2.6636942,
  },
  {
    UPRN: 95,
    LATITUDE: 51.5161237,
    LONGITUDE: -2.6929101,
  },
  {
    UPRN: 96,
    LATITUDE: 51.515102,
    LONGITUDE: -2.6737611,
  },
  {
    UPRN: 97,
    LATITUDE: 51.5156325,
    LONGITUDE: -2.5941536,
  },
  {
    UPRN: 98,
    LATITUDE: 51.5156332,
    LONGITUDE: -2.5940239,
  },
  {
    UPRN: 99,
    LATITUDE: 51.5150705,
    LONGITUDE: -2.6931246,
  },
  {
    UPRN: 100,
    LATITUDE: 51.5155886,
    LONGITUDE: -2.5939513,
  },
  {
    UPRN: 101,
    LATITUDE: 51.5155392,
    LONGITUDE: -2.5948153,
  },
  {
    UPRN: 102,
    LATITUDE: 51.515503,
    LONGITUDE: -2.5948725,
  },
  {
    UPRN: 103,
    LATITUDE: 51.5154988,
    LONGITUDE: -2.5939213,
  },
  {
    UPRN: 104,
    LATITUDE: 51.5154848,
    LONGITUDE: -2.5949155,
  },
  {
    UPRN: 105,
    LATITUDE: 51.5154268,
    LONGITUDE: -2.5939491,
  },
  {
    UPRN: 106,
    LATITUDE: 51.5153971,
    LONGITUDE: -2.594482,
  },
  {
    UPRN: 107,
    LATITUDE: 51.5153816,
    LONGITUDE: -2.5939918,
  },
  {
    UPRN: 109,
    LATITUDE: 51.5153632,
    LONGITUDE: -2.5940636,
  },
  {
    UPRN: 110,
    LATITUDE: 51.515343,
    LONGITUDE: -2.5945101,
  },
  {
    UPRN: 112,
    LATITUDE: 51.5149739,
    LONGITUDE: -2.6584488,
  },
  {
    UPRN: 113,
    LATITUDE: 51.5149739,
    LONGITUDE: -2.6584488,
  },
  {
    UPRN: 114,
    LATITUDE: 51.5153157,
    LONGITUDE: -2.5945674,
  },
  {
    UPRN: 115,
    LATITUDE: 51.5153033,
    LONGITUDE: -2.5952301,
  },
  {
    UPRN: 116,
    LATITUDE: 51.5153026,
    LONGITUDE: -2.5936017,
  },
  {
    UPRN: 117,
    LATITUDE: 51.5153034,
    LONGITUDE: -2.5934431,
  },
  {
    UPRN: 118,
    LATITUDE: 51.5152885,
    LONGITUDE: -2.5946103,
  },
  {
    UPRN: 119,
    LATITUDE: 51.5152767,
    LONGITUDE: -2.5951577,
  },
  {
    UPRN: 120,
    LATITUDE: 51.5152145,
    LONGITUDE: -2.5950272,
  },
  {
    UPRN: 121,
    LATITUDE: 51.5152121,
    LONGITUDE: -2.5937302,
  },
  {
    UPRN: 122,
    LATITUDE: 51.5152053,
    LONGITUDE: -2.5932833,
  },
  {
    UPRN: 123,
    LATITUDE: 51.5151848,
    LONGITUDE: -2.594969,
  },
  {
    UPRN: 124,
    LATITUDE: 51.5151484,
    LONGITUDE: -2.5956316,
  },
  {
    UPRN: 125,
    LATITUDE: 51.5151343,
    LONGITUDE: -2.5948676,
  },
  {
    UPRN: 126,
    LATITUDE: 51.5151287,
    LONGITUDE: -2.5942191,
  },
  {
    UPRN: 127,
    LATITUDE: 51.5151193,
    LONGITUDE: -2.594291,
  },
  {
    UPRN: 128,
    LATITUDE: 51.5151064,
    LONGITUDE: -2.5932821,
  },
  {
    UPRN: 129,
    LATITUDE: 51.515092,
    LONGITUDE: -2.5943483,
  },
  {
    UPRN: 130,
    LATITUDE: 51.515072,
    LONGITUDE: -2.5947515,
  },
  {
    UPRN: 131,
    LATITUDE: 51.51504,
    LONGITUDE: -2.5957311,
  },
  {
    UPRN: 132,
    LATITUDE: 51.5150344,
    LONGITUDE: -2.5933099,
  },
  {
    UPRN: 133,
    LATITUDE: 51.5150183,
    LONGITUDE: -2.5946932,
  },
  {
    UPRN: 134,
    LATITUDE: 51.5150132,
    LONGITUDE: -2.5939438,
  },
  {
    UPRN: 135,
    LATITUDE: 51.5150068,
    LONGITUDE: -2.5934249,
  },
  {
    UPRN: 136,
    LATITUDE: 51.5149917,
    LONGITUDE: -2.5946352,
  },
  {
    UPRN: 137,
    LATITUDE: 51.5149791,
    LONGITUDE: -2.5953412,
  },
  {
    UPRN: 138,
    LATITUDE: 51.5149769,
    LONGITUDE: -2.5940009,
  },
  {
    UPRN: 139,
    LATITUDE: 51.5149321,
    LONGITUDE: -2.5957441,
  },
  {
    UPRN: 140,
    LATITUDE: 51.5149409,
    LONGITUDE: -2.5940149,
  },
  {
    UPRN: 141,
    LATITUDE: 51.5149202,
    LONGITUDE: -2.5945478,
  },
  {
    UPRN: 142,
    LATITUDE: 51.51492,
    LONGITUDE: -2.5928185,
  },
  {
    UPRN: 143,
    LATITUDE: 51.5149046,
    LONGITUDE: -2.594072,
  },
  {
    UPRN: 144,
    LATITUDE: 51.5148757,
    LONGITUDE: -2.5944608,
  },
  {
    UPRN: 145,
    LATITUDE: 51.5148835,
    LONGITUDE: -2.5929189,
  },
  {
    UPRN: 146,
    LATITUDE: 51.5148624,
    LONGITUDE: -2.5952964,
  },
  {
    UPRN: 147,
    LATITUDE: 51.5148769,
    LONGITUDE: -2.5957762,
  },
  {
    UPRN: 148,
    LATITUDE: 51.514865,
    LONGITUDE: -2.5930195,
  },
  {
    UPRN: 150,
    LATITUDE: 51.5145165,
    LONGITUDE: -2.6582548,
  },
  {
    UPRN: 151,
    LATITUDE: 51.514858,
    LONGITUDE: -2.5943885,
  },
  {
    UPRN: 152,
    LATITUDE: 51.5148374,
    LONGITUDE: -2.5931344,
  },
  {
    UPRN: 153,
    LATITUDE: 51.5148153,
    LONGITUDE: -2.5957281,
  },
  {
    UPRN: 154,
    LATITUDE: 51.5148086,
    LONGITUDE: -2.5952669,
  },
  {
    UPRN: 155,
    LATITUDE: 51.5148041,
    LONGITUDE: -2.5926152,
  },
  {
    UPRN: 156,
    LATITUDE: 51.5147865,
    LONGITUDE: -2.5943155,
  },
  {
    UPRN: 157,
    LATITUDE: 51.5147437,
    LONGITUDE: -2.5956551,
  },
  {
    UPRN: 158,
    LATITUDE: 51.5147459,
    LONGITUDE: -2.5952228,
  },
  {
    UPRN: 159,
    LATITUDE: 51.5147212,
    LONGITUDE: -2.5983065,
  },
  {
    UPRN: 160,
    LATITUDE: 51.5147361,
    LONGITUDE: -2.5936087,
  },
  {
    UPRN: 161,
    LATITUDE: 51.5147125,
    LONGITUDE: -2.5982343,
  },
  {
    UPRN: 162,
    LATITUDE: 51.514733,
    LONGITUDE: -2.5942283,
  },
  {
    UPRN: 163,
    LATITUDE: 51.5147355,
    LONGITUDE: -2.593724,
  },
  {
    UPRN: 164,
    LATITUDE: 51.5147024,
    LONGITUDE: -2.5984503,
  },
  {
    UPRN: 165,
    LATITUDE: 51.5146863,
    LONGITUDE: -2.5980899,
  },
  {
    UPRN: 166,
    LATITUDE: 51.5145367,
    LONGITUDE: -2.6267084,
  },
  {
    UPRN: 167,
    LATITUDE: 51.5145415,
    LONGITUDE: -2.6241288,
  },
  {
    UPRN: 168,
    LATITUDE: 51.5146662,
    LONGITUDE: -2.5985075,
  },
  {
    UPRN: 169,
    LATITUDE: 51.5146902,
    LONGITUDE: -2.5937954,
  },
  {
    UPRN: 170,
    LATITUDE: 51.5146795,
    LONGITUDE: -2.5941412,
  },
  {
    UPRN: 171,
    LATITUDE: 51.5146596,
    LONGITUDE: -2.5980319,
  },
  {
    UPRN: 172,
    LATITUDE: 51.5146649,
    LONGITUDE: -2.5952362,
  },
  {
    UPRN: 173,
    LATITUDE: 51.5146541,
    LONGITUDE: -2.5955963,
  },
  {
    UPRN: 174,
    LATITUDE: 51.5145014,
    LONGITUDE: -2.6248921,
  },
  {
    UPRN: 175,
    LATITUDE: 51.5146582,
    LONGITUDE: -2.5947894,
  },
  {
    UPRN: 176,
    LATITUDE: 51.5144823,
    LONGITUDE: -2.6267941,
  },
  {
    UPRN: 177,
    LATITUDE: 51.5146296,
    LONGITUDE: -2.5986223,
  },
  {
    UPRN: 178,
    LATITUDE: 51.5146426,
    LONGITUDE: -2.5960862,
  },
  {
    UPRN: 179,
    LATITUDE: 51.5146504,
    LONGITUDE: -2.5927717,
  },
  {
    UPRN: 180,
    LATITUDE: 51.5144786,
    LONGITUDE: -2.6241135,
  },
  {
    UPRN: 181,
    LATITUDE: 51.5146024,
    LONGITUDE: -2.5986796,
  },
  {
    UPRN: 182,
    LATITUDE: 51.5146093,
    LONGITUDE: -2.5955669,
  },
  {
    UPRN: 183,
    LATITUDE: 51.514446,
    LONGITUDE: -2.6268512,
  },
  {
    UPRN: 184,
    LATITUDE: 51.514621,
    LONGITUDE: -2.5932613,
  },
  {
    UPRN: 185,
    LATITUDE: 51.5144476,
    LONGITUDE: -2.6248769,
  },
  {
    UPRN: 186,
    LATITUDE: 51.514599,
    LONGITUDE: -2.5940536,
  },
  {
    UPRN: 187,
    LATITUDE: 51.5145751,
    LONGITUDE: -2.5952062,
  },
  {
    UPRN: 188,
    LATITUDE: 51.5149036,
    LONGITUDE: -2.6582988,
  },
  {
    UPRN: 190,
    LATITUDE: 51.5145526,
    LONGITUDE: -2.5978575,
  },
  {
    UPRN: 191,
    LATITUDE: 51.5144067,
    LONGITUDE: -2.6241126,
  },
  {
    UPRN: 192,
    LATITUDE: 51.5145716,
    LONGITUDE: -2.5923672,
  },
  {
    UPRN: 193,
    LATITUDE: 51.51456,
    LONGITUDE: -2.594644,
  },
  {
    UPRN: 194,
    LATITUDE: 51.5145709,
    LONGITUDE: -2.5924969,
  },
  {
    UPRN: 195,
    LATITUDE: 51.5145545,
    LONGITUDE: -2.5939522,
  },
  {
    UPRN: 196,
    LATITUDE: 51.5145485,
    LONGITUDE: -2.5933757,
  },
  {
    UPRN: 197,
    LATITUDE: 51.5143757,
    LONGITUDE: -2.6248615,
  },
  {
    UPRN: 198,
    LATITUDE: 51.5145251,
    LONGITUDE: -2.5961999,
  },
  {
    UPRN: 199,
    LATITUDE: 51.5145212,
    LONGITUDE: -2.5952055,
  },
  {
    UPRN: 200,
    LATITUDE: 51.5143557,
    LONGITUDE: -2.6269221,
  },
  {
    UPRN: 201,
    LATITUDE: 51.5145194,
    LONGITUDE: -2.5955657,
  },
  {
    UPRN: 202,
    LATITUDE: 51.5145079,
    LONGITUDE: -2.5978137,
  },
  {
    UPRN: 203,
    LATITUDE: 51.5145152,
    LONGITUDE: -2.5946146,
  },
  {
    UPRN: 204,
    LATITUDE: 51.5143337,
    LONGITUDE: -2.6275867,
  },
  {
    UPRN: 205,
    LATITUDE: 51.5143338,
    LONGITUDE: -2.6276567,
  },
  {
    UPRN: 206,
    LATITUDE: 51.5143528,
    LONGITUDE: -2.6240974,
  },
  {
    UPRN: 207,
    LATITUDE: 51.5145165,
    LONGITUDE: -2.5925826,
  },
  {
    UPRN: 208,
    LATITUDE: 51.5145116,
    LONGITUDE: -2.5935481,
  },
  {
    UPRN: 209,
    LATITUDE: 51.5143202,
    LONGITUDE: -2.6274388,
  },
  {
    UPRN: 210,
    LATITUDE: 51.514324,
    LONGITUDE: -2.6278151,
  },
  {
    UPRN: 211,
    LATITUDE: 51.5143135,
    LONGITUDE: -2.6273685,
  },
  {
    UPRN: 212,
    LATITUDE: 51.5144917,
    LONGITUDE: -2.5939225,
  },
  {
    UPRN: 213,
    LATITUDE: 51.5143105,
    LONGITUDE: -2.6269647,
  },
  {
    UPRN: 214,
    LATITUDE: 51.5144585,
    LONGITUDE: -2.5986777,
  },
  {
    UPRN: 215,
    LATITUDE: 51.5143218,
    LONGITUDE: -2.6248464,
  },
  {
    UPRN: 216,
    LATITUDE: 51.5143056,
    LONGITUDE: -2.6278869,
  },
  {
    UPRN: 217,
    LATITUDE: 51.5144565,
    LONGITUDE: -2.5955505,
  },
  {
    UPRN: 218,
    LATITUDE: 51.5144735,
    LONGITUDE: -2.592193,
  },
  {
    UPRN: 219,
    LATITUDE: 51.5142779,
    LONGITUDE: -2.6280307,
  },
  {
    UPRN: 220,
    LATITUDE: 51.5144436,
    LONGITUDE: -2.5945416,
  },
  {
    UPRN: 221,
    LATITUDE: 51.5144229,
    LONGITUDE: -2.5986052,
  },
  {
    UPRN: 222,
    LATITUDE: 51.5144348,
    LONGITUDE: -2.5962852,
  },
  {
    UPRN: 223,
    LATITUDE: 51.5144472,
    LONGITUDE: -2.5938499,
  },
  {
    UPRN: 224,
    LATITUDE: 51.5143208,
    LONGITUDE: -2.6182604,
  },
  {
    UPRN: 225,
    LATITUDE: 51.5142899,
    LONGITUDE: -2.6240965,
  },
  {
    UPRN: 226,
    LATITUDE: 51.5142594,
    LONGITUDE: -2.6281169,
  },
  {
    UPRN: 227,
    LATITUDE: 51.5144315,
    LONGITUDE: -2.5951611,
  },
  {
    UPRN: 228,
    LATITUDE: 51.5143032,
    LONGITUDE: -2.6181881,
  },
  {
    UPRN: 229,
    LATITUDE: 51.5144094,
    LONGITUDE: -2.597726,
  },
  {
    UPRN: 230,
    LATITUDE: 51.5144334,
    LONGITUDE: -2.5930139,
  },
  {
    UPRN: 231,
    LATITUDE: 51.5142588,
    LONGITUDE: -2.6248599,
  },
  {
    UPRN: 232,
    LATITUDE: 51.5142408,
    LONGITUDE: -2.6282319,
  },
  {
    UPRN: 233,
    LATITUDE: 51.5144265,
    LONGITUDE: -2.5926103,
  },
  {
    UPRN: 234,
    LATITUDE: 51.5142927,
    LONGITUDE: -2.6184762,
  },
  {
    UPRN: 235,
    LATITUDE: 51.5142859,
    LONGITUDE: -2.6180582,
  },
  {
    UPRN: 236,
    LATITUDE: 51.5144025,
    LONGITUDE: -2.5955498,
  },
  {
    UPRN: 237,
    LATITUDE: 51.5142292,
    LONGITUDE: -2.6270356,
  },
  {
    UPRN: 238,
    LATITUDE: 51.5142223,
    LONGITUDE: -2.6283325,
  },
  {
    UPRN: 239,
    LATITUDE: 51.5143935,
    LONGITUDE: -2.5937916,
  },
  {
    UPRN: 240,
    LATITUDE: 51.5143693,
    LONGITUDE: -2.5985324,
  },
  {
    UPRN: 241,
    LATITUDE: 51.514227,
    LONGITUDE: -2.6240813,
  },
  {
    UPRN: 242,
    LATITUDE: 51.5143607,
    LONGITUDE: -2.5984603,
  },
  {
    UPRN: 243,
    LATITUDE: 51.5143558,
    LONGITUDE: -2.5976532,
  },
  {
    UPRN: 244,
    LATITUDE: 51.5142413,
    LONGITUDE: -2.6179855,
  },
  {
    UPRN: 245,
    LATITUDE: 51.514205,
    LONGITUDE: -2.6248448,
  },
  {
    UPRN: 246,
    LATITUDE: 51.5143703,
    LONGITUDE: -2.5930563,
  },
  {
    UPRN: 247,
    LATITUDE: 51.5142386,
    LONGITUDE: -2.6184899,
  },
  {
    UPRN: 248,
    LATITUDE: 51.5143535,
    LONGITUDE: -2.5963562,
  },
  {
    UPRN: 249,
    LATITUDE: 51.514193,
    LONGITUDE: -2.6270784,
  },
  {
    UPRN: 250,
    LATITUDE: 51.5141857,
    LONGITUDE: -2.6284473,
  },
  {
    UPRN: 251,
    LATITUDE: 51.5143458,
    LONGITUDE: -2.5960967,
  },
  {
    UPRN: 252,
    LATITUDE: 51.5142328,
    LONGITUDE: -2.617899,
  },
  {
    UPRN: 253,
    LATITUDE: 51.5143546,
    LONGITUDE: -2.5943675,
  },
  {
    UPRN: 254,
    LATITUDE: 51.5143414,
    LONGITUDE: -2.5951887,
  },
  {
    UPRN: 255,
    LATITUDE: 51.5141674,
    LONGITUDE: -2.6285047,
  },
  {
    UPRN: 256,
    LATITUDE: 51.5131809,
    LONGITUDE: -2.7009207,
  },
  {
    UPRN: 257,
    LATITUDE: 51.514184,
    LONGITUDE: -2.623706,
  },
  {
    UPRN: 258,
    LATITUDE: 51.5142152,
    LONGITUDE: -2.6178123,
  },
  {
    UPRN: 259,
    LATITUDE: 51.5143073,
    LONGITUDE: -2.5983587,
  },
  {
    UPRN: 260,
    LATITUDE: 51.5143217,
    LONGITUDE: -2.5955343,
  },
  {
    UPRN: 261,
    LATITUDE: 51.5143171,
    LONGITUDE: -2.5946696,
  },
  {
    UPRN: 262,
    LATITUDE: 51.5141514,
    LONGITUDE: -2.6236645,
  },
  {
    UPRN: 263,
    LATITUDE: 51.5141565,
    LONGITUDE: -2.625507,
  },
  {
    UPRN: 264,
    LATITUDE: 51.5143291,
    LONGITUDE: -2.5923064,
  },
  {
    UPRN: 265,
    LATITUDE: 51.514321,
    LONGITUDE: -2.5921334,
  },
  {
    UPRN: 266,
    LATITUDE: 51.5141479,
    LONGITUDE: -2.6254204,
  },
  {
    UPRN: 267,
    LATITUDE: 51.5143155,
    LONGITUDE: -2.5932141,
  },
  {
    UPRN: 268,
    LATITUDE: 51.5141399,
    LONGITUDE: -2.6252474,
  },
  {
    UPRN: 269,
    LATITUDE: 51.5141403,
    LONGITUDE: -2.6251609,
  },
  {
    UPRN: 270,
    LATITUDE: 51.514304,
    LONGITUDE: -2.5937039,
  },
  {
    UPRN: 271,
    LATITUDE: 51.5141217,
    LONGITUDE: -2.6286482,
  },
  {
    UPRN: 272,
    LATITUDE: 51.5141755,
    LONGITUDE: -2.6185323,
  },
  {
    UPRN: 273,
    LATITUDE: 51.5142717,
    LONGITUDE: -2.5982862,
  },
  {
    UPRN: 274,
    LATITUDE: 51.5141399,
    LONGITUDE: -2.6235613,
  },
  {
    UPRN: 275,
    LATITUDE: 51.5141124,
    LONGITUDE: -2.6287057,
  },
  {
    UPRN: 276,
    LATITUDE: 51.5142877,
    LONGITUDE: -2.5951448,
  },
  {
    UPRN: 277,
    LATITUDE: 51.5141314,
    LONGITUDE: -2.6234603,
  },
  {
    UPRN: 278,
    LATITUDE: 51.5142663,
    LONGITUDE: -2.59758,
  },
  {
    UPRN: 279,
    LATITUDE: 51.5142742,
    LONGITUDE: -2.5960237,
  },
  {
    UPRN: 280,
    LATITUDE: 51.5142551,
    LONGITUDE: -2.5997703,
  },
  {
    UPRN: 281,
    LATITUDE: 51.5140847,
    LONGITUDE: -2.6288494,
  },
  {
    UPRN: 282,
    LATITUDE: 51.5141295,
    LONGITUDE: -2.6204195,
  },
  {
    UPRN: 283,
    LATITUDE: 51.5142398,
    LONGITUDE: -2.5992369,
  },
  {
    UPRN: 284,
    LATITUDE: 51.5142631,
    LONGITUDE: -2.5946833,
  },
  {
    UPRN: 285,
    LATITUDE: 51.5141054,
    LONGITUDE: -2.623287,
  },
  {
    UPRN: 286,
    LATITUDE: 51.5140754,
    LONGITUDE: -2.628907,
  },
  {
    UPRN: 287,
    LATITUDE: 51.5142361,
    LONGITUDE: -2.5982136,
  },
  {
    UPRN: 288,
    LATITUDE: 51.5142451,
    LONGITUDE: -2.5964556,
  },
  {
    UPRN: 289,
    LATITUDE: 51.5141215,
    LONGITUDE: -2.6185459,
  },
  {
    UPRN: 290,
    LATITUDE: 51.5142187,
    LONGITUDE: -2.5998563,
  },
  {
    UPRN: 291,
    LATITUDE: 51.5142469,
    LONGITUDE: -2.5943373,
  },
  {
    UPRN: 292,
    LATITUDE: 51.5142222,
    LONGITUDE: -2.5991646,
  },
  {
    UPRN: 293,
    LATITUDE: 51.5142504,
    LONGITUDE: -2.5936312,
  },
  {
    UPRN: 294,
    LATITUDE: 51.5142337,
    LONGITUDE: -2.5951585,
  },
  {
    UPRN: 295,
    LATITUDE: 51.5140878,
    LONGITUDE: -2.6232003,
  },
  {
    UPRN: 296,
    LATITUDE: 51.5142092,
    LONGITUDE: -2.599957,
  },
  {
    UPRN: 297,
    LATITUDE: 51.5140748,
    LONGITUDE: -2.62565,
  },
  {
    UPRN: 298,
    LATITUDE: 51.5142127,
    LONGITUDE: -2.5975072,
  },
  {
    UPRN: 299,
    LATITUDE: 51.5140477,
    LONGITUDE: -2.6290363,
  },
  {
    UPRN: 300,
    LATITUDE: 51.5142228,
    LONGITUDE: -2.595533,
  },
  {
    UPRN: 301,
    LATITUDE: 51.5141997,
    LONGITUDE: -2.6000578,
  },
  {
    UPRN: 302,
    LATITUDE: 51.5142295,
    LONGITUDE: -2.5942217,
  },
  {
    UPRN: 303,
    LATITUDE: 51.5140462,
    LONGITUDE: -2.6276384,
  },
  {
    UPRN: 304,
    LATITUDE: 51.5142298,
    LONGITUDE: -2.5923772,
  },
  {
    UPRN: 305,
    LATITUDE: 51.5141897,
    LONGITUDE: -2.600245,
  },
  {
    UPRN: 306,
    LATITUDE: 51.5141902,
    LONGITUDE: -2.6001441,
  },
  {
    UPRN: 307,
    LATITUDE: 51.5141958,
    LONGITUDE: -2.5990634,
  },
  {
    UPRN: 308,
    LATITUDE: 51.5141914,
    LONGITUDE: -2.5981554,
  },
  {
    UPRN: 309,
    LATITUDE: 51.5140292,
    LONGITUDE: -2.6291225,
  },
  {
    UPRN: 310,
    LATITUDE: 51.5140614,
    LONGITUDE: -2.6230991,
  },
  {
    UPRN: 311,
    LATITUDE: 51.5142003,
    LONGITUDE: -2.5964118,
  },
  {
    UPRN: 312,
    LATITUDE: 51.5142023,
    LONGITUDE: -2.5960228,
  },
  {
    UPRN: 313,
    LATITUDE: 51.5140368,
    LONGITUDE: -2.6277103,
  },
  {
    UPRN: 314,
    LATITUDE: 51.5142057,
    LONGITUDE: -2.5935874,
  },
  {
    UPRN: 315,
    LATITUDE: 51.5142002,
    LONGITUDE: -2.5946681,
  },
  {
    UPRN: 316,
    LATITUDE: 51.5140806,
    LONGITUDE: -2.6177672,
  },
  {
    UPRN: 317,
    LATITUDE: 51.5141692,
    LONGITUDE: -2.598991,
  },
  {
    UPRN: 318,
    LATITUDE: 51.5140106,
    LONGITUDE: -2.6292519,
  },
  {
    UPRN: 319,
    LATITUDE: 51.5140297,
    LONGITUDE: -2.6256782,
  },
  {
    UPRN: 320,
    LATITUDE: 51.5140438,
    LONGITUDE: -2.6230268,
  },
  {
    UPRN: 321,
    LATITUDE: 51.5140674,
    LONGITUDE: -2.6185596,
  },
  {
    UPRN: 322,
    LATITUDE: 51.5140184,
    LONGITUDE: -2.6261104,
  },
  {
    UPRN: 323,
    LATITUDE: 51.5141779,
    LONGITUDE: -2.5955181,
  },
  {
    UPRN: 324,
    LATITUDE: 51.5140001,
    LONGITUDE: -2.6278539,
  },
  {
    UPRN: 325,
    LATITUDE: 51.5141874,
    LONGITUDE: -2.5918722,
  },
  {
    UPRN: 326,
    LATITUDE: 51.514156,
    LONGITUDE: -2.5980397,
  },
  {
    UPRN: 327,
    LATITUDE: 51.5139831,
    LONGITUDE: -2.629338,
  },
  {
    UPRN: 328,
    LATITUDE: 51.5141427,
    LONGITUDE: -2.5988897,
  },
  {
    UPRN: 329,
    LATITUDE: 51.5139861,
    LONGITUDE: -2.6279259,
  },
  {
    UPRN: 330,
    LATITUDE: 51.5141719,
    LONGITUDE: -2.5931546,
  },
  {
    UPRN: 331,
    LATITUDE: 51.5140354,
    LONGITUDE: -2.6178098,
  },
  {
    UPRN: 332,
    LATITUDE: 51.5141578,
    LONGITUDE: -2.5941632,
  },
  {
    UPRN: 333,
    LATITUDE: 51.5141441,
    LONGITUDE: -2.5950853,
  },
  {
    UPRN: 334,
    LATITUDE: 51.514117,
    LONGITUDE: -2.6003881,
  },
  {
    UPRN: 335,
    LATITUDE: 51.5141294,
    LONGITUDE: -2.5979817,
  },
  {
    UPRN: 336,
    LATITUDE: 51.5141285,
    LONGITUDE: -2.5963965,
  },
  {
    UPRN: 337,
    LATITUDE: 51.5141374,
    LONGITUDE: -2.5946385,
  },
  {
    UPRN: 338,
    LATITUDE: 51.5141304,
    LONGITUDE: -2.5960218,
  },
  {
    UPRN: 339,
    LATITUDE: 51.5140134,
    LONGITUDE: -2.6185733,
  },
  {
    UPRN: 340,
    LATITUDE: 51.5141162,
    LONGITUDE: -2.5988029,
  },
  {
    UPRN: 341,
    LATITUDE: 51.5141385,
    LONGITUDE: -2.5926642,
  },
  {
    UPRN: 342,
    LATITUDE: 51.5139464,
    LONGITUDE: -2.6294816,
  },
  {
    UPRN: 343,
    LATITUDE: 51.5139496,
    LONGITUDE: -2.6260857,
  },
  {
    UPRN: 344,
    LATITUDE: 51.514129,
    LONGITUDE: -2.5927649,
  },
  {
    UPRN: 345,
    LATITUDE: 51.5139361,
    LONGITUDE: -2.6280548,
  },
  {
    UPRN: 346,
    LATITUDE: 51.5139281,
    LONGITUDE: -2.629539,
  },
  {
    UPRN: 347,
    LATITUDE: 51.5139836,
    LONGITUDE: -2.6272173,
  },
  {
    UPRN: 348,
    LATITUDE: 51.5141238,
    LONGITUDE: -2.5920155,
  },
  {
    UPRN: 349,
    LATITUDE: 51.5140806,
    LONGITUDE: -2.6004741,
  },
  {
    UPRN: 350,
    LATITUDE: 51.5140848,
    LONGITUDE: -2.597909,
  },
  {
    UPRN: 351,
    LATITUDE: 51.5140992,
    LONGITUDE: -2.5950847,
  },
  {
    UPRN: 352,
    LATITUDE: 51.5139311,
    LONGITUDE: -2.6272909,
  },
  {
    UPRN: 353,
    LATITUDE: 51.5139176,
    LONGITUDE: -2.628141,
  },
  {
    UPRN: 354,
    LATITUDE: 51.514088,
    LONGITUDE: -2.5955169,
  },
  {
    UPRN: 355,
    LATITUDE: 51.5140953,
    LONGITUDE: -2.5940759,
  },
  {
    UPRN: 356,
    LATITUDE: 51.5140893,
    LONGITUDE: -2.593485,
  },
  {
    UPRN: 357,
    LATITUDE: 51.5139632,
    LONGITUDE: -2.6178665,
  },
  {
    UPRN: 358,
    LATITUDE: 51.514063,
    LONGITUDE: -2.5986437,
  },
  {
    UPRN: 359,
    LATITUDE: 51.5138914,
    LONGITUDE: -2.6296826,
  },
  {
    UPRN: 360,
    LATITUDE: 51.5138899,
    LONGITUDE: -2.6282847,
  },
  {
    UPRN: 361,
    LATITUDE: 51.5140659,
    LONGITUDE: -2.5945655,
  },
  {
    UPRN: 362,
    LATITUDE: 51.5140353,
    LONGITUDE: -2.6005456,
  },
  {
    UPRN: 363,
    LATITUDE: 51.5140493,
    LONGITUDE: -2.5978221,
  },
  {
    UPRN: 364,
    LATITUDE: 51.5138805,
    LONGITUDE: -2.6283567,
  },
  {
    UPRN: 365,
    LATITUDE: 51.5139345,
    LONGITUDE: -2.6164827,
  },
  {
    UPRN: 366,
    LATITUDE: 51.513935,
    LONGITUDE: -2.6163818,
  },
  {
    UPRN: 367,
    LATITUDE: 51.513864,
    LONGITUDE: -2.6297543,
  },
  {
    UPRN: 368,
    LATITUDE: 51.5139231,
    LONGITUDE: -2.6186585,
  },
  {
    UPRN: 369,
    LATITUDE: 51.5139269,
    LONGITUDE: -2.6179236,
  },
  {
    UPRN: 370,
    LATITUDE: 51.5140297,
    LONGITUDE: -2.5963664,
  },
  {
    UPRN: 371,
    LATITUDE: 51.5140341,
    LONGITUDE: -2.5955018,
  },
  {
    UPRN: 372,
    LATITUDE: 51.5139248,
    LONGITUDE: -2.6166122,
  },
  {
    UPRN: 373,
    LATITUDE: 51.5140185,
    LONGITUDE: -2.5985566,
  },
  {
    UPRN: 374,
    LATITUDE: 51.5140444,
    LONGITUDE: -2.59347,
  },
  {
    UPRN: 375,
    LATITUDE: 51.5139244,
    LONGITUDE: -2.6166987,
  },
  {
    UPRN: 376,
    LATITUDE: 51.5140473,
    LONGITUDE: -2.592908,
  },
  {
    UPRN: 377,
    LATITUDE: 51.5140417,
    LONGITUDE: -2.5940031,
  },
  {
    UPRN: 378,
    LATITUDE: 51.5136274,
    LONGITUDE: -2.6707367,
  },
  {
    UPRN: 379,
    LATITUDE: 51.5138617,
    LONGITUDE: -2.6285005,
  },
  {
    UPRN: 380,
    LATITUDE: 51.5140511,
    LONGITUDE: -2.5921587,
  },
  {
    UPRN: 381,
    LATITUDE: 51.5139989,
    LONGITUDE: -2.6006315,
  },
  {
    UPRN: 382,
    LATITUDE: 51.5140275,
    LONGITUDE: -2.5950405,
  },
  {
    UPRN: 383,
    LATITUDE: 51.5138498,
    LONGITUDE: -2.6273763,
  },
  {
    UPRN: 384,
    LATITUDE: 51.5139055,
    LONGITUDE: -2.6168714,
  },
  {
    UPRN: 385,
    LATITUDE: 51.5138434,
    LONGITUDE: -2.6285723,
  },
  {
    UPRN: 386,
    LATITUDE: 51.5140195,
    LONGITUDE: -2.5930661,
  },
  {
    UPRN: 387,
    LATITUDE: 51.5138853,
    LONGITUDE: -2.6189895,
  },
  {
    UPRN: 388,
    LATITUDE: 51.5138998,
    LONGITUDE: -2.6162372,
  },
  {
    UPRN: 389,
    LATITUDE: 51.513885,
    LONGITUDE: -2.6190615,
  },
  {
    UPRN: 390,
    LATITUDE: 51.5138961,
    LONGITUDE: -2.6169577,
  },
  {
    UPRN: 391,
    LATITUDE: 51.5139822,
    LONGITUDE: -2.5915525,
  },
  {
    UPRN: 392,
    LATITUDE: 51.5138912,
    LONGITUDE: -2.616165,
  },
  {
    UPRN: 393,
    LATITUDE: 51.5138183,
    LONGITUDE: -2.6298834,
  },
  {
    UPRN: 394,
    LATITUDE: 51.5138139,
    LONGITUDE: -2.6287182,
  },
  {
    UPRN: 395,
    LATITUDE: 51.5139829,
    LONGITUDE: -2.5984985,
  },
  {
    UPRN: 396,
    LATITUDE: 51.5140033,
    LONGITUDE: -2.5944926,
  },
  {
    UPRN: 397,
    LATITUDE: 51.5138753,
    LONGITUDE: -2.6191911,
  },
  {
    UPRN: 398,
    LATITUDE: 51.5139996,
    LONGITUDE: -2.593455,
  },
  {
    UPRN: 399,
    LATITUDE: 51.5138659,
    LONGITUDE: -2.619263,
  },
  {
    UPRN: 400,
    LATITUDE: 51.5138727,
    LONGITUDE: -2.6179806,
  },
  {
    UPRN: 401,
    LATITUDE: 51.5139626,
    LONGITUDE: -2.6007031,
  },
  {
    UPRN: 402,
    LATITUDE: 51.513978,
    LONGITUDE: -2.5976915,
  },
  {
    UPRN: 403,
    LATITUDE: 51.51386,
    LONGITUDE: -2.6186721,
  },
  {
    UPRN: 404,
    LATITUDE: 51.5138,
    LONGITUDE: -2.6299552,
  },
  {
    UPRN: 405,
    LATITUDE: 51.5138135,
    LONGITUDE: -2.6274334,
  },
  {
    UPRN: 406,
    LATITUDE: 51.5138592,
    LONGITUDE: -2.6171301,
  },
  {
    UPRN: 407,
    LATITUDE: 51.5139495,
    LONGITUDE: -2.5997374,
  },
  {
    UPRN: 408,
    LATITUDE: 51.5139515,
    LONGITUDE: -2.5993484,
  },
  {
    UPRN: 409,
    LATITUDE: 51.5137972,
    LONGITUDE: -2.6288023,
  },
  {
    UPRN: 410,
    LATITUDE: 51.513944,
    LONGITUDE: -2.6008182,
  },
  {
    UPRN: 411,
    LATITUDE: 51.5139687,
    LONGITUDE: -2.5959909,
  },
  {
    UPRN: 412,
    LATITUDE: 51.5138566,
    LONGITUDE: -2.6159196,
  },
  {
    UPRN: 413,
    LATITUDE: 51.513939,
    LONGITUDE: -2.6000399,
  },
  {
    UPRN: 414,
    LATITUDE: 51.5138165,
    LONGITUDE: -2.6245778,
  },
  {
    UPRN: 415,
    LATITUDE: 51.5138183,
    LONGITUDE: -2.6245792,
  },
  {
    UPRN: 416,
    LATITUDE: 51.5138174,
    LONGITUDE: -2.6245778,
  },
  {
    UPRN: 417,
    LATITUDE: 51.5138408,
    LONGITUDE: -2.6172019,
  },
  {
    UPRN: 418,
    LATITUDE: 51.5139338,
    LONGITUDE: -2.5992905,
  },
  {
    UPRN: 419,
    LATITUDE: 51.5139599,
    LONGITUDE: -2.5924169,
  },
  {
    UPRN: 420,
    LATITUDE: 51.5139546,
    LONGITUDE: -2.5934544,
  },
  {
    UPRN: 421,
    LATITUDE: 51.513922,
    LONGITUDE: -2.5998379,
  },
  {
    UPRN: 422,
    LATITUDE: 51.5139442,
    LONGITUDE: -2.5955006,
  },
  {
    UPRN: 423,
    LATITUDE: 51.5139293,
    LONGITUDE: -2.5984258,
  },
  {
    UPRN: 424,
    LATITUDE: 51.5137695,
    LONGITUDE: -2.6289316,
  },
  {
    UPRN: 425,
    LATITUDE: 51.5138392,
    LONGITUDE: -2.6158041,
  },
  {
    UPRN: 426,
    LATITUDE: 51.5138185,
    LONGITUDE: -2.6180231,
  },
  {
    UPRN: 427,
    LATITUDE: 51.5138994,
    LONGITUDE: -2.5960743,
  },
  {
    UPRN: 428,
    LATITUDE: 51.513917,
    LONGITUDE: -2.5990741,
  },
  {
    UPRN: 429,
    LATITUDE: 51.5139243,
    LONGITUDE: -2.5976331,
  },
  {
    UPRN: 430,
    LATITUDE: 51.5137359,
    LONGITUDE: -2.6300677,
  },
  {
    UPRN: 431,
    LATITUDE: 51.5139027,
    LONGITUDE: -2.6000971,
  },
  {
    UPRN: 432,
    LATITUDE: 51.5139318,
    LONGITUDE: -2.5944052,
  },
  {
    UPRN: 433,
    LATITUDE: 51.5137991,
    LONGITUDE: -2.6247353,
  },
  {
    UPRN: 434,
    LATITUDE: 51.5137974,
    LONGITUDE: -2.6247395,
  },
  {
    UPRN: 435,
    LATITUDE: 51.5137974,
    LONGITUDE: -2.6247353,
  },
  {
    UPRN: 436,
    LATITUDE: 51.5139286,
    LONGITUDE: -2.5950248,
  },
  {
    UPRN: 437,
    LATITUDE: 51.5138306,
    LONGITUDE: -2.6157319,
  },
  {
    UPRN: 438,
    LATITUDE: 51.5138898,
    LONGITUDE: -2.6008607,
  },
  {
    UPRN: 439,
    LATITUDE: 51.5137421,
    LONGITUDE: -2.6290033,
  },
  {
    UPRN: 440,
    LATITUDE: 51.5139251,
    LONGITUDE: -2.5939584,
  },
  {
    UPRN: 441,
    LATITUDE: 51.5138041,
    LONGITUDE: -2.6156307,
  },
  {
    UPRN: 442,
    LATITUDE: 51.5139189,
    LONGITUDE: -2.5933963,
  },
  {
    UPRN: 443,
    LATITUDE: 51.513741,
    LONGITUDE: -2.6275333,
  },
  {
    UPRN: 444,
    LATITUDE: 51.513775,
    LONGITUDE: -2.6248565,
  },
  {
    UPRN: 445,
    LATITUDE: 51.5137741,
    LONGITUDE: -2.6248579,
  },
  {
    UPRN: 446,
    LATITUDE: 51.513775,
    LONGITUDE: -2.6248594,
  },
  {
    UPRN: 447,
    LATITUDE: 51.5137181,
    LONGITUDE: -2.6301414,
  },
  {
    UPRN: 448,
    LATITUDE: 51.513894,
    LONGITUDE: -2.5965231,
  },
  {
    UPRN: 449,
    LATITUDE: 51.5137957,
    LONGITUDE: -2.6155297,
  },
  {
    UPRN: 450,
    LATITUDE: 51.5138847,
    LONGITUDE: -2.5983531,
  },
  {
    UPRN: 451,
    LATITUDE: 51.5137772,
    LONGITUDE: -2.6173308,
  },
  {
    UPRN: 452,
    LATITUDE: 51.5137145,
    LONGITUDE: -2.6291326,
  },
  {
    UPRN: 453,
    LATITUDE: 51.5138845,
    LONGITUDE: -2.5966238,
  },
  {
    UPRN: 454,
    LATITUDE: 51.5139056,
    LONGITUDE: -2.5924882,
  },
  {
    UPRN: 455,
    LATITUDE: 51.5138724,
    LONGITUDE: -2.5990015,
  },
  {
    UPRN: 456,
    LATITUDE: 51.5138933,
    LONGITUDE: -2.5949091,
  },
  {
    UPRN: 457,
    LATITUDE: 51.513887,
    LONGITUDE: -2.5961483,
  },
  {
    UPRN: 458,
    LATITUDE: 51.5137556,
    LONGITUDE: -2.625369,
  },
  {
    UPRN: 459,
    LATITUDE: 51.5137522,
    LONGITUDE: -2.625366,
  },
  {
    UPRN: 460,
    LATITUDE: 51.5137548,
    LONGITUDE: -2.6253675,
  },
  {
    UPRN: 461,
    LATITUDE: 51.5138534,
    LONGITUDE: -2.6009467,
  },
  {
    UPRN: 462,
    LATITUDE: 51.5138572,
    LONGITUDE: -2.6002118,
  },
  {
    UPRN: 463,
    LATITUDE: 51.513875,
    LONGITUDE: -2.5967246,
  },
  {
    UPRN: 464,
    LATITUDE: 51.5136904,
    LONGITUDE: -2.6302707,
  },
  {
    UPRN: 465,
    LATITUDE: 51.5137691,
    LONGITUDE: -2.6154428,
  },
  {
    UPRN: 466,
    LATITUDE: 51.5138806,
    LONGITUDE: -2.5938713,
  },
  {
    UPRN: 467,
    LATITUDE: 51.5137048,
    LONGITUDE: -2.6275761,
  },
  {
    UPRN: 468,
    LATITUDE: 51.5138843,
    LONGITUDE: -2.5954766,
  },
  {
    UPRN: 469,
    LATITUDE: 51.5137321,
    LONGITUDE: -2.625539,
  },
  {
    UPRN: 470,
    LATITUDE: 51.513733,
    LONGITUDE: -2.625539,
  },
  {
    UPRN: 471,
    LATITUDE: 51.5137321,
    LONGITUDE: -2.6255404,
  },
  {
    UPRN: 472,
    LATITUDE: 51.5137498,
    LONGITUDE: -2.6174169,
  },
  {
    UPRN: 473,
    LATITUDE: 51.5137514,
    LONGITUDE: -2.6250308,
  },
  {
    UPRN: 474,
    LATITUDE: 51.5137522,
    LONGITUDE: -2.6250308,
  },
  {
    UPRN: 475,
    LATITUDE: 51.5137514,
    LONGITUDE: -2.6250308,
  },
  {
    UPRN: 476,
    LATITUDE: 51.5137606,
    LONGITUDE: -2.6153563,
  },
  {
    UPRN: 477,
    LATITUDE: 51.5138299,
    LONGITUDE: -2.600269,
  },
  {
    UPRN: 478,
    LATITUDE: 51.5138401,
    LONGITUDE: -2.5982805,
  },
  {
    UPRN: 479,
    LATITUDE: 51.5137531,
    LONGITUDE: -2.6150679,
  },
  {
    UPRN: 480,
    LATITUDE: 51.5137372,
    LONGITUDE: -2.618094,
  },
  {
    UPRN: 481,
    LATITUDE: 51.5138557,
    LONGITUDE: -2.5934531,
  },
  {
    UPRN: 482,
    LATITUDE: 51.5138557,
    LONGITUDE: -2.5934531,
  },
  {
    UPRN: 483,
    LATITUDE: 51.5138557,
    LONGITUDE: -2.5934531,
  },
  {
    UPRN: 484,
    LATITUDE: 51.5138557,
    LONGITUDE: -2.5934531,
  },
  {
    UPRN: 485,
    LATITUDE: 51.5137432,
    LONGITUDE: -2.6152407,
  },
  {
    UPRN: 486,
    LATITUDE: 51.5138171,
    LONGITUDE: -2.6010182,
  },
  {
    UPRN: 487,
    LATITUDE: 51.5138512,
    LONGITUDE: -2.5943465,
  },
  {
    UPRN: 488,
    LATITUDE: 51.5136631,
    LONGITUDE: -2.630328,
  },
  {
    UPRN: 489,
    LATITUDE: 51.5138195,
    LONGITUDE: -2.598799,
  },
  {
    UPRN: 490,
    LATITUDE: 51.5137356,
    LONGITUDE: -2.6149812,
  },
  {
    UPRN: 491,
    LATITUDE: 51.5183892,
    LONGITUDE: -2.6933713,
  },
  {
    UPRN: 492,
    LATITUDE: 51.5138511,
    LONGITUDE: -2.5925884,
  },
  {
    UPRN: 493,
    LATITUDE: 51.5137132,
    LONGITUDE: -2.6256617,
  },
  {
    UPRN: 494,
    LATITUDE: 51.5137132,
    LONGITUDE: -2.6256631,
  },
  {
    UPRN: 495,
    LATITUDE: 51.5137123,
    LONGITUDE: -2.6256616,
  },
  {
    UPRN: 496,
    LATITUDE: 51.5138134,
    LONGITUDE: -2.5982225,
  },
  {
    UPRN: 497,
    LATITUDE: 51.5138459,
    LONGITUDE: -2.591839,
  },
  {
    UPRN: 498,
    LATITUDE: 51.5136593,
    LONGITUDE: -2.6276907,
  },
  {
    UPRN: 499,
    LATITUDE: 51.5136879,
    LONGITUDE: -2.6258233,
  },
  {
    UPRN: 500,
    LATITUDE: 51.5136888,
    LONGITUDE: -2.6258233,
  },
  {
    UPRN: 501,
    LATITUDE: 51.5136879,
    LONGITUDE: -2.6258233,
  },
  {
    UPRN: 502,
    LATITUDE: 51.5137715,
    LONGITUDE: -2.598717,
  },
  {
    UPRN: 503,
    LATITUDE: 51.5138069,
    LONGITUDE: -2.5977325,
  },
  {
    UPRN: 504,
    LATITUDE: 51.5138216,
    LONGITUDE: -2.5948505,
  },
  {
    UPRN: 505,
    LATITUDE: 51.5137181,
    LONGITUDE: -2.6148945,
  },
  {
    UPRN: 506,
    LATITUDE: 51.5138116,
    LONGITUDE: -2.5968102,
  },
  {
    UPRN: 507,
    LATITUDE: 51.5138186,
    LONGITUDE: -2.5954413,
  },
  {
    UPRN: 508,
    LATITUDE: 51.5137807,
    LONGITUDE: -2.6011042,
  },
  {
    UPRN: 509,
    LATITUDE: 51.5137754,
    LONGITUDE: -2.6003692,
  },
  {
    UPRN: 510,
    LATITUDE: 51.5136174,
    LONGITUDE: -2.6304715,
  },
  {
    UPRN: 511,
    LATITUDE: 51.5137697,
    LONGITUDE: -2.5962562,
  },
  {
    UPRN: 512,
    LATITUDE: 51.513683,
    LONGITUDE: -2.6181509,
  },
  {
    UPRN: 513,
    LATITUDE: 51.5138145,
    LONGITUDE: -2.5927176,
  },
  {
    UPRN: 514,
    LATITUDE: 51.5137996,
    LONGITUDE: -2.5938847,
  },
  {
    UPRN: 515,
    LATITUDE: 51.5136772,
    LONGITUDE: -2.6175456,
  },
  {
    UPRN: 516,
    LATITUDE: 51.5136916,
    LONGITUDE: -2.6147933,
  },
  {
    UPRN: 517,
    LATITUDE: 51.5136047,
    LONGITUDE: -2.6294769,
  },
  {
    UPRN: 518,
    LATITUDE: 51.5137888,
    LONGITUDE: -2.5942304,
  },
  {
    UPRN: 519,
    LATITUDE: 51.5136139,
    LONGITUDE: -2.6277622,
  },
  {
    UPRN: 520,
    LATITUDE: 51.5135991,
    LONGITUDE: -2.6305289,
  },
  {
    UPRN: 521,
    LATITUDE: 51.5136113,
    LONGITUDE: -2.626566,
  },
  {
    UPRN: 522,
    LATITUDE: 51.5136113,
    LONGITUDE: -2.626566,
  },
  {
    UPRN: 523,
    LATITUDE: 51.5136741,
    LONGITUDE: -2.6147066,
  },
  {
    UPRN: 524,
    LATITUDE: 51.5137599,
    LONGITUDE: -2.5981353,
  },
  {
    UPRN: 525,
    LATITUDE: 51.5137443,
    LONGITUDE: -2.6011758,
  },
  {
    UPRN: 526,
    LATITUDE: 51.5136498,
    LONGITUDE: -2.6176173,
  },
  {
    UPRN: 527,
    LATITUDE: 51.5137653,
    LONGITUDE: -2.5953109,
  },
  {
    UPRN: 528,
    LATITUDE: 51.5137393,
    LONGITUDE: -2.600412,
  },
  {
    UPRN: 529,
    LATITUDE: 51.513768,
    LONGITUDE: -2.5947922,
  },
  {
    UPRN: 530,
    LATITUDE: 51.5137438,
    LONGITUDE: -2.5977604,
  },
  {
    UPRN: 531,
    LATITUDE: 51.5137689,
    LONGITUDE: -2.5928323,
  },
  {
    UPRN: 532,
    LATITUDE: 51.5136566,
    LONGITUDE: -2.6146055,
  },
  {
    UPRN: 533,
    LATITUDE: 51.513657,
    LONGITUDE: -2.6145334,
  },
  {
    UPRN: 534,
    LATITUDE: 51.5136476,
    LONGITUDE: -2.6163203,
  },
  {
    UPRN: 535,
    LATITUDE: 51.5136476,
    LONGITUDE: -2.6163203,
  },
  {
    UPRN: 536,
    LATITUDE: 51.5136476,
    LONGITUDE: -2.6163203,
  },
  {
    UPRN: 537,
    LATITUDE: 51.5135713,
    LONGITUDE: -2.630687,
  },
  {
    UPRN: 538,
    LATITUDE: 51.5137648,
    LONGITUDE: -2.5918811,
  },
  {
    UPRN: 539,
    LATITUDE: 51.5135681,
    LONGITUDE: -2.6296061,
  },
  {
    UPRN: 540,
    LATITUDE: 51.5137572,
    LONGITUDE: -2.5933654,
  },
  {
    UPRN: 541,
    LATITUDE: 51.5137572,
    LONGITUDE: -2.5933654,
  },
  {
    UPRN: 542,
    LATITUDE: 51.5137572,
    LONGITUDE: -2.5933654,
  },
  {
    UPRN: 543,
    LATITUDE: 51.5137681,
    LONGITUDE: -2.5912183,
  },
  {
    UPRN: 544,
    LATITUDE: 51.5137078,
    LONGITUDE: -2.6012762,
  },
  {
    UPRN: 545,
    LATITUDE: 51.5137303,
    LONGITUDE: -2.5968956,
  },
  {
    UPRN: 546,
    LATITUDE: 51.5137439,
    LONGITUDE: -2.5942298,
  },
  {
    UPRN: 547,
    LATITUDE: 51.5135498,
    LONGITUDE: -2.6296635,
  },
  {
    UPRN: 548,
    LATITUDE: 51.5136369,
    LONGITUDE: -2.6166516,
  },
  {
    UPRN: 549,
    LATITUDE: 51.5136369,
    LONGITUDE: -2.6166516,
  },
  {
    UPRN: 550,
    LATITUDE: 51.5136369,
    LONGITUDE: -2.6166516,
  },
  {
    UPRN: 551,
    LATITUDE: 51.5135542,
    LONGITUDE: -2.6288422,
  },
  {
    UPRN: 552,
    LATITUDE: 51.5133326,
    LONGITUDE: -2.5919908,
  },
  {
    UPRN: 553,
    LATITUDE: 51.5133535,
    LONGITUDE: -2.5914147,
  },
  {
    UPRN: 554,
    LATITUDE: 51.5134968,
    LONGITUDE: -2.5915174,
  },
  {
    UPRN: 555,
    LATITUDE: 51.5135983,
    LONGITUDE: -2.6205564,
  },
  {
    UPRN: 556,
    LATITUDE: 51.5136125,
    LONGITUDE: -2.6161613,
  },
  {
    UPRN: 557,
    LATITUDE: 51.5136125,
    LONGITUDE: -2.6161613,
  },
  {
    UPRN: 558,
    LATITUDE: 51.5136125,
    LONGITUDE: -2.6161613,
  },
  {
    UPRN: 559,
    LATITUDE: 51.5136217,
    LONGITUDE: -2.6144177,
  },
  {
    UPRN: 560,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 561,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 562,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 563,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 564,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 565,
    LATITUDE: 51.5135973,
    LONGITUDE: -2.6190576,
  },
  {
    UPRN: 566,
    LATITUDE: 51.5137279,
    LONGITUDE: -2.5938261,
  },
  {
    UPRN: 567,
    LATITUDE: 51.5137119,
    LONGITUDE: -2.5952093,
  },
  {
    UPRN: 568,
    LATITUDE: 51.5137235,
    LONGITUDE: -2.5929326,
  },
  {
    UPRN: 569,
    LATITUDE: 51.5135416,
    LONGITUDE: -2.6278332,
  },
  {
    UPRN: 570,
    LATITUDE: 51.5136688,
    LONGITUDE: -2.6018665,
  },
  {
    UPRN: 571,
    LATITUDE: 51.5136688,
    LONGITUDE: -2.6018665,
  },
  {
    UPRN: 572,
    LATITUDE: 51.5136897,
    LONGITUDE: -2.597803,
  },
  {
    UPRN: 573,
    LATITUDE: 51.5135163,
    LONGITUDE: -2.6308736,
  },
  {
    UPRN: 574,
    LATITUDE: 51.5135952,
    LONGITUDE: -2.6160313,
  },
  {
    UPRN: 575,
    LATITUDE: 51.5136467,
    LONGITUDE: -2.6164932,
  },
  {
    UPRN: 576,
    LATITUDE: 51.5136467,
    LONGITUDE: -2.6164932,
  },
  {
    UPRN: 577,
    LATITUDE: 51.5135904,
    LONGITUDE: -2.6169392,
  },
  {
    UPRN: 578,
    LATITUDE: 51.5135904,
    LONGITUDE: -2.6169392,
  },
  {
    UPRN: 579,
    LATITUDE: 51.5135904,
    LONGITUDE: -2.6169392,
  },
  {
    UPRN: 580,
    LATITUDE: 51.5136467,
    LONGITUDE: -2.6164932,
  },
  {
    UPRN: 581,
    LATITUDE: 51.5135773,
    LONGITUDE: -2.6177316,
  },
  {
    UPRN: 582,
    LATITUDE: 51.513513,
    LONGITUDE: -2.6298071,
  },
  {
    UPRN: 583,
    LATITUDE: 51.513558,
    LONGITUDE: -2.6213773,
  },
  {
    UPRN: 584,
    LATITUDE: 51.5136963,
    LONGITUDE: -2.5947336,
  },
  {
    UPRN: 585,
    LATITUDE: 51.5135956,
    LONGITUDE: -2.6142444,
  },
  {
    UPRN: 586,
    LATITUDE: 51.5135293,
    LONGITUDE: -2.6267666,
  },
  {
    UPRN: 587,
    LATITUDE: 51.5135293,
    LONGITUDE: -2.6267666,
  },
  {
    UPRN: 588,
    LATITUDE: 51.5137102,
    LONGITUDE: -2.5920101,
  },
  {
    UPRN: 589,
    LATITUDE: 51.5135432,
    LONGITUDE: -2.6224723,
  },
  {
    UPRN: 590,
    LATITUDE: 51.5135432,
    LONGITUDE: -2.6224723,
  },
  {
    UPRN: 591,
    LATITUDE: 51.5136789,
    LONGITUDE: -2.5963906,
  },
  {
    UPRN: 592,
    LATITUDE: 51.5135093,
    LONGITUDE: -2.6288271,
  },
  {
    UPRN: 593,
    LATITUDE: 51.513676,
    LONGITUDE: -2.596967,
  },
  {
    UPRN: 594,
    LATITUDE: 51.5135783,
    LONGITUDE: -2.6158294,
  },
  {
    UPRN: 595,
    LATITUDE: 51.5135689,
    LONGITUDE: -2.6159013,
  },
  {
    UPRN: 596,
    LATITUDE: 51.5136288,
    LONGITUDE: -2.6006552,
  },
  {
    UPRN: 597,
    LATITUDE: 51.5136303,
    LONGITUDE: -2.6006577,
  },
  {
    UPRN: 598,
    LATITUDE: 51.5135781,
    LONGITUDE: -2.6141577,
  },
  {
    UPRN: 599,
    LATITUDE: 51.5134947,
    LONGITUDE: -2.6298789,
  },
  {
    UPRN: 600,
    LATITUDE: 51.5136182,
    LONGITUDE: -2.616781,
  },
  {
    UPRN: 601,
    LATITUDE: 51.5136182,
    LONGITUDE: -2.616781,
  },
  {
    UPRN: 602,
    LATITUDE: 51.5136182,
    LONGITUDE: -2.616781,
  },
  {
    UPRN: 603,
    LATITUDE: 51.5136331,
    LONGITUDE: -2.6018228,
  },
  {
    UPRN: 604,
    LATITUDE: 51.5136331,
    LONGITUDE: -2.6018228,
  },
  {
    UPRN: 605,
    LATITUDE: 51.5136598,
    LONGITUDE: -2.5966209,
  },
  {
    UPRN: 606,
    LATITUDE: 51.5136765,
    LONGITUDE: -2.5933355,
  },
  {
    UPRN: 607,
    LATITUDE: 51.5136765,
    LONGITUDE: -2.5933355,
  },
  {
    UPRN: 608,
    LATITUDE: 51.5136765,
    LONGITUDE: -2.5933355,
  },
  {
    UPRN: 609,
    LATITUDE: 51.5134963,
    LONGITUDE: -2.6278902,
  },
  {
    UPRN: 610,
    LATITUDE: 51.5136606,
    LONGITUDE: -2.596448,
  },
  {
    UPRN: 611,
    LATITUDE: 51.5136827,
    LONGITUDE: -2.5921107,
  },
  {
    UPRN: 612,
    LATITUDE: 51.5134796,
    LONGITUDE: -2.6310172,
  },
  {
    UPRN: 613,
    LATITUDE: 51.5135608,
    LONGITUDE: -2.6157283,
  },
  {
    UPRN: 614,
    LATITUDE: 51.5135611,
    LONGITUDE: -2.6139557,
  },
  {
    UPRN: 615,
    LATITUDE: 51.5136383,
    LONGITUDE: -2.599056,
  },
  {
    UPRN: 616,
    LATITUDE: 51.5136222,
    LONGITUDE: -2.6021973,
  },
  {
    UPRN: 617,
    LATITUDE: 51.5136651,
    LONGITUDE: -2.5938109,
  },
  {
    UPRN: 618,
    LATITUDE: 51.5136444,
    LONGITUDE: -2.59786,
  },
  {
    UPRN: 619,
    LATITUDE: 51.5136585,
    LONGITUDE: -2.5951078,
  },
  {
    UPRN: 620,
    LATITUDE: 51.5135516,
    LONGITUDE: -2.6140709,
  },
  {
    UPRN: 621,
    LATITUDE: 51.5134611,
    LONGITUDE: -2.6311034,
  },
  {
    UPRN: 622,
    LATITUDE: 51.5136542,
    LONGITUDE: -2.5941854,
  },
  {
    UPRN: 623,
    LATITUDE: 51.5135433,
    LONGITUDE: -2.6156416,
  },
  {
    UPRN: 624,
    LATITUDE: 51.5135319,
    LONGITUDE: -2.617803,
  },
  {
    UPRN: 625,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 626,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 627,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 628,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 629,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 630,
    LATITUDE: 51.5135267,
    LONGITUDE: -2.6187973,
  },
  {
    UPRN: 631,
    LATITUDE: 51.5134807,
    LONGITUDE: -2.6257716,
  },
  {
    UPRN: 632,
    LATITUDE: 51.5134807,
    LONGITUDE: -2.6257716,
  },
  {
    UPRN: 633,
    LATITUDE: 51.5134689,
    LONGITUDE: -2.6263046,
  },
  {
    UPRN: 634,
    LATITUDE: 51.5134689,
    LONGITUDE: -2.6263046,
  },
  {
    UPRN: 635,
    LATITUDE: 51.513646,
    LONGITUDE: -2.5922543,
  },
  {
    UPRN: 636,
    LATITUDE: 51.5134424,
    LONGITUDE: -2.6312473,
  },
  {
    UPRN: 637,
    LATITUDE: 51.513449,
    LONGITUDE: -2.630008,
  },
  {
    UPRN: 638,
    LATITUDE: 51.5135352,
    LONGITUDE: -2.613768,
  },
  {
    UPRN: 639,
    LATITUDE: 51.5135539,
    LONGITUDE: -2.617054,
  },
  {
    UPRN: 640,
    LATITUDE: 51.5135082,
    LONGITUDE: -2.6171974,
  },
  {
    UPRN: 641,
    LATITUDE: 51.5135082,
    LONGITUDE: -2.6171974,
  },
  {
    UPRN: 642,
    LATITUDE: 51.5135539,
    LONGITUDE: -2.617054,
  },
  {
    UPRN: 643,
    LATITUDE: 51.5135539,
    LONGITUDE: -2.617054,
  },
  {
    UPRN: 644,
    LATITUDE: 51.5135082,
    LONGITUDE: -2.6171974,
  },
  {
    UPRN: 645,
    LATITUDE: 51.513586,
    LONGITUDE: -2.6022401,
  },
  {
    UPRN: 646,
    LATITUDE: 51.5136247,
    LONGITUDE: -2.594675,
  },
  {
    UPRN: 647,
    LATITUDE: 51.5136398,
    LONGITUDE: -2.5916922,
  },
  {
    UPRN: 648,
    LATITUDE: 51.513433,
    LONGITUDE: -2.6313192,
  },
  {
    UPRN: 649,
    LATITUDE: 51.5135078,
    LONGITUDE: -2.6155546,
  },
  {
    UPRN: 650,
    LATITUDE: 51.5135166,
    LONGITUDE: -2.6138831,
  },
  {
    UPRN: 651,
    LATITUDE: 51.5136229,
    LONGITUDE: -2.5932627,
  },
  {
    UPRN: 652,
    LATITUDE: 51.5136229,
    LONGITUDE: -2.5932627,
  },
  {
    UPRN: 653,
    LATITUDE: 51.5136229,
    LONGITUDE: -2.5932627,
  },
  {
    UPRN: 654,
    LATITUDE: 51.5135083,
    LONGITUDE: -2.6154537,
  },
  {
    UPRN: 655,
    LATITUDE: 51.5134329,
    LONGITUDE: -2.6279902,
  },
  {
    UPRN: 656,
    LATITUDE: 51.513576,
    LONGITUDE: -2.6006836,
  },
  {
    UPRN: 657,
    LATITUDE: 51.513576,
    LONGITUDE: -2.6006836,
  },
  {
    UPRN: 658,
    LATITUDE: 51.5134217,
    LONGITUDE: -2.6300797,
  },
  {
    UPRN: 659,
    LATITUDE: 51.5136093,
    LONGITUDE: -2.5941704,
  },
  {
    UPRN: 660,
    LATITUDE: 51.5135086,
    LONGITUDE: -2.6136812,
  },
  {
    UPRN: 661,
    LATITUDE: 51.5135752,
    LONGITUDE: -2.599084,
  },
  {
    UPRN: 662,
    LATITUDE: 51.513463,
    LONGITUDE: -2.620641,
  },
  {
    UPRN: 663,
    LATITUDE: 51.513562,
    LONGITUDE: -2.6016633,
  },
  {
    UPRN: 664,
    LATITUDE: 51.513562,
    LONGITUDE: -2.6016633,
  },
  {
    UPRN: 665,
    LATITUDE: 51.5134528,
    LONGITUDE: -2.622572,
  },
  {
    UPRN: 666,
    LATITUDE: 51.5134528,
    LONGITUDE: -2.622572,
  },
  {
    UPRN: 667,
    LATITUDE: 51.5134718,
    LONGITUDE: -2.617269,
  },
  {
    UPRN: 668,
    LATITUDE: 51.5134718,
    LONGITUDE: -2.617269,
  },
  {
    UPRN: 669,
    LATITUDE: 51.5134718,
    LONGITUDE: -2.617269,
  },
  {
    UPRN: 670,
    LATITUDE: 51.5134913,
    LONGITUDE: -2.6135657,
  },
  {
    UPRN: 671,
    LATITUDE: 51.5134728,
    LONGITUDE: -2.6153668,
  },
  {
    UPRN: 672,
    LATITUDE: 51.5134318,
    LONGITUDE: -2.6231337,
  },
  {
    UPRN: 673,
    LATITUDE: 51.5134318,
    LONGITUDE: -2.6231337,
  },
  {
    UPRN: 674,
    LATITUDE: 51.5134318,
    LONGITUDE: -2.6231337,
  },
  {
    UPRN: 675,
    LATITUDE: 51.5134477,
    LONGITUDE: -2.6201364,
  },
  {
    UPRN: 676,
    LATITUDE: 51.5135443,
    LONGITUDE: -2.6016055,
  },
  {
    UPRN: 677,
    LATITUDE: 51.5135443,
    LONGITUDE: -2.6016055,
  },
  {
    UPRN: 678,
    LATITUDE: 51.513422,
    LONGITUDE: -2.6232921,
  },
  {
    UPRN: 679,
    LATITUDE: 51.513422,
    LONGITUDE: -2.6232921,
  },
  {
    UPRN: 680,
    LATITUDE: 51.513422,
    LONGITUDE: -2.6232921,
  },
  {
    UPRN: 681,
    LATITUDE: 51.5135752,
    LONGITUDE: -2.5937953,
  },
  {
    UPRN: 682,
    LATITUDE: 51.5133966,
    LONGITUDE: -2.6280474,
  },
  {
    UPRN: 683,
    LATITUDE: 51.5135862,
    LONGITUDE: -2.5916194,
  },
  {
    UPRN: 684,
    LATITUDE: 51.5133782,
    LONGITUDE: -2.631477,
  },
  {
    UPRN: 685,
    LATITUDE: 51.5135688,
    LONGITUDE: -2.5950489,
  },
  {
    UPRN: 686,
    LATITUDE: 51.5135421,
    LONGITUDE: -2.5985216,
  },
  {
    UPRN: 687,
    LATITUDE: 51.5133761,
    LONGITUDE: -2.6301943,
  },
  {
    UPRN: 688,
    LATITUDE: 51.5133831,
    LONGITUDE: -2.628883,
  },
  {
    UPRN: 689,
    LATITUDE: 51.5135495,
    LONGITUDE: -2.5970806,
  },
  {
    UPRN: 690,
    LATITUDE: 51.513465,
    LONGITUDE: -2.6134212,
  },
  {
    UPRN: 691,
    LATITUDE: 51.5135627,
    LONGITUDE: -2.5927143,
  },
  {
    UPRN: 692,
    LATITUDE: 51.5135465,
    LONGITUDE: -2.5958989,
  },
  {
    UPRN: 693,
    LATITUDE: 51.513388,
    LONGITUDE: -2.6262891,
  },
  {
    UPRN: 694,
    LATITUDE: 51.513388,
    LONGITUDE: -2.6262891,
  },
  {
    UPRN: 695,
    LATITUDE: 51.5134475,
    LONGITUDE: -2.6133345,
  },
  {
    UPRN: 696,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 697,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 698,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 699,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 700,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 701,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6183347,
  },
  {
    UPRN: 702,
    LATITUDE: 51.5134378,
    LONGITUDE: -2.6151934,
  },
  {
    UPRN: 703,
    LATITUDE: 51.5135334,
    LONGITUDE: -2.5967057,
  },
  {
    UPRN: 704,
    LATITUDE: 51.5133749,
    LONGITUDE: -2.6270527,
  },
  {
    UPRN: 705,
    LATITUDE: 51.5133749,
    LONGITUDE: -2.6270527,
  },
  {
    UPRN: 706,
    LATITUDE: 51.5133822,
    LONGITUDE: -2.6228869,
  },
  {
    UPRN: 707,
    LATITUDE: 51.5135311,
    LONGITUDE: -2.5971668,
  },
  {
    UPRN: 708,
    LATITUDE: 51.5135209,
    LONGITUDE: -2.5991554,
  },
  {
    UPRN: 709,
    LATITUDE: 51.5133779,
    LONGITUDE: -2.6227989,
  },
  {
    UPRN: 710,
    LATITUDE: 51.5133636,
    LONGITUDE: -2.6294673,
  },
  {
    UPRN: 711,
    LATITUDE: 51.5135155,
    LONGITUDE: -2.5984492,
  },
  {
    UPRN: 712,
    LATITUDE: 51.5134292,
    LONGITUDE: -2.6151068,
  },
  {
    UPRN: 713,
    LATITUDE: 51.5133727,
    LONGITUDE: -2.6257845,
  },
  {
    UPRN: 714,
    LATITUDE: 51.5133727,
    LONGITUDE: -2.6257845,
  },
  {
    UPRN: 715,
    LATITUDE: 51.5134304,
    LONGITUDE: -2.6131758,
  },
  {
    UPRN: 716,
    LATITUDE: 51.513526,
    LONGITUDE: -2.5946305,
  },
  {
    UPRN: 717,
    LATITUDE: 51.5133468,
    LONGITUDE: -2.6289546,
  },
  {
    UPRN: 718,
    LATITUDE: 51.5135391,
    LONGITUDE: -2.5920511,
  },
  {
    UPRN: 719,
    LATITUDE: 51.513333,
    LONGITUDE: -2.6315196,
  },
  {
    UPRN: 720,
    LATITUDE: 51.5133397,
    LONGITUDE: -2.6302659,
  },
  {
    UPRN: 721,
    LATITUDE: 51.5135241,
    LONGITUDE: -2.5950051,
  },
  {
    UPRN: 722,
    LATITUDE: 51.5135238,
    LONGITUDE: -2.5932903,
  },
  {
    UPRN: 723,
    LATITUDE: 51.5135238,
    LONGITUDE: -2.5932903,
  },
  {
    UPRN: 724,
    LATITUDE: 51.5135238,
    LONGITUDE: -2.5932903,
  },
  {
    UPRN: 725,
    LATITUDE: 51.5133993,
    LONGITUDE: -2.6173833,
  },
  {
    UPRN: 726,
    LATITUDE: 51.5133993,
    LONGITUDE: -2.6173833,
  },
  {
    UPRN: 727,
    LATITUDE: 51.5133993,
    LONGITUDE: -2.6173833,
  },
  {
    UPRN: 728,
    LATITUDE: 51.5134217,
    LONGITUDE: -2.6131036,
  },
  {
    UPRN: 729,
    LATITUDE: 51.5134117,
    LONGITUDE: -2.6150201,
  },
  {
    UPRN: 730,
    LATITUDE: 51.5133663,
    LONGITUDE: -2.6236228,
  },
  {
    UPRN: 731,
    LATITUDE: 51.5133663,
    LONGITUDE: -2.6236228,
  },
  {
    UPRN: 732,
    LATITUDE: 51.5133663,
    LONGITUDE: -2.6236228,
  },
  {
    UPRN: 733,
    LATITUDE: 51.5133664,
    LONGITUDE: -2.623594,
  },
  {
    UPRN: 734,
    LATITUDE: 51.5133664,
    LONGITUDE: -2.623594,
  },
  {
    UPRN: 735,
    LATITUDE: 51.5133664,
    LONGITUDE: -2.623594,
  },
  {
    UPRN: 736,
    LATITUDE: 51.5135195,
    LONGITUDE: -2.5941404,
  },
  {
    UPRN: 737,
    LATITUDE: 51.5135215,
    LONGITUDE: -2.5937514,
  },
  {
    UPRN: 738,
    LATITUDE: 51.5133331,
    LONGITUDE: -2.6281474,
  },
  {
    UPRN: 739,
    LATITUDE: 51.5133264,
    LONGITUDE: -2.6294011,
  },
  {
    UPRN: 740,
    LATITUDE: 51.5133583,
    LONGITUDE: -2.6234353,
  },
  {
    UPRN: 741,
    LATITUDE: 51.5133583,
    LONGITUDE: -2.6234353,
  },
  {
    UPRN: 742,
    LATITUDE: 51.5133583,
    LONGITUDE: -2.6234353,
  },
  {
    UPRN: 743,
    LATITUDE: 51.5134046,
    LONGITUDE: -2.6129449,
  },
  {
    UPRN: 744,
    LATITUDE: 51.5134757,
    LONGITUDE: -2.599198,
  },
  {
    UPRN: 745,
    LATITUDE: 51.5134947,
    LONGITUDE: -2.5954803,
  },
  {
    UPRN: 746,
    LATITUDE: 51.5134371,
    LONGITUDE: -2.6049473,
  },
  {
    UPRN: 747,
    LATITUDE: 51.513461,
    LONGITUDE: -2.6003218,
  },
  {
    UPRN: 748,
    LATITUDE: 51.513396,
    LONGITUDE: -2.6128583,
  },
  {
    UPRN: 749,
    LATITUDE: 51.5134709,
    LONGITUDE: -2.5983765,
  },
  {
    UPRN: 750,
    LATITUDE: 51.5133379,
    LONGITUDE: -2.6238818,
  },
  {
    UPRN: 751,
    LATITUDE: 51.5133379,
    LONGITUDE: -2.6238818,
  },
  {
    UPRN: 752,
    LATITUDE: 51.5133379,
    LONGITUDE: -2.6238818,
  },
  {
    UPRN: 753,
    LATITUDE: 51.5133767,
    LONGITUDE: -2.6148323,
  },
  {
    UPRN: 754,
    LATITUDE: 51.5134911,
    LONGITUDE: -2.5926558,
  },
  {
    UPRN: 755,
    LATITUDE: 51.5134267,
    LONGITUDE: -2.605221,
  },
  {
    UPRN: 756,
    LATITUDE: 51.5133762,
    LONGITUDE: -2.6149332,
  },
  {
    UPRN: 757,
    LATITUDE: 51.5132601,
    LONGITUDE: -2.6304863,
  },
  {
    UPRN: 758,
    LATITUDE: 51.5134721,
    LONGITUDE: -2.5946154,
  },
  {
    UPRN: 759,
    LATITUDE: 51.5133396,
    LONGITUDE: -2.6201782,
  },
  {
    UPRN: 760,
    LATITUDE: 51.5134655,
    LONGITUDE: -2.5959266,
  },
  {
    UPRN: 761,
    LATITUDE: 51.5132969,
    LONGITUDE: -2.6282045,
  },
  {
    UPRN: 762,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 763,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 764,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 765,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 766,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 767,
    LATITUDE: 51.5133507,
    LONGITUDE: -2.6180744,
  },
  {
    UPRN: 768,
    LATITUDE: 51.513434,
    LONGITUDE: -2.6020796,
  },
  {
    UPRN: 769,
    LATITUDE: 51.5132697,
    LONGITUDE: -2.6291696,
  },
  {
    UPRN: 770,
    LATITUDE: 51.5133701,
    LONGITUDE: -2.6126562,
  },
  {
    UPRN: 771,
    LATITUDE: 51.5134496,
    LONGITUDE: -2.5972666,
  },
  {
    UPRN: 772,
    LATITUDE: 51.5134656,
    LONGITUDE: -2.5941253,
  },
  {
    UPRN: 773,
    LATITUDE: 51.5132986,
    LONGITUDE: -2.626187,
  },
  {
    UPRN: 774,
    LATITUDE: 51.5132986,
    LONGITUDE: -2.626187,
  },
  {
    UPRN: 775,
    LATITUDE: 51.5133593,
    LONGITUDE: -2.6147312,
  },
  {
    UPRN: 776,
    LATITUDE: 51.5133697,
    LONGITUDE: -2.6127426,
  },
  {
    UPRN: 777,
    LATITUDE: 51.5134346,
    LONGITUDE: -2.6002062,
  },
  {
    UPRN: 778,
    LATITUDE: 51.5132733,
    LONGITUDE: -2.6292418,
  },
  {
    UPRN: 779,
    LATITUDE: 51.5132848,
    LONGITUDE: -2.6270947,
  },
  {
    UPRN: 780,
    LATITUDE: 51.5132848,
    LONGITUDE: -2.6270947,
  },
  {
    UPRN: 781,
    LATITUDE: 51.5133185,
    LONGITUDE: -2.6207688,
  },
  {
    UPRN: 782,
    LATITUDE: 51.513267,
    LONGITUDE: -2.630409,
  },
  {
    UPRN: 783,
    LATITUDE: 51.5134212,
    LONGITUDE: -2.6010562,
  },
  {
    UPRN: 784,
    LATITUDE: 51.5134016,
    LONGITUDE: -2.6048748,
  },
  {
    UPRN: 785,
    LATITUDE: 51.5134349,
    LONGITUDE: -2.5966324,
  },
  {
    UPRN: 786,
    LATITUDE: 51.5133906,
    LONGITUDE: -2.6052493,
  },
  {
    UPRN: 787,
    LATITUDE: 51.5134264,
    LONGITUDE: -2.5983039,
  },
  {
    UPRN: 788,
    LATITUDE: 51.5134072,
    LONGITUDE: -2.602036,
  },
  {
    UPRN: 789,
    LATITUDE: 51.5134584,
    LONGITUDE: -2.5920069,
  },
  {
    UPRN: 790,
    LATITUDE: 51.5134431,
    LONGITUDE: -2.5932604,
  },
  {
    UPRN: 791,
    LATITUDE: 51.5134431,
    LONGITUDE: -2.5932604,
  },
  {
    UPRN: 792,
    LATITUDE: 51.5134431,
    LONGITUDE: -2.5932604,
  },
  {
    UPRN: 793,
    LATITUDE: 51.5133437,
    LONGITUDE: -2.612555,
  },
  {
    UPRN: 794,
    LATITUDE: 51.5132729,
    LONGITUDE: -2.6259561,
  },
  {
    UPRN: 795,
    LATITUDE: 51.5132729,
    LONGITUDE: -2.6259561,
  },
  {
    UPRN: 796,
    LATITUDE: 51.513408,
    LONGITUDE: -2.6001338,
  },
  {
    UPRN: 797,
    LATITUDE: 51.5133327,
    LONGITUDE: -2.6146444,
  },
  {
    UPRN: 798,
    LATITUDE: 51.5133244,
    LONGITUDE: -2.614529,
  },
  {
    UPRN: 799,
    LATITUDE: 51.5134317,
    LONGITUDE: -2.5937214,
  },
  {
    UPRN: 800,
    LATITUDE: 51.5133938,
    LONGITUDE: -2.6011423,
  },
  {
    UPRN: 801,
    LATITUDE: 51.5134207,
    LONGITUDE: -2.5958972,
  },
  {
    UPRN: 802,
    LATITUDE: 51.5134252,
    LONGITUDE: -2.5950038,
  },
  {
    UPRN: 803,
    LATITUDE: 51.5133351,
    LONGITUDE: -2.6124684,
  },
  {
    UPRN: 804,
    LATITUDE: 51.5134229,
    LONGITUDE: -2.5954505,
  },
  {
    UPRN: 805,
    LATITUDE: 51.5132759,
    LONGITUDE: -2.6220219,
  },
  {
    UPRN: 806,
    LATITUDE: 51.5132759,
    LONGITUDE: -2.6220219,
  },
  {
    UPRN: 807,
    LATITUDE: 51.5133542,
    LONGITUDE: -2.6053353,
  },
  {
    UPRN: 808,
    LATITUDE: 51.5133816,
    LONGITUDE: -2.6000182,
  },
  {
    UPRN: 809,
    LATITUDE: 51.5132568,
    LONGITUDE: -2.6222234,
  },
  {
    UPRN: 810,
    LATITUDE: 51.5132568,
    LONGITUDE: -2.6222234,
  },
  {
    UPRN: 811,
    LATITUDE: 51.5133479,
    LONGITUDE: -2.6048164,
  },
  {
    UPRN: 812,
    LATITUDE: 51.5134104,
    LONGITUDE: -2.5926115,
  },
  {
    UPRN: 813,
    LATITUDE: 51.5132243,
    LONGITUDE: -2.6283188,
  },
  {
    UPRN: 814,
    LATITUDE: 51.5133728,
    LONGITUDE: -2.5982311,
  },
  {
    UPRN: 815,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 816,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 817,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 818,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 819,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 820,
    LATITUDE: 51.5132712,
    LONGITUDE: -2.6177995,
  },
  {
    UPRN: 821,
    LATITUDE: 51.5132892,
    LONGITUDE: -2.6143844,
  },
  {
    UPRN: 822,
    LATITUDE: 51.5132808,
    LONGITUDE: -2.614269,
  },
  {
    UPRN: 823,
    LATITUDE: 51.5133825,
    LONGITUDE: -2.594571,
  },
  {
    UPRN: 824,
    LATITUDE: 51.5131891,
    LONGITUDE: -2.631532,
  },
  {
    UPRN: 825,
    LATITUDE: 51.513355,
    LONGITUDE: -2.5999458,
  },
  {
    UPRN: 826,
    LATITUDE: 51.5133232,
    LONGITUDE: -2.6061131,
  },
  {
    UPRN: 827,
    LATITUDE: 51.5133232,
    LONGITUDE: -2.6061131,
  },
  {
    UPRN: 828,
    LATITUDE: 51.5133451,
    LONGITUDE: -2.6018766,
  },
  {
    UPRN: 829,
    LATITUDE: 51.5133269,
    LONGITUDE: -2.6053926,
  },
  {
    UPRN: 830,
    LATITUDE: 51.5133723,
    LONGITUDE: -2.5965595,
  },
  {
    UPRN: 831,
    LATITUDE: 51.5133149,
    LONGITUDE: -2.6059833,
  },
  {
    UPRN: 832,
    LATITUDE: 51.5133149,
    LONGITUDE: -2.6059833,
  },
  {
    UPRN: 833,
    LATITUDE: 51.5133714,
    LONGITUDE: -2.5949743,
  },
  {
    UPRN: 834,
    LATITUDE: 51.5133593,
    LONGITUDE: -2.5973519,
  },
  {
    UPRN: 835,
    LATITUDE: 51.5133759,
    LONGITUDE: -2.5940953,
  },
  {
    UPRN: 836,
    LATITUDE: 51.5133396,
    LONGITUDE: -2.6011993,
  },
  {
    UPRN: 837,
    LATITUDE: 51.5133133,
    LONGITUDE: -2.6063003,
  },
  {
    UPRN: 838,
    LATITUDE: 51.5133133,
    LONGITUDE: -2.6063003,
  },
  {
    UPRN: 839,
    LATITUDE: 51.5132722,
    LONGITUDE: -2.6141968,
  },
  {
    UPRN: 840,
    LATITUDE: 51.5133713,
    LONGITUDE: -2.5932306,
  },
  {
    UPRN: 841,
    LATITUDE: 51.5133713,
    LONGITUDE: -2.5932306,
  },
  {
    UPRN: 842,
    LATITUDE: 51.5133713,
    LONGITUDE: -2.5932306,
  },
  {
    UPRN: 843,
    LATITUDE: 51.5133035,
    LONGITUDE: -2.6064587,
  },
  {
    UPRN: 844,
    LATITUDE: 51.5133035,
    LONGITUDE: -2.6064587,
  },
  {
    UPRN: 845,
    LATITUDE: 51.5133409,
    LONGITUDE: -2.5991962,
  },
  {
    UPRN: 846,
    LATITUDE: 51.5133122,
    LONGITUDE: -2.6047583,
  },
  {
    UPRN: 847,
    LATITUDE: 51.5133512,
    LONGITUDE: -2.5954208,
  },
  {
    UPRN: 848,
    LATITUDE: 51.5131791,
    LONGITUDE: -2.6283614,
  },
  {
    UPRN: 849,
    LATITUDE: 51.5133599,
    LONGITUDE: -2.593706,
  },
  {
    UPRN: 850,
    LATITUDE: 51.5132975,
    LONGITUDE: -2.6058677,
  },
  {
    UPRN: 851,
    LATITUDE: 51.5131625,
    LONGITUDE: -2.6314596,
  },
  {
    UPRN: 852,
    LATITUDE: 51.5133285,
    LONGITUDE: -2.5998589,
  },
  {
    UPRN: 853,
    LATITUDE: 51.513328,
    LONGITUDE: -2.5981873,
  },
  {
    UPRN: 854,
    LATITUDE: 51.5132458,
    LONGITUDE: -2.6140812,
  },
  {
    UPRN: 855,
    LATITUDE: 51.5133401,
    LONGITUDE: -2.5958241,
  },
  {
    UPRN: 856,
    LATITUDE: 51.5133033,
    LONGITUDE: -2.6012564,
  },
  {
    UPRN: 857,
    LATITUDE: 51.5133165,
    LONGITUDE: -2.5986771,
  },
  {
    UPRN: 858,
    LATITUDE: 51.5131481,
    LONGITUDE: -2.6307965,
  },
  {
    UPRN: 859,
    LATITUDE: 51.5133004,
    LONGITUDE: -2.6018184,
  },
  {
    UPRN: 860,
    LATITUDE: 51.5133282,
    LONGITUDE: -2.5964004,
  },
  {
    UPRN: 861,
    LATITUDE: 51.5133376,
    LONGITUDE: -2.594556,
  },
  {
    UPRN: 862,
    LATITUDE: 51.513142,
    LONGITUDE: -2.6302488,
  },
  {
    UPRN: 863,
    LATITUDE: 51.5131921,
    LONGITUDE: -2.6208535,
  },
  {
    UPRN: 864,
    LATITUDE: 51.5131956,
    LONGITUDE: -2.6202051,
  },
  {
    UPRN: 865,
    LATITUDE: 51.5133019,
    LONGITUDE: -2.5997865,
  },
  {
    UPRN: 866,
    LATITUDE: 51.5110831,
    LONGITUDE: -2.7030044,
  },
  {
    UPRN: 867,
    LATITUDE: 51.513271,
    LONGITUDE: -2.6057809,
  },
  {
    UPRN: 868,
    LATITUDE: 51.5131298,
    LONGITUDE: -2.6308539,
  },
  {
    UPRN: 869,
    LATITUDE: 51.513322,
    LONGITUDE: -2.5940802,
  },
  {
    UPRN: 870,
    LATITUDE: 51.5132192,
    LONGITUDE: -2.6140088,
  },
  {
    UPRN: 871,
    LATITUDE: 51.5133298,
    LONGITUDE: -2.5925528,
  },
  {
    UPRN: 872,
    LATITUDE: 51.5132935,
    LONGITUDE: -2.5996711,
  },
  {
    UPRN: 873,
    LATITUDE: 51.5132489,
    LONGITUDE: -2.6065732,
  },
  {
    UPRN: 874,
    LATITUDE: 51.5132489,
    LONGITUDE: -2.6065732,
  },
  {
    UPRN: 875,
    LATITUDE: 51.5131954,
    LONGITUDE: -2.616833,
  },
  {
    UPRN: 876,
    LATITUDE: 51.5132874,
    LONGITUDE: -2.599109,
  },
  {
    UPRN: 877,
    LATITUDE: 51.5132803,
    LONGITUDE: -2.5987343,
  },
  {
    UPRN: 878,
    LATITUDE: 51.5132023,
    LONGITUDE: -2.6138068,
  },
  {
    UPRN: 879,
    LATITUDE: 51.5132017,
    LONGITUDE: -2.6139077,
  },
  {
    UPRN: 880,
    LATITUDE: 51.5132394,
    LONGITUDE: -2.606674,
  },
  {
    UPRN: 881,
    LATITUDE: 51.5132394,
    LONGITUDE: -2.606674,
  },
  {
    UPRN: 882,
    LATITUDE: 51.513102,
    LONGITUDE: -2.6309976,
  },
  {
    UPRN: 883,
    LATITUDE: 51.5132906,
    LONGITUDE: -2.5949444,
  },
  {
    UPRN: 884,
    LATITUDE: 51.513225,
    LONGITUDE: -2.6077114,
  },
  {
    UPRN: 885,
    LATITUDE: 51.5132611,
    LONGITUDE: -2.6007227,
  },
  {
    UPRN: 886,
    LATITUDE: 51.5131156,
    LONGITUDE: -2.6284759,
  },
  {
    UPRN: 887,
    LATITUDE: 51.5132698,
    LONGITUDE: -2.5990224,
  },
  {
    UPRN: 888,
    LATITUDE: 51.5131688,
    LONGITUDE: -2.6167605,
  },
  {
    UPRN: 889,
    LATITUDE: 51.5132276,
    LONGITUDE: -2.6054777,
  },
  {
    UPRN: 890,
    LATITUDE: 51.5132526,
    LONGITUDE: -2.6006217,
  },
  {
    UPRN: 891,
    LATITUDE: 51.5131919,
    LONGITUDE: -2.6123512,
  },
  {
    UPRN: 892,
    LATITUDE: 51.5132794,
    LONGITUDE: -2.595391,
  },
  {
    UPRN: 893,
    LATITUDE: 51.5130977,
    LONGITUDE: -2.6301329,
  },
  {
    UPRN: 894,
    LATITUDE: 51.513269,
    LONGITUDE: -2.5974228,
  },
  {
    UPRN: 895,
    LATITUDE: 51.5130837,
    LONGITUDE: -2.6310694,
  },
  {
    UPRN: 896,
    LATITUDE: 51.5132626,
    LONGITUDE: -2.5969183,
  },
  {
    UPRN: 897,
    LATITUDE: 51.5132182,
    LONGITUDE: -2.6055641,
  },
  {
    UPRN: 898,
    LATITUDE: 51.5131757,
    LONGITUDE: -2.61372,
  },
  {
    UPRN: 899,
    LATITUDE: 51.5132399,
    LONGITUDE: -2.6013421,
  },
  {
    UPRN: 900,
    LATITUDE: 51.5132792,
    LONGITUDE: -2.5936473,
  },
  {
    UPRN: 901,
    LATITUDE: 51.5132565,
    LONGITUDE: -2.5963562,
  },
  {
    UPRN: 902,
    LATITUDE: 51.5130882,
    LONGITUDE: -2.6285475,
  },
  {
    UPRN: 903,
    LATITUDE: 51.5131993,
    LONGITUDE: -2.607466,
  },
  {
    UPRN: 904,
    LATITUDE: 51.5130733,
    LONGITUDE: -2.6313286,
  },
  {
    UPRN: 905,
    LATITUDE: 51.5130967,
    LONGITUDE: -2.6269625,
  },
  {
    UPRN: 906,
    LATITUDE: 51.5130593,
    LONGITUDE: -2.6322508,
  },
  {
    UPRN: 907,
    LATITUDE: 51.5130711,
    LONGITUDE: -2.6300605,
  },
  {
    UPRN: 908,
    LATITUDE: 51.5132342,
    LONGITUDE: -2.5989498,
  },
  {
    UPRN: 909,
    LATITUDE: 51.5130596,
    LONGITUDE: -2.6321931,
  },
  {
    UPRN: 910,
    LATITUDE: 51.5132633,
    LONGITUDE: -2.5932436,
  },
  {
    UPRN: 911,
    LATITUDE: 51.5132633,
    LONGITUDE: -2.5932436,
  },
  {
    UPRN: 912,
    LATITUDE: 51.5132633,
    LONGITUDE: -2.5932436,
  },
  {
    UPRN: 913,
    LATITUDE: 51.5130682,
    LONGITUDE: -2.6312518,
  },
  {
    UPRN: 914,
    LATITUDE: 51.5131059,
    LONGITUDE: -2.6218467,
  },
  {
    UPRN: 915,
    LATITUDE: 51.5131798,
    LONGITUDE: -2.6077684,
  },
  {
    UPRN: 916,
    LATITUDE: 51.5130791,
    LONGITUDE: -2.6268902,
  },
  {
    UPRN: 917,
    LATITUDE: 51.5131108,
    LONGITUDE: -2.6209389,
  },
  {
    UPRN: 918,
    LATITUDE: 51.5130367,
    LONGITUDE: -2.6320343,
  },
  {
    UPRN: 919,
    LATITUDE: 51.5130149,
    LONGITUDE: -2.6320286,
  },
  {
    UPRN: 920,
    LATITUDE: 51.5130993,
    LONGITUDE: -2.6231004,
  },
  {
    UPRN: 921,
    LATITUDE: 51.5131332,
    LONGITUDE: -2.616688,
  },
  {
    UPRN: 922,
    LATITUDE: 51.5132411,
    LONGITUDE: -2.5958372,
  },
  {
    UPRN: 923,
    LATITUDE: 51.5132084,
    LONGITUDE: -2.600477,
  },
  {
    UPRN: 924,
    LATITUDE: 51.5132388,
    LONGITUDE: -2.5945259,
  },
  {
    UPRN: 925,
    LATITUDE: 51.5130908,
    LONGITUDE: -2.6230138,
  },
  {
    UPRN: 926,
    LATITUDE: 51.5132345,
    LONGITUDE: -2.595376,
  },
  {
    UPRN: 927,
    LATITUDE: 51.5132369,
    LONGITUDE: -2.5949005,
  },
  {
    UPRN: 928,
    LATITUDE: 51.5132412,
    LONGITUDE: -2.5940503,
  },
  {
    UPRN: 929,
    LATITUDE: 51.5131728,
    LONGITUDE: -2.6073792,
  },
  {
    UPRN: 930,
    LATITUDE: 51.5131667,
    LONGITUDE: -2.6068171,
  },
  {
    UPRN: 931,
    LATITUDE: 51.5130855,
    LONGITUDE: -2.6223076,
  },
  {
    UPRN: 932,
    LATITUDE: 51.5131318,
    LONGITUDE: -2.6135176,
  },
  {
    UPRN: 933,
    LATITUDE: 51.5131318,
    LONGITUDE: -2.6135176,
  },
  {
    UPRN: 934,
    LATITUDE: 51.5131379,
    LONGITUDE: -2.6123649,
  },
  {
    UPRN: 935,
    LATITUDE: 51.5132169,
    LONGITUDE: -2.5970618,
  },
  {
    UPRN: 936,
    LATITUDE: 51.5132207,
    LONGITUDE: -2.5963125,
  },
  {
    UPRN: 937,
    LATITUDE: 51.5131156,
    LONGITUDE: -2.6166157,
  },
  {
    UPRN: 938,
    LATITUDE: 51.5130308,
    LONGITUDE: -2.6325386,
  },
  {
    UPRN: 939,
    LATITUDE: 51.5130308,
    LONGITUDE: -2.6325386,
  },
  {
    UPRN: 940,
    LATITUDE: 51.5130448,
    LONGITUDE: -2.6299304,
  },
  {
    UPRN: 941,
    LATITUDE: 51.5130516,
    LONGITUDE: -2.6286767,
  },
  {
    UPRN: 942,
    LATITUDE: 51.5132253,
    LONGITUDE: -2.5936466,
  },
  {
    UPRN: 943,
    LATITUDE: 51.5130643,
    LONGITUDE: -2.6229125,
  },
  {
    UPRN: 944,
    LATITUDE: 51.5131769,
    LONGITUDE: -2.6013556,
  },
  {
    UPRN: 945,
    LATITUDE: 51.5130331,
    LONGITUDE: -2.6287629,
  },
  {
    UPRN: 946,
    LATITUDE: 51.5131143,
    LONGITUDE: -2.6134309,
  },
  {
    UPRN: 947,
    LATITUDE: 51.5131143,
    LONGITUDE: -2.6134309,
  },
  {
    UPRN: 948,
    LATITUDE: 51.5130789,
    LONGITUDE: -2.6201603,
  },
  {
    UPRN: 949,
    LATITUDE: 51.513182,
    LONGITUDE: -2.6003614,
  },
  {
    UPRN: 950,
    LATITUDE: 51.5131589,
    LONGITUDE: -2.6048428,
  },
  {
    UPRN: 951,
    LATITUDE: 51.5130648,
    LONGITUDE: -2.6228261,
  },
  {
    UPRN: 952,
    LATITUDE: 51.5131924,
    LONGITUDE: -2.5983296,
  },
  {
    UPRN: 953,
    LATITUDE: 51.5131395,
    LONGITUDE: -2.60686,
  },
  {
    UPRN: 954,
    LATITUDE: 51.5130194,
    LONGITUDE: -2.6289367,
  },
  {
    UPRN: 955,
    LATITUDE: 51.5130612,
    LONGITUDE: -2.6218029,
  },
  {
    UPRN: 956,
    LATITUDE: 51.5131373,
    LONGITUDE: -2.6072923,
  },
  {
    UPRN: 957,
    LATITUDE: 51.5130037,
    LONGITUDE: -2.632567,
  },
  {
    UPRN: 958,
    LATITUDE: 51.5130037,
    LONGITUDE: -2.632567,
  },
  {
    UPRN: 959,
    LATITUDE: 51.513051,
    LONGITUDE: -2.6237338,
  },
  {
    UPRN: 960,
    LATITUDE: 51.5130182,
    LONGITUDE: -2.6298724,
  },
  {
    UPRN: 961,
    LATITUDE: 51.5131876,
    LONGITUDE: -2.5975082,
  },
  {
    UPRN: 962,
    LATITUDE: 51.5130424,
    LONGITUDE: -2.6236616,
  },
  {
    UPRN: 963,
    LATITUDE: 51.5131872,
    LONGITUDE: -2.5958365,
  },
  {
    UPRN: 964,
    LATITUDE: 51.5131644,
    LONGITUDE: -2.6002891,
  },
  {
    UPRN: 965,
    LATITUDE: 51.5131939,
    LONGITUDE: -2.5945109,
  },
  {
    UPRN: 966,
    LATITUDE: 51.513043,
    LONGITUDE: -2.6235463,
  },
  {
    UPRN: 967,
    LATITUDE: 51.5131825,
    LONGITUDE: -2.5967588,
  },
  {
    UPRN: 968,
    LATITUDE: 51.5131804,
    LONGITUDE: -2.5971622,
  },
  {
    UPRN: 969,
    LATITUDE: 51.5130047,
    LONGITUDE: -2.629022,
  },
  {
    UPRN: 970,
    LATITUDE: 51.5131165,
    LONGITUDE: -2.6078252,
  },
  {
    UPRN: 971,
    LATITUDE: 51.5130344,
    LONGITUDE: -2.6234598,
  },
  {
    UPRN: 972,
    LATITUDE: 51.5130715,
    LONGITUDE: -2.6164566,
  },
  {
    UPRN: 973,
    LATITUDE: 51.5130405,
    LONGITUDE: -2.6223214,
  },
  {
    UPRN: 974,
    LATITUDE: 51.5131316,
    LONGITUDE: -2.6049144,
  },
  {
    UPRN: 975,
    LATITUDE: 51.5130351,
    LONGITUDE: -2.6233445,
  },
  {
    UPRN: 976,
    LATITUDE: 51.5131784,
    LONGITUDE: -2.5940207,
  },
  {
    UPRN: 977,
    LATITUDE: 51.5129867,
    LONGITUDE: -2.6307078,
  },
  {
    UPRN: 978,
    LATITUDE: 51.5129803,
    LONGITUDE: -2.6318894,
  },
  {
    UPRN: 979,
    LATITUDE: 51.5129803,
    LONGITUDE: -2.6318894,
  },
  {
    UPRN: 980,
    LATITUDE: 51.512992,
    LONGITUDE: -2.6297279,
  },
  {
    UPRN: 981,
    LATITUDE: 51.5131107,
    LONGITUDE: -2.6072199,
  },
  {
    UPRN: 982,
    LATITUDE: 51.5129931,
    LONGITUDE: -2.6291561,
  },
  {
    UPRN: 983,
    LATITUDE: 51.5130266,
    LONGITUDE: -2.6232435,
  },
  {
    UPRN: 984,
    LATITUDE: 51.5130539,
    LONGITUDE: -2.6163699,
  },
  {
    UPRN: 985,
    LATITUDE: 51.5129856,
    LONGITUDE: -2.6292379,
  },
  {
    UPRN: 986,
    LATITUDE: 51.5130553,
    LONGITUDE: -2.6161105,
  },
  {
    UPRN: 987,
    LATITUDE: 51.5131384,
    LONGITUDE: -2.6001014,
  },
  {
    UPRN: 988,
    LATITUDE: 51.5129744,
    LONGITUDE: -2.6296412,
  },
  {
    UPRN: 989,
    LATITUDE: 51.5131289,
    LONGITUDE: -2.6002022,
  },
  {
    UPRN: 990,
    LATITUDE: 51.5131471,
    LONGITUDE: -2.5948849,
  },
  {
    UPRN: 991,
    LATITUDE: 51.5129667,
    LONGITUDE: -2.6294105,
  },
  {
    UPRN: 992,
    LATITUDE: 51.5129663,
    LONGITUDE: -2.6294826,
  },
  {
    UPRN: 993,
    LATITUDE: 51.5130952,
    LONGITUDE: -2.6050004,
  },
  {
    UPRN: 994,
    LATITUDE: 51.5130804,
    LONGITUDE: -2.6078679,
  },
  {
    UPRN: 995,
    LATITUDE: 51.5130524,
    LONGITUDE: -2.6132284,
  },
  {
    UPRN: 996,
    LATITUDE: 51.5130524,
    LONGITUDE: -2.6132284,
  },
  {
    UPRN: 997,
    LATITUDE: 51.5131448,
    LONGITUDE: -2.5953316,
  },
  {
    UPRN: 998,
    LATITUDE: 51.513131,
    LONGITUDE: -2.5962681,
  },
  {
    UPRN: 999,
    LATITUDE: 51.5131018,
    LONGITUDE: -2.6019743,
  },
  {
    UPRN: 1000,
    LATITUDE: 51.5131204,
    LONGITUDE: -2.5983575,
  },
  {
    UPRN: 1001,
    LATITUDE: 55.97824058137012,
    LONGITUDE: -3.2432627677917485,
  },
  {
    UPRN: 1002,
    LATITUDE: 55.97728011080647,
    LONGITUDE: -3.2431340217590336,
  },
  {
    UPRN: 1003,
    LATITUDE: 55.977051995542695,
    LONGITUDE: -3.244872093200684,
  },
  {
    UPRN: 1004,
    LATITUDE: 55.9796572319176,
    LONGITUDE: -3.242125511169434,
  },
  {
    UPRN: 1005,
    LATITUDE: 55.977952442705295,
    LONGITUDE: -3.245644569396973,
  },
  {
    UPRN: 1006,
    LATITUDE: 55.97740017093123,
    LONGITUDE: -3.246631622314453,
  },
  {
    UPRN: 1007,
    LATITUDE: 55.97861275730191,
    LONGITUDE: -3.238627910614014,
  },
  {
    UPRN: 1008,
    LATITUDE: 55.97903295163043,
    LONGITUDE: -3.2368683815002446,
  },
  {
    UPRN: 1009,
    LATITUDE: 55.97893690761494,
    LONGITUDE: -3.2373833656311035,
  },
  {
    UPRN: 1010,
    LATITUDE: 55.97909297901898,
    LONGITUDE: -3.2362675666809086,
  },
  {
    UPRN: 1011,
    LATITUDE: 55.978348632816044,
    LONGITUDE: -3.236052989959717,
  },
  {
    UPRN: 1012,
    LATITUDE: 55.98050959834557,
    LONGITUDE: -3.244550228118897,
  },
  {
    UPRN: 1013,
    LATITUDE: 55.98198618868297,
    LONGITUDE: -3.246095180511475,
  },
  {
    UPRN: 1014,
    LATITUDE: 55.98083373276132,
    LONGITUDE: -3.2506871223449707,
  },
  {
    UPRN: 1015,
    LATITUDE: 55.981746096531374,
    LONGITUDE: -3.2496571540832524,
  },
  {
    UPRN: 1016,
    LATITUDE: 55.98163805457686,
    LONGITUDE: -3.2509231567382817,
  },
  {
    UPRN: 1017,
    LATITUDE: 56.020597605083196,
    LONGITUDE: -3.428764343261719,
  },
  {
    UPRN: 1018,
    LATITUDE: 56.02275620951742,
    LONGITUDE: -3.430309295654297,
  },
  {
    UPRN: 1019,
    LATITUDE: 56.022396450491385,
    LONGITUDE: -3.434557914733887,
  },
  {
    UPRN: 1020,
    LATITUDE: 56.022780193333325,
    LONGITUDE: -3.438377380371094,
  },
  {
    UPRN: 1021,
    LATITUDE: 56.023691567298634,
    LONGITUDE: -3.4375619888305664,
  },
  {
    UPRN: 1022,
    LATITUDE: 56.02460291975296,
    LONGITUDE: -3.4361028671264653,
  },
  {
    UPRN: 1023,
    LATITUDE: 56.024027331231814,
    LONGITUDE: -3.441252708435059,
  },
  {
    UPRN: 1024,
    LATITUDE: 56.02568212510315,
    LONGITUDE: -3.442153930664063,
  },
  {
    UPRN: 1025,
    LATITUDE: 56.02551425069656,
    LONGITUDE: -3.443055152893067,
  },
  {
    UPRN: 1026,
    LATITUDE: 56.027672580286456,
    LONGITUDE: -3.4441709518432617,
  },
  {
    UPRN: 1027,
    LATITUDE: 56.02719296191601,
    LONGITUDE: -3.451595306396485,
  },
  {
    UPRN: 1028,
    LATITUDE: 56.02726490505144,
    LONGITUDE: -3.4530973434448247,
  },
  {
    UPRN: 1029,
    LATITUDE: 52.960608395466075,
    LONGITUDE: -0.012102127075195312,
  },
  {
    UPRN: 1030,
    LATITUDE: 52.96099615357355,
    LONGITUDE: -0.01703739166259766,
  },
  {
    UPRN: 1031,
    LATITUDE: 52.96197845854876,
    LONGITUDE: -0.015535354614257814,
  },
  {
    UPRN: 1032,
    LATITUDE: 52.96182335924693,
    LONGITUDE: -0.01768112182617188,
  },
  {
    UPRN: 1033,
    LATITUDE: 52.96358111875474,
    LONGITUDE: -0.019998550415039066,
  },
  {
    UPRN: 1034,
    LATITUDE: 52.9654938934886,
    LONGITUDE: -0.015277862548828127,
  },
  {
    UPRN: 1035,
    LATITUDE: 52.966010845105274,
    LONGITUDE: -0.010342597961425783,
  },
  {
    UPRN: 1036,
    LATITUDE: 52.961694109403695,
    LONGITUDE: 0.0010299682617187502,
  },
  {
    UPRN: 1037,
    LATITUDE: 52.962624699650846,
    LONGITUDE: -0.00012874603271484378,
  },
  {
    UPRN: 1038,
    LATITUDE: 52.96316753471169,
    LONGITUDE: -0.0006437301635742189,
  },
  {
    UPRN: 1039,
    LATITUDE: 52.9604791419908,
    LONGITUDE: 0.0015878677368164062,
  },
];
