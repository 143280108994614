import Divider from "@mui/material/Divider";
import SocialStatusLine from "common/SocialStatusLine";
import { useSelector } from "services";
import { selectDemographic } from "services/project";

const SocialStatus = () => {
  const demographic = useSelector(selectDemographic);

  return (
    <>
      <h1 className='text-3xl leading-6 mb-10'>Social Status</h1>
      <Divider />
      <SocialStatusLine
        title='Managerial'
        percentage={demographic.socialStatus.managerial}
        className='mt-2'
      />
      <SocialStatusLine
        title='Professional'
        percentage={demographic.socialStatus.professional}
      />
      <SocialStatusLine
        title='Skilled'
        percentage={demographic.socialStatus.skilled}
      />
      <SocialStatusLine
        title='Unskilled'
        percentage={demographic.socialStatus.unskilled}
      />
    </>
  );
};

export default SocialStatus;
