export const salesDateValues = [
  { option: "Last 3 months", value: 3 },
  { option: "Last 6 months", value: 6 },
  { option: "Last 9 months", value: 9 },
  { option: "Last 12 months", value: 12 },
];

export const propertyTemplateValues = [
  { option: "All", value: "All" }
];

export const radiusValues = [
  { option: "0.25 miles", value: 0.25 },
  { option: "0.5 miles", value: 0.5 },
  { option: "1 mile", value: 1 },
  { option: "2 miles", value: 2 },
  { option: "5 miles", value: 5 },
];
