import MapPreview from "common/MapPreview";
import { formatDate } from "helpers/date-format";
import { createRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import logo from "styles/img/logo.svg";
import rics from "styles/img/rics.svg";
import Button from "ui/Button";
import Editor from "widgets/Editor";
import EditorSidebar from "widgets/EditorSidebar";
const ProjectReportPhase2 = ({ formValues }) => {


  const componentRef = useRef();
  let docToPrint = createRef();
  const [tileLoaded, setTileLoaded] = useState(false);
  const { projectData } = useSelector((state) => state.projects);
  const [showCover, setShowCover] = useState(false);
  const [widgetComponents, setWidgetComponents] = useState([]);


  const addWidgetComponent = (widgetType) => {
    const newWidget = { id: Date.now(), type: widgetType, value: "" };
    setWidgetComponents([...widgetComponents, newWidget]);
  };


  const updateWidgetValue = (widgetId, value) => {
    const updatedWidgets = widgetComponents.map((widget) => {
      if (widget.id === widgetId) {
        return { ...widget, value: value };
      }
      return widget;
    });
    setWidgetComponents(updatedWidgets);
  };

  const removeWidgetComponent = (widgetId) => {
    const updatedWidgets = widgetComponents.filter(
      (widget) => widget.id !== widgetId
    );
    setWidgetComponents(updatedWidgets);
  };


  const handlePrint = useReactToPrint({
    content: () => docToPrint.current,
  });


  useEffect(() => {
    showCover &&
      tileLoaded &&
      setTimeout(() => {
        handlePrint();
        setShowCover(false);
      }, 1000);
  }, [showCover, tileLoaded]);


  // handlePrint();
  const totalHeightLimit = 1120.51;
  const boxIds = []; // Array containing the ids of the MUI boxes

  // Function to calculate the height of a box based on its id
  const calculateBoxHeight = (boxId) => {
    const box = document.getElementById(boxId);
    if (box) {
      return box.offsetHeight + 56;
    }
    return 0;
  };

  // Function to check if there is an overflow
  const checkOverflow = (index = 0) => {
    console.log("index", index);
    let totalHeight = 0;
    let overflowId = null;
    let remainingHeight = totalHeightLimit;
    let needRerun = false;
    for (let i = index; i < widgetComponents.length; i++) {
      console.log(`Box${i}`);
      const boxId = `Box${i}`;
      const boxHeight = calculateBoxHeight(boxId);
      console.log(boxHeight);
      totalHeight += boxHeight;

      if (totalHeight > totalHeightLimit) {
        overflowId = boxId;
        break;
      }

      remainingHeight -= boxHeight;
      const divElement = document.getElementById(boxId);
      console.log(
        boxId,
        divElement.scrollWidth,
        divElement.clientWidth,
        divElement.offsetWidth
      );
      if (divElement && divElement.scrollWidth > 778) {
        const scaleRatio = 778 / divElement.scrollWidth;
        divElement.style.transform = `scale(${scaleRatio})`;
      }
    }

    if (overflowId) {
      const previousBoxId = subtractOneFromBoxNumber(overflowId);
      console.log(overflowId, previousBoxId);
      const previousBoxElement = document.getElementById(previousBoxId);
      if (previousBoxElement) {
        const extraHeight = remainingHeight > 0 ? remainingHeight : 0;
        previousBoxElement.style.height = `${
          calculateBoxHeight(previousBoxId) + extraHeight
        }px`;
        console.log(`${overflowId} overflow`);
        needRerun = true;
      }
    }
    console.log(widgetComponents.length, needRerun);
    if (needRerun) {
      // Run the function again for the remaining boxes
      checkOverflow(parseInt(overflowId.match(/Box(\d+)/)[1]));
    } else {
      console.log(calculateBoxHeight("Box0"), calculateBoxHeight("Box1"));
      setShowCover(true);
    }
  };

  // Call the checkOverflow function when needed

  return (
    <>
      <div className='flex gap-5'>
        <>
          <EditorSidebar addWidgetComponent={addWidgetComponent} />
          <div
            className='scaled-down-div'
            style={{ width: "793px" }}
            ref={docToPrint}
          >
            {showCover && (
              <div
                id={"mapId"}
                style={{
                  backgroundColor: "white",
                  width: "800px",
                  height: "1132px",
                  margin: "0 auto",
                }}
                className='relative flex'
              >
                <div
                  className='bg-background-blue w-1/2 relative flex flex-col items-center justify-center px-8'
                  style={{
                    backgroundColor: `${
                      formValues.color ? formValues.color : "#141831"
                    }`,
                  }}
                >
                  <div className='flex flex-col gap-6'>
                    <h2 className='text-total-white text-3xl'>
                      {formValues.title ? formValues.title : projectData.title}
                    </h2>
                    <p className='text-total-white text-base'>
                      {projectData.streetAddress} {projectData.streetNumber}.{" "}
                      {projectData.postcode}
                    </p>
                    <p className='text-total-white text-lg'>
                      Date:{" "}
                      {formValues.date
                        ? formatDate(formValues.date)
                        : formatDate(projectData.createdAt)}
                    </p>
                  </div>
                  <div>
                    <div className='flex absolute bottom-2 left-4'>
                      <img src={logo} alt='logo' className='w-[26px] mr-2' />
                      <img src={rics} alt='rics-logo' />
                    </div>
                  </div>
                </div>
                <div className='h-full w-full'>
                  <MapPreview
                    setReportTile={setTileLoaded}
                    mapHeight='1132px'
                    className=''
                    zoom={17}
                    lat={projectData.latitude}
                    long={projectData.longitute}
                  />
                </div>
              </div>
            )}
            <div className='w-full min-h-70vh'>
              <Editor
                customRef={componentRef}
                updateWidgetValue={updateWidgetValue}
                widgetComponents={widgetComponents}
                removeWidgetComponent={removeWidgetComponent}
              />
            </div>
          </div>
        </>
      </div>

      <div className='flex justify-end mt-5'>
        <Button
          title='Export'
          className='bg-green py-2 px-11 rounded-2xl text-xl'
          type='button'
          onClick={() => {
            checkOverflow();
            // ;
          }}
        />
      </div>
    </>
  );
};

export default ProjectReportPhase2;

const subtractOneFromBoxNumber = (boxString) => {
  // Extract the number after "Box" using a regular expression
  const boxNumber = parseInt(boxString.match(/Box(\d+)/)[1]);

  // Subtract 1 from the box number
  const newBoxNumber = boxNumber - 1;

  // Construct the updated box string with the new box number
  const updatedBoxString = boxString.replace(boxNumber, newBoxNumber);

  return updatedBoxString;
};
