import GrayCard from "common/GrayCard";
import { useSelector } from "react-redux";
import { selectProjectData } from "services/project";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SoldProperties = () => {
  const { salesStudy: transactions } = useSelector(selectProjectData);

  function transformData(data) {
    // Object to store aggregated data
    const chartData = {};

    // Iterate over each data object
    data.forEach((obj) => {
      // Extract the month from the dateOfTransfer property
      const month = new Date(obj.dateOfTransfer).toLocaleString("en-US", {
        month: "long",
      });

      // If the month is not already in the chartData object, initialize it
      if (!chartData[month]) {
        chartData[month] = {
          unitsSold: 0,
          totalAmount: 0,
          count: 0,
        };
      }

      // Increment the unitsSold and add the price to the totalAmount
      chartData[month].unitsSold++;
      chartData[month].totalAmount += obj.price;
      chartData[month].count++;
    });

    // Calculate the average price for each month
    for (const month in chartData) {
      chartData[month].averagePrice =
        chartData[month].totalAmount / chartData[month].count;
    }

    return chartData;
  }

  // Call the function to transform the data
  const transformedData: any = transformData(transactions.sales);

  // Print the transformed data
  const dataUnits = {
    labels: Object.keys(transformedData),
    datasets: [
      {
        label: "Units Sold",
        data: Object.values(transformedData).map((e: any) => e.unitsSold),
        backgroundColor: [
          "#A69FDA",
          "#FF4B4B",
          "#50EBBE",
          "#9164DC",
          "#CD4354",
          "#50EBBE",
          "#9164DC",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const dataPrice = {
    labels: Object.keys(transformedData),
    datasets: [
      {
        label: "Units Sold",
        data: Object.values(transformedData).map((e: any) => e.averagePrice),
        backgroundColor: [
          "#A69FDA",
          "#FF4B4B",
          "#50EBBE",
          "#9164DC",
          "#CD4354",
          "#50EBBE",
          "#9164DC",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value;
          },
          color: "#000",
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
        },
      },
    },

    maintainAspectRatio: false,
    categoryPercentage: 0.8,
    barPercentage: 0.7,
    borderRadius: 5,
  };
  return (
    <>
      <h1 className='text-3xl leading-6 mb-10'>Property Prices</h1>
      <GrayCard>
        <h1 className='text-xl leading-6 mb-10'>Units sold</h1>
        <div className='h-[170px] mb-10'>
          <Bar data={dataUnits} options={options} />
        </div>{" "}
        <h1 className='text-xl leading-6 mb-10'>Average price sold</h1>
        <div className='h-[170px]'>
          <Bar data={dataPrice} options={options} />
        </div>
      </GrayCard>
    </>
  );
};

export default SoldProperties;
