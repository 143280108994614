import { NavLink } from "react-router-dom";

const SidebarItem = (props) => {
  const { url, icon, text, className, onClick } = props;

  return (
    <li className={`cursor-pointer ${className}`} onClick={onClick}>
      <NavLink
        to={`${url}`}
        className="flex flex-col justify-center items-center gap-1"
      >
        <img src={`${icon}`} alt="logo" />
        <h1 className="">{text}</h1>
      </NavLink>
    </li>
  );
};

export default SidebarItem;
