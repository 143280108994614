import EditorSidebarItem from "common/EditorSidebarItem";
import GrayCard from "common/GrayCard";

import { Divider } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TableChartIcon from "@mui/icons-material/TableChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import ageChartIcon from "styles/img/age-chart.svg";
import commuteChartIcon from "styles/img/commute-chart.svg";
import developmentMapIcon from "styles/img/development-map.svg";
import paragraphIcon from "styles/img/editorParagraph.svg";
import localMapIcon from "styles/img/local-map.svg";
import propertiesChartIcon from "styles/img/properties-chart.svg";
import salesSummaryIcon from "styles/img/sales-summary.svg";
import socialStatusIcon from "styles/img/social-status.svg";
import AgeChart from "./Components/AgeChart";
import Demographic from "./Components/Demographic";
import Previews from "./Components/Image/Files";
import LocalMap from "./Components/LocalMap";
import Map from "./Components/Map";
import PropertyPrice from "./Components/PropertyPrice";
import RichEditor from "./Components/RichEditor";
import SalesStudies from "./Components/SalesStudies";
import SocialStatus from "./Components/SocialStatus";
import Schedules from "./Components/Schedules";
import SoldProperties from "./Components/Sold Properties";

import PropertyTemplateWidget from "./Components/PropertyTemplateWidget";
import FeasibilityCostsWidget from "./Components/FeasibilityCostsWidget";
import FeasibilitySummaryWidget from "./Components/FeasibilitySummaryWidget";
import PredictedValueWidget from "./Components/PredictedValueWidget";



const EditorSidebar = ({ addWidgetComponent }) => {
  return (
    <GrayCard className='!p-2 min-w-[160px]sticky max-h-70vh'>
      <div className='bg-total-white p-2.5 rounded-xl '>
        <h1 className='text-lg text-center'> Add Widget</h1>
        <Divider />
        <ul className='my-3 flex flex-col gap-8 overflow-x-hidden max-h-60vh'>

                  <EditorSidebarItem
                    onClickHandler={() => addWidgetComponent(RichEditor)}
                    icon={paragraphIcon}
                    heading='Paragraph'
                  />

          <EditorSidebarItem
            onClickHandler={() => addWidgetComponent(LocalMap)}
            icon={localMapIcon}
            heading='Local Area Map'
          />

         <EditorSidebarItem
           onClickHandler={() => addWidgetComponent(PropertyTemplateWidget)}
           muiIcon={<SummarizeIcon />}
           heading='Property templates'
         />

       <EditorSidebarItem
         onClickHandler={() => addWidgetComponent(PredictedValueWidget)}
         icon={ageChartIcon}
         heading='Sales Study'
       />

     <EditorSidebarItem
       onClickHandler={() => addWidgetComponent(Schedules)}
       icon={salesSummaryIcon}
       heading='Accommodation schedules'
     />

        <EditorSidebarItem
          onClickHandler={() => addWidgetComponent(FeasibilitySummaryWidget)}
          icon={propertiesChartIcon}
          heading='Feasibility summary'
        />

        <EditorSidebarItem
          onClickHandler={() => addWidgetComponent(FeasibilityCostsWidget)}
          muiIcon={<TimelineIcon />}
          heading='Project Costs'
        />


          <EditorSidebarItem
            onClickHandler={() => addWidgetComponent(Demographic)}
            icon={commuteChartIcon}
            heading='Commute Chart'
          />

          <EditorSidebarItem
            onClickHandler={() => addWidgetComponent(Map)}
            icon={developmentMapIcon}
            heading='Development Map'
          />{" "}
          <EditorSidebarItem
            onClickHandler={() => addWidgetComponent(SocialStatus)}
            icon={socialStatusIcon}
            heading='Social Status'
          />
          <EditorSidebarItem
            onClickHandler={() => addWidgetComponent(AgeChart)}
            icon={ageChartIcon}
            heading='Age Chart'
          />

        </ul>
      </div>
    </GrayCard>
  );
};

export default EditorSidebar;
