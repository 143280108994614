import AccountDetailsHeader from "common/AccountDetailsHeader";
import BillingAddressForm from "forms/BillingAddressForm";
import PaymentMethodForm from "forms/PaymentMethodForm";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const UpdatePaymentDetails = (props) => {
  const location = useLocation();
  const updateType = location.pathname.split("/").at(-1);

  const [paymentFormValues, setPaymentFormValues] = useState({
    accountHolderName: "",
    accountNumber: "",
    sortCode: "",
  });

  const handlePaymentFormInputChange = (evt) => {
    const { name, value } = evt.target;
    setPaymentFormValues({ ...paymentFormValues, [name]: value });
  };

  const handlePaymentFormSubmit = (evt) => {
    evt.preventDefault();
  };

  const [billingFormValues, setBillingFormValues] = useState({
    fullName: "",
    billingAddress: "",
    city: "",
    country: "",
    postCode: "",
  });

  const handleBillingFormInputChange = (evt) => {
    const { name, value } = evt.target;
    setBillingFormValues({ ...billingFormValues, [name]: value });
  };

  const handleBillingFormSubmit = (evt) => {
    evt.preventDefault();
  };

  return (
    <div className='my-12 mx-7'>
      <AccountDetailsHeader back='/dashboard/account/subscription/details' />
      {updateType === "update-payment" ? (
        <PaymentMethodForm
          handleInputChange={handlePaymentFormInputChange}
          formValues={paymentFormValues}
          handleSubmit={handlePaymentFormSubmit}
        />
      ) : (
        <BillingAddressForm
          handleInputChange={handleBillingFormInputChange}
          formValues={billingFormValues}
          handleSubmit={handleBillingFormSubmit}
        />
      )}
    </div>
  );
};

export default UpdatePaymentDetails;
