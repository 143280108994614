import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "services";
import { authActions } from "services/auth";

const Protected = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("UE:[]")
    console.log(JSON.stringify(userData, null, 4))

    if (!token) return;

    const decoded = jwtDecode(token);
    const expiration = decoded.exp;

    const checkTokenExpiration = () => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (expiration < currentTimestamp) {
        dispatch(authActions.logout());
        Cookies.remove("propToken");
        navigate("/login", { replace: true });
      }
    };

    const interval = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(interval);
  }, []);


  const location = useLocation();

  let token = useSelector((state) => state.auth.token);
  if (!token) token = Cookies.get("propToken");

  if (!token) {
    const searchParams = new URLSearchParams(location.search);
    token = searchParams.get("jwt");
    if (token) Cookies.set("propToken", token);
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default Protected;
