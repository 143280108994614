import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const StatCard = ({title, value}) => {
  return (
    <Card sx={{ minWidth: 180,  textAlign:"center"}}>
      <CardContent>
        <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography sx={{ fontSize: 40 }} color="text.secondary" gutterBottom>
         {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;