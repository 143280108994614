const Button = (props) => {
  const { className, type, onClick, title, icon, disabled = false } = props;

  return (
    <button
      className={`${className} hover:translate-y-[-3px] hover:shadow-md transition-all duration-300 ${
        disabled ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && icon}
      {title}
    </button>
  );
};

export default Button;
