import EnergyClassDiagram from "./EnergyClassDiagram";
import EpcStatisticLine from "./EpcStatisticLine";

import house from "styles/img/mini-home.svg";

const EpcStatistic = ({ className, epcData }) => {
  const {
    address1,
    builtFrom,
    constructionAgeBand,
    currentEnergyEfficiency,
    currentEnergyRating,
    localAuthority,
    lodgementDate,
    numberHabitableRooms,
    potentialEnergyEfficiency,
    potentialEnergyRating,
    tenure,
    totalFloorArea,
  } = epcData;

  return (
    <div className={`flex justify-between ${className}`}>
      <div className="flex flex-col gap-3.5 flex-1">
        <div className="flex items-center gap-3.5">
          <img src={house} alt="house" />
          <EpcStatisticLine title="Street" value={address1} noMargin={true} />
        </div>
        <EpcStatisticLine
          title="Construction Age"
          value={constructionAgeBand}
        />
        <EpcStatisticLine title="Current Rating" value={currentEnergyRating} />
        <EpcStatisticLine
          title="Potential Rating"
          value={potentialEnergyRating}
        />
      </div>

      <div className="flex flex-col gap-3.5 flex-1">
        <EpcStatisticLine title="Lodgement Date" value={lodgementDate} />
        <EpcStatisticLine title="Local Authority" value={localAuthority} />
        <EpcStatisticLine
          title="Current Energy Efficiency"
          value={currentEnergyEfficiency}
        />
        <EpcStatisticLine
          title="Potential Energy Efficiency"
          value={potentialEnergyEfficiency}
        />
      </div>
      <div className="flex flex-col gap-3.5 flex-1">
        <EpcStatisticLine title="Built From" value={builtFrom} />
        <EpcStatisticLine title="Rooms" value={numberHabitableRooms} />
        <EpcStatisticLine title="Floor Area" value={totalFloorArea} />
        <EpcStatisticLine title="Tenure" value={tenure} />
      </div>
      <EnergyClassDiagram currentEnergyEfficiency={currentEnergyEfficiency} />
    </div>
  );
};

export default EpcStatistic;
