import { CircularProgress } from "@mui/material";
import MapProjectList from "common/MapProjectList";
import SearchBar from "common/SearchBar";
import Cookies from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "services";
import { fetchUserProjects, resetPolygon } from "services/project";
import Header from "typography/Header";
import Button from "ui/Button";
import { useDebouncedCallback } from "use-debounce";
import {fetchPropertyTemplates} from "services/property";
import DebugJson from "common/DebugJson";



const Projects = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("propToken");
  const params = useParams();
  const projectId = params.id;

  const {
    projectsData,
    status,
    activeProjects,
    archivedProjects,
    // error,
  } = useSelector((state) => state.projects);

  useEffect(() => {
   console.log("UE:[]")
    dispatch(resetPolygon());
    if (projectsData && Object.keys(projectsData).length !== 0) return;
    dispatch(fetchUserProjects({ token }));
    dispatch(fetchPropertyTemplates({token}));
  }, []);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [projectOptions, setProjectOptions] = useState("");

  const debouncedSearch = useDebouncedCallback((value) => {
    setQuery(value);
  }, 1000);

  const handleSearchChange = (event) => {
    setLoading(true);
    const value = event.target.value;
    debouncedSearch(value);
  };

  useEffect(() => {
    console.log("UE:[query, activeProjects, loading]")
    dispatch(resetPolygon());
    if (query !== "") {
      const filteredProjects = activeProjects.filter((project) =>
        project.projectTitle.toLowerCase().includes(query.toLowerCase())
      );
      setProjectOptions(filteredProjects)
    } else {
      setProjectOptions(activeProjects)
    }

  }, [query, activeProjects, loading]);


//  const projectOptions = useMemo(() => {
//    console.log("useMemo: projects")
//    if (query !== "") {
//      const filteredProjects = activeProjects.filter((project) =>
//        project.projectTitle.toLowerCase().includes(query.toLowerCase())
//      );
//
//      return filteredProjects;
//    } else {
//      return activeProjects;
//    }
//  }, [query, activeProjects, loading]);

  useEffect(() => {
    console.log("UE:[projectOptions]")
    setLoading(false);
  }, [projectOptions]);

  useEffect(() => {
    console.log("UE:[status]"+status)
  }, [status]);

  useEffect(() => {
    console.log("UE:[loading]"+loading)
  }, [loading]);

  return (
    <>
      <div className='flex justify-between items-center pb-4'>
        <Header header='Projects' />
        {/* <Filter /> */}
      </div>
      <div className='bg-background-gray py-8 px-5 pb-8 pl-6 rounded-3xl'>
        <div className='flex justify-between'>
          <div className='flex'>
            <SearchBar
              onChange={handleSearchChange}
              className='bg-total-white'
            />
            {/* <Filter /> */}
          </div>
          <Link to='add'>
            <Button
              type='button'
              className='bg-green text-background-blue rounded-2xl text-xl pt-3.5 pb-3.5 pr-14 pl-14'
              title='Add Project'
            />
          </Link>
        </div>
       {/* <h3 className='mt-12 text-xl text-total-black mb-4'></h3>*/}
       <div className='mt-12'></div>
        {status === "loading" || status === "idle" || loading ? (
          <div className='flex items-center justify-center'>
            <CircularProgress />
          </div>
        ) : (
          <MapProjectList projects={projectOptions} />
        )}
      </div>


    </>
  );
};

export default Projects;
