import Button from "ui/Button";
import GrayCard from "./GrayCard";

import LabelInput from "./LabelInput";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import { useDispatch, useSelector } from "services";
import {useEffect} from "react";

import {
     radiusValues, salesDateValues,
} from "helpers/sales-study-filter-dropdowns";
import plus from "../styles/img/small-plus-rounded.svg";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {Tooltip} from "chart.js";
import {CHLabel} from "./EditableTableStyle";
import Box from "@mui/material/Box";

const ProjectTableFilters = (props) => {

    const {
        className, handleAddHouseTemplate, handleSearch, formValues, handleSelect
    } = props;

    const {propertyTemplateOptions} = useSelector(
        (state) => state.property
    );

     useEffect(() => {
     console.log("UE:[propertyTemplateOptions]")
    }, [propertyTemplateOptions]);

    return (<GrayCard className={className}>
            <div className='flex gap-7'>

                <span className='flex flex-1 flex-row gap-1'>
                    <LabelInput
                        label='Property Template'
                        type='select'
                        labelWeight='bold'
                        className='self-end'
                        value={formValues.propertyTemplateId ? formValues.propertyTemplateId : 'All'}
                        onChange={handleSelect}
                        name='propertyTemplateId'
                        options={[{option: "All", value: "All"}].concat(propertyTemplateOptions)}
                    />
                    <IconButton onClick={ handleAddHouseTemplate}> <AddCircleOutlineTwoToneIcon /></IconButton>
                </span>

                <LabelInput
                    label='Sale date'
                    type='select'
                    labelWeight='bold'
                    className='self-end'
                    value={formValues.salesDate}
                    name='salesDate'
                    onChange={handleSelect}
                    options={salesDateValues}
                />

                <LabelInput
                    label='Search radius'
                    type='select'
                    labelWeight='bold'
                    className='self-end'
                    value={formValues.radius}
                    name='radius'
                    onChange={handleSelect}
                    options={radiusValues}
                />

                <span className='flex gap-2 flex-2 flex-row self-end'>
                <Button
                    type='button'
                    className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end'
                    title='Search'
                    onClick={handleSearch}
                />

                </span>

            </div>
        </GrayCard>);
};

export default ProjectTableFilters;
