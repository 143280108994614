import Header from "typography/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useSelector } from "services";
import { fetchCustomerAll } from "services/customerAdmin";
import ReactJson from 'react-json-view'
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowsProp } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import UserTable from "admin/UserTable";
import OuterBox from "admin/OuterBox";
import StatCard from "admin/StatCard";

import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';



const Admin = () => {

    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.customerAdmin);
    let token = Cookies.get("propToken");

    const [counts, setCounts] = useState({})
    const [customersRetrieved, setCustomersRetrieved] = useState(false);


    const [rows, setRows] = useState(null);
    const [adminUsers, setAdminUsers] = useState([]);

    const [preLoadedUsers, setPreLoadedUsers] = useState([]);
    const [notActivatedUsers, setNotActivatedUsers] = useState([]);

    const [trial, setTrial] = useState([]);
    const [starter, setStarter] = useState([]);
    const [intermediate, setIntermediate] = useState([]);
    const [professional, setProfessional] = useState([]);
    const [nonPropsUsers, setNonPropsUsers] = useState([]);


    useEffect(() => {
        console.log("UE:[]")
        dispatch(fetchCustomerAll({token}));
    }, []);


    useEffect(() => {
       console.log("UE:[admin]" + users.state)

        if (users?.length > 0) {

            var cnt = {}

           setRows(users)
           setAdminUsers( users.filter((u) => u.role == 'admin'))

           var nonPropsUsers = users.filter((u) =>  u.role.trim() != 'admin' && !u.email.includes('propsmarts'))
           cnt['all'] = nonPropsUsers.length
           setNonPropsUsers(nonPropsUsers)

           var preLoaded = nonPropsUsers.filter((u) => u.type.trim() === 'pre')
           cnt['pre'] = preLoaded.length

           setPreLoadedUsers(nonPropsUsers.filter((u) => u.type.trim() === 'pre' &&  u.active == true))
           setNotActivatedUsers( nonPropsUsers.filter((u) => u.name == 'default').map((u) => {
                    var user = { ...u };
                    user.name = "";
                    user.companyName = "";
                    return user;
               })
           )

           var subscribedUsers = nonPropsUsers.filter((u) => u.type == 'subs')

           setTrial(subscribedUsers.filter((u) => u.subscriptionId == 1))
           setStarter(subscribedUsers.filter((u) => u.subscriptionId == 2))
           setIntermediate(subscribedUsers.filter((u) => u.subscriptionId == 3))
           setProfessional(subscribedUsers.filter((u) => u.subscriptionId == 4))

            cnt['trial'] = trial.length
            cnt['starter'] = starter.length
            cnt['intermediate'] = intermediate.length
            cnt['professional'] = professional.length

           setCustomersRetrieved(true)
           setCounts(cnt)
        }
    }, [users]);

     const columns: GridColDef[] = [
        // { field: 'id', headerName: 'id', width: 10 },
         { field: 'name', headerName: 'Name', width: 150},
         { field: 'email', headerName: 'Email',width: 250},
         { field: 'companyName', headerName: 'Company Name',width: 150},
         { field: 'registrationDate', headerName: 'Registration Date',width: 150},
         { field: 'lastAccessedAgeDays', headerName: 'Last access (days)', width: 150},
       //  { field: 'active', headerName: 'Activated',width: 150},
       //  { field: 'type', headerName: 'Type', width: 150},
        // { field: 'role', headerName: 'Role', width: 80},
        // { field: 'subscriptionId', headerName: 'Subs-id', width: 80},
         { field: 'expiryDate', headerName: 'License expires', width: 150},
        // { field: 'hadTrial', headerName: 'hadTrial', width: 150},
        // { field: 'updatedAt', headerName: 'updatedAt', width: 150},
         { field: 'maxProjectsPerMonth', headerName: 'Projects/Month', width: 150},
         { field: 'subsAgeDays', headerName: 'Subs age (days)', width: 150},
         { field: 'projectCount', headerName: 'Project count', width: 150},

     ];


    const plans: String[] = ["", "Trial", "Starter", "Intermediate", "Professional"]


  return (
    <>

      <div className='flex justify-between items-center pb-4'>
        <Header header='User Admin' />
      </div>


      <div className="mb-4"/>

      <OuterBox header="User counts" boarder='true'>
        <div className="flex justify-around mt-5 mb-5 ml-5 mr-5">
          <StatCard title="All" value={counts.all}/>
          <StatCard title="Pre-loaded" value={counts.pre}/>
          <StatCard title="Trial" value={counts.trial}/>
          <StatCard title="Starter" value={counts.starter}/>
          <StatCard title="Intermediate" value={counts.intermediate}/>
          <StatCard title="Professional" value={counts.professional}/>
        </div>
      </OuterBox>




              {
                 customersRetrieved && rows ? (
                     <>
                     <OuterBox boarder='true'>
                         <OuterBox header="Pre-loaded users">
                            <UserTable rows={notActivatedUsers} columns={columns} title={'Not activated'}/>
                            <UserTable rows={preLoadedUsers} columns={columns} title={'Activated'}/>
                         </OuterBox>

                         <OuterBox header="Subscribed users">
                            <UserTable rows={trial} columns={columns} title={'Free trial'}/>
                            <UserTable rows={starter} columns={columns} title={'Starter'}/>
                            <UserTable rows={intermediate} columns={columns} title={'Intermediate'}/>
                            <UserTable rows={professional} columns={columns} title={'Professional'}/>
                          </OuterBox>

                         <OuterBox header="Other">
                            <UserTable rows={adminUsers} columns={columns} title={'Admin users'}/>
                            <UserTable rows={nonPropsUsers} columns={columns} title={'All non-Propsmarts'}/>
                         </OuterBox>
                     </OuterBox>
                     </>
                 ) : (
                 <div>Loading.....</div>
                 )
             }
    </>
  );
};

export default Admin;


