export const calcIndicator = (energyClass) => {
  if (energyClass >= 1 && energyClass <= 20) {
    return { indicatorColor: "bg-energy-red", indicatorTop: 110 };
  } else if (energyClass > 20 && energyClass <= 38) {
    return { indicatorColor: "bg-energy-dark-orange", indicatorTop: 91 };
  } else if (energyClass > 38 && energyClass <= 54) {
    return { indicatorColor: "bg-energy-orange", indicatorTop: 72 };
  } else if (energyClass > 54 && energyClass <= 68) {
    return { indicatorColor: "bg-energy-yellow", indicatorTop: 53 };
  } else if (energyClass > 68 && energyClass <= 80) {
    return { indicatorColor: "bg-energy-lighter-green", indicatorTop: 33 };
  } else if (energyClass > 80 && energyClass <= 91) {
    return { indicatorColor: "bg-energy-light-green", indicatorTop: 14 };
  } else if (energyClass > 91 && energyClass <= 100) {
    return { indicatorColor: "bg-energy-green", indicatorTop: -2 };
  }
};
