import ColorPicker from "common/ColorPicker";
import CustomFileInput from "ui/CustomFileInput";
import CustomLogo from "ui/CustomLogo";
import CustomTextField from "ui/CustomTextField";

const AddProjectReportForm = ({
  formValues,
  handleInputChange,
  handleColorChange,
  handleImageChange,
  handleLogoChange,
}) => {
  return (
    <div className='flex flex-col gap-4'>
      <CustomTextField
        type='text'
        id='title'
        name='title'
        value={formValues.title}
        onChange={handleInputChange}
        label='Add Title ...'
        fullWidth
        fontSize='18'
      />
      <div className='flex gap-4'>
        <CustomTextField
          type='date'
          label='Date'
          InputLabelProps={{
            shrink: true,
          }}
          className='flex-1'
          id='date'
          name='date'
          value={formValues.date}
          onChange={handleInputChange}
        />
      </div>

      <ColorPicker handleColorChange={handleColorChange} />
    </div>
  );
};

export default AddProjectReportForm;
