import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const TopbarItem = (props) => {
  const { url, icon, text } = props;

  return (
    <div>
        <NavLink
                className="flex flex-col justify-center items-center gap-1"
                to={url}>
            <img className="pb-1.5" src={`${icon}`} alt={`${text}`} />
            <h1>{text}</h1>
        </NavLink>
    </div>
  );
};

export default TopbarItem;
