import { Outlet } from "react-router-dom";

import ProfileSidebar from "layout/ProfileSidebar";

const AccountLayout = (props) => {
  return (
    <div className="flex">
      <ProfileSidebar />
      <div className="flex-1 ml-96">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountLayout;
