import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";


const initialState = {
        status: "idle",
        error: "",
        users: [],
    }


export const fetchCustomerAll = createAsyncThunk<any, any, any>(
    "get/customer/all",
    async (params, thunkApi) => {
        const { token } = params;

        const response = await api.get(
            `customer/details`,

            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);



const customerAdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchCustomerAll.fulfilled, (state, action) => {
            console.log("fetchCustomerAll.fulfilled");
            state.status = "success";
            console.log("++++"+JSON.stringify(action.payload))
            state.users = action.payload
        })
        .addCase(fetchCustomerAll.pending, (state, action) => {
            console.log("fetchCustomerAll.pending");
        })
        .addCase(fetchCustomerAll.rejected, (state, action) => {
            console.log("fetchCustomerAll.failed");
            state.status = "failed";
        })
    },
});



export default customerAdminSlice;
