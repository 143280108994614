const EpcStatisticLine = (props) => {
  const { title, value, noMargin = false } = props;

  return (
    <h5 className={`font-bold text-base ${noMargin ? "" : "ml-[29px]"}`}>
      {title}:<span className="font-normal pl-1">{value}</span>
    </h5>
  );
};

export default EpcStatisticLine;
