import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Cookies from "js-cookie";

import api from "api/api";

const initialState = {
  status: "idle",
  error: null,
  userData: {},
  loginStatus: "idle",
  loginError: null,
  loginData: {},
  login2FAStatus: "idle",
  login2FAError: null,
  token: "",
  verifyStatus: "idle",
  verifyError: null,
  rememberMe: false,
};

export const register = createAsyncThunk(
  "customer/auth/register",
  async (data) => {
    try {
      const response = await api.post("customer/auth/register", data);

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return error.message;
      }
    }
  }
);

export const registerVerify = createAsyncThunk(
  "auth/registerVerify",
  async (data) => {
    try {
      const response = await api.get("customer/auth/register", {
        params: {
          token: data,
        },
      });

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return error.message;
      }
    }
  }
);

export const login = createAsyncThunk("customer/auth/login", async (data) => {
  try {
    const response = await api.post("customer/auth/login", data);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.message;
    }
  }
});

export const login2FA = createAsyncThunk(
  "customer/auth/login2FA",
  async (data) => {
    try {
      const response = await api.post("customer/auth/login/verify/otp", data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return error.message;
      }
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async (token) => {
    try {
      const response = api.get("customer/info", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return error.message;
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.token = "";
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setRememberMe(state, action) {
      state.rememberMe = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          state.status = "failed";
          state.error = action.payload.data;
        } else {
          state.status = "success";
          state.userData = action.payload.data;
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(login.pending, (state, action) => {
        state.loginStatus = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          state.loginStatus = "failed";
          state.loginError = action.payload.data;
        } else {
          state.loginStatus = "success";
          state.loginData = action.payload.data;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatus = "failed";
        state.loginError = action.error;
      })
      .addCase(login2FA.pending, (state, action) => {
        state.login2FAStatus = "loading";
      })
      .addCase(login2FA.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          state.login2FAStatus = "failed";
          state.login2FAError = action.payload.data;
        } else {
          state.login2FAStatus = "success";
          state.token = action.payload.data.token;
          Cookies.set("propToken", action.payload.data.token);
        }
      })
      .addCase(login2FA.rejected, (state, action) => {
        state.login2FAStatus = "failed";
        state.login2FAError = action.error;
      })
      .addCase(registerVerify.pending, (state, action) => {
        state.verifyStatus = "loading";
      })
      .addCase(registerVerify.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          state.verifyStatus = "failed";
          state.verifyError = action.payload.data;
        } else {
          state.verifyStatus = "success";
          state.token = action.payload.data.token;
          Cookies.set("propToken", action.payload.data.token);
        }
      })
      .addCase(registerVerify.rejected, (state, action) => {
        state.verifyStatus = "failed";
        state.verifyError = action.error;
      })
      .addCase(fetchUserData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          state.status = "failed";
          state.error = action.payload.data;
        } else {
          state.status = "success";
          //console.log(JSON.stringify(action.payload.data))
          state.userData = action.payload.data;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export default authSlice;

export const authActions = authSlice.actions;
