import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartHorizontalBars = ({ stats }) => {
  const statLabels = stats.map((stat) => stat.type);
  const statValues = stats.map((stat) => stat.count);
  const data = {
    labels: statLabels,
    datasets: [
      {
        label: "Crime Statistics (last 12 months)",
        data: statValues,
        backgroundColor: [
          "#50EBBE",
          "#A69FDA",
          "#50EBBE",
          "#9164DC",
          "#CD4354",
          "#A69FDA",
          "#FF4B4B",
          "#9164DC",
          "#CD4354",
          "#50EBBE",
          "#A69FDA",
          "#50EBBE",
          "#9164DC",
          "#CD4354",
        ],
      },
    ],
  };

  const options = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#000",
        },
        grid: {
          display: false,
        },
        border: {
          width: 1.5,
          color: "#000",
        },
      },
      y: {
        ticks: {
          color: "#000",
        },
        grid: {
          display: false,
        },
        border: {
          width: 1.5,
          color: "#000",
        },
      },
    },
    maintainAspectRatio: false,
    borderRadius: 5,
    categoryPercentage: 0.8,
    barPercentage: 0.7,
  };

  return (
    <div className="h-[300px]">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ChartHorizontalBars;
