import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CircularProgress } from "@mui/material";
import api from "api/api";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "ui/Button";
import UpgradeSubscriptionInfo from "./UpgradeSubscriptionInfo";

const CancelSubscription = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const selectedPlanId = +params.id;
  const token = Cookies.get("propToken");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(false);

  const cancelPlan = async () => {
    try {
      setIsLoading(true);
      const response = await api.delete("customer/subscribeToPlan", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      if (response.status === 200) setCancelSuccess(true);
    } catch (error) {
      setIsLoading(false);

      if (error.response) {
        setError(error.response);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <div className="my-12 mx-7">
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-3xl font-bold">Cancel subscription</h1>
        <Button
          title="Back"
          className="text-sm px-4 py-3 rounded-xl bg-green"
          icon={
            <ArrowBackIosIcon
              style={{ fontSize: "13px", marginRight: "10px" }}
            />
          }
          onClick={() => navigate("/dashboard/account/subscription")}
        />
      </div>
      {cancelSuccess ? (
        <UpgradeSubscriptionInfo
          title="Subscription successfully cancelled!"
          body={false}
        />
      ) : (
        <>
          <p className="text-xl font-bold mb-10 text-[#7d7c7c]">
            We're sorry to see you go, you're always welcome back. <br />
            Finish your cancellation or change your subscription plan below.
          </p>
          <div className="flex gap-8">
            {isLoading ? (
              <div className="flex items-center justify-center w-full">
                <CircularProgress />
              </div>
            ) : (
              <>
                <Button
                  title="Change plan"
                  className="bg-green py-2.5 px-9 text-2xl text-background-blue rounded-xl"
                  onClick={() =>
                    navigate(
                      `/dashboard/account/subscription/upgrade/${selectedPlanId}`
                    )
                  }
                />
                <Button
                  title="Cancel plan"
                  className="text-2xl rounded-xl text-red border border-red py-2.5 px-9"
                  onClick={cancelPlan}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CancelSubscription;
