import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "services";

import WhiteCard from "common/WhiteCard";
import SignupForm from "forms/SignupForm";
import Toast from "ui/Toast";
import Modal from "layout/Modal";

import { isPasswordValid } from "helpers/password-check";
import { privacyLink, saaSLink } from "helpers/links-information";
import { authActions, register } from "services/auth";
import Cookies from "js-cookie";

const Signup = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  const userData = useSelector((state) => state.auth.userData);
  const [passwordError, setPasswordError] = useState(false);
  const [openSaas, setOpenSaas] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const openSaaSModal = () => {
    setOpenSaas(true);
  };

  const openPrivacyModal = () => {
    setOpenPrivacy(true);
  };

  const [formValues, setFormValues] = useState({
    name: "",
    company: "",
    email: "",
    password: "",
    agree: "",
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    if (name === "agree" && value === "agree") {
      setBtnDisabled(false);
    }

    if (name === "password") setPasswordError(false);

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const passwordValid = isPasswordValid(formValues.password);
    if (!passwordValid) {
      setPasswordError(true);
      return;
    }
    Cookies.remove("propToken");
    dispatch(authActions.logout());

    const data = {
      name: formValues.name,
      password: formValues.password,
      email: formValues.email,
      companyName: formValues.company,
    };

    const res = await dispatch(register(data));

    if (res.payload.status === 200) navigate("verify");
  };

  return (
    <>
      <WhiteCard>
        <SignupForm
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          formValues={formValues}
          disabled={btnDisabled}
          status={status}
          error={error}
          userData={userData}
          passwordError={passwordError}
          openSaaSModal={openSaaSModal}
          openPrivacyModal={openPrivacyModal}
        />

        <p className="text-center mt-2 text-sm">
          Already have an account?{" "}
          <Link to="/login" className="font-bold">
            Sign in
          </Link>
        </p>
      </WhiteCard>
      <Toast
        severity="error"
        message={error || passwordError}
        openToast={status === "failed"}
      />

      <Modal
        onClose={() => {
          setOpenSaas(false);
        }}
        open={openSaas}
        content={saaSLink}
      />

      <Modal
        onClose={() => {
          setOpenPrivacy(false);
        }}
        open={openPrivacy}
        content={privacyLink}
        className="whitespace-pre-line"
      />
    </>
  );
};

export default Signup;
