import {useState} from "react";
import {saveAs} from "file-saver";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GrayCard from "common/GrayCard";
import CustomTableCell from "ui/CustomTableCell";
import {moneyFormat, formatNumber} from "helpers/money"


import {
    Skeleton,
    Table,
    TableBody,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import numeral from "numeral";
import {useSelector} from "services";
import Button from "../ui/Button";
import Papa from "papaparse";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import IconButton from "@mui/material/IconButton";
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import {CHLabel, CNLabel, TCell} from "common/EditableTableStyle";



const ProjectTable = ({status, data, handleDeleteRow, handleMapZoom, showPagination = true}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const onPageChange = (evt, newPage) => {
        setPage(newPage);
    };

    const handleExportCsv = () => {
        const filtered = data.flatMap((d) => {return {
                                saleDate: d.saleDate,
                                address: d.address,
                                price: d.price,
                                distance:d.distance,
                                type: d.type,
                                bedrooms: d.bedrooms,
                                tenure: d.tenure,
                                sqFt: d.sqFt,
                                pricePerSqFt: d.pricePerSqFt,
        }});

        const csv = Papa.unparse(filtered);
        const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
        saveAs(blob, "sales_study_data.csv");
    };

    const onRowsPerPageChange = (evt) => {
        setRowsPerPage(+evt.target.value);
        setPage(0);
    };

    return (
        <GrayCard>
            <Table sx={{minWidth: 700, border: "none", borderCollapse: "separate"}} aria-label='customized table'>
                <TableHead>
                    <TableRow>
                        <TCell><CHLabel >Address</CHLabel></TCell>
                        <TCell><CHLabel>Date Sold</CHLabel></TCell>
                        <TCell><CHLabel>Distance(Miles)</CHLabel></TCell>
                        <TCell><CHLabel>Bedrooms</CHLabel></TCell>
                        <TCell><CHLabel>Property Type</CHLabel></TCell>
                        <TCell><CHLabel>Area(ft²)</CHLabel></TCell>
                        <TCell><CHLabel>Price</CHLabel></TCell>
                        <TCell><CHLabel>Price/ft²</CHLabel></TCell>
                        <TCell><CHLabel>Tenure</CHLabel></TCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {status === "idle" || status === "loading" ? (
                        <TableRow>
                            <TCell colSpan="7">
                                <Skeleton height={50} style={{width: "100%"}}/>
                            </TCell>
                        </TableRow>
                    ) : (
                        <>
                            {data && data.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TCell><CNLabel>
                                               {capitalizePostcode(
                                                    capitalizeFirstLetter(row.address.split(",",2).join(" ")+", "+row.address.split(" ").slice(-2).join(" ") )
                                                )}
                                            </CNLabel></TCell>
                                            <TCell><CNLabel>
                                                    { row.saleDate.split("-")[2] +"-"+ row.saleDate.split("-")[1] +"-"+ row.saleDate.split("-")[0]}
                                            </CNLabel></TCell>
                                            <TCell><CNLabel>
                                                {row.distance}
                                           </CNLabel></TCell>
                                            <TCell><CNLabel>
                                                {(row.bedrooms == 0) ? '' : row.bedrooms}
                                            </CNLabel></TCell>
                                            <TCell><CNLabel>
                                                {row.type}
                                            </CNLabel></TCell>
                                            <TCell><CNLabel>{formatNumber(row.sqft)}</CNLabel></TCell>
                                            <TCell><CNLabel>
                                             {moneyFormat(row.price)}
                                           </CNLabel></TCell>
                                           <TCell><CNLabel>
                                            {moneyFormat(row.pricePerSqFt)}
                                            </CNLabel></TCell>
                                            <TCell><CNLabel>
                                                {row.tenure}
                                           </CNLabel></TCell>
                                            <TCell><CNLabel>
                                             <div className="flex gap-6 flex-row-reverse">
                                                 <IconButton edge="end" onClick={(e) => {handleDeleteRow(e, index)}}> <DeleteOutlineTwoToneIcon/></IconButton>
                                                 <IconButton edge="end" onClick={(e) => {handleMapZoom(e, row.lat, row.lng)}}> <SearchTwoToneIcon/></IconButton>
                                                 </div>
                                           </CNLabel></TCell>
                                        </TableRow>
                                    ))}
                        </>
                    )}
                </TableBody>
            </Table>

            <div className="flex flex-row-reverse">
                <IconButton
                    onClick={ handleExportCsv}> <FileDownloadIcon/>
                </IconButton>
            </div>

        </GrayCard>
    );
};

export default ProjectTable;

function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
        return str;
    }

    const words = str.split(" ");

    const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
            return word;
        }
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
    });

    return capitalizedWords.join(" ");
}

const capitalizePostcode = (str) => {
    const postcodeIndex = str.lastIndexOf(",");
    if (postcodeIndex !== -1) {
        const restOfString = str.slice(0, postcodeIndex);
        const postcode = str.slice(postcodeIndex + 1);
        const capitalizedPostcode = postcode.toUpperCase();
        const result = `${restOfString},${capitalizedPostcode}`;
        return result;
    } else {
        return str;
    }
};

const convertDate = (data) => {
    const date = new Date(data);

    return date.toLocaleDateString("en-GB");
};
