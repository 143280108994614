export const getYearOptions = (data, identifier) => {
  // Extract the lodgementDate values
  const lodgementDates = Object.values(data).map((item) => item.lodgementDate);

  // Convert lodgementDate strings to Date objects
  const dateObjects = lodgementDates.map((date) => new Date(date));

  // Find the minimum and maximum dates
  const minDate = new Date(Math.min(...dateObjects));
  const maxDate = new Date(Math.max(...dateObjects));

  // Generate an array of objects with option and value properties
  const years = [];
  identifier === "from"
    ? years.push({ option: "From Year", value: "From Year" })
    : years.push({ option: "To Year", value: "To Year" });

  for (
    let year = maxDate.getFullYear();
    year >= minDate.getFullYear();
    year--
  ) {
    years.push({ option: year, value: year });
  }
  return years;
};

export const getTypeOptions = (data) => {
  // Extract the builtFrom values
  const builtFromValues = Object.values(data).map((item) => item.builtFrom);

  // Remove duplicates from the array
  const uniqueBuiltFromValues = [...new Set(builtFromValues)];

  // Add the 'All' option as the first item in the array
  uniqueBuiltFromValues.unshift("All");

  // Generate an array of objects with option and value properties
  const resultArray = uniqueBuiltFromValues.map((value) => ({
    option: value,
    value,
  }));

  return resultArray;
};

export const filterData = (data, fromYear, toYear, builtFrom, rooms) => {
  let filtered = data;

  if (fromYear !== "From Year") {
    filtered = filtered.filter((item) => {
      const year = new Date(item.lodgementDate).getFullYear();
      return year >= fromYear;
    });
  }

  if (toYear !== "To Year") {
    filtered = filtered.filter((item) => {
      const year = new Date(item.lodgementDate).getFullYear();
      return year <= toYear;
    });
  }

  if (rooms !== "All") {
    filtered = filtered.filter((item) => +item.numberHabitableRooms === +rooms);
  }

  if (builtFrom !== "All") {
    filtered = filtered.filter((item) => item.builtFrom === builtFrom);
  }

  return filtered;
};
