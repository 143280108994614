import {useLocation, useParams} from "react-router-dom";
import ReactJson from 'react-json-view'
import GrayCard from "common/GrayCard";

const DebugJson = ({data}) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

return (
    <>
        {
            searchParams.get('debug') ? (
            <div className='mb-10 mt-16'>
            <GrayCard>
            <div>
                {
                    data?.map((row, index) => (
                        <div key={index} className='mb-10 mt-6'><ReactJson collapsed={true} name={row[0]} src={row[1]}/><hr/></div>
                    ))
                }
            </div>
            </GrayCard>
            </div>
            ) : ( <div></div> )
        }
    </>
);
};

export default DebugJson