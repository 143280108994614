import {useDispatch} from "react-redux";
import Cookies from "js-cookie";
import {useSelector} from "services";
import ReactJson from 'react-json-view'
import {useEffect, useState} from "react";
import {fetchProjectSales} from "services/project";
import {createPredictedValue} from "services/PredictedValues"
import {fetchPredictedValues} from "services/PredictedValues";
import {fetchPropertyTemplates} from "services/property";
import {fetchFeasibility} from "services/feasibility";
import PredictedValueWidget from "widgets/Components/PredictedValueWidget";
import DebugJson from "common/DebugJson";

//export const propertyTemplateDefaultNames = [
//    { option: "All", value: "all" },
//];

const PSdebug = (props) => {

    const {templates, propertyTemplateOptions} = useSelector(
        (state) => state.property
    );

    const {auth} = useSelector(
        (state) => state
    );

    const {projects} = useSelector(
        (state) => state
    );

    const { feasibility } = useSelector(
        (state) => state
    );

    const { values } = useSelector(
        (state) => state.predictedValues
    );

    const {projectSalesData, salesStatus, projectData} = useSelector(
        (state) => state.projects
    );

//    const dispatch = useDispatch();
//    const token = Cookies.get("propToken");
//
//    useEffect(() => {
//        console.log("UE:[templates]")
//        //console.log(JSON.stringify(property));
//
//        let tt = templates?.map((propertyTemplate)=>{
//            return {option : propertyTemplate.name, value: propertyTemplate.type}
//        })
//
//        let cc = propertyTemplateDefaultNames.concat(tt);
//        console.log("cc " + JSON.stringify(cc))
//
//    }, [templates]);
//
//    useEffect(() => {
//        console.log("UE:[projectData.id]")
//    }, [projectData.id]);


//        useEffect(() => {
//            console.log("useEffect Debug ");
//            if (projectData.id != null) {
//                dispatch(fetchPropertyTemplates({token}));
//                dispatch(fetchFeasibility({projectId: projectData.id, token}));
//                dispatch(fetchPredictedValues({projectId: projectData.id, token}))
//             }
//        }, [projectData.id]);

    return (

        <>
            <PredictedValueWidget/>
            <h2>auth</h2>
            <div><ReactJson src={auth}/><hr/></div>

            <h2>Template value</h2>
            <div><ReactJson src={values}/><hr/></div>

            <h2>templates</h2>
            <div><ReactJson src={templates}/><hr/></div>

            <h2>feasibility</h2>
            <div><ReactJson src={feasibility}/><hr/></div>

            <h2>projectSalesData</h2>
            <div><ReactJson src={projectSalesData}/><hr/></div>

             <h2>template options</h2>
            <div><ReactJson src={propertyTemplateOptions}/><hr/></div>



             <h2>projects</h2>
            <div><ReactJson src={projects}/><hr/></div>
        </>
    )
}

export default PSdebug;
