import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";

const SearchBar = (props) => {
  const { className, onChange } = props;

  return (
    <>
      <TextField
        autoComplete='off'
        name='projectSearch'
        defaultValue=''
        autoFocus
        label={
          <label className='text-xl text-total-black font-normal'>
            <SearchIcon className='text-green' style={{ fontSize: 35 }} />{" "}
            Search by Project title
          </label>
        }
        onChange={onChange}
        className={`rounded-2xl ${className}`}
        style={{ minWidth: "340px" }}
        sx={{
          "& fieldset": { border: "none" },
        }}
      />
    </>
  );
};

export default SearchBar;
