import { Box } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const StyledEditor = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  background: "white",
  height: 300,
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
  "& .ql-container.ql-bubble.ql-disabled": { padding: 0 },
  "& .ql-container.ql-snow": {
    borderColor: "transparent",
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  "& .ql-editor strong": {
    fontWeight: "700!important",
  },
  "& .ql-editor": {
    // padding: 0,
    minHeight: 513,
    maxHeight: 513,
    "&.ql-blank::before": {
      fontStyle: "normal",
      color: theme.palette.text.disabled,
    },
    "& pre.ql-syntax": {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}));
