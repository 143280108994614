import MapProjectPolygons from "common/MapProjectPolygons";

const Map = () => {
  return (
    <>
      <h1 className=' text-3xl leading-6 mb-10'>Development Map</h1>
      <MapProjectPolygons
        mapHeight='640px'
        className='rounded-[20px]'
        zoom={19}
        isClickable={true}
        totalPolygons={0}
        polygons={undefined}
      />
    </>
  );
};
export default Map;
