import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "api/api";

const initialState = {
  status: "idle",
  error: null,
  paymentData: {},
  selectedPlanId: -1,
};

export const subscribe = createAsyncThunk(
  "customer/subscribe/billing",
  async (data) => {
    const { values, token } = data;
    try {
      const response = await api.post("customer/subscribeToPlan", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        return error.message;
      }
    }
  }
);


const subscribeSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    selectPlan(state, action) {
      state.selectedPlanId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(subscribe.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        if (action.payload.status !== 201) {
          state.status = "failed";
          state.error = action.payload.data;
        } else {
          state.status = "success";
          state.paymentData = action.payload.data;
        }
      })
      .addCase(subscribe.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export default subscribeSlice;

export const subscribeActions = subscribeSlice.actions;
