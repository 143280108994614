import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PrintTable from "./PrintTable";

import numeral from "numeral";


const PropertyTemplateWidget = () => {

  const { templates } = useSelector((state) => state.property);

  return (
    <>
        <PrintTable
            title = 'Property Templates'
            headers = {['Template name', 'Property type', 'Bedrooms', 'Tenure', 'Parking facility', 'Area (ft²)']}
            keys = {['templateName', 'propertyType', 'bedrooms', 'tenure', 'parkingFacility', 'area']}
            values = {templates}
        />
    </>
  );
};

export default PropertyTemplateWidget;

