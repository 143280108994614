import { TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTableCell = styled(TableCell)(({ theme, fontWeight = 400 }) => ({
  "&.MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: `${fontWeight}`,
  },
}));

export default CustomTableCell;
