import { useEffect } from "react";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {fetchUserData} from "services/auth";

const FirstPage = (props) => {

  const navigate = useNavigate();
  const token = Cookies.get("propToken");
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("UE:[]")
    if (token) {
      dispatch(fetchUserData({token}));
      navigate("/dashboard/projects");
    } else {
      navigate("/login");
    }
  }, []);

  return null;
};

export default FirstPage;
