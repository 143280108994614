import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import background from "styles/img/building-block-background-1.svg";
// import test from "styles/img/test.jpg";
// import test1 from "styles/img/test-1.jpg";
// import test2 from "styles/img/test-2.jpg";
import buildingBlocks from "../../styles/img/building-blocks.png";
import propLogo from "../../styles/img/prop_logo.svg";
const LoginLayout = (props) => {
  const location = useLocation();
  const { pathname } = location;
  // console.log(pathname);

  // let classname = "";
  // if (pathname.startsWith("/signup")) {
  //   classname =
  //     "bg-[url('styles/img/background-svg.svg')] bg-cover bg-left-top h-screen relative";
  // } else {
  //   classname =
  //     "bg-[url('styles/img/building-block-background-1.svg')] bg-cover bg-no-repeat h-screen relative";
  // }

  const containerStyle = {
    backgroundColor: "rgba(20, 24, 49, 1)",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
  };
  const navigate = useNavigate();
  return (
    <Box sx={containerStyle}>
        <img
          onClick={() => navigate("/dashboard")}
          src={propLogo}
          alt='logo'
          className='absolute top-9 left-9 w-[30%] cursor-pointer z-20'
        />
      <img
        src={buildingBlocks}
        alt='back'
        className='h-[100vh] absolute right-[-333px]'
      />
      <Outlet />
    </Box>
  );
};

export default LoginLayout;
