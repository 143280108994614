import Button from "ui/Button";
import CloseButton from "ui/CloseButton";
import GrayCard from "./GrayCard";
import ProjectPlanningInfo from "./ProjectPlanningInfo";

import { Divider } from "@mui/material";
import { useDispatch } from "services";

const MapProjectPopupSm = ({ handleClose, className, data }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`absolute bg-total-white z-50 top-2 right-2 rounded-3xl w-[472px] ${className}`}
    >
      <CloseButton onClick={handleClose} />
      <h1 className='text-lg mb-0.5'>{data.title ?? "N/A"}</h1>
      <h2 className='text-xs pb-0.5'>{data.address ?? "N/A"}</h2>
      <GrayCard className='my-2 !p-3'>
        <h1 className='text-xl font-bold mb-1'>Summary</h1>
        <Divider />
        <div className='flex mt-1 justify-between'>
          <div className='flex flex-col gap-4'>
            <ProjectPlanningInfo
              title='Reference Breeam'
              value={data?.reference ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='Planning Stage'
              value={data?.planningStage ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='Contact Stage'
              value={data?.contactStage ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='Project Size'
              value={data?.projectSize ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='Start Date'
              value={data.startDate ?? "N/A"}
            />
          </div>

          <div className='flex flex-col gap-4'>
            <ProjectPlanningInfo
              title='Funding'
              value={data.funding ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='Development Type'
              value={data.developmentType ?? "N/A"}
            />
            <ProjectPlanningInfo title='Contract Type' value='N/A' />
            <ProjectPlanningInfo
              title='Project Status'
              value={data.projectStatus ?? "N/A"}
            />
            <ProjectPlanningInfo
              title='End Date'
              value={data.endDate ?? "N/A"}
            />
          </div>
        </div>
      </GrayCard>
      <div className='flex justify-end mt-0.5'>
        <Button
          onClick={() => window.open(data?.url, "_blank")}
          type='button'
          title='View local authority planning'
          className='bg-green text-background-blue rounded-2xl text-sm pt-1 pb-1 px-4 '
        />
      </div>
    </div>
  );
};

export default MapProjectPopupSm;
