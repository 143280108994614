import Button from "ui/Button";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import { plans } from "helpers/pricing-plans";
import PricingPlanCard from "common/PricingPlanCard";
import Grid from '@mui/material/Grid';


const SubscribedPlanDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const selectedPlanId = +params.id;
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);

  const upgradeSubscription = () => {
    navigate(`/dashboard/account/subscription/upgrade/${selectedPlanId}`);
  };

  return (
    <div className="mt-12 mr-10 ml-8">
      <div className="flex justify-between mb-6 items-center">

        <h1 className="text-3xl font-bold">Your Plan</h1>
        <Button
          title="Back"
          className="text-sm px-4 py-3 rounded-xl bg-green"
          icon={
            <ArrowBackIosIcon
              style={{ fontSize: "13px", marginRight: "10px" }}
            />
          }
          onClick={() => navigate("/dashboard/account/subscription")}
        />
      </div>

    <div className="grid grid-cols-3 gap-6 mb-10">
      <PricingPlanCard
        key={selectedPlanId}
        plan={selectedPlan}
        className="flex-1"
        planBackgroundColor="#f1f1f1"
      />
    </div>


      {selectedPlanId !== 4 && (
        <Button
          title="Upgrade plan"
          className="bg-green rounded-xl text-xl py-4 px-24"
          onClick={upgradeSubscription}
        />

      )}
    </div>
  );
};

export default SubscribedPlanDetails;
