import { FC } from "react";
import { useSelector } from "services";
import { selectProjectData } from "services/project";

export interface StayCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  title: string;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  title,
}) => {
  const {
    uprns,
    ownership,
    class: classData,
    predictedUseClass,
    plot_size,
    polygons,
    estate_interest,
  } = data;
  const projectData = useSelector(selectProjectData);

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "space-y-1"}>
        <div className={size === "default" ? "space-y-2" : "space-y-1"}>
          <div className="flex items-center space-x-2">
            <h2
              className={`font-semibold capitalize text-neutral-900 ${
                size === "default" ? "text-base" : "text-base"
              }`}
            >
              <span className="line-clamp-1">
                {projectData?.map?.titleResponse?.title_number}
              </span>
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            <h5 className={`capitalize`}>
              <span className="line-clamp-1 text-gray-200">{classData}</span>
            </h5>
          </div>
          <div className="flex items-center space-x-2">
            <h5 className={`capitalize`}>
              <span className="line-clamp-1 text-gray-200">
                {predictedUseClass}
              </span>
            </h5>
          </div>

          <div className="space-y-2">
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {plot_size} Plot Size
            </span>

            <div className="space-y-2">
              <span className=" flex text-xs text-neutral-500 dark:text-neutral-400">
                {polygons[0].polygon_size} Polygon Size
              </span>
            </div>
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-1.5">
              {size === "default" && (
                <svg
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
              <span className="">{estate_interest}</span>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="flex items-center justify-center px-2.5 py-1.5 border-2 border-secondary-500 rounded-lg leading-none text-sm font-medium text-secondary-500">
            {`${ownership.type}`}
          </span>
        </div>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};

export default StayCard;
