import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import GrayCard from "common/GrayCard";
import {Table, TableBody, TableHead, TableRow, TableCell, TableContainer} from "@mui/material";


const PrintTable = ({title, headers, keys, values, margin=5}) => {

    const ThCell = styled(TableCell)({
        fontSize: '0.75rem',
        fontWeight: 'bold',
       padding: '4px 16px 4px 16px',
    });

    const TbCell = styled(TableCell)({
        fontSize: '0.75rem',
        padding: '4px 16px 4px 16px',
    });


  return (
    <>
    <Box sx={{ m: margin }}>
      <>{ title ? (<h1 className='text-3xl'>{title}</h1>) : (<div></div>) }</>
          <TableContainer>
                <Table sx={{ width: 'auto' }}>
                  <TableHead>
                      <TableRow>
                        { headers?.map((h,i) => ( <ThCell key={i}>{h}</ThCell> ))}
                      </TableRow>
                  </TableHead>
                  <TableBody >
                      {
                          values?.map(
                              (v, i) => (
                                  <TableRow key={i}>
                                      { keys?.map((k,c) => ( <TbCell key={c}> {v?.[k]} </TbCell> ))}
                                  </TableRow>
                              )
                          )}
                  </TableBody>
              </Table>
          </TableContainer>
      </Box>
    </>
  );
};

export default PrintTable;
