import Button from "ui/Button";
import CustomTextField from "ui/CustomTextField";

const PaymentMethodForm = ({ handleSubmit, formValues, handleInputChange }) => {
  return (
    <div>
      <h2 className="font-bold text-xl mb-5">Payment method</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8 mb-10">
          <CustomTextField
            label="Account holder name *"
            type="string"
            name="accountHolderName"
            backgroundcolor="#F0F0F0"
            value={formValues.accountHolderName}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#000" },
            }}
            className="w-3/5"
          />
          <div className="flex gap-7">
            <CustomTextField
              label="Account number *"
              type="number"
              name="accountNumber"
              backgroundcolor="#F0F0F0"
              fullWidth
              value={formValues.accountNumber}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#000" },
              }}
            />
            <CustomTextField
              label="Sort code *"
              type="text"
              name="sortCode"
              backgroundcolor="#F0F0F0"
              fullWidth
              value={formValues.sortCode}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#000" },
              }}
              inputProps={{
                maxLength: 8, // Limit the input length to 8 characters (including dashes)
              }}
            />
          </div>
        </div>
        <Button
          title="Confirm"
          className="bg-green text-2xl py-3 px-10 rounded-xl"
        />
      </form>
    </div>
  );
};

export default PaymentMethodForm;
