import { Divider, Skeleton } from "@mui/material";
import GrayCard from "./GrayCard";

const StatCard = (props) => {
  const { title, value, salesStatus } = props;

  return (
    <GrayCard className='flex-grow'>
      <h1 className='text-xl mb-2.5'>{title}</h1>
      <Divider />
      {salesStatus === "idle" || salesStatus === "loading" ? (
        < Skeleton className='mt-2.5' variant="rectangular" width={"100%"} height={30} />
      ) :(
        <h2 className='text-xl font-bold mt-2.5'>{value}</h2>
      )
      }
    </GrayCard>
  );
};

export default StatCard;
