import { useState } from "react";
import { Link } from "react-router-dom";

import WhiteCard from "common/WhiteCard";
import ForgotPasswordForm from "forms/ForgotPasswordForm";

import { CircularProgress } from "@mui/material";
import api from "api/api";
import emailImg from "styles/img/email.svg";
import Toast from "ui/Toast";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      await api.post(`customer/auth/reset-password/send-email?email=${email}`);
      setIsLoading(false);
      setEmailSent(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setError(error.response);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <>
      {emailSent ? (
        <WhiteCard>
          <h2 className='text-xl'>We have sent a password reset link to {email}</h2>
          <div className='my-6'>
            <img src={emailImg} alt='email' />
          </div>
          <p className='text-sm mb-5'>
            Didn't receive the email?{" "}
            <span onClick={handleSubmit} className='font-bold cursor-pointer'>
              Click to resend
            </span>
          </p>
          {isLoading ? (
            <div className='flex items-center justify-center'>
              <CircularProgress />
            </div>
          ) : (
            ""
          )}
        </WhiteCard>
      ) : (
        <WhiteCard>
          <h2 className='text-m text-center mb-5'>
            Enter your email address and we'll send you a link to reset your
            password
          </h2>
          <ForgotPasswordForm
            email={email}
            handleEmailChange={handleEmailChange}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <Link
            className='mt-5 mb-5 text-sm font-bold'
            to='..'
            relative='path'
          >
            Back to login
          </Link>
        </WhiteCard>
      )}
      <Toast
        severity='error'
        message={
          typeof error === "string"
            ? error
            : "Something went wrong. Please try again"
        }
        openToast={error}
      />
    </>
  );
};

export default ForgotPassword;
