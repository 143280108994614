import GrayCard from "./GrayCard";
import { Divider } from "@mui/material";

const GrayInfoCard = (props) => {
  const {
    title,
    content,
    className,
    contentBig = false,
    contentCenter = false,
    color = undefined,
  } = props;

  return (
    <GrayCard className={`${className}`}>
      <h1 className="text-xl mb-2">{title}</h1>
      <Divider />
      <h2
        className={`mt-2 font-bold ${contentBig ? "text-xl" : "text-sm"} ${
          contentCenter ? "text-center" : ""
        } ${color === "green" ? "text-energy-green" : "inherit"}`}
      >
        {content}
      </h2>
    </GrayCard>
  );
};

export default GrayInfoCard;
