import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartPie = ({ stats }) => {
  const data = {
    labels: Object.keys(stats),
    datasets: [
      {
        label: "Politics",
        data: Object.values(stats),
        backgroundColor: [
          "#FF4B4B",
          "#9164DC",
          "#50EBBE",
          "#A69FDA",
          "#CD4354",
        ],
        borderColor: ["#FF4B4B", "#9164DC", "#50EBBE", "#A69FDA", "#CD4354"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 15,
          boxWidth: 30,
          generateLabels: function (chart) {
            const { data } = chart;

            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function (label, i) {
                const dataset = data.datasets[0];
                const backgroundColor = dataset.backgroundColor[i];
                const borderColor = dataset.borderColor[i];

                return {
                  text: label,
                  fillStyle: backgroundColor,
                  strokeStyle: borderColor,
                  lineWidth: 2,
                  borderRadius: 5,
                  hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0,
                };
              });
            }

            return [];
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="h-[170px]">
      <Pie data={data} options={options} />
    </div>
  );
};

export default ChartPie;
