import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/api";


const initialState = {
        status: "idle",
        error: "",
        sales: [],
    }


export const fetchSales = createAsyncThunk<any, any, any>(
    "get/report-generation/projects/sales/postcode",
    async (params, thunkApi) => {
        const { token, postcode, radius, saleAge, bedrooms, propertyType } = params;
        const response = await api.get(
            "report-generation/projects/sales/postcode",
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    postcode,
                    radius,
                    saleAge,
                    bedrooms,
                    propertyType
                },
            }
        );
        if (response.status >= 400) {
            return thunkApi.rejectWithValue(response.data);
        }
        return thunkApi.fulfillWithValue(response.data);
    }
);


const salesSlice = createSlice({
    name: "sales",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchSales.fulfilled, (state, action) => {
            console.log("fetchSales.fulfilled");
            state.status = "success";
            console.log("++++"+JSON.stringify(action.payload))
            state.sales = action.payload
        })
        .addCase(fetchSales.pending, (state, action) => {
            console.log("fetchSales.pending");
            state.status = "pending";
        })
        .addCase(fetchSales.rejected, (state, action) => {
            console.log("fetchSales.failed");
            state.status = "failed";
        })
    },
});


export default salesSlice;
