import { useState } from "react";
import Button from "ui/Button";
import Cookies from "js-cookie";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "services";
import WhiteCard from "common/WhiteCard";

import { postCustomerSubscribeActivate } from "services/CustomerSubscribeService";

const Activate = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("propToken");

  const handleSubmit = async (evt) => {
    console.log("Activate submit")
    evt.preventDefault();
    await dispatch(postCustomerSubscribeActivate({id:1, token}));
    navigate("/dashboard");
    // else todo navigate error
  };

  return (
    <>
      <WhiteCard>
        <h2 className="font-bold text-2xl mb-3">Sign-up complete</h2>
        <Button
          title="Continue to application"
          type="submit"
          className="bg-green py-2 w-full rounded-[1.5rem] mt-3 text-2xl"
          onClick={handleSubmit}
        />
      </WhiteCard>
    </>
  );
};

export default Activate;
