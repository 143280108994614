const GrayCard = (props) => {
  return (
    <div
      className={`bg-background-gray rounded-[20px] pt-7 pr-8 pb-6 pl-6 ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default GrayCard;
