import {useSelector} from "react-redux";
import { moneyFormat, formatNumber } from "helpers/money"
import StatCard from "common/StatCard";

import "styles/print.css";


const FeasibilitySummaryWidget = () => {

    const {section, custom, contingency, summary } = useSelector(
        (state) => state.feasibility
    );

    return (
        <>
               <div className="grid grid-cols-4 gap-4">
                      <StatCard
                          title="GDV"
                          value={moneyFormat(summary?.totalGdv)}
                      />
                      <StatCard
                          title="NDV"
                          value={moneyFormat(summary?.totalAdjustedNdv)}
                      />
                      <StatCard
                          title="Project Cost"
                          value={moneyFormat(summary?.totalCost)}
                      />
                      <StatCard
                          title="Cost of Sale"
                          value={moneyFormat(summary?.costOfSale)}
                      />
                      <StatCard
                          title="Profit"
                          value={moneyFormat(summary?.profit)}
                      />
                      <StatCard
                          title="Profit on GDV"
                          value={formatNumber(summary?.profitOnGDV)+'%'}
                      />
                      <StatCard
                          title="Profit on NDV"
                          value={formatNumber(summary?.profitOnNet)+'%'}
                      />
                      <StatCard
                          title="Profit on Cost"
                          value={formatNumber(summary?.profitOnCost)+'%'}
                      />
               </div>
        </>
    )

}

export default FeasibilitySummaryWidget;
