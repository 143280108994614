import Button from "ui/Button";
import CustomTextField from "ui/CustomTextField";

import { CircularProgress } from "@mui/material";

const Login2FAForm = ({
  handleSubmit,
  code,
  handleCodeChange,
  status,
  error,
}) => {
  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
      <CustomTextField
        label="Code"
        type="number"
        name="code"
        backgroundcolor="#F0F0F0"
        fullWidth
        value={code}
        onChange={handleCodeChange}
        fontSize="20"
        borderradius="10"
        InputLabelProps={{
          style: { color: "#A9A9A9" },
        }}
      />

      {status === "loading" ? (
        <div className="flex justify-center mt-8">
          <CircularProgress style={{ color: "#141831" }} />
        </div>
      ) : (
        <Button
          title="Continue"
          className="bg-green py-3 text-2xl rounded-xl"
          type="submit"
        />
      )}
    </form>
  );
};

export default Login2FAForm;
