import { CircularProgress, Radio, Divider } from "@mui/material";
import { formatDate } from "helpers/date-format";
import Button from "ui/Button";
import CustomRadioInput from "ui/CustomRadioInput";
import CustomTextField from "ui/CustomTextField";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const PricingPlansBillingForm = ({
  handleSubmit,
  formValues,
  handleInputChange,
  btnDisabled,
  status,
  error,
  postCodeError,
  showRadio = true,
  freeTrial = false,
  backLink = '..'
}) => {
  const today = formatDate(new Date());

  return (
    <div>
      <form onSubmit={handleSubmit}>

       <div className="flex flex-col gap-2 mt-6 mb-6">
            <div className="flex flex-row gap-3.5 mb-2">
                  <CustomTextField
                    label="First name"
                    type="string"
                    name="firstName"
                    backgroundcolor="#F0F0F0"
                    style={{ width: "70%" }}
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    fontSize="20"
                    borderradius="10"
                    InputLabelProps={{
                      style: { color: "#A9A9A9" },
                    }}
                  />

                <CustomTextField
                  label="Last name"
                  type="string"
                  name="lastName"
                  backgroundcolor="#F0F0F0"
                  style={{ width: "70%" }}
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  fontSize="20"
                  borderradius="10"
                  InputLabelProps={{
                    style: { color: "#A9A9A9" },
                  }}
                />
          </div>
            <CustomTextField
              label="Company name (if company account)"
              type="string"
              name="companyName"
              backgroundcolor="#F0F0F0"
              style={{ width: "100%" }}
              value={formValues.companyName}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#A9A9A9" },
              }}
            />
        </div>
        <div className="flex gap-3.5 mb-6 mt-10">

          <CustomTextField
            label="Bank account number"
            type="number"
            name="bankAccountNumber"
            backgroundcolor="#F0F0F0"
            fullWidth
            value={formValues.bankAccountNumber}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#A9A9A9" },
            }}
          />
          <CustomTextField
            label="Sort code"
            type="text"
            name="sortCode"
            backgroundcolor="#F0F0F0"
            fullWidth
            value={formValues.sortCode}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#A9A9A9" },
            }}
            inputProps={{
              maxLength: 8, // Limit the input length to 8 characters (including dashes)
            }}
          />
       </div>

        <div className="flex flex-col gap-3.5 mt-10">
          <CustomTextField
            label="Billing address line 1"
            type="string"
            name="billingAddressLineOne"
            backgroundcolor="#F0F0F0"

            style={{ width: "100%" }}
            value={formValues.billingAddressLineOne}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: "#A9A9A9" },
            }}
          />
        <CustomTextField
          label="Billing address line 2 (optional)"
          type="string"
          name="billingAddressLineTwo"
          backgroundcolor="#F0F0F0"
          style={{ width: "100%" }}
          value={formValues.billingAddressTwo}
          onChange={handleInputChange}
          fontSize="20"
          borderradius="10"
          InputLabelProps={{
            style: { color: "#A9A9A9" },
          }}
        />

        <div className="flex flex-row gap-3.5">
            <CustomTextField
              label="City or Town"
              type="string"
              name="city"
              backgroundcolor="#F0F0F0"
              fullWidth
              value={formValues.city}
              onChange={handleInputChange}
              fontSize="20"
              borderradius="10"
              InputLabelProps={{
                style: { color: "#A9A9A9" },
              }}
            />
          <CustomTextField
            label="Post code"
            type="text"
            name="postCode"
            backgroundcolor={"#F0F0F0"}
            style={{
              width: "48%",
            }}
            value={formValues.postCode}
            onChange={handleInputChange}
            fontSize="20"
            borderradius="10"
            InputLabelProps={{
              style: { color: `${postCodeError ? "red" : "#A9A9A9"}` },
            }}
            className={`text-sm ${postCodeError ? " animate-jiggle" : ""} `}
          />
          </div>

          {showRadio && !freeTrial && (
            <>
                <CustomRadioInput
                  name="agree"
                  value="agree"
                  control={<Radio size="small" />}
                  label={
                  <div>
                    <br/>
                    <p className="leading-6">
                      By proceeding you are agreeing to the set-up of a Direct Debit.
                      Billing will occur monthly, commencing at first opportunity.
                    </p>
                  </div>
                  }
                  className="mb-5 text-xl font-normal"
                  onChange={handleInputChange}
                />
            </>
          )}
          {showRadio && freeTrial && (
              <>
                  <CustomRadioInput
                    name="agree"
                    value="agree"
                    control={<Radio size="small" />}
                    label={
                    <div>
                      <br/>
                      <p className="leading-6">
                        By proceeding you are agreeing to the set-up of a Direct Debit.
                        No charges will be taken unless you decide to upgrade to a paid plan.
                      </p>
                      </div>
                    }
                    className="mb-5 text-xl font-normal"
                    onChange={handleInputChange}
                  />
              </>
          )}
        </div>
        {status === "loading" ? (
          <div className="flex justify-center mt-5">
            <CircularProgress style={{ color: "#141831" }} />
          </div>
        ) : (
        <>
        <div className="flex flex-row gap-3.5">
          <Button
            title="Confirm"
            className={`bg-green text-2xl py-3 px-10 rounded-xl ${
              !showRadio ? "mt-5" : ""
            }`}
            disabled={btnDisabled}
          />


        <Link to={backLink} relative='path'>
          <Button
            title='Cancel'
            className='bg-green text-2xl py-3 px-10 rounded-xl'
          />
        </Link>

        </div>

          <div className="mb-5 mt-10 text-small font-normal"> Payments are handled by <a href="https://www.GoCardless.com">GoCardless Ltd</a> </div>

        </>
        )}

      </form>
    </div>
  );
};

export default PricingPlansBillingForm;
