import { useSelector } from "services";
import { selectDemographic } from "services/project";
import ChartBars from "widgets/ChartBars";

const AgeChart = () => {
  const demographic = useSelector(selectDemographic);

  return (
    <>
      <h1 className='text-3xl leading-6 mb-10'>Age Percentage</h1>
      <ChartBars age={demographic.agePercentage} />
    </>
  );
};

export default AgeChart;
