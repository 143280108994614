import pencil from "styles/img/pencil-gray.svg";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const CustomInput = ({
  label,
  value,
  type,
  name,
  arrowValue,
  onClick,
  noIcon = false,
  noArrow = false,
  inputRef,
  handlePencilClick,
  handleFocusOut,
}) => {
  let val;
  if (type === "file") {
    val = (
      <label htmlFor="file-input" className="cursor-pointer">
        <input
          type={type}
          value={value}
          name={name}
          className="hidden"
          id="file-input"
        />
        <img src={pencil} alt="pencil" />
      </label>
    );
  } else if (type === "arrow") {
    val = (
      <div className="flex items-center">
        {arrowValue ? <h3 className="text-xl mr-7">{arrowValue}</h3> : ""}
        {noArrow ? "" : <ArrowForwardIosSharpIcon />}
      </div>
    );
  } else {
    val = (
      <>
        <input
          defaultValue={value}
          type={type}
          name={name}
          className="focus:outline-none text-xl"
          ref={inputRef}
          onBlur={handleFocusOut}
        />
        {noIcon ? (
          ""
        ) : (
          <img src={pencil} onClick={handlePencilClick} alt="pencil" />
        )}
      </>
    );
  }

  return (
    <div
      className="flex border-b border-[#bcbcbc] pb-8 cursor-pointer"
      onClick={onClick}
    >
      <label className="text-xl font-bold text-[#7d7c7c] mr-auto">
        {label}
      </label>
      {val}
    </div>
  );
};

export default CustomInput;
