import { useSelector } from "services";
import { selectPlanningUrl } from "services/planning";

const ShowPlanning = () => {
  const url = useSelector(selectPlanningUrl);

  return (
    <div>
      <iframe src={url} width='100%' height='500px' title='Web Page'></iframe>
    </div>
  );
};

export default ShowPlanning;
