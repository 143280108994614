import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "services";

import WhiteCard from "common/WhiteCard";
import Login2FAForm from "forms/Login2FAForm";
import Toast from "ui/Toast";

import { login2FA } from "services/auth";

const Login2FA = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const status = useSelector((state) => state.auth.login2FAStatus);
  const error = useSelector((state) => state.auth.login2FAError);
  const userData = useSelector((state) => state.auth.loginData);
  const rememberMe = useSelector((state) => state.auth.rememberMe);

  const handleCodeChange = (evt) => {
    setCode(evt.target.value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = {
      email: userData.email,
      code,
      rememberMe,
    };
    const res = await dispatch(login2FA(data));

    if (res.payload.status === 200) navigate("/dashboard");
  };

  return (
    <>
      <WhiteCard>
        <h2 className="text-l text-center mb-5">
          Your account is protected with two-factor authentication. We've sent
          you an email. Please enter the code below
        </h2>
        <Login2FAForm
          code={code}
          handleCodeChange={handleCodeChange}
          handleSubmit={handleSubmit}
          status={status}
          error={error}
        />
        <Link className="mt-5 mb-5 text-sm font-bold" to=".." relative="path">
          Back to login
        </Link>
      </WhiteCard>
      <Toast severity="error" message={error} openToast={status === "failed"} />
    </>
  );
};

export default Login2FA;
