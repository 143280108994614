import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledStatusCheckBox } from "./CustomCheckbox";
const CustomStatusCheckboxField = (props) => {
  const { id, text, disabled, status } = props;
  return (
    <div className='flex items-center justify-center gap-5 '>
      {status === "PENDING" ? (
        <Box sx={{ display: "flex", padding: "10px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <StyledStatusCheckBox
          id={id}
          isSuccess={status === "SUCCESS"}
          disabled={disabled ? true : false}
          checked={true}
        />
      )}
      <label htmlFor={id} className='text-xl cursor-pointer'>
        {text}
      </label>
    </div>
  );
};

export default CustomStatusCheckboxField;
