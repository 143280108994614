import { Box, IconButton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import "styles/editor.css";
import "styles/print.css";

const Editor = ({
  widgetComponents,
  removeWidgetComponent,
  updateWidgetValue,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='!px-2 !py-2 flex-1'>
      {widgetComponents.map((widget, index) => (
        <Box
          id={`Box${index}`}
          key={widget.id}
          mb={7}
          mt={7}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <IconButton
            sx={{
              float: "right",
              visibility: isHovered ? "inherit" : "hidden",
            }}
            onClick={() => removeWidgetComponent(widget.id)}
          >
            <ClearIcon />
          </IconButton>

          <widget.type
            customId={widget.id}
            value={widget.value}
            onChange={(e) => updateWidgetValue(widget.id, e)}
          />
        </Box>
      ))}
    </div>
  );
};

export default Editor;
