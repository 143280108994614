import { Divider, Skeleton } from "@mui/material";
import EpcStatistic from "common/EpcStatistic";
import EpcTableFilters from "common/EpcTableFilters";
import GrayCard from "common/GrayCard";
import { filterData } from "helpers/data-tansform-functions";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import React, { useEffect, useState } from "react";
import { useSelector } from "services";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const ProjectEpc = () => {
  const { fetchProjectStatus } = useSelector((state) => state.projects);
  const epc = useSelector((state) => state.projects.projectData.epc);
  const postcode = useSelector((state) => state.projects.projectData.postcode);
  const [copiedStatistics, setCopiedStatistics] = useState([]);

  const [formValues, setFormValues] = useState({
    type: "All",
    size: "All",
    fromYear: "From Year",
    toYear: "To Year",
  });

  const handleSelect = (evt) => {
    const { name, value } = evt.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSearch = () => {
    setCopiedStatistics(
      filterData(
        epc.statistics,
        formValues.fromYear,
        formValues.toYear,
        formValues.type,
        formValues.size
      )
    );
  };

  const handleExportCsv = () => {
    const csv = Papa.unparse(copiedStatistics);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "epc_statistics_data.csv");
  };

  useEffect(() => {
    console.log("UE:[epc]")
    if (!epc?.statistics) return;
    setCopiedStatistics([...epc.statistics]);
  }, [epc]);

  return (
    <>
      <ProjectLayoutHeader header="EPC Register" />
      <Topbar />
      <EpcTableFilters
        handleSelect={handleSelect}
        handleSearch={handleSearch}
        handleExportCsv={handleExportCsv}
        formValues={formValues}
      />
      <GrayCard className="mt-8">
        {fetchProjectStatus === "idle" || fetchProjectStatus === "loading" ? (
          <Skeleton
            variant="rectangular"
            style={{ width: "100%", height: "200px" }}
          />
        ) : (
          <>
            <h1 className="font-bold text-lg mb-3">
              EPC Statistics for {postcode}
            </h1>
            {epc &&
              epc.statistics &&
              copiedStatistics.map((stat, index) => (
                <React.Fragment key={index}>
                  <Divider />
                  <div className="my-8">
                    <EpcStatistic epcData={stat} />
                  </div>
                </React.Fragment>
              ))}
          </>
        )}
      </GrayCard>
    </>
  );
};

export default ProjectEpc;
