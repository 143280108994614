export const plans = [
  {
    id: 1,
    color: "#9164DC",
    title: "Free Trial",
    subtitle: "Free",
    description: "Try out our free trial today.",
    fullDescription:
      "Try out our free trial today.",
    properties: [
           " ",
           " ",
           " ",
      "3 projects for 3 days",
    ],
    btn: "Start trial",
  },
  {
    id: 2,
    color: "#FF4B4B",
    title: "Starter",
    subtitle: "£99",
    description:
      "Get started with our basic plan and set yourself up for success.",
    fullDescription:
      "Get started with our basic plan and set yourself up for success.",
    properties: [
      "per month",
      "(£118.80 including VAT)",
      "10 projects per month",
      "",
    ],
    btn: "Choose plan",
  },
  {
    id: 3,
    color: "#2FA89F",
    title: "Intermediate",
    subtitle: "£299",
    description: "For more opportunities, you'll need our intermediate plan.",
    fullDescription:
      "For more opportunities, you'll need our intermediate plan.",
    properties: [
      "per month",
      "(£358.80 including VAT)",
      "40 projects per month",
      "",
    ],
    btn: "Choose plan",
  },
  {
    id: 4,
    color: "#7D7C7C",
    title: "Professional",
    subtitle: "Contact us",
    description:
      "Scale up your business and gain limitless sourcing with our professional plan.",
    fullDescription:
      "Scale up your business and gain limitless sourcing with our professional plan.",
    properties: [
       " ",
       " ",
       " ",
      "Unlimited projects",
    ],
    mail: "prof@propsmarts.com"
  },
];
