import PricingPlanCard from "common/PricingPlanCard";
import { useNavigate } from "react-router-dom";

const ChooseSubscription = ({ plans }) => {
  const navigate = useNavigate();

  return (
    <div className="my-12 mx-7">
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-3xl font-bold">Subscribe to a plan</h1>
      </div>
      <p className="text-xl font-bold mb-10 text-[#7d7c7c]">
        You currently haven't subscribed to a plan. Compare and subscribe below.
      </p>
      <div className="flex gap-8">
        {plans
          .filter((plan) => plan.id !== 1)
          .map((plan) => (
            <PricingPlanCard
              key={plan.id}
              plan={plan}
              className="flex-1"
              planBackgroundColor="#f1f1f1"
              handleChoosePlan={() => navigate(`create/${plan.id}`)}
            />
          ))}
      </div>
    </div>
  );
};

export default ChooseSubscription;
