import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import authSlice from "./auth";
import subscribeSlice from "./payment";
import planningSlice from "./planning";
import projectsSlice from "./project";
import propertySlice from "./property";
import feasibilitySlice from "./feasibility";
import predictedValuesSlice from "./PredictedValues"
import customerAdminSlice from "./customerAdmin"
import salesSlice from "./SalesService"
import customerSubscribeSlice from "./CustomerSubscribeService"

export const rootReducer = combineReducers({
  planning: planningSlice.reducer,
  auth: authSlice.reducer,
  subscribe: subscribeSlice.reducer,
  projects: projectsSlice.reducer,
  property: propertySlice.reducer,
  feasibility: feasibilitySlice.reducer,
  predictedValues: predictedValuesSlice.reducer,
  customerAdmin: customerAdminSlice.reducer,
  sales: salesSlice.reducer,
  customerSubscribe : customerSubscribeSlice.reducer
});

export const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {},
      }),
  });

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
