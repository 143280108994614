import GrayCard from "common/GrayCard";
import { useNavigate } from "react-router-dom";
import checkmark from "styles/img/checkmark.svg";
import Button from "ui/Button";

const UpgradeSubscriptionInfo = ({ title, body, planTitle }) => {
  const navigate = useNavigate();

  return (
    <GrayCard className="w-3/4 flex flex-col items-center justify-center">
      <div>
        <img src={checkmark} alt="check mark" />
      </div>
      <h1 className="text-4xl mt-6 mb-4 text-center">{title}</h1>
      {body ? (
        <p className="text-lg">
          You're now on the <span className="font-bold">{planTitle}</span> plan.
        </p>
      ) : (
        ""
      )}
      <div className="flex justify-end mt-10 w-full">
        <Button
          title="OK"
          className="bg-green rounded-xl py-3 px-14"
          onClick={() => navigate("/dashboard/account")}
        />
      </div>
    </GrayCard>
  );
};

export default UpgradeSubscriptionInfo;
