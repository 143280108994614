import GrayCard from "common/GrayCard";
import ProjectReportCover from "common/ProjectReportCover";
import AddProjectReportForm from "forms/AddProjectReportForm";

const ProjectReportPhase1 = ({
  formValues,
  handleInputChange,
  handleColorChange,
  handleLogoChange,
  handleImageChange,
}) => {
  return (
    <div className='flex gap-8'>
      <GrayCard className='flex-1'>
        <AddProjectReportForm
          formValues={formValues}
          handleInputChange={handleInputChange}
          handleColorChange={handleColorChange}
          handleLogoChange={handleLogoChange}
          handleImageChange={handleImageChange}
        />
      </GrayCard>
      <ProjectReportCover formValues={formValues} className='flex-1' />
    </div>
  );
};

export default ProjectReportPhase1;
