import { CircularProgress, Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomDialog from "ui/CustomDialog";

const PollingModal = (props) => {
  const {
    onClose,
    open,
    content,
    maxWidth,
    fullWidth,
    className,
    projectId,
    loading = false,
    others,
    pollingStatus,
  } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && pollingStatus) {
      const redirectTimeout = setTimeout(() => {
        navigate(`/dashboard/projects/details/${projectId}`);
      }, 300);

      return () => clearTimeout(redirectTimeout);
    }
  }, [loading, navigate]);
  return (
    <CustomDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth ? maxWidth : "sm"}
      fullWidth={fullWidth}
      {...others}
    >
      {loading && (
        <Stack direction={"row"} alignItems={"center"}>
          <h1>Pulling external data...</h1>{" "}
          <CircularProgress sx={{ padding: "10px" }} />
        </Stack>
      )}
      <div className={`flex flex-col items-start ${className}`}>{content}</div>
    </CustomDialog>
  );
};

export default PollingModal;
