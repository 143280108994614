const WhiteCard = ({ children, widthVal = 40 }) => {
  return (
    <div className="absolute bottom-2 left-6 flex flex-col items-center 2xl:bottom-9 z-30">
      <div className="bg-total-white py-[2rem] px-[1.75rem] rounded-[1.5rem] ">
        <div
          className="flex flex-col justify-center items-center"
          style={{ width: `${widthVal}ch` }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default WhiteCard;
