import { Link } from "react-router-dom";

import Button from "ui/Button";
import CustomRadioInput from "ui/CustomRadioInput";
import CustomTextField from "ui/CustomTextField";

import { CircularProgress, Radio } from "@mui/material";

const SignupForm = ({
  handleSubmit,
  formValues,
  handleInputChange,
  disabled,
  status,
  passwordError,
  error,
  userData,
  openSaaSModal,
  openPrivacyModal,
}) => {
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="flex flex-col gap-4">
        <CustomTextField
          label="Name"
          type="string"
          name="name"
          backgroundcolor="#F0F0F0"
          fullWidth
          value={formValues.name}
          onChange={handleInputChange}
          fontSize="20"
          borderradius="10"
          InputLabelProps={{
            style: { color: "#A9A9A9" },
          }}
        />
        <CustomTextField
          label="Company"
          type="string"
          name="company"
          backgroundcolor="#F0F0F0"
          fullWidth
          value={formValues.company}
          onChange={handleInputChange}
          fontSize="20"
          borderradius="10"
          InputLabelProps={{
            style: { color: "#A9A9A9" },
          }}
        />
        <CustomTextField
          label="Email"
          type="email"
          name="email"
          backgroundcolor="#F0F0F0"
          fullWidth
          value={formValues.email}
          onChange={handleInputChange}
          fontSize="20"
          borderradius="10"
          InputLabelProps={{
            style: { color: "#A9A9A9" },
          }}
        />
        <CustomTextField
          label="Password"
          type="password"
          name="password"
          backgroundcolor="#F0F0F0"
          fullWidth
          value={formValues.password}
          onChange={handleInputChange}
          fontSize="20"
          borderradius="10"
          InputLabelProps={{
            style: { color: "#A9A9A9" },
          }}
        />
      </div>
      <p
        className={`text-sm ${
          passwordError ? "text-red animate-jiggle" : "text-light-gray"
        } mb-3`}
      >
        Password must be at least 8 characters long.
      </p>
      <CustomRadioInput
        name="agree"
        value="agree"
        control={<Radio size="small" />}
        label={
          <p className="text-sm">
            I agree and approve the{" "}
            <span
              className="text-red underline decoration-1"
              onClick={openSaaSModal}
            >
              SaaS Agreement
            </span>
            <p>By signing up you also agree to our{" "}
                          <span
                            className="text-red underline decoration-1"
                            onClick={openPrivacyModal}
                          >
                            privacy notice
                          </span>
             </p>
          </p>

        }
        className="mb-1 text-xl font-normal"
        onChange={handleInputChange}
      />

      {status === "loading" ? (
        <div className="flex justify-center mt-4">
          <CircularProgress style={{ color: "#141831" }} />
        </div>
      ) : (
        <Button
          title="Sign up"
          type="submit"
          className="bg-green py-3 w-full rounded-[20px] mt-2 text-2xl"
          disabled={disabled}
        />
      )}
    </form>
  );
};

export default SignupForm;
